export default {
  getBurEventInWork(state){
    return state.work.events?.find(el => el.type === 'BUR')
  },
  getDisEventInWork(state){
    return state.work.events?.find(el => el.type === 'DIS')
  },
  getAsrEventInWork(state){
    return state.work.events?.find(el => el.type === 'ASR')
  },
  getOthEventInWork(state){
    return state.work.events?.find(el => el.type === 'OTH')
  },
  getConEventInWork(state){
    return state.work.events?.find(el => el.type === 'CON')
  },
}
