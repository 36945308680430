export default {
  setCheckDepositList(state, checkDepositList){
    state.checkDepositList = checkDepositList
  },
  setCheckListByType(state, checkListByType){
    state.checkListByType = checkListByType
  },
  setCheckDeposit(state, check){
    state.checkDeposit = check
  },
  setCheckMetadata(state, checkMetadata){
    state.checkMetadata = checkMetadata
  },
  alterCheckDeposit(state, payload){
    state.checkDeposit[payload.attribute] = payload.value
  },
  alterCheckDepositPayments(state, payload){
    if(state.checkDeposit.payments !== null){
      if( ! state.checkDeposit.payments.some( element => element.id === payload.id )){
        state.checkDeposit.payments.push(payload)
      }else{
        state.checkDeposit.payments = state.checkDeposit.payments.filter( element => element.id !== payload.id )
      }
    }
  },
  setEditedCheckList(state, payload){
    state.editedCheckList = payload
  },
}
