export default {
  global: {
    dropzone_title: 'glissez un fichier ici ou cliquez',
    dropzone_text: 'c\'est une dropzone demo qui ne fait rien pour le moment',
    asset: 'middelen',
    assets: 'middelen',
    quote: 'kostenraming',
    purchase_order: 'bestelbon',
    general_information: 'achtergrondinformatie',
    roles: 'rollen',
    permissions: 'permissies',
    company: 'firma',
    user: 'gebruiker',
    customer: 'klant',
    credit_note: 'creditnota',
    implantation: 'uitvoering',
    item: 'artikle',
    users_list: 'lijst van gebruikers',
    invoice: 'factuur',
    work: 'bestand',
    urn: 'urn',
    document: 'document',
    annex: 'bijlage',
    billing: 'facturatie',
    deceased: 'overledene',
    invoicing: 'facturatie',
    unpaid: 'onbetaald',
    card: 'kaart',
    cash: 'gled',
    payment: 'betalingen',
    death: 'overlijden',
    events: 'evenementen',
    attachments: 'bijlagen',
    documents: 'documenten',
    new_event: 'nieuw evenement',
    add_event: 'nieuw evenement toevoegen',
    yes: 'ja',
    no: 'nee',
    my_profile: 'mijn profile',
    first_name: 'voornaam',
    last_name: 'naam',
    username: 'gebruiknaam',
    password: 'waachtwoord',
    email_address: 'e-mail addressfv b',
    invoicing_title: {
      INV: 'nouvelle facture',
      PO: 'nouveau bon de commande',
      QUO: 'nouveau devis',
      CN: 'nouvelle note de crédit'
    }
  }
}

