<template>
  <BaseSimpleCard name="work-invoice" classes='col-start-2 col-span-10' >
    <template #default>
      <router-view></router-view>
    </template>
  </BaseSimpleCard>
</template>

<script>
export default {
  name: 'TheWorkInvoicingRouter',
}
</script>
