import getters from '@/store/modules/content/itemList/getters'
import mutations from '@/store/modules/content/itemList/mutations'
import actions from '@/store/modules/content/itemList/actions'

export default {
  namespaced: true,
  state() {
    return {
      listItemsIndex: [],
      listItem: {},
      listItemMetadata: {},
    }
  },
  getters,
  actions,
  mutations
}
