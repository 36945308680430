<template>
  <BaseSimpleTab :tabs="tabs" />

  <div class='mt-5'>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'TheUserInfoPage',
  data() {
    return {
      tabs: [
        {label: this.$t('global.general_information'), icon: 'info-square', routeName: 'settings_edit_user_route'},
        {label: this.$t('global.activities'), icon: 'file-alt', routeName: 'settings_user_activity_route'},
      ]
    }
  }
}
</script>
