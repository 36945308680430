<template>
  <div class='grid md:grid-cols-2 sm:grid-cols-1 gap-x-5'>
    <div class='col-start-1'>
      <BaseShowEditSelectSimple v-if="metadata?.event_type"
                                :label="$t('attributes.event_type')"
                                :placeholder="$t('attributes.event_type')"
                                name="event_type"
                                :editionMode='editionMode'
                                v-model='local_event.type'
                                @change='resetEventDataAndDispoVisibility'
                                :options="filteredEventType"
                                :errors="errors?.event_type"/>
    </div>
    <div>
      <BaseShowEditSelectSimple v-if="eventTypeHasAssetType"
                                :label="$t('attributes.asset')"
                                :placeholder="$t('attributes.asset')"
                                name="asset_id"
                                :editionMode='editionMode'
                                v-model='local_event.asset_id'
                                :options="filteredAssets"
                                :errors="errors?.asset_id"/>
    </div>
    <div class='col-start-1'>
      <BaseShowEditInput :label="$t('attributes.event_title')"
                         v-model="local_event.title"
                         :errors="errors.title"
                         :placeholder="$t('attributes.event_title')"
                         :editionMode="editionMode"/>
    </div>
    <div v-if='numberOfPeopleIsDisplayed' class='col-start-1'>
      <BaseShowEditInput :label="$t('attributes.nb_peoples')"
                         v-model="local_event.nb_peoples"
                         :errors="errors.nb_peoples"
                         :placeholder="$t('attributes.nb_peoples')"
                         type='number'
                         :editionMode="editionMode"/>
    </div>
    <div v-if='eventKindIsDisplayed' :key='local_event.event_kind_id'>
      <BaseShowEditRadioGroup name='event_kind_id'
                              :label="$t('attributes.event_kind')"
                              :options='eventKindIdOptions'
                              v-model="local_event.event_kind_id"
                              :editionMode="editionMode"
                              trackBy='id'
                              :translatable='false'
                              @change='changeDispoVisibility'
                              attribute-label='name'/>
    </div>
    <div class='col-start-1 grid grid-cols-2 gap-x-3'>
      <div id='scroll_date_from'>
        <BaseShowEditDatePicker :editionMode='editionMode'
                                :label="local_event.type === 'CON' ? $t('attributes.from'): $t('attributes.date_from')"
                                v-model='local_event.date_from'
                                class='z-10'
                                name='date_from'
                                @click='scrollToDateFrom'
                                :errors='errors.date_from'/>
      </div>
      <div v-if="[ 'CON','MAI','FOR','SEV','OTH' ].includes(local_event.type)" id='scroll_date_to'>
        <BaseShowEditDatePicker :editionMode='editionMode'
                                :label="local_event.type === 'CON' ? $t('attributes.to'): $t('attributes.date_to')"
                                v-model='local_event.date_to'
                                class='z-10'
                                name='date_to'
                                @click='scrollToDateTo'
                                :errors='errors.date_from'/>
      </div>
    </div>
    <div class='grid grid-cols-2 gap-x-3'>
      <div>
        <BaseShowEditTimeInput :label="$t('attributes.time_from')"
                               :placeholder="'10:00'"
                               name='time_from'
                               :editionMode='editionMode'
                               v-model='local_event.time_from'
                               :errors='errors?.time_from'/>
      </div>
      <div>
        <BaseShowEditTimeInput :label="$t('attributes.time_to')"
                               :placeholder="'12:00'"
                               name='time_to'
                               :editionMode='editionMode'
                               v-model='local_event.time_to'
                               :errors='errors?.time_to'/>
      </div>
    </div>
    <div v-if='timeSlotIsDisplayed' class='col-start-1 w-1/6'>
      <BaseButton class='mt-10' :tooltip='dispoButtonText' :icon='dispoButtonIcon' @click='HandleDispoButton' />
    </div>
    <div v-if='dispoVisible % 2 === 0' class='col-span-2'>
      <SelectEventTimeslot v-if='local_event.timeslots && editionMode'
                           class='my-5'
                           :asset='local_event.asset_id'
                           :type='local_event.type'
                           :eventKind='local_event.event_kind_id'
                           :options='local_event.timeslots'
                           :index='index'
                           v-model='selectedEventTimeslot' />
    </div>
    <div class='col-start-1'>
      <BaseShowEditTextArea :label="$t('attributes.remarks')"
                            :placeholder="$t('attributes.remarks')"
                            v-model="local_event.remarks"
                            :errors="errors.remarks"
                            :editionMode="editionMode" />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import moment from 'moment'

import SelectEventTimeslot from '@p/components/Event/SelectEventTimeslot'

export default {
  name: 'FormAssetEvent',
  components: {
    SelectEventTimeslot,
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true,
    },
    local_event: {
      type: Object,
      required: true,
    },
    resetEventData: {
      type: Function,
      required: true,
    },
    metadata: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    filteredAssets: {
      type: Array,
      required: true,
    },
    scrollToDateFrom: {
      type: Function,
      required: true
    },
    scrollToDateTo: {
      type: Function,
      required: true
    },
    timeSlotIsDisplayed: {
      type: Boolean,
      required: true,
    },
    index: {
      type: [String, Number, Boolean],
      required: true,
    },
    numberOfPeopleIsDisplayed: {
      type: Boolean,
      required:true
    },
    eventKindIsDisplayed: {
      type: Boolean,
      required:true
    },
    eventKindIdOptions: {
      type: Array,
      required: true
    },
    filteredEventType: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dispoVisible: 1,
      selectedEventTimeslot: null,
      today: null,
      date_to_fetch: null
    }
  },
  computed: {
    ...mapGetters({
      work: 'work/getWork'
    }),
    dispoButtonIcon() {
      return this.dispoVisible % 2 !== 0 ? 'calendar-alt' : 'compress-alt'
    },
    dispoButtonText() {
      return this.dispoVisible % 2 !== 0 ? this.$t('button.show_plural', { var: this.$t('global.availabilities') }) : this.$t('button.hide_plural', { var: this.$t('global.availabilities') })
    },
    eventTypeHasAssetType(){
      if(this.metadata?.assets_type && this.local_event?.type){
        return this.metadata?.assets_type[this.local_event?.type]?.length > 0
      }
    }
  },
  methods: {
    ...mapActions({fetchEventDisponbilitiesByOption: 'fetchEventDisponbilitiesByOption'}),
    ...mapMutations({
      alterOrPushEventInWork: 'work/alterOrPushEventInWork',
    }),
    HandleDispoButton() {
      this.dispoVisible += 1
      if (this.dispoVisible % 2 === 0) {
        this.fetchEventDisponbilitiesByOption({
          params: {
            asset_id: this.local_event.asset_id,
            type: this.local_event.type,
            week_from: moment().locale('fr').week(),
            year: moment().format('YYYY'),
            event_kind_id: this.local_event.event_kind_id ? this.local_event.event_kind_id : null
          },
          index: this.index
        })
      }
    },
    changeDispoVisibility(){
      this.dispoVisible = 1
    },
    addOtherEventIfCremation(){
      console.log('newEvents')
      this.alterOrPushEventInWork({
        type: 'ASR',
        title: this.work ? 'Remise des cendres - ' + this.work?.deceased?.first_name + ' ' + this.work?.deceased?.last_name: ''
      });

      this.alterOrPushEventInWork({
        type: 'CON',
      })

    },
    resetEventDataAndDispoVisibility(){
      console.log(this.local_event)
      this.changeDispoVisibility()
      console.log(this.local_event.type)
      if(this.local_event.type === 'CRE') {
        this.addOtherEventIfCremation()
      }
      this.resetEventData()

    }
  },
  watch: {
    selectedEventTimeslot(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== null) {
        this.local_event['date_from'] = newValue?.string_date
        this.local_event['date_to'] = newValue?.string_date
        this.local_event['time_from'] =  newValue?.string_from
        this.local_event['time_to'] = newValue?.string_to
        this.local_event['asset_id'] = newValue?.asset?.id
      }
    }
  },
}
</script>

<style scoped>

</style>
