import apiClient from '@u/apiClient'

export default {

  //#region update

  async renameWorkAttachment({commit}, payload){
    await apiClient.post(`api/work/${payload.work_id}/${payload.attachment_id}/attachment-rename`, {new_name: payload.new_name})
      .then(async response => {
        await commit('setWork', response.data.work)
      }).catch(async reason => {
        await console.error(reason)
      })
  },

  //#endregion

  //#region delete

  async deleteWorkAttachment({commit}, payload){
    await apiClient.delete(`api/work/${payload.work_id}/${payload.attachment_id}/attachment-delete`)
      .then(async response => {
        await commit('setWork', response.data.work)
      }).catch(async reason => {
        await console.error(reason)
      })
  },

  //#endregion

}
