export default {
  getMessageTextList(state){
    return state.messageTextList
  },
  getMessageText(state){
    return state.messageText
  },
  getMessageTextMetadata(state){
    return state.messageTextMetadata
  }
}
