<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="modal-content p-0 max-h-screen flex flex-col"  :class="modalClasses" open v-if="isModalVisible">

        <div class='flex'>
          <div class='flex-1 p-5'>
            <h2 class="text-xl font-bold capitalize-first">
              {{ ($t('attributes.payment'))}}
            </h2>
          </div>
          <div class='flex items-center p-5 cursor-pointer' @click="_close">

            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div>
        </div>

        <section class="flex-1 modal-body overflow-scroll">

          <InvoiceItemEditDialogItem v-for="(item, index) in this.invoice.payments" :key="index" :payment='item' class="my-2"/>


          <div class="flex flex-row justify-between bg-theme-20 p-5 mt-10">
            <p class="uppercase font-bold">{{ ($t('global.total'))}}</p>
            <p>{{ invoice.total_paid }}</p>
          </div>

        </section>

        <div class="flex flex-row justify-end py-3 px-5 bg-gray-100 gap-x-5">
          <BaseButton :title="$t('button.print')" icon='print' />
          <BaseButton :title="$t('button.add_new_m', {var: $t('attributes.payment')} )" icon='plus' @click="switchToAddPayment" />
          <BaseButton :title="$t('button.close')" icon='times' color='danger' @click="_close"/>
        </div>
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isModalVisible"></div>
    </transition>
  </teleport>
</template>

<script>
import { mapGetters } from 'vuex'

import InvoiceItemEditDialogItem from './InvoiceItemEditDialogItem'

export default {
  name: 'InvoiceItemEditDialog',
  components:{ InvoiceItemEditDialogItem },
  emits: ['close', 'backdropClicked'],
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function(value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPulsing: false
    }
  },
  computed: {
    ...mapGetters({invoice: 'invoicing/getInvoice' }),
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    }
  },
  methods: {
    switchToAddPayment(){
      this.isPulsing = false
      this.$emit('switchToPayment')
    },
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    }
  },
  watch: {
    isPulsing: function(currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    }
  }
}
</script>

<style scoped>

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index : 1000;
  overflow : hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width : 90%;
  }
}

@media only screen and (min-width:  577px){
  dialog.small {
    width : 20%;
    max-width : 300px;
    scroll-behavior: auto;
  }
  dialog.medium {
    width : 30%;
    max-width : 500px;
  }
  dialog.large {
    width : 50%;
    max-width : 700px;
  }
  dialog.x-large{
    width : 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active{
  animation: modal 0.3s ease-in ;
}

.modal-leave-active{
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active{
  animation: backdrop 0.3s ease-in ;
}
.backdrop-leave-active{
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation : pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform : scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,0.3);
  }
}
</style>
