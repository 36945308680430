<template>
    <ActivityTable v-if='assetActivity && assetActivity.length > 0' :activities="assetActivity" />
  <div v-else class='mt-10 pt-10 flex justify-center font-semibold text-gray-500'>
    {{$t('global.no_activity')}}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheAssetActivityPage',
  computed: { ...mapGetters({assetActivity: 'asset/getAssetActivity'}) },
  methods:{ ...mapActions({fetch: 'asset/fetchAssetActivitiesByAssetID'})},
  beforeMount() {
    this.fetch({asset_id: this.$route.params.id})
  }
}
</script>

