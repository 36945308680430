import ability from '@u/ability'

export default {
    setAuth(state, payload) {
        state.auth = payload.auth
        if (typeof payload.expiration !== 'undefined') {
            state.sessionExpiration = payload.expiration
        }
        if (payload.remember) {
            state.remember = payload.remember
        }
        state.errors = payload.errors
    },
    setErrors(state, errors) {
        state.errors = errors
    },

    setPermissions(state, payload) {
        const permissions = state.auth.permissions

        const arrayAbilities = []
        permissions.map(perm => arrayAbilities.push({action: perm, subject: 'all'}))
        ability.update([])
        ability.update(arrayAbilities)
    }
}
