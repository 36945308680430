export default {
  getAssetIndex(state) {
    return state.assetList
  },
  getSelectedAsset(state) {
    return state.asset
  },
  getAssetMetadata(state) {
    return state.assetMetadata
  },
  getAssetActivity(state){
    return state.assetActivity
  }
}
