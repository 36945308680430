<template>
  <SettingsPageFull>

    <template #cta>
      <BaseButton class="absolute right-12 block mr-10"
                  v-if="$can('manage_content')"
                  color="warning"
                  :icon="editionMode ? 'times' : 'pen'"
                  @click="getSettingsAndChangeEditionMode"
                  :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
    </template>

    <template #body>
      <div class='space-y-8'>
        <BaseCardSection>
          <template #default>
            <div class='grid grid-cols-12 gap-5 mx-12'>
              <div  class='col-span-12 xl:col-span-6'>

                <BaseDocShowEditSelectMultiple v-model="settings.available_docs"
                                               :editionMode="editionMode"
                                               :metadata="metadata"
                                               :required='true'
                                               :errors="errors"/>

              </div>
              <div  class='col-span-12 xl:col-span-6'>
                <BaseShowEditInput :label="$t('attributes.default_deadLine')"
                                   :placeholder="$t('attributes.default_deadLine')"
                                   v-model="settings.default_deadLine"
                                   type='number'
                                   parseType='int'
                                   :min="1"
                                   :max="9999999"
                                   :editionMode="editionMode"
                                   :errors="errors.simultaneous_cremation"
                                   :required="true" />
              </div>
            </div>
          </template>
        </BaseCardSection>
      </div>
      <div class='flex justify-end mt-12 mx-12'>
        <BaseButton v-if="editionMode"
                    :isElevated='true'
                    icon='paper-plane'
                    @click='submitForm'
                    :title="$t('button.save_plural', {var: $t('global.invoicing_settings')})" />
      </div>
    </template>
  </SettingsPageFull>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InvoicingInfosTabComponent',
  data() {
    return {
      editionMode: false
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      metadata:'settings/getSettingsMetadata',
      errors: 'auth/getErrors',
    }),
    permission(){return !this.$can('manage_settings')},
  },
  methods:{
    ...mapActions({
      fetchInvoicingSettings: 'settings/fetchInvoicingSettings',
      updateInvoicingSettings: 'settings/updateInvoicingSettings',
    }),
    submitForm(){
      this.updateInvoicingSettings({
        invoicingSettings: this.settings,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.updated_plurals', {var: this.$t('global.invoicing_settings')}) , {className: 'toastify-content success'})
          this.editionMode = false
        }
      })
    },
    getSettingsAndChangeEditionMode() {
      if(this.editionMode){
        this.fetchInvoicingSettings()
      }
      this.editionMode = !this.editionMode
    },
  },
  beforeMount() {
    this.fetchInvoicingSettings()
  }
}
</script>
