<template>
  <teleport to='body' class="overflow-scroll">
    <transition name="modal" class="overflow-scroll">
      <dialog class="modal-content p-0 max-h-screen flex flex-col overflow-visible"  :class="modalClasses" open v-if="isModalVisible">
        <div class='flex overflow-visible'>
          <div class='flex-1 p-5'>
            <h2 class="text-xl font-bold capitalize-first">
<!--              {{ ($t('attributes.payment'))}}-->
            </h2>
          </div>
          <div class='flex items-center p-5 cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div>
        </div>
        <section class="flex-1 modal-body">
          
          <div v-if="form === 'starting_form' || form === 'ending_form'">
            <div v-for='(question, index) in this.form === "starting_form" ? gWorkTask.starting_form : gWorkTask.ending_form' :key='index' class='grid grid-cols-3 h-32 overflow-visible'>

              <DynamicFormInput :question="question" :questionIndex='index' @questionValue='getQuestionValue' class="overflow-visible" />

            </div>
          </div>
          <div v-else>
            <BaseInput class='col-start-2'
                       :label="$t('attributes.awaiting_reason')"
                       :placeholder="$t('attributes.awaiting_reason')"
                       name="awaiting_reason"
                       :disabled="permission"
                       :required="true"
                       v-model="gWorkTask.awaiting_reason"/>
          </div>

        </section>

        <div class="flex flex-row justify-end py-3 px-5 bg-gray-100 gap-x-5">
          <BaseButton :title="$t('button.confirm')" icon='paper-plane' @click="submitForm"/>
          <BaseButton :title="$t('button.close')" icon='times' color='danger' @click="_close"/>
        </div>
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isModalVisible"></div>
    </transition>
  </teleport>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'WorkTaskStatusChangeDialog',
  emits: ['close', 'backdropClicked'],
  props: {
    task:{},
    form:{},
    nextStatus: {},
    isModalVisible: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function(value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPulsing: false,
      test: [{"type": 'input', "options": [], "question": "ah ?"},
            {"type": 'textArea', "options": [], "question": "oh ?"},
            {"type": 'select', "options": [{id: 1, name:'un'}, {id: 2, name: 'deux'}], "question": "oui"},
            {"type": 'radio', "options": [{id: 1, name:'un'}, {id: 2, name: 'deux'}], "question": "oui"}],
    }
  },
  computed: {
    ...mapGetters({gWorkTask: 'work/getSelectedWorkTask'}),
    permission(){
      return !this.$can('manage_work')
    },
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    }
  },
  methods: {
    ...mapActions({updateWorkTask: 'work/updateWorkTask', updateWorkTaskStatus: 'work/updateWorkTaskStatus'}),
    ...mapMutations({alterWorkTaskQuestionValue: 'work/alterWorkTaskQuestionValue'}),
    getQuestionValue({attribute, value, index}){
      this.alterWorkTaskQuestionValue({form: this.form, index: index, value: value})
    },
    getInputValue({attribute, value}){
      this.gWorkTask.awaiting_reason = value
    },
    submitForm(){
      // this.$h.toastify('submit form called successfully', { className: 'toastify-settings success' })
      this.updateWorkTask({
        id: this.gWorkTask.id,
        task: this.gWorkTask,
        successCallback: () => {
          this.updateWorkTaskStatus({
            id: this.gWorkTask.id,
            new_status: this.nextStatus,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.updated_f', { var: this.$t('attributes.task') }), { className: 'toastify-settings success' })
            }
          })
          this._close()
        }
      })
    },
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    }
  },
  watch: {
    isPulsing: function(currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    }
  }
}
</script>

<style scoped>
/*
    - backdrop
    - dialog
    - dialog : responsive
    - animations (usage)
    - animations (declaration)
 */

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index : 1000;
  overflow : hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width : 90%;
  }
}

@media only screen and (min-width:  577px){
  dialog.small {
    width : 20%;
    max-width : 300px;
    scroll-behavior: auto;
  }
  dialog.medium {
    width : 30%;
    max-width : 500px;
  }
  dialog.large {
    width : 50%;
    max-width : 700px;
  }
  dialog.x-large{
    width : 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active{
  animation: modal 0.3s ease-in ;
}

.modal-leave-active{
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active{
  animation: backdrop 0.3s ease-in ;
}
.backdrop-leave-active{
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation : pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform : scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,0.3);
  }
}
</style>

