<template>
  <BasePageFull>
    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','book-open']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.note_book') }}</span>
      </div>
    </template>
    <template #cta>
      <BaseButton color='primary' icon='plus'
                  :title="$t('button.add_new_m', {var: $t('global.note')})"
                  @click="openForm"/>
    </template>
    <template #body>
      <NoteFormDialog
          ref="eventModal"
          :isOpen="isOpen"
          size="x-large"
          :note_load_id="note_load_id"
          @backdropClicked='openForm'
          @close="closeDialogNote"
      />

      <BaseSimpleCard name='note' title='' classes='col-span-12 h-screen' :borderedHeader="false">
        <template #default>
          <!--#region filtres -->

          <div class='flex space-x-5 mb-5'>
            <div class='flex-1 flex flex-col space-y-2'>

              <BaseSelectMultiple v-if="metadata.note_types"
                                  :label="$t('attributes.type')"
                                  :placeholder="$t('attributes.type')"
                                  name='type_id'
                                  :value="null"
                                  :options='metadata.note_types'
                                  @setSelected.self='handleFilterTable'
              />



              <BaseSearchInput
                               groupClass="mt-3"
                               @typeInput="filterList"
              />

            </div>
            <div class='flex-1 flex flex-col space-y-2'>

              <div class='flex flex-col'>
                <label class="form-label mt-3 capitalize-first">{{ $t('attributes.hour') }}&nbsp;</label>
                <litepie-datepicker
                    i18n="fr"
                    :placeholder="$t('global.from_to')"
                    separator=" - "
                    :formatter="date_formatter"
                    :shortcuts="false"
                    v-model="emission_date"
                />
              </div>



              <div class='flex justify-end pt-3 space-x-3'>

                <div class="dropdown">
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1">

                      <div class="p-2">
                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                          <i data-feather="printer" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> Print </a>
                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                          <i data-feather="external-link" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> Excel </a>
                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                          <i data-feather="file-text" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> CSV </a>
                        <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                          <i data-feather="archive" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> PDF </a>
                      </div>
                    </div>
                  </div>
                </div>

                <BaseButton color="secondary"
                            :isoutline="true"
                            icon="sync"
                            @click="this.$emit('refresh-click')"
                            scopedClasses=""
                            :tooltip="$t('button.refresh')"
                            :title="null"/>

              </div>

            </div>
          </div>
          <!--#endregion -->

          <!--#region table -->
          <div class="overflow-x-auto">
            <table class="table table--sm">
              <thead class="bg-gray-50">
              <tr class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <th scope="col">
                  Title
                </th>
                <th scope="col">Description</th>
                <th scope="col">Dossier</th>
                <th scope="col">Date</th>
                <th scope="col">Type</th>
                <th scope="col">Constatataire</th>
              </tr>
              </thead>
              <tbody class="bg-gray-50">
              <tr
                  v-for="(note, idx) in filteredNotes" :key="idx"
                  @click="openForm(note.id)"
                  :class="idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  class="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
                <td>{{ note.title }}</td>
                <td v-html="note.description"></td>
                <td>{{ note.work_title }}</td>
                <td>{{ note.date }}</td>
                <td>{{note.type_string}}</td>
                <td>{{note.reporter_string}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <!--#endregion -->

        </template>
      </BaseSimpleCard>

    </template>
  </BasePageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import LitepieDatepicker from "litepie-datepicker";
import moment from 'moment';
import NoteFormDialog from "./NoteFormDialog"

export default {
  name: "NoteIndex",
  components: {
    LitepieDatepicker,
    NoteFormDialog,
  },
  data() {
    return {
      search:'',
      isOpen: false,
      note_load_id: null,
      searchTerms: '',
      filter: {},
      emission_date: '',
      date_formatter: {
        date: 'DD/MM/YYYY',
        month: 'MM'
      },
    }
  },
  computed: {
    ...mapGetters({
      noteList: 'note/getNoteIndex',
      note: "note/getNote",
      metadata: "note/getNoteMetadata"
    }),
    filteredNotes() {
      let temp_notes = this.noteList

      if (this.searchTerms.length >= 1) {
        temp_notes = temp_notes.filter((model) => {
          let search = this.searchTerms.length ? this.searchTerms.toLowerCase() : '';
          var values = this.filtrableColumns;

          var flag = false
          values.forEach((val) => {
            if (model[val] && model[val].toLowerCase().indexOf(search.toLowerCase()) > -1) {
              flag = true;
              return;
            }
          })
          if (flag) return model
        });
      }
      if(this.filter.type_id) {
        temp_notes = temp_notes.filter((model) => {
          return this.filter.type_id.find((type) => {
            return type.id === model.type
          })

        })
      }
      if(this.emission_date !== '') {
        temp_notes = temp_notes.filter((model) => {
          const model_date = moment(model.date, 'DD/MM/YYYY').add(1, 'minutes')
          const search_start = moment(this.emission_date[0], 'DD/MM/YYYY').startOf('day')
          const search_end = moment(this.emission_date[1], 'DD/MM/YYYY').endOf('day')
          return model_date.isBetween(search_start, search_end);
        });
      }

      return temp_notes
    },
    filtrableColumns() {
      return ['title','description', 'work_title']
    },
  },
  methods: {
    ...mapActions({
      fetchNotesIndex: 'note/fetchNoteIndex',
      fetchNewNote: 'note/fetchNewNote',
    }),
    openForm(id) {
      if (id) {
        this.note_load_id = id
      }
      this.isOpen = !this.isOpen;
    },
    closeDialogNote() {
      this.isOpen = !this.isOpen;
    },
    filterList(terms) {
      this.searchTerms = terms
    },
    handleFilterTable({attribute, value}) {
      let temp_filter = this.filter
      if (value.length === 0) {
        delete temp_filter[attribute];
        this.filter = temp_filter
      } else {
        this.filter = {...this.filter, [attribute]: value}
      }
    }
  },
  beforeMount() {
    this.fetchNotesIndex()
  }
}
</script>
