import apiClient from '@/utils/apiClient'
import router from '@/router'

let base_errors = {}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {

    //region fetch
    async fetchAllCustomer({ commit }){
        await apiClient.get('api/customer')
            .then(response => {
                commit('setCustomerList',  response.data.customers)
                commit('setCustomerMetadata', response.data.metadata)
            })
            .catch(async reason => {
            await commit('fetchAllCustomer', reason)
            })
    },

    async fetchCustomerByID({ commit }, payload){
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/customer/${payload.id}`)
            .then(async response => {
                await commit('setCustomer', response.data.customer )
                await commit('setCustomerMetadata', response.data.metadata )
            })
            .catch(async reason => {
                await commit('fetchCustomerByID', reason)
            })
    },
    async fetchNewCustomer({commit}){
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get('api/model/new?model=customer')
            .then(response => {
                commit('setCustomer', response.data.customer )
                commit('setCustomerMetadata', response.data.metadata )
             })
            .catch(async reason => {
                await commit('fetchNewCustomer', reason)
            })

    },
    async fetchCustomerActivitiesByCustomerID({commit}, payload){
      await apiClient.get(`api/customer/fetch-activities/${payload.customer_id}`)
          .then(async response => {
              await commit('setCustomerActivity', response.data.activities)
          })
          .catch(async reason => {
              await console.error('fetchCustomerActivitiesByCustomerID', reason)
          })
    },
    //endregion

    //region store
    async storeNewCustomer({ commit }, payload) {
        console.log(payload)
        await apiClient.post('api/customer', payload.customer)
            .then(response => {
                if(response.status === 201){
                  processSuccessCallback(payload)
                }
            })
            .catch(async reason => {
                console.error('storeNewCustomer', reason)
            })
    },
    async storeNewCustomerUser({ commit,dispatch }, payload) {
        await apiClient.post('api/user', payload.user)
            .then(response => {
                if(response.status === 201){
                    processSuccessCallback(payload)
                    dispatch('fetchCustomerByID', {id: response.data.user.customer_id  })
                }
            })
            .catch(async reason => {
                console.error("storeNewCustomerUser reason", reason)
            })
    },
    //endregion

    //region update
    async updateCustomer({ commit }, payload) {
        console.log(payload)
        await apiClient.put(`api/customer/${payload.id}`, payload.customer)
            .then(response => {
            if(response.status === 200){
                processSuccessCallback(payload)
            }
            })
            .catch(reason => {
          console.error('updateCustomer', reason)
        })
    },

    async updateCustomerUser({ commit,dispatch }, payload) {
        await apiClient.put(`api/user/${payload.id}`, payload.user)
            .then(response => {
                if(response.status === 201){
                  processSuccessCallback(payload)
                  dispatch('fetchCustomerByID', {id: response.data.user.customer_id  })
                }
            })
            .catch(async reason => {
            await commit('updateCustomerUser', reason)
        })
    },
    //endregion

    //region delete
    async deleteCustomerById({commit}, payload) {
        await apiClient.delete(`api/customer/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                  commit('setCustomerList',  response.data.customers)
                  commit('setCustomerMetadata', response.data.metadata)
                  processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
        console.error('deleteCustomerById', reason)
      })
    },
    //endregion

    //region undoDelete
    async undoDeleteCustomerById({commit}, payload) {
        await apiClient.get(`api/customer/undo-delete/${payload.id}`)
            .then(async response => {
              if(response.status  === 200 ){
                commit('setCustomerList',  response.data.customers)
                commit('setCustomerMetadata', response.data.metadata)
                processSuccessCallback(payload);
              }
            })
            .catch(async reason => {
            console.error('undoDeleteCustomerById', reason)
        })
    },
    //endregion
}
