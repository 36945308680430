<template>

  <div class="">
    <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md" @click.prevent="switchMode">
      <font-awesome-icon v-if="!darkModeIconSwitcher" :icon='["fal","moon"]' class='mr-2' />
      <font-awesome-icon v-else :icon='["fal","sun"]' class='mr-2' />
      DarkMode
    </a>
  </div>

</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheDarkModeSwitcher2',
  computed: {
    ...mapGetters('darkMode', ['darkMode']),

    darkModeIconSwitcher(){
      return this.darkMode
    }
  },
  methods: {
    ...mapActions('darkMode', ['setDarkMode']),
    setDarkModeClass() {
      this.darkMode
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    },
    // setDarkModeClass() {
    //   this.darkMode
    //     ? cash('html').addClass('dark')
    //     : cash('html').removeClass('dark')
    // },
    switchMode() {
      this.setDarkMode(!this.darkMode)
      this.setDarkModeClass()
    }
  },
  mounted() {
    this.setDarkModeClass()
  }
}
</script>
