<template >
  <BaseSimpleCard :borderedHeader="false" classes='col-span-12 h-screen' name='user body' title=''>
    <template #default>
      <div class="flex justify-between p-10 mb-10">
        <h1 class="text-2xl font-bold mt-4 capitalize-first">{{ $t('global.invoicing_title.CR') }}</h1 >
        <BaseButton
          :title="$t('button.back')"
          color='dark'
          icon='long-arrow-left'
          @click="this.$router.go(-1)"
        />
      </div >
      <div class="mx-12 box px-6 py-5 mt-4 mb-12 container mx-auto py-20 w-2/3">
        <div class=" m-5">
          <div class="text-sm text-gray-500 capitalize-first">période prise en compte</div >
          <litepie-datepicker
            v-model="due_date"
            :errors="errors.period"
            :formatter="date_formatter"
            :placeholder="$t('global.from_to')"
            :shortcuts="false"
            i18n="fr"
            separator=" - "
          />
          <!--                {{typeof errors}}-->
          <div v-if="errors !== ''" class="text-red-700 text-xs">{{ errors }}</div >
        </div >

        <div class="flex flex-row-reverse mt-8 mr-5">
          <BaseButton
            color='primary'
            icon=''
            title="Générer le document"
            @click="submitForm"
          />
        </div >
      </div >
    </template >
  </BaseSimpleCard >

</template >

<script >
import LitepieDatepicker from "litepie-datepicker";
import { mapGetters } from "vuex";

export default {
  name: "TheReportPlanning",
  components: {
    LitepieDatepicker
  },
  data() {
    return {
      due_date: null,
      date_formatter: {
        date: 'DD/MM/YYYY',
        month: 'MM'
      },
      errors: ''
    }
  },
  methods: {
    submitForm() {

      if(this.due_date.startDate !== '' && this.due_date.endDate !== '') {
        this.$router.push({name : 'report_planning_document_route', params: {start_date: this.due_date.startDate, end_date: this.due_date.endDate}})
        this.errors = ''
      } else {
        this.errors = 'Veuillez selectionner une date valide'
      }
    }
  },
}
</script >

