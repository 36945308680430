<template>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditSelectSimple v-if="metadata?.events?.death_place_type"
                              :label="$t('attributes.death_place')"
                              :placeholder="$t('attributes.death_place')"
                              name="death_place_type"
                              :editionMode='editionMode'
                              v-model='work.deceased.death_place_type'
                              :defaultValue="metadata?.events?.death_place_type?.filter(el => el.id === work.deceased.death_place_type)"
                              :options="metadata?.events?.death_place_type"
                              :errors="errors?.group"/>
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <div v-if='computedDeceased && computedDeceased.death_place_type === 13'>
      <BaseShowEditSelectAjax
                              :editionMode='editionMode'
                              url='api/hospital-for-select'
                              :label="$t('attributes.hospital')"
                              v-model='computedDeceased.death_place_id'
                              name='death_place_id'
                              trackBy='id'
                              attributeLabel='title'
                              :defaultAjax='deathPlaceBaseValue'/>
    </div>
    <div v-if='computedDeceased && computedDeceased.death_place_type === 14'>
      <BaseShowEditSelectAjax
                              :editionMode='editionMode'
                              url='api/rest-home-for-select'
                              :label="$t('attributes.rest_home')"
                              v-model='computedDeceased.death_place_id'
                              name='death_place_id'
                              trackBy='id'
                              attributeLabel='title'
                              :defaultAjax='deathPlaceBaseValue'/>
    </div>
    <div v-if='computedDeceased && computedDeceased.death_place_type === 15'>
      <BaseShowEditInput :label="$t('attributes.other')"
                         v-model="computedDeceased.death_place"
                         :errors="errors.deceased ? errors.deceased.death_place : []"
                         :placeholder="$t('attributes.death_place')"
                         :editionMode="editionMode"
                         :required="true" />
    </div>
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditDatePicker :editionMode='editionMode'
                            :label="$t('attributes.death_date')"
                            :required='true'
                            v-model='computedDeceased.death_date'
                            name='death_date'
                            :errors='errors.deceased ? errors.deceased.death_date : []'/>
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.death_post_code')"
                       v-model="computedDeceased.death_post_code"
                       :errors="errors.deceased ? errors.deceased.death_post_code : []"
                       :placeholder="$t('attributes.death_post_code')"
                       name='death_post_code'
                       :editionMode="editionMode"
                       :required="true" />
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.death_city')"
                       v-model="computedDeceased.death_city"
                       :errors="errors.deceased ? errors.deceased.death_city : []"
                       :placeholder="$t('attributes.death_city')"
                       :editionMode="editionMode"
                       name='death_city'
                       :required="true" />
  </div>
  <div class='col-span-12 xl:col-span-6'>
<!--    Micky - 28/02/2022: on laisse une grid vide pour le placement des inputs-->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WorkFormDeceasedDeath',
  props: {
    computedDeceased: {type: Object, required: true},
    editionMode: {type: Boolean, required: true},
  },
  computed: {
    ...mapGetters({work: 'work/getWork', metadata: 'work/getWorkMetadata', errors: 'auth/getErrors'}),
    deathPlaceBaseValue(){
      if (this.computedDeceased.death_place_id && this.computedDeceased.death_place_type) {
        switch(this.computedDeceased.death_place_type){
          case 13:
             return this.computedDeceased.death_place_id && this.computedDeceased.hospital_death ? { id: this.computedDeceased.death_place_id, title: this.computedDeceased.hospital_death.nom_formated } : {}
            break
          case 14:
            return this.computedDeceased.death_place_id && this.computedDeceased.rest_home_death ? { id: this.computedDeceased.death_place_id, title: this.computedDeceased.rest_home_death.nom_formated } : {}
            break
          case null:
          default:
            return {}
            break
        }
      } else {
        return {}
      }
    },
  }
}
</script>
