import getters from '@/store/modules/stats/getters'
import mutations from '@/store/modules/stats/mutations'
import actions from '@/store/modules/stats/actions'

export default {
  namespaced: true,
  state() {
    return {
      statsBlock: [],
      metadata:[]
    }
  },
  getters,
  actions,
  mutations
}
