import apiClient from '@u/apiClient'

export default {

    //region Fetch
    async fetchAccountingCodeList({commit}) {
        await apiClient.get('api/accountingCode')
            .then(async response => {
                commit('setAccountingCodeList', response.data.accounting_codes)
            })
            .catch(async reason => {
                console.error('fetchAccountingCodeList', reason)
            })
    },

    //endregion

    //region store
    async storeAccountingCode({commit}, payload){
        await apiClient.post('api/accountingCode', payload.accountingCode)
            .then(async response => {
                if(response.status === 200){
                    commit('setAccountingCodeList', response.data.accounting_codes)
                    if (payload.successCallback) {
                        payload.successCallback()
                    }
                }
            })
            .catch(async reason => {
                console.error('storeAccountingCode', reason)
            })
    },
    //endregion
}
