<template >
  <BaseSimpleCard
    :button-back-left='true'
    :button-back-left-route="getRouteBackLeft()"
    title="Compte rendu"
  >
    <template #cta>
      <div class='flex flex-row space-x-2'>

        <button class='btn btn-primary flex space-x-2' @click='mReloadIFrame'>
          <font-awesome-icon :icon='["fal","sync"]' />
        </button >
<!--        <button class='btn btn-primary flex space-x-2' @click='saveAsAttachment'>-->
<!--          <font-awesome-icon :icon='["fal","arrow-square-down"]' />-->
<!--        </button >-->
      </div >

    </template >

    <template #default>
      <div class='flex h-full'>
        <IFramePdf ref='iframe_doc' :src='cUrlDocument' />
      </div >
    </template >
  </BaseSimpleCard >

</template >

<script >
import VuePdfEmbed from 'vue-pdf-embed'
import cash from 'cash-dom/dist/cash'
import { mapActions, mapGetters } from "vuex";


export default {
  name: "ReportPlanningDocument",
  components: {
    VuePdfEmbed
  },
  computed: {
    ...mapGetters({ auth: 'auth/getAuth' }),
    cUrlDocument() {

      const start_date = this.$route.params.start_date
      const end_date = this.$route.params.end_date


      let baseURL
      let filter = {}
      if (localStorage.getItem('url') !== null) {
        baseURL = localStorage.getItem('url')
      }
      else {
        baseURL = window.location.protocol + "//" + window.location.host + '/';
      }
      filter['start_date'] = start_date
      filter['end_date'] = end_date
      filter['responsible'] = this.auth.full_name
      filter['lang'] = this.auth.language

      return baseURL + 'api/planning/report-planning/?filter=' + JSON.stringify(filter)
    }
  },
  methods: {
    ...mapActions({
      saveDocumentAsAttachment: "document/saveDocumentAsAttachment"
    }),
    getRouteBackLeft() {
      return { name: 'report_planning_route' };
    },
    mReloadIFrame() {
      console.log(cash('iframe')[0].src)
      return cash('iframe')[0].src = cash('iframe')[0].src
    },

  },

}
</script >


