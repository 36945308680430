import getters from '@/store/modules/settings/getters'
import mutations from '@/store/modules/settings/mutations'
import actions from '@/store/modules/settings/actions'

export default {
  namespaced: true,
  state() {
    return {
      settings: {},
      systemSettings: {},
      settingsMetadata: {},
      settingsEvent_kinds: {},
      packageVersion: process.env.PACKAGE_VERSION || 'not found',
    }
  },
  getters,
  actions,
  mutations
}
