<template>
  <main class="relative pt-6 flex-1 min-h-full flex">
    <div class="bg-white rounded-lg shadow overflow-hidden flex-1 min-h-full">
      <div class="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside class="py-6 md:col-span-3 lg:col-span-2">
          <settings-nav />
        </aside>
        <div class="md:col-span-9 lg:col-span-10 overflow-scroll">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex'

import SettingsNav from './nav/TheSettingsNav'

export default {
  name: 'TheSettingsPage',
  components: {
    SettingsNav
  },
  methods: {
    ...mapActions('sideMenu', ['collapse'])
  },
}
</script>

