<template >
  <teleport to='body'>
    <transition name="modal">
      <dialog v-if="isOpen" :class="modalClasses" class="modal-content p-0 max-h-screen flex flex-col" open>
        
        <!--#region header -->
        <div class='flex p-5 items-center'>
          <div class='w-3/5 text-2xl font-bold'>{{ document?.name }}</div >
          <div class='w-2/5 flex justify-end cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div >
        </div >
        <!--#endregion -->
        
        <section class="flex-1 modal-body overflow-scroll pt-0">
          <div class="space-y-6 mb-8 px-12 sm:space-y-5 ">
            <template v-for="(formItem, index) in document?.form" v-if="cFormAttestationUrn" :key="index">
             
              <DynamicFormInput
                v-if="['receiver_type', 'person_type'].includes(formItem.name)"
                :defaultValue="formItem.value"
                :question="formItem"
                :questionIndex="index"
                :vertical_align="formItem.name !== 'receiver_type'"
                ajax-attribute-label='title'
                ajax-track-by='title'
                radioTrackBy="id"
                @questionValue="setQuestionValue"
              />
              <DynamicFormInput
                v-if="document.form[0]?.value === 2 && ['receive_name', 'receiver_family_link', 'receiver_adresse', 'receiver_phone'].includes(formItem.name)"
                :defaultValue="formItem.value"
                :question="formItem"
                :questionIndex="index"
                ajax-attribute-label='title'
                ajax-track-by='title'
                radioTrackBy="id"
                @questionValue="setQuestionValue"
              />
              <BaseSelectSimpleAjax
                v-if="document.form[0]?.value === 1 && formItem.name === 'customer' "
                :key="formItem"
                v-model="formItem.value"
                :label="formItem.question"
                :name="formItem.name"
                :url='formItem.url'
                :default-ajax="cCustomer(index, formItem)"
                attributeLabel="name"
                trackBy="id"
                @setSelected="getInputValue"
              />
            </template >
            <template v-for="(formItem, idx) in document?.form" v-else :key="idx">
              <DynamicFormInput
                :defaultValue="formItem.value"
                :question="formItem"
                :questionIndex="idx"
                ajax-attribute-label='title'
                ajax-track-by='title'
                radioTrackBy="id"
                @questionValue="setQuestionValue"
              />
            </template >
            
            <div class="flex justify-center ">
              <BaseButton :title="$t('button.send')" color='primary' icon='paper-plane' @click="submitFormParameter" />
            </div >
          
          </div >
        </section >
      </dialog >
    </transition >
    
    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div v-if="isOpen" class="backdrop" @click="_backdropClicked"></div >
    </transition >
  </teleport >
</template >

<script >
import { mapActions, mapGetters, mapMutations } from "vuex";


export default {
  name: "WorkFormDocumentDialog",
  props: {
    isOpen: { type: Boolean, required: true, },
    document: { required: true },
    headerTitle: { type: String, required: false },
    size: {
      type: String, required: false, default: 'x-large', validator: function (value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: { type: Boolean, required: false, default: false }
  },
  computed: {
    ...mapGetters({
      work: 'work/getWork',
      customer: 'customer/getSelectedCustomer',
      errors: 'auth/getErrors'
    }),
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    },
    
    cFormAttestationUrn() {
      return this.document?.name === 'Attestation de remise de l\'urne'
    }
  },
  methods: {
    ...mapMutations({ processAlterDocumentQuestionValueMutation: 'document/alterDocumentQuestionValue' }),
    ...mapActions({ processFetchCustomerAction: 'customer/fetchCustomerByID' }),
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    },
    cCustomer(index, formItem) {
      if (formItem.name === 'customer') {
        if (this.work) {
          if(this.document.form[index].value === undefined || this.document.form[index].value === '') {
            this.document.form[index].value = this.work.customer_id
          }
          return {
            id: this.work.customer_id,
            name: this.customer.name
          }
        }
      }
    },
    setQuestionValue({ attribute, index, value }) {
      this.processAlterDocumentQuestionValueMutation({ index: index, value: value, attribute: attribute })
    },
    submitFormParameter() {
      this.$router.push({ name: 'work_form_view_document_route', params: { document_id: this.document.id } })
      this.$emit('close')
    },
  },
  watch: {
    isPulsing: function (currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    },
  },
  mounted() {
    this.processFetchCustomerAction({ id: this.work.customer_id })
  }
  
}
</script >

<style scoped>
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 577px) {
  dialog.small {
    width: 20%;
    max-width: 300px;
    scroll-behavior: auto;
  }
  
  dialog.medium {
    width: 30%;
    max-width: 500px;
  }
  
  dialog.large {
    width: 50%;
    max-width: 700px;
  }
  
  dialog.x-large {
    width: 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active {
  animation: modal 0.3s ease-in;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active {
  animation: backdrop 0.3s ease-in;
}

.backdrop-leave-active {
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation: pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style >


