<template>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditSelectSimple v-if="metadata?.deceased?.civilities"
                              :label="$t('attributes.civility')"
                              :placeholder="$t('attributes.civility')"
                              name="civility"
                              :editionMode='editionMode'
                              v-model='work.deceased.civility'
                              :defaultValue="metadata?.deceased?.civilities.filter(el => el.id === work.deceased.civility)"
                              :options="metadata?.deceased?.civilities"
                              :errors="errors?.civility"/>
  </div>
  <div class='col-span-12 xl:col-span-6 xl:col-start-1'>
    <BaseShowEditInput :label="$t('attributes.first_name')"
                       v-model="computedDeceased.first_name"
                       :errors="errors.deceased ? errors.deceased.first_name : []"
                       :placeholder="$t('attributes.first_name')"
                       :editionMode="editionMode"
                       :required="true" />
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.last_name')"
                       v-model="computedDeceased.last_name"
                       :errors="errors.deceased ? errors.deceased.last_name : []"
                       :placeholder="$t('attributes.last_name')"
                       :editionMode="editionMode"
                       :required="true" />
  </div>
  <div class='col-span-12 xl:col-span-6 xl:col-start-1'>
    <BaseShowEditInput :label="$t('attributes.usual_first_name')"
                       v-model="computedDeceased.usual_first_name"
                       :errors="errors.deceased ? errors.deceased.usual_first_name : []"
                       :placeholder="$t('attributes.usual_first_name')"
                       :editionMode="editionMode"
                       />
  </div>
  <div v-if='metadata.deceased.formInputs.maiden_name.visible.type.includes(String(computedDeceased.civility))'
       class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.maiden_name')"
                       v-model="computedDeceased.maiden_name"
                       :errors="errors.deceased ? errors.deceased.maiden_name : []"
                       :placeholder="$t('attributes.maiden_name')"
                       :editionMode="editionMode"
                       :required="true" />
  </div>
  <hr class='col-span-12 mt-5'>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditDateInput :label="$t('attributes.birth_date')"
                           :placeholder="$t('attributes.birth_date')"
                           name='birth_date'
                           :editionMode='editionMode'
                           v-model='work.deceased.birth_date'
                           :errors='errors?.deceased?.birth_date'
                           :required="true"/>
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.birth_place')"
                         v-model="computedDeceased.birth_place"
                         :errors="errors.deceased ? errors.deceased.birth_place : []"
                         :placeholder="$t('attributes.birth_place')"
                         :editionMode="editionMode"
                          />
  </div>
  <div v-if="settings.general.default_country  !== 'FR'" class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.national_number')"
                       v-model="computedDeceased.national_number"
                       :errors="errors.deceased ? errors.deceased.national_number : []"
                       :placeholder="$t('attributes.national_number')"
                       :editionMode="editionMode"
                       :required="true" />
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditRadioGroup
                          v-if='computedDeceased.weight'
                          name='weight'
                          :label="$t('attributes.weight')"
                          :key='computedDeceased.weight'
                          :options='metadata.deceased.size_types'
                          :required="true"
                          v-model="computedDeceased.weight"
                          translationExtention='enums.deceased.size_types_returning'
                          :translatable="true"
                          :getByKey="false"
                          :is-showing-key="true"
                          :editionMode="editionMode"
                          :is-vertical="false"
    />
  </div>
  <hr class='col-span-12 mt-5'>
  <div class='col-span-12 xl:col-span-6 xl:col-start-1'>
    <BaseShowEditInput :label="$t('attributes.street')"
                       v-model="computedDeceased.street"
                       :errors="errors.deceased ? errors.deceased.street : []"
                       :placeholder="$t('attributes.street')"
                       :editionMode="editionMode"
                       />
  </div>
  <div class='col-span-12 xl:col-span-6 xl:col-start-1'>
    <div class='grid grid-cols-3 space-x-5'>
      <div class='col-span-12 xl:col-span-1'>
        <BaseShowEditInput :label="$t('attributes.post_code')"
                           v-model="computedDeceased.post_code"
                           :errors="errors.deceased ? errors.deceased.post_code : []"
                           :placeholder="$t('attributes.post_code')"
                           :editionMode="editionMode"
                           />
      </div>
      <div class='col-span-12 xl:col-span-2'>
        <BaseShowEditInput :label="$t('attributes.city')"
                           v-model="computedDeceased.city"
                           :errors="errors.deceased ? errors.deceased.city : []"
                           :placeholder="$t('attributes.city')"
                           :editionMode="editionMode"
                            />
      </div>
    </div>
  </div>
  <div class='col-span-12 xl:col-span-6 xl:col-start-1'>
<!--    Micky: on laisse une div vide ici pour le placement des inputs -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex'


export default {
  name: 'WorkFormDeceasedInfo',
  props: {
    computedDeceased: {type: Object, required: true},
    getInputValue: {type: Function, required: true},
    editionMode: {type: Boolean, required: true},
  },
  computed:{
    ...mapGetters({metadata: 'work/getWorkMetadata', work: 'work/getWork', settings: 'settings/getSystemSettings', errors: 'auth/getErrors'}),
  },

}
</script>
