<template>
  <BaseSimpleCard :title="$t('global.events')" name="company-form" classes='col-start-2 col-span-10'>
    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100'>
      <BaseButton v-if='editionMode' icon="paper-plane" :title="$t('button.save')" @click="submitForm"/>
      <BaseButton
        v-if="this.$route.name !== 'settings_create_user' && this.$can('manage_content')"
        color="warning"
        :icon="editionMode ? 'times' : 'pen'"
        @click="switchEditMode"
        :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
      </div>
    </template>
    <template #default>
      <div class='flex flex-col space-y-10'>

        <div v-for='(event, index) in cEvents' :key='index'>
          <WorkEvent :propsEvent="event"
                     :metadata="metadata"
                     :index="index"
                     :errors="errors.events && errors.events.length ? errors.events[index] : {}"
                     :editionMode='editionMode'
                     @eventDeleteButtonPressed='deleteEvent' />

          <hr class='mt-5' v-if='work.events.length > 1 && index < work.events.length - 1'>
        </div>
        <div v-if='editionMode' class='flex flex-row justify-end'>
          <div class='flex-1'>
            <BaseButton icon='plus' type='dark' :title='$t("global.add_event")' @click="addEvent"/>
          </div>
<!--          <div>-->
<!--            -->
<!--          </div>-->
        </div>
      </div>

    </template>
  </BaseSimpleCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import WorkEvent from '@p/works/form/subpages/events/WorkEvent'


export default {
  name: 'TheWorkFormEventsPage',
  components:{
    WorkEvent
  },
  data() {
    return {
      editionMode: false,
    }
  },
  computed:{
    ...mapGetters({work:'work/getWork', errors: 'auth/getErrors',metadata:'work/getWorkMetadata'}),
    cEvents(){
      let sorted_events = this.work.events
      return sorted_events
    }
  },
  methods:{
    ...mapActions({updateWork: 'work/updateWork', fetchWorkById: 'work/fetchWorkByID' }),
    async submitForm(){
      await this.updateWork({
        form_data: {
          id: this.$route.params.id,
          work: this.work
        },
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.updated_m', { var: this.$t('attributes.work') }), { className: 'toastify-content success' })
          this.editionMode = false
        },
        errorCallback: () => {
          this.$h.toastify(this.$t('toasts.form_error'), { className: 'toastify-content warning' })
        }

      })
    },
    addEvent(){
      this.work.events.push({})
    },
    deleteEvent(id){
      console.log('delete event', Object.values(this.work.events))
      this.work.events.splice(id, 1)
    },
    switchEditMode(){
      if (this.editionMode){
        this.fetchWorkById(this.$route.params.id)
      }
      this.editionMode = !this.editionMode
    },
  }
}
</script>

<style scoped>

</style>
