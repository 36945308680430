<template>
  <BasePageFull>
    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','database']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.urn_title') }}</span>
      </div>
    </template>
    <template #cta>
      <BaseButton color='primary' icon='long-arrow-left' @click="this.$router.go(-1)"
                  :title="$t('button.back')"/>
    </template>
    <template #body>
      <BaseSimpleCard name='user body' title='' classes='col-span-12 h-screen' :borderedHeader="false">

        <template #default>
          <div class='flex ml-4'>
            <h1 class="text-md font-bold mr-3 mt-1 capitalize-first">{{ getTitleSwitch }}</h1>
            <BaseSwitch
              :key="all_urns"
              name="subject_to_confirmation"
              v-model="all_urns"
            />
          </div>
          <BaseContentTable
            v-if="metadata.columns"
            :columns="metadata.columns"
            :modelList="filteredUrnList"
            :search="true"
            redirect_to_field="work_id"
            @rowClick="redirectToModelPage"
            @refreshClick="fetchUrnIndex"
            @deleteModel="deleteModelById"
            :isHoverable="true"
            :isResponsive="true" />

        </template>
      </BaseSimpleCard>
    </template>
  </BasePageFull>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'UrnIndex',
  data() {
    return {
      all_urns: false
    }
  },
  computed:{
    ...mapGetters({urnList:'urn/getUrnIndex',metadata:'urn/getUrnMetadata'}),
    filteredUrnList(){
      if(this.all_urns === true){
        return this.urnList
      }
      return this.urnList.filter(urn => !urn.awaiting_dead_line)
    },
    getTitleSwitch() {
      return this.$t(this.all_urns ? 'button.hide_plural' : 'button.show_plural', { var: this.$t('global.urn_returned') })
    }
  },
  methods: {
    ...mapActions({fetchUrnsIndex:'urn/fetchUrnIndex'}),
    redirectToModelPage(id) {
      this.$router.push({ name: 'work_form_urn_route', params: { id: id } })
    },
    deleteModelById(){
    },
    fetchUrnIndex(){
    },
  },
  beforeMount() {
    this.fetchUrnsIndex()
  }
}
</script>

<style scoped>

</style>
