<template>
  <BaseCardSection :sectionTitle='$t("button.add_new_f", { var: $t("global.disponibility") })'>
    <template #default>
      <div class='grid grid-cols-12 gap-x-5'>

        <div class='col-span-6 xl:col-span-3'>
          <BaseTimeInput
            :label="$t('attributes.time_from')"
            placeholder='12:00'
            name='start_time'
            :value='assetTimeSlot.time_from'
            :errors='errors.start_time'
            :get-input-value='getInputValue'/>
        </div>

        <div class='col-span-6 xl:col-span-3'>
          <BaseTimeInput
            :label="$t('attributes.time_to')"
            placeholder='12:00'
            name='end_time'
            :value='assetTimeSlot.time_to'
            :errors='errors.end_time'
            :get-input-value='getInputValue' />
        </div>

        <div v-if='eventKindsOptions.length > 0' class='col-span-6 xl:col-span-3'>
          <BaseSimpleRadioGroup name="event_kind"
                                :label="$t('attributes.event_kind')"
                                :extra_id="asset?.id"
                                :options="eventKindsOptions"
                                :is-returning-key='true'
                                :is-showing-key='true'
                                trackBy="id"
                                :isVertical="true"
                                :translatable="false"
                                :errors="errors.event_kind"
                                @change.self="getInputValue"/>
        </div>

        <div class='col-span-12 xl:col-span-6 col-start-1' v-if="metadata.ceremony_type && asset.type === 'ROM' ">
          <BaseSelectSimple v-if='metadata.ceremony_type'
                            :label="$t('attributes.ceremony_type')"
                            :placeholder="$t('attributes.ceremony_type')"
                            name='ceremony_type'
                            value=''
                            :options='metadata.ceremony_type'
                            :disabled="$can('manage-work')"
                            :errors='errors.ceremony_type'
                            @change.self='getInputValue' />
        </div>

        <div class='col-span-12 col-start-1'>
          <BaseSimpleCheckboxGroup
                          name='days'
                         :label="$t('attributes.days')"
                         :options='metadata.days'
                         translationExtention='enums.config.days'
                         :errors='errors.days'
                         @change.self='getInputValue' />
        </div>
      </div>

      <div class='flex justify-end'>
        <BaseButton
            v-if="editionMode"
            :isElevated='true'
            :title="$t('button.add_plural', {var: $t('global.disponibilities')})"
            icon='plus'
            @click='submitForm'
        />
      </div>

    </template>
  </BaseCardSection>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AssetTimeSlotFormComponent',
  props: {
    editionMode: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      assetTimeSlot: {
        start_time: '',
        end_time: '',
        days: [],
        ceremony_type: null,
        event_kind: null,
      }
    }
  },
  computed: {
    ...mapGetters({
      asset: 'asset/getSelectedAsset',
      metadata: 'asset/getAssetMetadata',
      errors: 'auth/getErrors'
    }),
    eventKindsOptions(){
        let options = []
      if(this.metadata.event_kinds){
        this.asset?.event_kinds?.forEach(el => {
          options.push(this.metadata?.event_kinds?.filter(kind => kind.id === el))
        })
      }
      return options.flat()
    }
  },
  methods: {
    ...mapActions({postTimeSlot: 'asset/storeTimeSlot'}),
    getInputValue({ attribute, value }) {
      console.log('attribute', attribute)
      this.assetTimeSlot[attribute] = value
    },
    submitForm() {
      this.postTimeSlot({
        id: this.asset.id,
        timeSlot: this.assetTimeSlot,
        successCallback: () => {
          this.$h.toastify(this.$t('global.asset_updated'), { className: 'toastify-content success' })
        }
      })
    }
  }
}
</script>
