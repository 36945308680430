import apiClient from '@/utils/apiClient'
import router from '@/router'

let base_errors = {}

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {
    //region fetch
    async fetchAllUser({commit}) {
            commit('auth/setErrors', base_errors, {root: true})
            await apiClient.get('api/user')
                .then(async response => {
                    await commit('setUserList', response.data.users)
                    await commit('setUserMetadata', response.data.metadata)
                })
                .catch(async reason => {
                    console.error('fetchAllUser', reason)
                })
        },
        async fetchUserByID({commit}, payload) {
            commit('auth/setErrors', base_errors, {root: true})
            await apiClient.get(`api/user/${payload.id}`)
                .then(async response => {
                    await commit('setUser', response.data.user)
                    await commit('setUserMetadata', response.data.metadata)
                })
                .catch(async reason => {
                    console.error('fetchUserByID', reason)
                })
        },

    async fetchNewUser({commit}) {
        commit('setUser', {})
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get('api/model/new?model=user')
            .then(async response => {
                await commit('setUser', response.data.user)
                await commit('setUserMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchNewUser', reason)
            })
    },
    async fetchUserActivitiesByUserID({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/user/fetch-activities/${payload.user_id}`)
            .then(async response => {
                await commit('setUserActivities', response.data.activities)
            })
            .catch(async reason => {
                await console.error(reason)
            })
    },
    //endregion

    //region store
    async storeNewUser({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        console.log(payload)
        await apiClient.post('api/user', payload.user)
            .then(async response => {
                if (response.status === 201) {
                    processSuccessCallback(payload)
                    if (payload.user.customer_id) {
                        await router.push({name: 'settings_edit_customer', params: {id: payload.user.customer_id}})
                    } else {
                        await router.push({name: 'settings_list_user'})
                    }
                }
            })
            .catch(reason => {
                console.error("error storeNewUser", reason)
            })
    },
    //endregion

    //region update
    async updateUser({commit, dispatch}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.put(`api/user/${payload.id}`, payload.user)
            .then(async response => {
                if (response.status === 201) {
                    dispatch('auth/refreshAuth', null, { root: true })
                    commit('setUser', response.data.user)
                    processSuccessCallback(payload)
                    // dispatch('user/refreshAuth'); //user/user/refreshAuth
                }
            })
            .catch(async reason => {
                console.error("error updateUser", reason)
            })
    },
    //endregion

    //region delete
    async deleteUserById({commit}, payload) {
        await apiClient.delete(`api/user-delete/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setUserList', response.data.users)
                    await commit('setUserMetadata', response.data.metadata)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('deleteUserById', reason)
            })
    },
    //endregion

    //region undoDelete
    async undoDeleteUserById({commit}, payload) {
        await apiClient.get(`api/user/undo-delete/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setUserList', response.data.users)
                    await commit('setUserMetadata', response.data.metadata)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('undoDeleteUserById', reason)
            })
    },
    //endregion
}
