<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','cogs']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ $t('global.events_settings') }}</span>
      </div>
    </template>
    <template #cta>
      <BaseButton
          v-if="$can('manage_content')"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getSettingsAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
    </template>

    <template #body>
      <div class='space-y-8'>
        <BaseCardSection>
          <template #default>
            <div class='grid grid-cols-12 gap-5 mx-14'>

              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditInput
                    :label="$t('attributes.simultaneous_cremation')"
                    v-model="settings.simultaneous_cremation"
                    type='number'
                    parseType='int'
                    :min="1"
                    :max="9999999"
                    :errors="errors.simultaneous_cremation"
                    :placeholder="$t('attributes.simultaneous_cremation')"
                    :editionMode="editionMode"
                    :required="true"
                />
              </div>

              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditInput
                    :label="$t('attributes.default_coffin_arriving_in_minutes')"
                    v-model="settings.default_coffin_arriving_in_minutes"
                    type='number'
                    parseType='int'
                    :min="1"
                    :max="9999999"
                    :errors="errors.default_coffin_arriving_in_minutes"
                    :placeholder="$t('attributes.default_coffin_arriving_in_minutes')"
                    :editionMode="editionMode"
                    :required="true"
                />
              </div>
              <div class='col-span-8'>
                <BaseTrueFalseShowEditRadioGroup
                    name="ask_nb_people_when_work_creating"
                    v-model="settings.ask_nb_people_when_work_creating"
                    :label="$t('attributes.ask_nb_people_when_work_creating')"
                    :defaultValue="settings.ask_nb_people_when_work_creating"
                    :editionMode="editionMode"
                />
              </div>

            </div>

          </template>
        </BaseCardSection>
        <div class='flex justify-end mt-12 mx-12'>
          <BaseButton
              v-if="editionMode"
              :isElevated='true'
              icon='paper-plane'
              @click='submitForm'
              :title="$t('button.save_plural', {var: $t('global.events_settings')})"
          />
        </div>
      </div>

      <BaseCardSection>
        <h1 class='text-2xl font-bold capitalize-first mx-12 my-10'>{{ $t('global.event_types') }}</h1>
        <EventTypeSettingsComponent
            v-for="(event_type, event_typeTitle) in event_kinds"
            :key="event_typeTitle"
            :editionMode="editionMode"
            :event_type="event_type"
            :event_typeTitle="event_typeTitle"
            :errors="errors"/>
      </BaseCardSection>

      <div class='flex justify-end mt-12 mx-12'>
        <BaseButton v-if="$can('see_settings') && editionMode"
                    :isElevated='true'
                    icon='paper-plane'
                    @click='submitFormEventType'
                    :title="$t('button.save_plural', {var: $t('global.event_types')})"/>
      </div>

    </template>
  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

import EventTypeSettingsComponent from "./components/EventTypeSettingsComponent"


export default {
  name: 'TheEventSettingsView',
  components: {
    EventTypeSettingsComponent,
  },
  data() {
    return {
      yes_no_options: {true: true, false: false},
      editionMode: false
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      event_kinds: 'settings/getSettingsEventKinds',
      errors: 'auth/getErrors'
    }),

    permission() {
      return !this.$can('manage_settings')
    },
  },
  methods: {
    ...mapActions({
      fetchEventSettings: 'settings/fetchEventSettings',
      updateEventSettings: 'settings/updateEventSettings',
      updateEventKindsSettings: "settings/updateEventKindsSettings"
    }),
    ...mapMutations({alterSettings: 'settings/alterSettings'}),

    getSettingsAndChangeEditionMode() {
      if(this.editionMode){
        this.fetchEventSettings()
      }
      this.editionMode = !this.editionMode
    },
    getInputValue({attribute, value}) {
      this.alterSettings({attribute: attribute, value: value})
    },
    submitForm() {
      this.updateEventSettings({
        eventSettings: this.settings,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.updated_plurals', {var: this.$t('global.events_settings')}), {className: 'toastify-content success'})
          this.fetchEventSettings()
          this.editionMode = false
        }
      })
    },
    submitFormEventType() {
      this.updateEventKindsSettings({
        event_kinds: this.event_kinds,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.updated_plurals', {var: this.$t('global.event_types')}), {className: 'toastify-content success'})
          this.fetchEventSettings()
          this.editionMode = false
        }
      })
    }
  },
  beforeMount() {
    this.fetchEventSettings()
  }
}
</script>

