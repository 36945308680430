import getters from '@/store/modules/content/company/getters'
import mutations from '@/store/modules/content/company/mutations'
import actions from '@/store/modules/content/company/actions'

export default {
  namespaced: true,
  state() {
    return {
      companiesList: [],
      company: {},
      companyActivity: [],
      metadata: {},
    }
  },
  getters,
  actions,
  mutations
}
