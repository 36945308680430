<template>
  <div class="refresh-container  bg-theme-red flex p-2 justify-center"
       v-if="process?.env?.NODE_ENV === 'production' && hashChanged ">
    <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <font-awesome-icon :icon="['fal', 'exclamation-triangle']" size="2x" class='text-yellow-700' color="warning"/>
        </div>
        <div class="ml-3 flex flex-row">

          <div class="ml-3 flex flex-col">
            <p class="text-lg text-yellow-700">
              {{ $t('toasts.new_update_1') }}
            </p>
            <p class="text-sm text-yellow-700">
              {{ $t('toasts.new_update_2') }}
            </p>
          </div>
          <button class="btn btn-warning justify-self-end w-30 ml-10" @click="reloadApp">{{ $t('toasts.update') }}
          </button>
        </div>

      </div>
    </div>
  </div>
  <router-view/>
</template>
<script>
// mixins
import {refreshPageMixin} from '@u/mixins/refresh-page.mixin';

export default {
  data() {
    return {}
  },
  components: {},
  mixins: [refreshPageMixin],
  methods: {},
  mounted() {
    if (process.env.NODE_ENV !== 'development') {
      this.initVersionCheck('/version.json')
    }
    console.log("%c✘ Env! " + process.env.NODE_ENV, "color: #fd3995");


  },
}
</script>
