<template>
  <table class='table table--sm'>
    <thead>
    <tr>
      <th class='border text-center uppercase'>{{ $t('global.quantity_short') }}</th>
      <th class='border uppercase'>{{ $t('global.product') }}</th>
      <th class='border text-center uppercase'>{{ $t('global.vat') }}</th>
      <th class='border text-center uppercase'>{{ $t('global.unit_price_excluding_vat') }}</th>
      <th class='border text-center uppercase'>{{ $t('global.unit_price_tax_included') }}</th>
      <th class='border text-center uppercase' v-if='has_reduction'>réduction</th>
      <th class='border text-center uppercase'>{{ $t('global.total') }}</th>
      <th class='border text-center uppercase'>{{ $t('global.total_all_tax_included') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for='item in invoice_items'>
      <td class='border'>{{ item.quantity }}</td>
      <td
          v-if="invoiceIsNotEstablished && auth_role !== 'customer-user'"
          class='border text-indigo-600 cursor-pointer'
          @click="this.$emit('event-click', item.id)" >{{ item.name }}</td>
      <td v-else class='border' >{{ item.name }}</td>
      <td class='border text-center'>{{ item.vat_rate }}%</td>
      <td class='border text-right'>{{ $h.formatCurrency( item.final_price_excluding_vat ) }}</td>
      <td class='border text-right'>{{ $h.formatCurrency( item.final_price_including_vat ) }}</td>
      <td class='border text-center' v-if='has_reduction'>{{ item.reduction }}%</td>
      <td class='border text-right'>{{ $h.formatCurrency( item.final_price_total_excluding_vat ) }}</td>
      <td class='border text-right'>{{ $h.formatCurrency( item.final_price_total_including_vat ) }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InvoicingItemsTable',
  props: {
    invoice_items: {
      type: Array,
      required: true
    },
    invoiceIsNotEstablished: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters({auth: 'auth/getAuth'}),
    has_reduction() {
      return this.invoice_items && this.invoice_items.some( item => item.reduction > 0 );
    },
    auth_role(){
      return this.auth.roles[0];
    }
  },
}
</script>
