<template>
  <div class='grid grid-cols-2 gap-x-5' v-if="metadata && worksEventOfTypeOth">

    <RecovererForm :editionMode='editionMode' />

      <BaseShowEditTextArea
        :label="$t('attributes.additional_info')"
        :placeholder="$t('attributes.additional_info')"
        v-model="worksEventOfTypeOth.operator_infos"
        :errors="errors.operator_infos"
        :editionMode="editionMode"
      />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RecovererForm from './RecovererForm'

export default {
  name: 'SelectOthForm',
  components:{
    RecovererForm,
  },
  props:{
    editionMode: {type: Boolean, required: true},
  },
  computed:{
    ...mapGetters({
      work: 'work/getWork',
      metadata: 'work/getWorkMetadata',
      errors: 'auth/getErrors',
      worksEventOfTypeOth: 'work/getOthEventInWork',
    }),
  }
}
</script>
