<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="bg-white rounded-xl p-0 max-h-screen flex flex-col" :class="modalClasses" open
              v-if="isModalVisible">
        <!--#region header -->
        <div class='flex'>
          <div class='flex-1 p-5'>
            <h2 class="text-2xl font-bold capitalize-first">
              {{ $t('button.edit_new_mv', {var: $t('global.item')}) }}
            </h2>
          </div>
          <div class='flex items-center p-5 cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg'/>
          </div>
        </div>
        <!--#endregion -->
        <!--#region settings -->
        <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.messageText')}) "/>
        <section class="flex-1 modal-body pt-0 overflow-scroll">
          <div class='flex flex-col gap-3' v-if="invoice_item">

            <div>

              <BaseInput :label="$t('attributes.name')"
                         :placeholder="$t('attributes.name')"
                         name="name"
                         v-model="invoice_item.name"
                         :disabled="$can('manage-settings')"
                         :errors="errors.name"
              />
            </div>

            <div>

              <BaseTextArea :label="$t('attributes.description')"
                            :placeholder="$t('attributes.description')"
                            name="description"
                            :value="invoice_item.description"
                            :errors="errors.description"
                            @change.self='getInputValue'/>

            </div>

            <div class='grid grid-cols-6 gap-3'>

              <div class='col-span-1'>

                <BaseInput :label="$t('attributes.quantity')"
                           :placeholder="$t('attributes.quantity')"
                           type="number"
                           parseType="int"
                           v-model="invoice_item.quantity"
                           :disabled="$can('manage-settings')"
                           :errors="errors.quantity"
                />

              </div>

              <div class='col-span-2'>

                <BaseButtonInput :label="$t('attributes.price_excluding_vat')"
                                 :placeholder="$t('attributes.price_excluding_vat')"
                                 name="price_excluding_vat"
                                 :value="invoice_item.price_excluding_vat"
                                 :disabled="$can('manage-settings')"
                                 :errors="errors.price_excluding_vat"
                                 icon='euro-sign'
                                 @change.self="getInputValue"/>

              </div>

              <div class='col-span-2'>

                <BaseButtonInput :label="$t('attributes.price_including_vat')"
                                 :placeholder="$t('attributes.price_including_vat')"
                                 name="price_including_vat"
                                 :value="price_including_vat"
                                 :disabled="$can('manage-settings')"
                                 icon='calculator'
                                 :isClickable='true'
                                 :buttonFunction='vatCalculation'
                                 @change.self="getInputValue"/>

              </div>

              <div class='col-span-1 col-start-1'>

                <BaseButtonInput :label="$t('attributes.vat_rate')"
                                 name="vat_rate"
                                 :value="invoice_item.vat_rate"
                                 :disabled="$can('manage-settings')"
                                 :errors="errors.vat_rate"
                                 icon='percent'
                                 @change.self="getInputValue"/>

              </div>

              <div class='col-span-1'>

                <BaseButtonInput :label="$t('attributes.reduction')"
                                 :placeholder="$t('attributes.reduction')"
                                 name="reduction"
                                 :value="invoice_item.reduction"
                                 :disabled="$can('manage-settings')"
                                 :errors="errors.reduction"
                                 icon='percent'
                                 :isClickable='true'
                                 @change.self="getInputValue"/>

              </div>

            </div>

          </div>

          <BaseTextArea :label="$t('attributes.remarks')"
                        :placeholder="$t('attributes.remarks')"
                        name="remarks"
                        :value="invoice_item?.remarks"
                        :errors="errors.remarks"
                        @change.self='getInputValue'/>

        </section>
        <!--#endregion -->
        <!--#region footer -->
        <div class="flex flex-row justify-end py-3 px-5 bg-gray-100 space-x-3">
          <BaseButton
              :title="$t('button.delete_new_mv', {var: $t('global.item')} )"
              icon='times'
              @click="deleteItem"
          />
          <BaseButton
              :title="$t('button.edit_new_mv', {var: $t('global.item')} )"
              icon='pen'
              @click="submitForm"
          />
          <BaseButton
              :title="$t('button.close')"
              icon='times'
              color='danger'
              @click="_close"
          />
        </div>
        <!--#endregion -->
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isModalVisible"></div>
    </transition>
  </teleport>
</template>

<script>
import {mapMutations, mapActions, mapGetters} from "vuex";


export default {
  name: 'InvoiceItemEditDialog',
  emits: ['close', 'backdropClicked'],
  props: {
    invoiceItemId: {},
    isModalVisible: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function (value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPulsing: false,
      price_including_vat: ''
    }
  },
  computed: {
    ...mapGetters({
      invoice_item: 'invoicing/getInvoiceItem',
      errors: 'auth/getErrors'
    }),
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    }
  },
  methods: {
    ...mapMutations({setErrors: "auth/setErrors"}),
    ...mapActions({
      fetchInvoiceItemID: 'invoicing/fetchInvoiceItemID',
      putInvoiceItem: 'invoicing/updateInvoiceItem',
      deleteInvoiceItem: 'invoicing/deleteInvoiceItem',
      undoDeleteInvoiceItem: 'invoicing/undoDeleteInvoiceItem'
    }),
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    },
    submitForm() {
      this.putInvoiceItem({id: this.invoiceItemId, invoice_item: this.invoice_item, successCallback: this._close})
    },
    getInputValue({attribute, value}) {
      this.invoice_item[attribute] = value
    },
    vatCalculation() {
      let errors = {}
      this.$store.commit(this.setErrors, errors)

      if (!this.invoice_item.vat_rate) {
        errors['vat_rate'] = ["ce champ est requis"]
        this.$store.commit(this.setErrors, errors)
        return
      }
      if (!(this.invoice_item.vat_rate >= 0 && this.invoice_item.vat_rate <= 100)) {
        errors['vat_rate'] = ["ce champ doit être un nombre compris entre 0 et 100"]
        this.$store.commit(this.setErrors, errors)
        return
      }
      let vat = (100 + parseInt(this.invoice_item.vat_rate)) / 100
      this.invoice_item.price_excluding_vat = (this.invoice_item.price_including_vat / vat).toFixed(2)
    },
    deleteItem() {
      this.deleteInvoiceItem({
        id: this.invoiceItemId,
        invoice_item: this.invoice_item,
        successCallback: () => {
          let that = this
          console.log('1 success')
          let toast = cash("#notification-with-actions-content").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteMessageText(this.invoiceItemId)
          })
          console.log('2 success')
          this.$h.toastifyHtml({
            close:true,
            duration: 10000,
            node: toast.removeClass("hidden")[0]
          })
          that._close()
        },
      })
    },
    undoDeleteMessageText(id) {
      this.undoDeleteInvoiceItem({
        id:  id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_m', {var: this.$t('global.item')}) , {className: 'toastify-content success'})
        }
      })
    },
  },
  watch: {
    isPulsing: function (currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    },
    invoiceItemId(newValue) {
      if (newValue) {
        this.fetchInvoiceItemID({id: this.invoiceItemId})
      }
    }
  },

}
</script>

<style scoped>

/* dialog */
dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 577px) {
  dialog.small {
    width: 20%;
    max-width: 300px;
    scroll-behavior: auto;
  }

  dialog.medium {
    width: 30%;
    max-width: 500px;
  }

  dialog.large {
    width: 50%;
    max-width: 700px;
  }

  dialog.x-large {
    width: 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active {
  animation: modal 0.3s ease-in;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active {
  animation: backdrop 0.3s ease-in;
}

.backdrop-leave-active {
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation: pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
