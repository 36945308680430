<template>
  <!-- BEGIN: Dark Mode Switcher-->
  <div
    class="dark-mode-switcher cursor-pointer shadow-md fixed bottom-0 left-0 box dark:bg-dark-2 border rounded-full w-40 h-12 flex items-center justify-center z-50 mb-10 ml-10"
    @click="switchMode"
  >
    <div class="mr-4 text-gray-700 dark:text-gray-300">Dark Mode</div>
    <div
      :class="{ 'dark-mode-switcher__toggle--active': darkMode }"
      class="dark-mode-switcher__toggle border"
    ></div>
  </div>
  <!-- END: Dark Mode Switcher-->
</template>

<script>

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheDarkModeSwitcher',
  computed: {
    ...mapGetters('darkMode', ['darkMode'])
  },
  methods: {
    ...mapActions('darkMode', ['setDarkMode']),
    setDarkModeClass() {
      this.darkMode
        ? cash('html').addClass('dark')
        : cash('html').removeClass('dark')
    },
    // setDarkModeClass() {
    //   this.darkMode
    //     ? cash('html').addClass('dark')
    //     : cash('html').removeClass('dark')
    // },
    switchMode() {
      this.setDarkMode(!this.darkMode)
      this.setDarkModeClass()
    }
  },
  mounted() {
    this.setDarkModeClass()
  }
}
</script>
