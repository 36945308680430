<template>

  <div class="w-full flex items-center justify-between p-6 space-x-6">
    <div class="flex-1 truncate">
      <router-link :to="{ name: 'work_form_invoicing_show_route', params: { invoicing_id: invoice.id } }">
        <div class="flex items-center justify-between">
          <h3 class="text-gray-900 text-base font-medium truncate">{{ invoice.reference }}</h3>
          <span class="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">{{ $t( `enums.invoicing.status.${invoice.status}`) }}</span>
        </div>
        <p class='capitalize-first'><span class='font-bold'>{{ $t('attributes.total_htva') }}:</span> {{  invoice.total_htva  }} €</p>
        <p class='capitalize-first'><span class='font-bold'>{{ $t('attributes.total_tvac') }}:</span> {{  invoice.total_tvac  }} €</p>
        <p class='capitalize-first'><span class='font-bold'>{{ $t('attributes.total_paid') }}:</span> {{  invoice.total_paid }} €</p>
      </router-link>
    </div>
  </div>

  <div>
    <div class="-mt-px flex divide-x divide-gray-200">
      <div class="-ml-px w-0 flex-1 flex">
        <router-link :to="{name: 'invoice_print_route', params: {invoicing_id: invoice.id}}" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
          <font-awesome-icon :icon='["fal","print"]' />
          <span class="ml-3">{{ $t('global.print') }}</span>
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'WorkFormInvoicingCard',
  props: {
    invoice: {type: Object, required: true}
  },
  computed:{
    badgeColor(){
      return this.invoice.status_color ? this.invoice.status_color : 4
    }
  }
}
</script>
