import CheckMutations from './check/mutations'

export default {
  ...CheckMutations,

  //region invoice
  setInvoiceIndex(state, invoiceList) {
    state.invoiceIndex = invoiceList
  },
  setInvoice(state, invoice) {
    if (!'id' in invoice || invoice.id === null) {
      invoice.reference =  state.invoiceMetadata .next_reference["1"]
      invoice.number =  state.invoiceMetadata .next_number["1"]
    }
    state.invoice = invoice
  },
  setInvoiceMetadata(state, metadata) {
    state.invoiceMetadata = metadata
  },
  setInvoiceActivity(state, activity){
    state.invoiceActivity = activity
  },
  setSendInvoiceData(state,sendInvoiceData){
    state.sendInvoiceData = sendInvoiceData
  },
  setSendInvoiceMessageText(state,message){
    state.sendInvoiceData.messageConverted = message
  },

  //endregion

  //region invoiceItem
  setInvoiceItem(state, invoiceItem){
    state.invoiceItem = invoiceItem
  },
  //endregion

  //region payment
  setPayment(state, payment){
    state.payment = payment
  },
  setPaymentMetadata(state, metadata){
    state.paymentMetadata = metadata
  },
  alterPayment(state, payload){
    state.payment[payload.attribute] = payload.value
  },
  //endregion

}
