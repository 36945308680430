import CheckGetters from './check/getters'

export default {
  ...CheckGetters,

  getInvoiceIndex(state) {
    return state.invoiceIndex
  },
  getInvoice(state) {
    return state.invoice
  },
  getInvoiceMetadata(state) {
    return state.invoiceMetadata
  },
  getInvoiceItem(state){
    return state.invoiceItem
  },
  getPayment(state){
    return state.payment
  },
  getPaymentMetadata(state){
    return state.paymentMetadata
  },
  getInvoiceActivity(state){
    return state.invoiceActivity
  },
  getSendInvoiceData(state){
    return state.sendInvoiceData
  }
}
