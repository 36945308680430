<template>
  <BaseSimpleCard :title="document ? document.name : ''" :button-back-left='true' :button-back-left-route="getRouteBackLeft()">

    <template #cta>
      <div class='flex flex-row space-x-2'>

        <button class='btn btn-primary flex space-x-2' @click='mReloadIFrame'>
          <font-awesome-icon :icon='["fal","sync"]' />
        </button>

        <button class='btn btn-primary flex space-x-2' @click='saveAsAttachment'>
          <font-awesome-icon :icon='["fal","arrow-square-down"]' />
        </button>

      </div>

      <WorkFormDocumentDialog
          ref="eventModal"
          :isOpen="isOpen"
          :document="document"
          @close="closeDialog" />

    </template>

    <template #default>
      <div class='flex h-full'>
        <IFramePdf :src='cUrlDocument' ref='iframe_doc' />
      </div>
    </template>
  </BaseSimpleCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import VuePdfEmbed from 'vue-pdf-embed'
import WorkFormDocumentDialog from './components/WorkFormDocumentDialog'

export default {
  name: 'WorkDocumentRenderPage',
  components: {
    VuePdfEmbed,
    WorkFormDocumentDialog,
  },
  props: {
    id: { type: String, },
    document_id: { type: String, },
  },
  data() {
    return { isOpen : false, paramsForm : {} }
  },
  computed: {
    ...mapGetters({ work: 'work/getWork', auth: 'auth/getAuth' }),
    ...mapGetters('document', { document: 'getDocument' }),

   BaseUrlFunction() {
      let baseURL;
      if (localStorage.getItem('url') !== null) {
        baseURL = localStorage.getItem('url')
        return baseURL.endsWith('/') ? baseURL : baseURL + '/'
      } else {
        return window.location.protocol + "//" + window.location.host + "/";
      }
    },
    cUrlDocument(){

      let baseURL = this.BaseUrlFunction;
      if(this.document) {
        if(this.document.with_form) {
          const params = this.document.form.reduce((init, question) => {
            const attr = question.name
            question['value'] =  question['value'] === undefined ? '' : question['value']


            init[attr] = question['value'];
            return init
          }, []);
          params['lang'] = this.auth.language
          const queryString = new URLSearchParams(Object.entries(params)).toString();
          return baseURL + 'api/render/document/' + this.id + '/' + this.document_id + '?' + queryString
        }
        return baseURL + 'api/render/document/' + this.id + '/' + this.document_id + '?lang=' + this.auth.language
      }else {
        this.$router.push({name : 'work_form_documents_route'})
      }

    }
  },
  methods: {
    ...mapActions('document', ['fetchDocument']),
    ...mapActions({saveDocumentAsAttachment: "document/saveDocumentAsAttachment", fetchWork: 'work/fetchWorkByID', fetchDocument: 'document/fetchDocument'}),
    closeDialog() {
      this.isOpen = !this.isOpen;
    },
    mReloadIFrame() {
      if(this.document.with_form && this.document.form) {
        this.fetchDocument({document_id: this.document.id})
        this.isOpen = !this.isOpen
      } else {
        cash('iframe')[0].src = cash('iframe')[0].src
      }
    },
    getRouteBackLeft() {
      return {name : 'work_form_documents_route'};
    },
    saveAsAttachment() {
      let url = '';
      if(this.document.with_form) {
        const params = this.document.form.reduce((init, question) => {
          const attr = question.name
          question['value'] = question['value'] === undefined ? '' : question['value']

          const newObject = {[attr]: question['value']}
          init[attr] = question['value'];
          return init
        }, []);
        params['lang'] = this.auth.language
        const queryString = new URLSearchParams(Object.entries(params)).toString();
        url = 'save/document/' + this.id + '/' + this.document_id + '?' + queryString
      } else {
        url = 'save/document/' + this.id + '/' + this.document_id + '?lang=' + this.auth.language
      }
      this.saveDocumentAsAttachment({
        url: url,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.save_document_as_attachment'), {className: 'toastify-content success'})
          this.fetchWork(this.work.id);
        }
      })
    },
  },
}
</script>

