export default {
  setMessageTextList(state, messageTextList){
    state.messageTextList = messageTextList
  },
  setMessageText(state, messageText){
    state.messageText = messageText
  },
  setMessageTextMetadata(state, metadata){
    state.messageTextMetadata = metadata
  },
}
