<template>
  <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg my-3	">
    <h3 class="text-lg leading-6 font-medium my-3 px-4 capitalize-first">{{ $t('enums.listeItem.' + groupName) }}</h3>

    <AddItemFormComponent
        v-if="editionMode"
        :key="listItem"
        :listItem="listItem"
        :showForm="showForm"
        :groupName="groupName"
        @value-updated="handleValueUpdated"
    />

    <table class="min-w-full divide-y divide-gray-200 mx-4 mb-4">
      <thead class="bg-gray-50">
      <tr>
        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
          {{ $t('attributes.name') }}
        </th>
        <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        </th>
      </tr>
      </thead>
      <tbody>
      <ItemRowComponent
          v-for="(item, idx) in listItems"
          :key="idx"
          :item="item"
          :editionMode="editionMode"
          :index="idx"
          :update-item="updateItem"
          :delete-item="deleteItem"
      />
      </tbody>
    </table>

  </div>

</template>
<script>
import {mapActions} from "vuex";

import AddItemFormComponent from '../../form/AddListItemFormComponent'
import ItemRowComponent from './ItemRowComponent'

export default {
  name: 'ItemListComponent',
  components: {
    AddItemFormComponent,
    ItemRowComponent,
  },
  props: {
    listItems: {
      type: Object,
      required: true
    },
    groupName: {
      type: String,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: true
    },
  },
  emits: ['valueUpdated'],
  data() {
    return {
      listItem: {},
      showForm: false
    }
  },
  methods: {
    ...mapActions({
      deleteListItem: 'itemList/deleteListItemById'
    }),
    deleteItem(item) {
      this.deleteListItem({listItem: item.id});
    },
    updateItem(item) {
      this.listItem = item;
      this.showForm = true
    },
    handleValueUpdated(isForUpdating) {
      if (isForUpdating) {
        this.listItem = {}
        this.showForm = !this.showForm;
      } else {
        this.listItem = {}
      }
    }
  }
}
</script>

