<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="modal-content p-0 max-h-screen flex flex-col" :class="modalClasses" open v-if="isModalVisible">
        <!--#region header -->
        <div class='flex p-5 items-center'>
          <div class='w-2/5 text-2xl font-bold'>
            {{ title }}
          </div>
          <div class='w-2/5 flex justify-end' v-if="event">
            <h1 class="text-xl font-bold mr-3">{{ $t('global.subject_to_confirmation') }}</h1>
            <BaseSwitch :key="event.subject_to_confirmation" name="subject_to_confirmation" @change="getInputValue" v-model="event.subject_to_confirmation"/>
          </div>
          <div class='w-1/5 flex justify-end cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg'/>
          </div>
        </div>
        <!--#endregion -->


        <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('global.event')}) "/>

        <section class="flex-1 modal-body overflow-scroll pt-0">
          <div class="overflow-x-auto">
            <!--#region Work -->
            <div v-if="!['MAI', 'FOR', 'OD', 'SEV',].includes(event.type)">
            <div class='grid grid-cols-12 gap-x-5' v-if="(!event.subject_to_confirmation || event.id !== null) ">
              <div class='col-span-9'>
                <BaseSelectSimpleAjax
                    v-if="event.work || !event.id"
                    :key="event"
                    url='api/work-for-select'
                    :label="$t('global.work')"
                    v-model="event.work_id"
                    name="work_id"
                    trackBy="id"
                    attributeLabel="title"
                    :defaultAjax="computedWork"
                />

              </div>
              <div class='col-span-3 mt-10'>
                <BaseButton
                            v-if="event.work"
                            icon='chevron-right'
                            :title="$t('button.to_m', {var: $t('attributes.work')})"
                            @click="this.$router.push({name: 'work_form_edit_route', params: {id: event.work_id}})"/>
              </div>
            </div>
            </div>
            <!--#endregion -->

            <div class='grid grid-cols-12 gap-x-5' v-if="event.subject_to_confirmation ">
              <!--#region customer -->
              <div class='col-span-9'>
                <SelectCustomer
                    name="customer_id"
                    :key='event.customer_id'
                    :errors='errors'
                    :get-input-value='getInputValue'
                    :model='event'
                    :permission="$can('manage-planning')"
                    :required="true"
                    :metadata='metadata'/>
              </div>
              <!--#endregion -->

              <div class='col-span-12 xl:col-span-6 xl:col-start-1'>
                <BaseInput :label="$t('attributes.first_name')"
                           :required='true'
                           :placeholder="$t('attributes.first_name')"
                           name='first_name'
                           v-model='event.first_name'
                           :errors='errors ? errors.first_name : []'
                           />
              </div>
              <div class='col-span-12 xl:col-span-6 '>
                <BaseInput :label="$t('attributes.last_name')"
                           :required='true'
                           :placeholder="$t('attributes.last_name')"
                           name='last_name'
                           v-model='event.last_name'
                           :errors='errors ? errors.last_name : []'
                           />
              </div>
            </div>

            <formEvent
                :key="event"
                :errors="errors"
                :filtered-assets="filteredAssets"
                :get-input-value="getInputValue"
                :after-work-selected="afterWorkSelected"
                :index="index"
                :metadata="metadata"
                :withPermission="$can('manage-planning')"
            />

            <div class="grid grid-cols-12 gap-x-5 mt-6" v-if="event.subject_to_confirmation && event.id">
              <div class="col-span-12 xl:col-span-6 xl:col-start-1">
                <div class='flex-1 text-xl font-bold'>
                  {{ $t('validation.automatic_unconfirmed_deletion_m', {var: $t('attributes.work')}) }}:
                </div>
              </div>
              <div class="col-span-12 xl:col-span-6 xl:col-start-1">
                <div class="grid grid-cols-3 gap-x-5">
                  <div class="col-span-12 xl:col-span-2">

                    <BaseDateInput
                        :label="$t('attributes.date_from')"
                        :placeholder="$t('attributes.date_from')"
                        name='expiration_date'
                        :value='event.expiration_date'
                        :errors='errors.expiration_date'
                        :disabled="false"
                        :get-input-value="getInputValue"/>
                  </div>
                  <div class="col-span-12 xl:col-span-1">
                    <BaseTimeInput
                        :label="$t('attributes.time')"
                        placeholder="10:00"
                        name="expiration_time"
                        :value='event.expiration_time'
                        :errors="errors.expiration_time"
                        :event="event"
                        :get-input-value="getInputValue"
                        :index="index"/>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>

        <!--#region footer-->
        <div class="flex flex row justify-end py-3 px-5 bg-gray-100 gap-x-5">
          <BaseButton v-if="!event.work_id && event.id && this.$can('delete_planning')" :icon='submitButtonIcon' @click="deleteEvent(event.id)"
                      :title="$t('button.delete_v', {var: $t('global.event')})"/>
          <BaseButton :icon='submitButtonIcon' @click="submitForm"
                      :title="submitButtonTitle"/>
          <BaseButton :title="$t('button.close')" icon='times' color='danger' @click="_close"/>

        </div>
        <!--#endregion -->
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isModalVisible"></div>
    </transition>
  </teleport>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import moment from "moment";

import FormEvent from "../../../components/Event/FormEvent";

export default {
  name: 'EventFormDialog',
  emits: ['close', 'backdropClicked','refetchCalendarEvents'],
  components: {
    FormEvent,
  },
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function (value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    },

  },
  data() {
    return {
      isPulsing: false,
      editeditemList: [],
    }
  },
  computed: {
    ...mapGetters({event: 'getEvent', metadata: 'getEventMetadata', errors: 'auth/getErrors'}),
    filteredAssets() {
      let tempModels = this.metadata.assets
      if (tempModels !== undefined && this.event.type) {
        tempModels = tempModels.filter((asset) => {
          return this.metadata.assets_type[this.event.type].includes(asset.type)
        })
      }
      return tempModels;
    },
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    },
    computedWork() {
      return this.event && this.event.work ? {
        id: this.event.work.id,
        title: `${this.event.work.title} ${this.event.work.reference ? `(${this.event.work.reference})`: ''}`
      } : {}
    },
    title() {
      return this.event.id ? this.event.title : this.$t('global.new_event')
    },
    submitButtonTitle() {
      return this.event.id ? this.$t('button.edit_new_mv', {var: this.$t('global.event')}) : this.$t('button.add_new_m', {var: this.$t('global.event')})
    },
    submitButtonIcon() {
      return this.event.id ? 'edit' : 'plus'
    }
  },
  methods: {
    ...mapActions(['fetchEventById', 'fetchNewEvent', 'postEvent', 'updateEvent', 'deleteEventById', 'undoDeleteEventById']),
    ...mapMutations(['setEvent', 'alterEvent', 'deleteEventKey']),
    async loadEvent(eventId, eventTime, resource_id) {
      if (eventId) {
        await this.fetchEventById({event_id: eventId})
      } else {
        await this.fetchNewEvent()
        const a_times = eventTime.split(' _ ')
        this.setEvent({
          ...this.event,
          date_from: moment(a_times[0]).format('DD/MM/YYYY'),
          date_to: moment(a_times[1]).format('DD/MM/YYYY'),
          asset_id: resource_id ? parseInt(resource_id.id) : null,
          all_day: a_times[0].length === 10,
          time_from: a_times[0].length === 10 ? null : moment(a_times[0]).format('HH:mm'),
          time_to: a_times[0].length === 10 ? null : moment(a_times[1]).format('HH:mm'),
        })
      }
    },
    deleteEvent(id){
      this.deleteEventById({
        id: id,
        successCallback: () => {
          this.$emit('refetchCalendarEvents')
          this._close()
          let toast = cash("#notification-with-actions-settings").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteEvent(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteEvent(id){
      this.undoDeleteEventById({
        id:  id,
        successCallback: () => {
          this.$emit('refetchCalendarEvents')
          this.$h.toastify( this.$t('toasts.reseted_m', {var: this.$t('global.event')})  , {className: 'toastify-content success'})
        }
      })
    },
    getInputValue({attribute, value}) {
      if(attribute === 'type'){
        this.deleteEventKey('asset_id')
      }
      this.alterEvent({attribute, value})
      // this.event[attribute] = value
    },
    afterWorkSelected(work) {
      this.event.work_id = work.id
    },
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    },
    async submitNewEvent() {
      await this.postEvent({event: this.event, successCallback: this._close})
      this.$emit('refetchCalendarEvents')
    },
    async submitUpdateEvent() {
      await this.updateEvent({id: this.eventId, event: this.event, successCallback: this._close})
      this.$emit('refetchCalendarEvents')
    },
    submitForm() {
      this.event.id ? this.submitUpdateEvent() : this.submitNewEvent()
    },
  },
  watch: {
    isPulsing: function (currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    },

  },
}
</script>

<style scoped>
/*
    - backdrop
    - dialog
    - dialog : responsive
    - animations (usage)
    - animations (declaration)
 */

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 577px) {
  dialog.small {
    width: 20%;
    max-width: 300px;
    scroll-behavior: auto;
  }

  dialog.medium {
    width: 30%;
    max-width: 500px;
  }

  dialog.large {
    width: 50%;
    max-width: 700px;
  }

  dialog.x-large {
    width: 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active {
  animation: modal 0.3s ease-in;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active {
  animation: backdrop 0.3s ease-in;
}

.backdrop-leave-active {
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation: pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
