export default {
  getCompaniesList(state) {
      return state.companiesList
  },
  getCompanyMetadata(state) {
      return state.metadata
  },
  getSelectedCompany(state) {
      return state.company
  },
  getCompanyActivity(state){
      return state.companyActivity
  }
}
