<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','landmark']" size='2x' />
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.content_implantation_title') }}</span>
      </div>
    </template>

    <template #cta>
      <BaseButton
          color='primary'
          icon='plus'
          @click='redirectToForm'
          v-if="$can('manage_content')"
          :title="$t('button.add_new_f', {var: $t('global.implantation')})"
      />
    </template>

    <template #body>
      <ToastUndoComponent :text="$t('toasts.deleted_f', {var: $t('attributes.implantation')}) "/>
      <div class='grid grid-cols-2 gap-5'>
        <ImplantationCardComponent
            v-for='implantation in  implantations'
            :implantation='implantation'
            :key='implantation.id'
            :open='redirectToImplantationPage'
        />
      </div>
    </template>

  </SettingsPageFull>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ImplantationCardComponent from './components/ImplantationCardComponent'

export default {
  name: 'TheImplantationIndexPage',
  components: {
    ImplantationCardComponent,
  },
  computed: {
    ...mapGetters({
      implantations: 'implantation/getImplantationIndex',
      errors: 'auth/getErrors'
    })
  },
  methods: {
    ...mapActions({
      fetchImplantationIndex: "implantation/fetchImplantationIndex",
      deleteImplantationById: "implantation/deleteImplantationById",
      undoDeleteImplantationById: 'implantation/undoDeleteImplantationById'
    }),
    redirectToForm() {
      this.$router.push({ name: 'settings_create_implantation_route' })
    },
    redirectToImplantationPage(id) {
      this.$router.push({
        name: 'settings_edit_implantation_route',
        params: { id: id }
      })
    },
    deleteImplantation(id){
      this.deleteImplantationById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-settings").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteImplantation(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteImplantation(id){
      this.undoDeleteImplantationById({
        id: id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_f', {var: this.$t('attributes.implantation')})  , {className: 'toastify-content success'})
        }
      })
    }
  },
  async mounted() {
    await this.fetchImplantationIndex()
  }
}
</script>
