export default {
  getTasksList(state){
    return state.tasksList
  },
  getTask(state){
    return state.task
  },
  getTaskMetadata(state){
    return state.taskMetadata
  },
  getTaskActivity(state){
    return state.taskActivity
  }
}
