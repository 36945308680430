<template>
  <hr class='my-3'>
  <div class='flex'>
    <div class='flex-1'>
      <h2 class='text-2xl font-bold capitalize-first' v-text='$t("global.users_list")'></h2>
    </div>
    <div class='flex-shrink-1'>
      <BaseButton
          color='primary'
          icon='plus'
          v-if="$can('manage_content') && unSavedUser === 0 && editionMode"
          @click='addUser'
          :title="$t('button.add_new_m', { var: $t('global.user') })"
      />
    </div>
  </div>

  <div class="grid grid-cols-3 gap-4 py-6">
    <CustomerUserCard
      v-for="user in this.customer.users"
      :key="user"
      :user="user"
      :focusUser="focusUser"
      ref="myCard"
      :id="`collapse-${user.id}`"
      :editionMode="editionMode"
      @changeUser="userChange"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import CustomerUserCard from './CustomerUserCardComponent'

export default {
  name: 'CustomerUserIndexComponent',
  components: {
    CustomerUserCard
  },
  props: {
    editionMode: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      focusUser: ''
    }
  },
  computed: {
    ...mapGetters({
      customer: 'customer/getSelectedCustomer'
    }),
    unSavedUser() {
      return this.customer.users?.filter(function (user) {
        return user.id === null
      }).length
    },
  },
  methods: {
    ...mapActions({
      fetchCustomerByID: 'customer/fetchCustomerByID',
    }),
    userChange (user) {
      this.focusUser = user
      if (this.unSavedUser > 0) {
        this.customer.users.pop();
      }
    },
    async addUser()  {
      this.$router.push({ name: 'settings_create_customer_user_route',params:{id : this.$route.params.id} })
    }
  },
  mounted() {
    this.fetchCustomerByID({ id: this.$route.params.id })
  }
}
</script>
