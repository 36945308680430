<template>
  <div class="col-span-12 md:col-span-4 xl:col-span-4 xxl:col-span-4 mt-3 xxl:mt-8 report-box zoom-in intro-y">
    <div class="box p-5 cursor-pointer">

      <div class="text-3xl font-medium leading-8 mt-6">
        {{ company.name }}
      </div>
      <div class="text-base text-gray-600 mt-1">{{ company.city }}</div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'CompanyCardComponent',
  props: {
    company: {
      type: Object,
      required: true
    }
  },
}
</script>
