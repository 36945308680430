// import faker from './faker'
import helper from './helper'
import lodash from './lodash'
import moment from "moment";
import VueTheMask from 'vue-the-mask'
import LitepieDatepicker from 'litepie-datepicker';

// import apiClient from './apiClient'

export default app => {
  // app.use(faker)
  app.use(helper)
  app.use(lodash)
  app.use(VueTheMask)
  app.use(LitepieDatepicker)
  app.use(moment)
}
