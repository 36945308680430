<template>
  <ActivityTable v-if='invoiceActivity && invoiceActivity.length > 0' :activities="invoiceActivity" />
  <div v-else class='mt-10 pt-10 flex justify-center font-semibold text-gray-500'>
    {{$t('global.no_activity')}}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InvoicingTabActivity',
  computed: { ...mapGetters({invoiceActivity: 'invoicing/getInvoiceActivity'}) },
  methods: { ...mapActions({fetch: 'invoicing/fetchInvoiceActivitiesByInvoiceID'})},
  beforeMount() {
    this.fetch({invoice_id: this.$route.params.invoicing_id})
  }
}
</script>

