<template>
  <BaseSimpleCard :title="$t('global.attachments')" name="company-form">
    <template #default>
      <div class='flex flex-col space-y-10'>

        <form enctype="multipart/form-data" method="post" name="fileinfo">
          <BaseFileDropComponent  :url="url" @confirmSubmit="handleSubmit"/>
        </form>

        <div class='grid grid-cols-5'>
          <ul role="list" class="col-start-2 col-span-3 border border-gray-200 rounded-md divide-y divide-gray-200">
            <AttachmentsRow
              v-for='(attachment, index) in work.attachments'
              :key='index'
              :attachment='attachment'
              :attachmentIndex='index'
            />
          </ul>
        </div>

      </div>
    </template>
  </BaseSimpleCard>
</template>

<script>
import AttachmentsRow from '@p/works/form/subpages/attachments/components/AttachmentsRow'
import {mapGetters} from 'vuex'
export default {
  name: 'TheWorkFormAttachmentsPage',
  components: {  AttachmentsRow },
  computed: {
    ...mapGetters({ work: 'work/getWork' }),
    url() {
      return `api/work/${this.work.id}/add-attachments`
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('refresh-work')
    }
  }
}
</script>
