<template>
  <div class="space-y-8 divide-y divide-gray-200 mt-10 w-1/2" v-if="metadata">
    <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">Générer les stats</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Appliquez les filtres afin de générer votre stats.</p>
        </div>

        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
          <!--#region type -->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize-fist">
              {{ $t('attributes.type') }}<span class="text-red-600">*</span> </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <BaseSelectSimple
                :label="null"
                :placeholder="$t('attributes.stats_type')"
                name="type"
                :defaultValue="metadata?.stats_type.filter(el=> el.id ===block_form?.stats_type)"
                :options="metadata?.stats_type"
                :errors="errors?.type"
                @setSelected="getInputValue"/>
            </div>
          </div>
          <!--#endregion -->

          <!--#region period-->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize-fist">
              {{ $t('attributes.period') }}<span class="text-red-600">*</span></label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <litepie-datepicker
                overlay
                i18n="fr"
                :placeholder="$t('global.from_to')"
                separator=" - "
                :formatter="date_range.date_formatter"
                :shortcuts="date_range.customShortcuts"
                v-model="block_form.period"

              />
              <div v-if="errors" v-for='(error,index) in errors.period' :key='index' class="form-help text-red-600">
                {{ $t(error, {'attribute': $t('attributes.period')}) }}
              </div>
            </div>
          </div>
          <!--#endregion -->

          <!--#region company-->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
               v-if="metadata.companies.length > 1">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize-fist">
              {{ $t('attributes.company') }} </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <BaseSelectMultiple :label="null"
                                  :placeholder="$t('global.companies')"
                                  name="companies"
                                  :options="metadata.companies"
                                  @setSelected="getInputValue"/>
            </div>
          </div>
          <!--#endregion-->
          <!--#region customer-->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize-fist">
              {{ $t('attributes.customer') }} </label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <BaseSelectMultiple :label="null"
                                  :placeholder="$t('global.customers')"
                                  name="customers"
                                  :options="metadata.customers"
                                  @setSelected="getInputValue"/>

            </div>
          </div>
          <!--#endregion -->
          <!--#region item type -->
          <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
               v-if="block_form.type === 'IN_IT' ">
            <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize-fist">
              {{ $t('attributes.item_type') }}</label>
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <BaseSelectMultiple :label="null"
                                  :placeholder="$t('global.item_type')"
                                  name="item_types"
                                  :options="metadata?.item_types"
                                  @setSelected="getInputValue"/>

            </div>
          </div>
          <!--#endregion -->
        </div>
      </div>

      <div class="space-y-6 sm:space-y-5 divide-y divide-gray-200">
        <div class="pt-6 sm:pt-5">
          <div role="group" aria-labelledby="label-email">
            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
              <div>
                <div class="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700" id="label-email">
                  {{ $t('global.display') }}<span class="text-red-600">*</span>
                </div>
              </div>
              <div class="mt-4 sm:mt-0 sm:col-span-2">
                <div class="max-w-lg space-y-4">
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input id="table" name="show_table" type="checkbox" @click="()=>this.getInputValue('show_table')"
                             class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="table" class="font-medium text-gray-700">{{ $t('global.table') }}</label>
                    </div>
                  </div>
                  <div v-if="!['SAL_TUR'].includes(block_form.type) ">
                    <div class="relative flex items-start">
                      <div class="flex items-center h-5">
                        <input id="chart" name="show_chart" type="checkbox"
                               @click="()=>this.getInputValue('show_chart')"
                               class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="chart" class="font-medium text-gray-700">{{ $t('global.chart') }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="pt-5">
      <div class="flex justify-end">

        <button type="submit"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium
                rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                @click="handleGenerateStats">
          {{ $t('attributes.generate') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'StatsBlockForm',
  data() {
    return {
      date_range: {
        date_formatter: {
          date: 'DD/MM/YYYY',
          month: 'MM'
        },
        customShortcuts: [
          {
            label: 'Last 15 Days',
            atClick: () => {
              const date = new Date();
              return [
                new Date(date.setDate(date.getDate() + 1)),
                date
              ];
            }
          },
          {
            label: 'Last Years',
            atClick: () => {
              const date = new Date();
              return [
                new Date(date.setFullYear(date.getFullYear() - 1)),
                new Date()
              ];
            }
          }
        ]
      },
      period: {}
    }
  },
  props: {
    index: {
      type: Number,
      required: 0
    },
    block_form: {}
  },

  computed: {
    ...mapGetters({metadata: 'stats/getStatMetadata', errors: 'auth/getErrors'})
  },
  methods: {
    ...mapActions({processFetchStatsBlockDataAction: 'stats/fetchStatsBlockData'}),
    getInputValue(values) {
      if (typeof values != 'object') {
        this.block_form[values] = this.block_form[values] == 'undefined' ? this.block_form[values] == false : this.block_form[values]
        this.$emit('updateFormField', {index: this.index, attribute: values, value: !this.block_form[values]})
      } else {
        this.$emit('updateFormField', {index: this.index,...values})
      }

    },
    handleGenerateStats() {
      this.processFetchStatsBlockDataAction({
        ...this.block_form,
        index: this.index,
        successCallback: () => {
          // this.$emit('closeForm')
        }
      })
    }

  },
  watch: {
    period(newValue, oldValue) {
      this.$emit('updateFormField', {index: this.index, attribute: 'period', value: newValue})
    }
  },
}
</script>
