<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
      <font-awesome-icon :icon="['fal','landmark']" size="2x" class='mr-3'/>
        &nbsp;<span class='text-2xl font-bold capitalize-first'>{{ this.$route.name === 'settings_create_implantation_route' ? $t('button.add_new_f', {var: $t('global.implantation')}) : pageTitle }}</span>
      </div>
    </template>

    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100 '>
        <div v-if="$can('manage_content')">
          <BaseButton
            v-if="editionMode"
            :isElevated='true'
            icon='paper-plane'
            @click='submitForm'
            :title="$t(buttonText, { var: $t('global.implantation')} ) "/>
        </div>
        <BaseButton
          v-if="this.$route.name === 'settings_edit_implantation_route' && $can('manage_content')"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getCompanyAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
        <BaseButton
          color="dark"
          icon='long-arrow-left'
          @click="this.$router.push({name: 'settings_list_implantation'})"
          :title="$t('button.back') "
        />
      </div>
    </template>

    <template #body>

          <BaseCardSection :sectionTitle="$t('global.general_information')">
            <template #default>

              <div class='grid grid-cols-12 gap-x-5'>
                <div class='col-span-12 xl:col-span-6'>
                  <BaseShowEditInput
                      :label="$t('attributes.name')"
                      v-model="implantation.name"
                      :errors="errors.name"
                      :placeholder="$t('attributes.name')"
                      :editionMode="editionMode"
                      :required="true" />

                </div>
                <div class='col-span-12 xl:col-span-6'>
                  <BaseShowEditInput
                      :label="$t('attributes.street')"
                      v-model="implantation.street"
                      :errors="errors.street"
                      :placeholder="$t('attributes.street')"
                      :editionMode="editionMode"
                     />

                </div>
                <div class='col-span-12 xl:col-span-6'>
                  <BaseShowEditInput
                      :label="$t('attributes.reference')"
                      v-model="implantation.reference"
                      :errors="errors.reference"
                      :placeholder="$t('attributes.reference')"
                      :editionMode="editionMode"
                      />

                </div>
                <div class='col-span-12 xl:col-span-6'>

                  <div class="flex flex-row mt-3 space-x-3">
                    <BaseShowEditInput
                        :label="$t('attributes.post_code')"
                        v-model="implantation.post_code"
                        :errors="errors.post_code"
                        :placeholder="$t('attributes.post_code')"
                        :editionMode="editionMode"
                        />
                    <BaseShowEditInput
                        :label="$t('attributes.city')"
                        v-model="implantation.city"
                        :errors="errors.city"
                        :placeholder="$t('attributes.city')"
                        :editionMode="editionMode"
                        />
                  </div>

                </div>
                <div class='col-span-12 xl:col-span-6'>
                  <BaseShowEditInput
                      :label="$t('attributes.email')"
                      v-model="implantation.email"
                      :errors="errors.email"
                      :placeholder="$t('attributes.email')"
                      :editionMode="editionMode"
                      :required="true" />

                </div>
                <div class='col-span-12 xl:col-span-6'>

                  <BaseCountryShowEditSelectSimple v-model='implantation.country'
                                                    :metadata='metadata'
                                                    :errors='errors'
                                                    :edition-mode='editionMode' />

                </div>
                <div class='col-span-12 xl:col-span-6'>
                  <BaseShowEditInput
                      :label="$t('attributes.website')"
                      v-model="implantation.website"
                      :errors="errors.website"
                      :placeholder="$t('attributes.website')"
                      :editionMode="editionMode"
                      />

                </div>
                <div class='col-span-12 xl:col-span-6'>
                  <BaseShowEditPhoneInput
                      :label="$t('attributes.phone')"
                      name='phone'
                      :editionMode="editionMode"
                      v-model:countryValue="implantation.phone_country"
                      v-model:numberValue="implantation.phone_field"
                      :errors='errors'
                      :metadata='metadata'/>

                </div>
                <div class='col-span-12 xl:col-span-6'>

                  <BaseLanguageShowEditSelectSimple v-model='implantation.language'
                                                    :metadata='metadata'
                                                    :errors='errors'
                                                    :edition-mode='editionMode' />

                </div>
                <div class='col-span-12 xl:col-span-6'>

                </div>
              </div>

<!--              <div class='flex justify-end' v-if="$can('manage_content')">-->
<!--                <BaseButton-->
<!--                            v-if="editionMode"-->
<!--                            :isElevated='true'-->
<!--                            icon='paper-plane'-->
<!--                            @click='submitForm'-->
<!--                            :title="$t(buttonText, { var: $t('global.implantation')} ) " />-->
<!--              </div>-->

            </template>
          </BaseCardSection>

          <ImplantationAssetIndexComponent
            :assets="implantation.assets"
            @addAsset="handleAddAsset(asset)"
            :implantationId="implantation.id"
            :editionMode="editionMode"
            v-if=" this.$route.name === 'settings_edit_implantation_route' && implantation.assets" />

    </template>
  </SettingsPageFull>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import ImplantationAssetIndexComponent from '../../asset/index/ImplantationAssetIndexComponent'

export default {
  name: 'TheImplantationFormPage',
  components: {
    ImplantationAssetIndexComponent,
  },
  data(){
    return {
      editionMode:false
    }
  },
  computed: {
    ...mapGetters({
      implantation: "implantation/getSelectedImplantation",
      metadata: 'implantation/getImplantationMetadata',
      asset: 'getass',
      errors: 'auth/getErrors'
    }),
    buttonText() {
      return this.$route.name === 'settings_create_implantation_route' ? 'button.add_new_f' : 'button.edit_new_f'
    },
    pageTitle() {
      return this.implantation.name
    }
  },
  methods: {
    ...mapActions({
      fetchNewImplantation: "implantation/fetchNewImplantation",
      fetchImplantationByID: 'implantation/fetchImplantationByID',
      fetchNewAsset: 'asset/fetchNewAsset',
      postNewImplantation: 'implantation/storeNewImplantation',
      updateImplantation: 'implantation/updateImplantation'
    }),
    getCompanyAndChangeEditionMode() {
      this.editionMode ? this.fetchImplantationByID({id: this.$route.params.id}) : {}
      this.editionMode = !this.editionMode
    },
    handleAddAsset(asset) {
      this.implantation.assets.push(asset)
    },
    getInputValue({attribute, value}) {
      if (value !== undefined) {
        this.implantation[attribute] = value
      }
    },
    submitForm() {
      this.$route.name === 'settings_create_implantation_route' ?
        this.postNewImplantation({
          implantation: this.implantation,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.created_f', {var: this.$t('global.implantation')}) , {className: 'toastify-content success'})
            this.$router.push({ name: 'settings_list_implantation' })
          }
        })
        :
        this.updateImplantation({
          id: this.$route.params.id,
          implantation: this.implantation,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.updated_f', {var: this.$t('global.implantation')}) , {className: 'toastify-content success'})
            this.editionMode = false
          }
        })
    }
  },
  beforeMount() {
    if (this.$route.name === 'settings_edit_implantation_route') {
      this.fetchImplantationByID({id: this.$route.params.id})
      this.fetchNewAsset()
    } else {
      this.fetchNewImplantation()
      this.fetchNewAsset()
      this.editionMode = true
    }
  },
}
</script>
