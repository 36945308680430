import apiClient from '@u/apiClient'
import fileDownload from "js-file-download";

export default {
    //region fetch
    fetchStatIndex({commit}) {
        apiClient.get('api/stats/metadata')
            .then(async response => {
                await commit('setStatMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchNoteIndex', reason)
            })
    },
    async fetchStatsBlockData({commit},payload) {
        console.log('fetchStatsBlockData',payload)
        commit('auth/setErrors', {}, {root: true})
        await apiClient.post(`api/stats/fetch-data`,payload)
            .then(async response => {
                await commit('setStatsBlockData', {index:payload.index,data:response.data})
                if (payload.successCallback) {
                    payload.successCallback()
                }
            })
            .catch(async reason => {
                console.error('fetchStatsBlockData', reason)
            })
    },
     async exportStatsBlockDataToExcel({commit},payload) {
        console.log('exportStatsBlockDataToExcel',payload)
        commit('auth/setErrors', {}, {root: true})
        await apiClient.post(`api/stats/fetch-data?action=${payload.action}`,payload)
            .then(async response => {
                window.open(response.data.url)

            })
            .catch(async reason => {
                console.error('fetchStatsBlockData', reason)
            })
    },

    // async fetchNoteById({commit}, payload) {
    //     await apiClient.get(`api/note/${payload.id}`)
    //         .then(async response => {
    //             commit('setNote', response.data.note)
    //             commit('setNoteMetadata', response.data.metadata)
    //         })
    //         .catch(async reason => {
    //             console.error('fetchNoteById', reason)
    //         })
    // },
    //endregion

    //region store
    async storeNote({commit}, payload) {
        commit('auth/setErrors', {}, {root: true})
        await apiClient.post('api/note', payload.note)
            .then(async response => {
                if (response.status === 200) {
                    commit('setNotesIndex', response.data.notes)
                    if (payload.successCallback) {
                        payload.successCallback()
                    }
                }
            })
            .catch(async reason => {
                console.error('storeNote', reason)
            })
    },
    //endregion

    //region update
    async updateNote({commit}, payload) {
        commit('auth/setErrors', {}, {root: true})
        await apiClient.put(`api/note/${payload.note.id}`, payload.note)
            .then(async response => {
                if (response.status === 200) {
                    commit('setNotesIndex', response.data.notes)
                    if (payload.successCallback) {
                        payload.successCallback()
                    }
                }
            })
            .catch(async reason => {
                console.error('updateNote', reason)
            })
    },
    //endregion
}
