<template>
  <SettingsPageFull>
    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','landmark']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{
            this.$route.name === 'settings_create_company_route' ? $t('button.add_new_f', {var: $t('global.company')}) : pageTitle
          }}</span>
      </div>
    </template>
    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100 '>
        <div v-if="editionMode && this.$can('manage_content')">
          <BaseButton :isElevated='true'
                      icon='paper-plane'
                      @click='submitForm'
                      :title="$t(buttonText, { var: $t('global.company')} ) ">
          </BaseButton>
        </div>
        <BaseButton v-if="this.$route.name !== 'settings_create_company_route' && $can('manage_content')"
                    color="warning"
                    :icon="editionMode ? 'times' : 'pen'"
                    @click="getCompanyAndChangeEditionMode"
                    :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
        <BaseButton color="dark"
                    icon='long-arrow-left'
                    @click="this.$router.push({name: 'settings_list_company_route'})"
                    :title="$t('button.back') "/>
      </div>
    </template>
    <template #body>
      <div class="grid grid-cols-2 gap-20 mx-10 mb-5">
        <div class="col-start-1 my-5">
          <BaseShowEditInput :label="$t('attributes.name')"
                             v-model="company.name"
                             :errors="errors.name"
                             :placeholder="$t('attributes.name')"
                             :editionMode="editionMode"
                             :required="true"/>
          <BaseShowEditInput :label="$t('attributes.TVA')"
                             v-model="company.TVA"
                             :errors="errors.TVA"
                             :placeholder="$t('attributes.TVA')"
                             :editionMode="editionMode"
          />
          <BaseShowEditInput :label="$t('attributes.bank')"
                             v-model="company.bank"
                             :errors="errors.bank"
                             :placeholder="$t('attributes.bank')"
                             :editionMode="editionMode"
          />
          <BaseShowEditInput :label="$t('attributes.email')"
                             v-model="company.email"
                             :errors="errors.email"
                             :placeholder="$t('attributes.email')"
                             :editionMode="editionMode"
                             :required="true"/>
          <BaseLanguageShowEditSelectSimple v-model='company.language'
                                            :metadata='metadata'
                                            :errors='errors'
                                            :edition-mode='editionMode'/>
        </div>
        <div class="col-start-2 my-5">
          <BaseShowEditInput :label="$t('attributes.street')"
                             v-model="company.street"
                             :errors="errors.street"
                             :placeholder="$t('attributes.street')"
                             :editionMode="editionMode"
          />
          <div class="flex flex-row mt-3 space-x-3">
            <BaseShowEditInput :label="$t('attributes.post_code')"
                               v-model="company.post_code"
                               :errors="errors.post_code"
                               :placeholder="$t('attributes.post_code')"
                               :editionMode="editionMode"
            />
            <BaseShowEditInput :label="$t('attributes.city')"
                               v-model="company.city"
                               :errors="errors.city"
                               :placeholder="$t('attributes.city')"
                               :editionMode="editionMode"
            />
          </div>
          <BaseCountryShowEditSelectSimple v-model='company.country'
                                           :metadata='metadata'
                                           :errors='errors'
                                           :edition-mode='editionMode'/>
          <BaseShowEditPhoneInput :label="$t('attributes.phone')"
                                  :editionMode='editionMode'
                                  name="phone"
                                  v-model:countryValue="company.phone_country"
                                  v-model:numberValue="company.phone_field"
                                  :errors="errors"
                                  @change.self="getInputValue"
                                  :metadata="metadata"/>
          <BaseShowEditInput :label="$t('attributes.website')"
                             v-model="company.website"
                             :errors="errors.website"
                             :placeholder="$t('attributes.website')"
                             :editionMode="editionMode"
          />
        </div>
      </div>
      <!--      <div class='flex justify-end mr-10 pb-5' v-if="editionMode && this.$can('manage_content')">-->
      <!--        <BaseButton :isElevated='true'-->
      <!--                    icon='paper-plane'-->
      <!--                    @click='submitForm'-->
      <!--                    :title="$t(buttonText, { var: $t('global.company')} ) ">-->
      <!--        </BaseButton>-->
      <!--      </div>-->
    </template>
  </SettingsPageFull>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'TheCompanyFormPage',
  data() {
    return {
      editionMode: false
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getSelectedCompany',
      errors: 'auth/getErrors',
      metadata: 'company/getCompanyMetadata',
    }),
    buttonText() {
      return this.$route.name === 'settings_create_company_route' ? 'button.add_new_f' : 'button.edit_new_f'
    },
    pageTitle() {
      return this.company ? this.company.name : ''
    }
  },
  methods: {
    ...mapActions({
      fetchCompanyByID: 'company/fetchCompanyByID',
      fetchNewCompany: 'company/fetchNewCompany',
      postNewCompany: 'company/storeNewCompany',
      putUpdateCompany: 'company/updateCompany'
    }),
    getCompanyAndChangeEditionMode() {
      this.editionMode ? this.fetchCompanyByID(this.$route.params.id) : {}
      this.editionMode = !this.editionMode
    },
    async submitForm() {
      if (this.$route.name === 'settings_create_company_route') {
        await this.postNewCompany({
          company: this.company,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.created_f', {var: this.$t('global.company')}), {className: 'toastify-content success'})
            this.$router.push({name: 'settings_list_company_route'})
          }
        })
      } else {
        await this.putUpdateCompany({
          id: this.$route.params.id,
          company: this.company,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('global.company')}), {className: 'toastify-content success'})
            this.editionMode = false
          }
        })
      }
    },
  },
  async beforeMount() {
    this.$route.name !== 'settings_create_company_route' ?
      (await this.fetchCompanyByID(this.$route.params.id)) : (await this.fetchNewCompany(), this.editionMode = true)
  },
}
</script>
