<template>
  <div>
    <div>
      <TheDarkModeSwitcher/>
      <div class="container sm:px-10">
        <div class="block xl:grid grid-cols-2 gap-4">

          <LoginLeftSide/>

          <LoginForm/>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheDarkModeSwitcher from '../../layouts/dark-mode-switcher/TheDarkModeSwitcher.vue'
import LoginForm from './LoginForm'
import LoginLeftSide from './LoginLeftSide'
import cash from 'cash-dom/dist/cash'

/*
 * @group Pages
 * Renders the Home Page
 */
export default {
  name: 'LoginPage',
  components: {
    TheDarkModeSwitcher,
    LoginForm,
    LoginLeftSide
  },
  data() {
    return {}
  },
  mounted() {
    cash('body')
      .removeClass('main')
      .removeClass('error-page')
      .addClass('login')
  }
}
</script>
