export default {
  getBanksList(state){
    return state.banksList
  },
  getBank(state){
    return state.bank
  },
  getBankMetadata(state){
    return state.bankMetadata
  }
}
