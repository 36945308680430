<template>

  <hr class='my-3'>

  <BaseCardSection :sectionTitle="$t('global.assets')">
    <template #default>
      <ToastUndoComponent :text="$t('toasts.deleted_f', {var: $t('attributes.asset')}) "/>
      <div class='flex'>
        <div class='flex-1'>
          <BaseSelectMultiple
              v-if="metadata.type"
              :placeholder="$t('global.filter')"
              :options='metadata.type'
              name='type'
              label=""
              @setSelected="selectChange"
          />
        </div>
        <div class='flex-1 flex justify-end'>
          <BaseButton
              class="mt-10 h-10"
              type='primary'
              icon='plus'
              v-if="editionMode && $can('manage_content')"
              @click='redirectToForm'
              :title="$t('button.add_new_f', { var: $t('global.asset') })"
          />
        </div>
      </div>
    </template>
  </BaseCardSection>

  <div class="grid grid-cols-3 gap-4 py-6">
    <template v-for="asset in filteredAssetsList">
      <div class="border border-gray-300 rounded-md p-5" :class="editionClass" >
        <div class='flex'>
          <div class='flex-1'>
            <h3 class="text-xl font-medium">
              <router-link :to="{ name: 'settings_edit_asset_route', params: { id: asset.id } }">
                {{ asset.name }}
              </router-link>
            </h3>
            <div class='my-4'>
              <BaseLabel :content="mAssetType( asset.type )"/>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ImplantationAssetIndexComponent',
  props: {
    assets: {
      type: Array,
      default: []
    },
    implantationId:{
      type: Number,
      required: true
    },
    editionMode: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      focusAsset: '',
      filters: [],
    }
  },
  computed: {
    ...mapGetters({ metadata: 'asset/getAssetMetadata' }),
    filteredAssetsList(){
      let tempAssets = this.assets
      if(this.filters.length !== 0){
        tempAssets = tempAssets.filter((asset) => {
          return this.filters.includes(asset.type)
        })
      }
      return tempAssets
    },
  },
  methods: {
    ...mapActions({
      deleteAssetById: 'asset/deleteAssetById',
      undoDeleteAssetById: 'asset/undoDeleteAssetById'
    }),
    redirectToForm() {
      this.$router.push({name:'settings_create_asset_route', params: { id: this.implantationId }})
    },
    selectChange({attribute, value}){
      this.filters = value.map(el=>el.id)
      this.filteredAssetsList = this.filteredAssetsList.filter(el => this.filters.includes(el.type))
    },
    mAssetType( type ){
      let item = this.metadata.type ? this.metadata.type.find( item => item.id === type ) : null;
      return item ? item.name : ''
    },
    deleteAsset(id){
      this.deleteAssetById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-settings").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteAsset(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteAsset(id){
      this.undoDeleteAssetById({
        id: id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_f', {var: this.$t('attributes.asset')})  , {className: 'toastify-content success'})
        }
      })
    }
  },
}
</script>

