import apiClient from '@u/apiClient'
import router from '@/router'
import Bugsnag from "@bugsnag/js";

export default {

    //region login
    async login({commit}, loginData) {
        await apiClient.get('/sanctum/csrf-cookie')
            .then(async response => {
                await apiClient.post('/login', loginData.auth_form).then(async (response) => {
                    if (response.data && response.data.auth && response.data.expiration) {
                        commit('setAuth', {
                            auth: response.data.auth,
                            expiration: response.data.expiration
                        })
                        Bugsnag.leaveBreadcrumb('connection',response.data.auth.full_name)
                        commit('settings/setSystemSettings', response.data.settings,{root:true})
                        commit('setPermissions', response.data.auth)
                        if (loginData.successCallback) {
                            loginData.successCallback()
                        }
                    }
                })
            })
            .catch(async reason => {
                console.error('login', reason)
            })
    },
    //endregion

    //region logout
    async logout({commit}, payload) {
        await commit('setAuth', {auth: {}, expiration: null, errors: []})
        localStorage.removeItem('vuex')
        router.push({name: 'login'})
    },
    //endregion

    //region refreshAuth
    async refreshAuth({commit, state}, payload) {
        apiClient.get('/sanctum/csrf-cookie')
            .then(async response => {
                await apiClient.get('/api/refreshAuth').then(async response => {

                    await commit('setAuth', {
                        auth: response.data.auth,
                        expiration: response.data.expiration
                    })
                    await commit('setPermissions', response.data.auth)
                    await commit('settings/setSystemSettings', response.data.settings, {root: true})
                }).catch(error => {

                    commit('auth/setErrors', {

                        errors: error.response
                    })
                })
            })
    },
    //endregion

    //region init pusher
    async initPusher({commit, state}, payload) {
     apiClient.get('api/getPusherKey').then(async response => {
         payload.successCallback(response.data.key)
     })
         .catch(error => {console.error(error)})

    }
    //endregion
}
