<template>
  <div class="intro-x relative mr-3 sm:mr-6">
    <SearchAllComponentInput
      @blur="resetSearch"/>

    <a class="notification sm:hidden" href="">
      <SearchIcon class="notification__icon dark:text-gray-300"/>
    </a>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0">

      <div class="search-result z-500" :class="{ show: searchDropdown }">
        <div class="search-result__content">

          <div class='flex flex-row justify-end cursor-pointer' @click='resetSearch'>
            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div>

          <div v-if='searchResult'>
            <!--#region Work -->
            <div v-if='searchResult["App\\\Models\\\Work"]' class='my-2'>

              <div class="search-result__content__title flex items-center gap-x-5">
                <div class="w-8 h-8 bg-theme-red text-white flex items-center justify-center rounded-full">
                  <font-awesome-icon :icon="['fal', 'folder-open']" />
                </div>
                <div class='capitalize-first'>{{$t('global.work')}}</div>
              </div>

              <div v-for='(obj, index) in searchResult["App\\\Models\\\Work"]' class="mb-2">
                <div :class="index %2 === 0 ? 'ml-10 my-1 cursor-pointer' : 'ml-10 my-1 cursor-pointer bg-gray-100'"  @mousedown="pushToWork(obj.searchable.id)">
                  <p><span>{{obj.searchable.title}}</span></p>
                </div>
              </div>

            </div>
            <!--#endregion -->

            <!--#region company -->
            <div v-if='searchResult["App\\\Models\\\Company"]' class='my-2'>

              <div class="search-result__content__title flex items-center gap-x-5">
                <div class="w-8 h-8  bg-theme-red text-white flex items-center justify-center rounded-full">
                  <font-awesome-icon :icon="['fal', 'landmark']" />
                </div>
                <div class='capitalize-first'>{{$t('global.company')}}</div>
              </div>

              <div v-for='(obj, index) in searchResult["App\\\Models\\\Company"]' class="mb-2">
                <div :class="index %2 === 0 ? 'ml-10 my-1 cursor-pointer' : 'ml-10 my-1 cursor-pointer bg-gray-100'"  @mousedown="pushToCompany(obj.searchable.id)">
                  <p><span>{{obj.searchable.name}}</span></p>
                </div>
              </div>

            </div>
            <!--#endregion -->

            <!--#region implantation -->
            <div v-if='searchResult["App\\\Models\\\Implantation"]' class='my-2'>

              <div class="search-result__content__title flex items-center gap-x-5">
                <div class="w-8 h-8  bg-theme-red text-white flex items-center justify-center rounded-full">
                  <font-awesome-icon :icon="['fal', 'hotel']" />
                </div>
                <div class='capitalize-first'>{{$t('global.implantation')}}</div>
              </div>

              <div v-for='(obj, index) in searchResult["App\\\Models\\\Implantation"]' class="mb-2">
                <div :class="index %2 === 0 ? 'ml-10 my-1 cursor-pointer' : 'ml-10 my-1 cursor-pointer bg-gray-100'"  @mousedown="pushToImplantation(obj.searchable.id)">
                  <p><span>{{obj.searchable.name}}</span></p>
                </div>
              </div>

            </div>
            <!--#endregion -->

            <!--#region asset -->
            <div v-if='searchResult["App\\\Models\\\Asset"]' class='my-2'>

              <div class="search-result__content__title flex items-center gap-x-5">
                <div class="w-8 h-8  bg-theme-red text-white flex items-center justify-center rounded-full">
                  <font-awesome-icon :icon="['fal', 'landmark']" />
                </div>
                <div class='capitalize-first'>{{$t('global.asset')}}</div>
              </div>

              <div v-for='(obj, index) in searchResult["App\\\Models\\\Asset"]' class="mb-2">
                <div :class="index %2 === 0 ? 'ml-10 my-1 cursor-pointer' : 'ml-10 my-1 cursor-pointer bg-gray-100'"   @mousedown="pushToAsset(obj.searchable.id)">
                  <p><span>{{obj.searchable.name}}</span></p>
                </div>
              </div>

            </div>
            <!--#endregion -->

            <!--#region item -->
            <div v-if='searchResult["App\\\Models\\\Item"]' class='my-2'>

              <div class="search-result__content__title flex items-center gap-x-5">
                <div class="w-8 h-8  bg-theme-red text-white flex items-center justify-center rounded-full">
                  <font-awesome-icon :icon="['fal', 'ticket']" />
                </div>
                <div class='capitalize-first'>{{$t('global.item')}}</div>
              </div>

              <div v-for='(obj, index) in searchResult["App\\\Models\\\Item"]' class="mb-2">
                <div :class="index %2 === 0 ? 'ml-10 my-1 cursor-pointer' : 'ml-10 my-1 cursor-pointer bg-gray-100'"  @mousedown="pushToItem(obj.searchable.id)">
                  <p><span>{{obj.title}}</span></p>
                </div>
              </div>

            </div>
            <!--#endregion -->

            <!--#region user -->
            <div v-if='searchResult["App\\\Models\\\User"]' class='my-2'>

              <div class="search-result__content__title flex items-center gap-x-5">
                <div class="w-8 h-8  bg-theme-red text-white flex items-center justify-center rounded-full">
                  <font-awesome-icon :icon="['fal', 'user']" />
                </div>
                <div class='capitalize-first'>{{$t('global.user')}}</div>
                <hr/>
              </div>

              <div v-for='(obj, index) in searchResult["App\\\Models\\\User"]' class="mb-2">
                <div :class="index %2 === 0 ? 'ml-10 my-1 cursor-pointer' : 'ml-10 my-1 cursor-pointer bg-gray-100'"  @mousedown="pushToUser(obj.searchable.id)">
                  <p><span>{{obj.title}}</span></p>
                </div>
              </div>

            </div>
            <!--#endregion -->
          </div>

          <div v-else>
            non
          </div>

        </div>
      </div>
    </transition>

    </div>

</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import SearchAllComponentInput from '@c/layouts/top-bar/components/SearchAllComponentInput'

export default {
  name: 'SearchAllComponent',
  components: { SearchAllComponentInput },
  computed: {
    ...mapGetters({searchResult: 'globalSearch/getSearchResult', searchValue: 'globalSearch/getSearchValue', searchDropdown: 'globalSearch/getSearchDropdown'}),
    test(){
    }
  },
  methods: {
    ...mapActions({
      fetchWorkByID: 'work/fetchWorkByID',
      fetchCompanyByID: 'company/fetchCompanyByID',
      fetchImplantationByID: 'implantation/fetchImplantationByID',
      fetchAssetByID: 'asset/fetchAssetByID',
      fetchNewAsset: 'asset/fetchNewAsset',
      fetchItemByID: 'item/fetchItemByID',
      fetchUserByID: 'user/fetchUserByID',
    }),
    ...mapMutations({
      resetSearchResult: 'globalSearch/resetSearchResult',
      resetSearchValue: 'globalSearch/resetSearchValue',
      setSearchDropdown: 'globalSearch/setSearchDropdown',
      unsetSearchDropdown: 'globalSearch/unsetSearchDropdown'
    }),
    resetSearch(){
      this.unsetSearchDropdown()
      this.resetSearchValue()
      this.resetSearchResult()
    },
    pushToWork(id){
      this.fetchWorkByID(id)
      this.$router.push({ name: 'work_form_edit_route', params: { id: id } })
      this.unsetSearchDropdown()
    },
    pushToCompany(id){
      this.fetchCompanyByID(id)
      this.$router.push({ name: 'settings_edit_company_route', params: { id: id } })
      this.unsetSearchDropdown()
    },
    pushToImplantation(id){
      this.fetchImplantationByID({ id: id })
      this.fetchNewAsset()
      this.$router.push({ name: 'settings_edit_implantation_route', params: { id: id } })
      this.unsetSearchDropdown()
    },
    pushToAsset(id){
      this.fetchAssetByID(id)
      this.$router.push({ name: 'settings_edit_asset_route', params: { id: id } })
      this.unsetSearchDropdown()
    },
    pushToItem(id){
      this.fetchItemByID({ id: id })
      this.router.push({ name: 'settings_edit_item_route', params: { id: id } })
      this.unsetSearchDropdown()
    },
    pushToUser(id){
      this.fetchUserByID({ id: id })
      this.$router.push({ name: 'settings_edit_user_route', params: { id: id } })
      this.unsetSearchDropdown()
    },
  },
}
</script>
