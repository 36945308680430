<template>
  <div class='grid grid-cols-4 mb-10'>
    <div class='col-start-1' v-if="editionMode">
      <input class="form-check-input" type="checkbox" :id="`checkbox-${label}`" v-model="checkBoxValue">
      <label class='ml-2 form-check-label font-semibold text-base' :for="`checkbox-${label}`">{{ label }}</label>
    </div>
    <div class='col-start-1 col-span-2' v-else>
      <h1 class="text-lg font-medium text-gray-500 capitalize-first" v-if="model.length > 0">{{ label }}</h1>
    </div>
  </div>

  <div class='grid grid-cols-5' v-if='checkBoxValue'>
    <TaskQuestionCardComponent
                      class="col-start-2 col-span-2 my-5 overflow-auto"
                      v-for='(question, index) in model'
                      :key='index'
                      :editionMode="editionMode"
                      :questionIndex='index'
                      :question="question"
                      :permission="permission"
                      :errors-form-start="errorsFormStart"
                      :errors-form-end="errorsFormEnd"
                      :errors-option-start="errorsOptionStart"
                      :errors-option-end="errorsOptionEnd"
                      @deleteButton='deleteQuestion'
    />
    <div class='col-start-3 justify-self-end'>
      <BaseButton
          v-if='checkBoxValue && editionMode'
          icon='plus'
          color='dark'
          :title="$t('button.add_new_f', {var: $t('global.question')})"
          @click="addQuestion('starting_form')"
      />
    </div>
  </div>

</template>

<script>
import { mapMutations } from 'vuex'

import TaskQuestionCardComponent from './TaskQuestionCardComponent'

export default {
  name: 'TaskQuestionComponent',
  components:{ TaskQuestionCardComponent},
  props:{
    label: {
      type: String,
      required: true
    },
    model: {
      type: Object,
      required: true
    },
    checkBoxValue: {
      type: Boolean,
      required: true
    },
    permission: {
      type: Object,
      required: true
    },
    editionMode:{
      type: Boolean,
      required: true
    },
    errorsFormStart:{
      type: Boolean,
      required: false,
      default: false
    },
    errorsFormEnd:{
      type: Boolean,
      required: false,
      default: false
    },
    errorsOptionStart:{
      type: Boolean,
      required: false,
      default: false
    },
    errorsOptionEnd:{
      type: Boolean,
      required: false,
      default: false
    },
  },
  methods:{
    ...mapMutations({alterTask: 'task/alterTask'}),
    addQuestion(){
      this.model.push({
        type: null,
        question: null,
        options: [],
        value: '',
      })
    },
    deleteQuestion(questionIndex){
      this.model.splice(questionIndex, 1)
    },
  },
  watch:{
    checkBoxValue(newValue){
      this.label === this.$t('attributes.start_task_survey') ? this.alterTask({attribute: 'form_on_start', value: newValue}) : this.alterTask({attribute: 'form_on_end', value: newValue})
    }
  }
}
</script>

