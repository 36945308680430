<template class="px-1.5">
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','file-alt']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first block'>{{ $t('navigation.message_texts') }}</span>
      </div>
    </template>

    <template #cta>
      <BaseButton color='primary' icon='plus'
                  :title="$t('button.add_new_m', {var: $t('global.message_text')})"
                  @click="openForm"/>
    </template>

    <template #body>

      <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.messageText')}) "/>

      <MessageTextFormDialog
        @backdropClicked='openForm'
        ref="eventModal"
        :isOpen="isOpen"
        :message_text_load_id="message_text_load_id"
        @close="closeDialog"
      />
      <!--    #region filter-->
      <div class='flex space-x-5 mb-5 w-full'>
        <div class="w-full">
          <BaseSelectMultiple
            v-if="metadata.types"
            :label="$t('attributes.type')"
            :placeholder="$t('attributes.type')"
            name='type_id'
            :value="null"
            :options="metadata.types"
            selectClass=" mx-4"
            @setSelected.self='handleFilterTable'
          />
        </div>
        <div class="w-full">
          <BaseSelectMultiple
            v-if="metadata.languages"
            :label="$t('attributes.language')"
            :placeholder="$t('attributes.language')"
            name='language'
            :value="null"
            :options="metadata.languages"
            selectClass=" mx-4"
            @setSelected.self='handleFilterTable'
          />
        </div>
        <div class="w-full">
          <BaseSelectMultiple
            v-if="metadata.groupes"
            :label="$t('attributes.group')"
            :placeholder="$t('attributes.group')"
            name='group_id'
            :value="null"
            :options="metadata.groupes"
            selectClass=" mx-4"
            @setSelected.self='handleFilterTable'
          />
        </div>
      </div>
      <div>
        <BaseSearchInput
          groupClass="mt-3"
          @typeInput="filterList"
        />
      </div>
      <!--    #endregion-->

      <div class="grid grid-cols-12 gap-4 mx-12 box px-6 py-5 my-4 bg-gray-200 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
        <div class=" col-span-2 ">{{ $t('attributes.language') }}</div>
        <div class="col-span-1 ">{{ $t('attributes.type') }}</div>
        <div class="col-span-6 ">{{ $t('global.message') }}</div>
        <div class="col-span-3 text-center">{{ $t('attributes.group') }}</div>
      </div>
      <SingleMessageTextComponent
        v-for="(message_text, index) in filteredMessageText"
        :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-200'"
        :key="index"
        :message_text="message_text"
        :groups="metadata.groupes"
        @delete-message="deleteMessageText"
        class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600"
        @click="openForm(message_text.id)"
      />

    </template>

  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import MessageTextFormDialog from "../form/MessageTextFormDialog";
import SingleMessageTextComponent from './components/SingleMessageTextComponent'

export default {
  name: "TheMessageTextIndexPage",
  components: {
    MessageTextFormDialog,
    SingleMessageTextComponent,
  },
  data() {
    return {
      isOpen: false,
      message_text_load_id: null,
      searchTerms: '',
      filter: {}
    }
  },
  computed: {
    ...mapGetters({
      messageTextList: 'messageText/getMessageTextList',
      metadata: "messageText/getMessageTextMetadata"
    }),
    filteredMessageText() {
      let temp_messageTexts = this.messageTextList

      if (this.searchTerms.length >= 1) {
        temp_messageTexts = temp_messageTexts?.filter((model) => {
          let search = this.searchTerms.length ? this.searchTerms.toLowerCase() : '';
          var values = this.filtrableColumns;

          var flag = false
          values.forEach((val) => {
            if (model[val] && model[val].toLowerCase().indexOf(search.toLowerCase()) > -1) {
              flag = true;
              return;
            }
          })
          if (flag) return model
        });
      }
      if(this.filter.type_id) {
        temp_messageTexts = temp_messageTexts?.filter((model) => {
          return this.filter.type_id?.find((type) => {
            return type.id.toUpperCase() === model.type
          })

        })
      }
      if(this.filter.language) {
        temp_messageTexts = temp_messageTexts?.filter((model) => {
          return this.filter.language?.find((language) => {
            return language.id === model.language
          })
        })
      }

      if(this.filter.group_id) {
        temp_messageTexts = temp_messageTexts?.filter((model) => {
          return this.filter.group_id?.find((group) => {
            return group.id === model.group
          })

        })
      }
      return temp_messageTexts
    },
    filtrableColumns() {
      return ['title','text']
    },
  },
  methods: {
    ...mapActions({
      fetchMessageTextList: 'messageText/fetchMessageTextList',
      deleteMessage: 'messageText/deleteMessageTextById',
      undoDeleteMessageTextById: "messageText/undoDeleteMessageTextById",
    }),
    openForm(id) {
      if(id) {
        this.message_text_load_id = id
      }
      this.isOpen = !this.isOpen
    },
    closeDialog() {
      this.isOpen = !this.isOpen;
    },
    filterList(terms) {
      this.searchTerms = terms
    },
    deleteMessageText(id) {
      this.deleteMessage({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-content").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteMessageText(id)
          })
          this.$h.toastifyHtml({
            close:true,
            duration: 10000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteMessageText(id) {
      this.undoDeleteMessageTextById({
        id:  id,
        successCallback: () => {}
      })
    },
    handleFilterTable({attribute, value}) {
      let temp_filter = this.filter
      if (value.length === 0) {
        delete temp_filter[attribute];
        this.filter = temp_filter
      } else {
        this.filter = {...this.filter, [attribute]: value}
      }
    },

  },
  async mounted() {
    await this.fetchMessageTextList();
  }
}
</script>
