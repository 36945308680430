import {sortEvents,updateCoffinArrival,addCoffinArrivalIfWorkDoesntHave, deleteCoffinArrival} from "../helpers/helpers";
export default {

  //#region setters

  setEventTimeSlotInWorkEvent(state, payload){
    console.log('setEventTimeSlotInWorkEvent',payload)
    state.work.events[payload.index].timeslots = payload.timeslots
  },

  //#endregion

  //#region modifiers

  alterWorkEventAttributeByIndex(state, payload){
    state.work.events[payload.index][payload.attribute] = payload.value
  },

  deleteWorkEventKeyByIndex(state, payload){
    delete state.work.events[payload.index][payload.attribute]
  },

  removeEventFromWork(state, payload) {
    state.work.events = state.work.events.filter(obj => obj.type !== payload.type)
    sortEvents(state)
  },

  removeEventKeyByEventType(state, payload) {
    let objIndex = state.work.events.findIndex((obj => obj.type === payload.type))
    delete state.work.events[objIndex][payload.key_to_delete]
    sortEvents(state)
  },

  cleanWorkEventsFromTypes(state) {
    const acceptedEventTypes = ['CRE', 'CAR', 'CER', 'REC']
    state.work.events = state.work.events.filter(event => acceptedEventTypes.includes(event.type))
    sortEvents(state)
  },

  alterWorkEventValuesByEventType(state, payload) {
    addCoffinArrivalIfWorkDoesntHave(state)

    let objIndex = state.work.events.findIndex((obj => obj.type === payload.type))
    if (objIndex !== -1) {
      state.work.events[objIndex][payload.attribute] = payload.value
    } else {
      state.work.events.push(payload)
    }
    updateCoffinArrival(state)
    sortEvents(state)
  },

  alterOrPushEventInWork(state, payload) {
    console.log('here')
    let index = state.work.events.findIndex(obj => obj.type === payload.type)
    console.log(index)
    if (index === -1) {
      state.work.events.push({ ...payload })
    } else {
      state.work.events[index] = { ...state.work.events[index], ...payload }
    }
    console.log(state.work.events)
    if(payload.event_kind_id && payload.event_kind_id != 5){
    updateCoffinArrival(state)
    sortEvents(state)
    } else {
      if(state?.work?.events?.find(event => event.type === 'CAR')){
        deleteCoffinArrival(state)
      }
    }
  },

  //#endregion

}
