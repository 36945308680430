import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import { getField, updateField } from 'vuex-map-fields';

export default {
  namespaced: true,
  state() {
    return {
      worksList: [],
      work: {deceased:{}, work_items: {} },
      workActivities: [],
      workMetadata: {},
      selectedWorkTask: null,
    }
  },
  getters: {
    ...getters,
    getField
  },
  actions,
  mutations: {
    ...mutations,
    updateField
  }
}
