<template>
  <div class="bg-white shadow-md px-4 pb-3 pt-1 rounded-lg sm:px-6 overflow-visible">
      <div class='flex-1 flex flex-row justify-end mt-10 gap-x-5' v-if="editionMode">
        <BaseButton
            icon='times'
            :tooltip="$t('button.delete_f', {var: $t('attributes.question')})"
            color='danger'
            @click="emitDeleteButton"
        />
      </div>
    <div class='grid grid-cols-2 gap-x-5'>
      <div class="">
        <BaseSelectSimple :label="$t('attributes.form_field_type')"
                          v-if="editionMode"
                          :placeholder="$t('attributes.form_field_type')"
                          name='type'
                          :defaultValue="test?.find(el => el.id === question.type)"
                          :options='test'
                          :disabled="permission"
                          @setSelected.self='getInputValue'
        />
        <template >
          <BaseShowLabel
              :label="$t('attributes.form_field_type')"
              :model-value="test?.find(el => el.id === question.type)?.name"
          />
        </template>
      </div>
      <div>
        <BaseInput v-if="editionMode"
                  :label="$t('attributes.question')"
                   :placeholder="$t('attributes.question')"
                   name='question'
                   v-model='question.question'
                   :disabled="permission"
                />
        <BaseShowLabel
            v-else
            :label="$t('attributes.question')"
            :model-value="question.question"
        />
      </div>
    </div>
    <div v-if="editionMode && (question.type === 'select' || question.type === 'radio') " class="flex justify-between">
      <div v-if="(errorsFormStart || errorsFormEnd) && question.options.length < 2" class="text-xs text-red-700"> Veuillez ajouter au moins 2 options</div>
      <div v-else></div>
      <BaseButton
          class='col-start-4 mt-1 w-8'
          :tooltip="$t('button.add_new_f', {var: $t('attributes.option')})"
          icon='plus'
          color='warning'
          @click="addOption"
      />
    </div>

    <div v-if="question.type === 'select' || question.type === 'radio' " :class="!editionMode ? 'pt-4' : ''" >
      <div v-if="!editionMode && question.options.length > 0" class="text-sm font-medium text-gray-500 mb-1.5 capitalize-first">Options</div>
      <div>
        <TaskOptionComponent
            v-for='(option, index) in question.options'
            :key="index"
            :option="option"
            :optionIndex='index'
            :permission='permission'
            :editionMode="editionMode"
            :errors-option-start="errorsOptionStart"
            :errors-option-end="errorsOptionEnd"
            @optionChange='getOptionInputValue'
            @deleteOptionButton="deleteOption(index)"/>
      </div>
    </div>

  </div>
</template>

<script>
import TaskOptionComponent from './TaskOptionComponent'

export default {
  name: 'TaskQuestionCardComponent',
  components:{
    TaskOptionComponent,
  },
  props:{
    question:{
      type: Object,
      required: true
    },
    questionIndex:{
      type: Number,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    permission: {
      type: Object,
      required: false
    },
    editionMode:{
      type: Boolean,
      required: true
    },
    errorsFormStart:{
      type: Boolean,
      required: false,
      default: false
    },
    errorsFormEnd:{
      type: Boolean,
      required: false,
      default: false
    },
    errorsOptionStart:{
      type: Boolean,
      required: false,
      default: false
    },
    errorsOptionEnd:{
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      optionId: 0,
      test: [
          {id: 'input', name: this.$t('enums.task.inputs.input')},
          {id: 'textArea', name: this.$t('enums.task.inputs.textArea')},
          {id: 'select', name: this.$t('enums.task.inputs.select')},
          {id: 'radio', name: this.$t('enums.task.inputs.radio')}
      ]
    }
  },
  methods:{
    getInputValue({attribute, value}){
      this.question[attribute] = value
    },
    getOptionInputValue({attribute, value, optionIndex}){
      this.question.options[optionIndex].name = value
    },
    emitDeleteButton(){
      this.$emit('deleteButton', this.questionIndex)
    },
    addOption(){
      this.optionId++
      this.question.options.push({id: this.optionId, name: ''})
    },
    deleteOption(index){
      this.question.options.splice(index, 1)
    },
  }
}
</script>

