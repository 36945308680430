<template>
  <p>{{ invoice.customer.name }}</p>
  <p>{{ invoice.customer.street }}</p>
  <p>{{ invoice.customer.post_code }} {{ invoice.customer.city}}</p>
</template>
<script>
export default {
  name: 'InvoicingCustomerInfosComponent',
  props: {
     invoice: {
       type: Object,
       required: true
     }
  }
}
</script>
