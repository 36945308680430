import apiClient from '@u/apiClient'

let base_errors = {}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default{

  getGlobalSearchResult({commit}, payload){
    // commit('setSearchResult', payload)
    apiClient.get(`api/search-global?term=${payload}`)
      .then(async response => {
        commit('setSearchResult', response.data)
    }).catch(async reason => {
      console.error(reason)
    })
  }

}
