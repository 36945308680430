<template>
  <div class="flex flex-col sm:flex-row items-center mt-8">
    
    <h2 class="w-full md:w-auto text-lg font-medium mr-auto flex-1 text-center sm:text-left">
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','analytics']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.stats') }}</span>
      </div>
    </h2>
    <div class="flex flex-col md:flex-row flex-1 justify-end mt-4 sm:mt-0">
      
      
      <BaseButton color='primary' icon='plus'
                  :title="$t('button.add_new_m', {var: $t('global.stats_bloc')})"
                  @click="addBlock"/>
    
    </div>
  </div>
  
  
  <div
    class="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0  sm:gap-px mt-9 ">
    <div v-for="(block,index) in statsBlocks" :key="index"
         :class="[index === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
         index === 1 ? 'sm:rounded-tr-lg' : '',
         index === statsBlocks.length - 2 ? 'sm:rounded-bl-lg' : '',
         index === statsBlocks.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
         'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 mb-6']">
      <StatsBlockComponent @clickRemove="handleRemove" :index="index" :block="block"/>
    </div>
  </div>


</template>

<script>
import {mapActions, mapGetters} from "vuex";

import StatsBlockComponent from "@p/stats/components/StatsBlockComponent";

export default {
  name: "StatsIndex",
  components: {
    StatsBlockComponent,
    
  },
  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      search: '',
      isOpen: false,
      note_load_id: null,
      searchTerms: '',
      filter: {},
      emission_date: '',
      date_formatter: {
        date: 'DD/MM/YYYY',
        month: 'MM'
      },
    }
  },
  computed: {
    ...mapGetters({
      statsBlocks: 'stats/getStatsBlock',
      metadata: "work/getWorkMetadata"
    }),
  },
  methods: {
    ...mapActions({
      fetchStatIndex: 'stats/fetchStatIndex',
    }),
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
    addBlock(id) {
      console.log('add')
      this.statsBlocks.push({form:{}})
    },
    handleRemove(index) {
      console.log('remove')
      this.statsBlocks.splice(index, 1)
    },
    
    closeDialogNote() {
      this.isOpen = !this.isOpen;
    },
    
    
  },
  beforeMount() {
    this.fetchStatIndex()
  }
}
</script>
