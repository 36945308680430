<template>
  <BaseSimpleCard :title="$t('global.documents')" name="company-form">
    <template #default>
      <div class='grid grid-cols-1 gap-4 sm:grid-cols-2'>

          <div v-for='document in documents'
               class="relative rounded-lg border border-gray-300 bg-gradient-to-br from-gray-100 to-gray-50 px-6 py-5 shadow-sm hover:shadow-lg flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
               @click="openDialogDocument(document)">

            <WorkFormDocumentDialog @backdropClicked='openForm'
                                    ref="eventModal"
                                    :isOpen="isOpen"
                                    :document="singleDocument"
                                    @close="closeDialog"/>

            <div class="flex-shrink-0">
              <div class="h-10 w-10 rounded-md bg-theme-red-lighter text-white flex justify-center items-center">
                <font-awesome-icon :icon='["fal","file-pdf"]' size='lg' />
              </div>
            </div>

            <div class="flex-1 min-w-0">
              <div>
                <span class="absolute inset-0" aria-hidden="true"></span>
                <p class="text-sm font-medium text-gray-900">{{ document.name }}</p>
                <p class="text-sm text-gray-500 truncate">{{ document.description }}</p>
              </div>

              <router-link :to='{ name: "work_form_view_document_route", params: {document_id: document.id} }' class="focus:outline-none" v-if="!document.with_form ">
                <span class="absolute inset-0" aria-hidden="true"></span>
              </router-link>
            </div>

          </div>

      </div>
    </template>
  </BaseSimpleCard>
</template>

<script>
import WorkFormDocumentDialog from './components/WorkFormDocumentDialog'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheWorkDocumentsPage',
  components: { WorkFormDocumentDialog },
  computed: {
    ...mapGetters({ work: 'work/getWork', documents: 'document/getDocuments', singleDocument : 'document/getDocument' }),
  },
  data() {
    return { isOpen : false }
  },
  methods: {
    ...mapActions('document', ['fetchDocumentsList']),
    ...mapActions({fetchDocument: 'document/fetchDocument'}),
    openDialogDocument(doc) {
      if(doc.with_form && doc.form ) {
        this.isOpen = !this.isOpen
      }
      this.fetchDocument({document_id: doc.id});
    },
    closeDialog() {
      this.isOpen = !this.isOpen;
    },
    openForm() {
      this.isOpen = false;
    },
  },
  mounted() {
    this.fetchDocumentsList();
  }
}
</script>

