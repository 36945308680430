<template>
  <div
      class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg my-3">
    <h3 class="text-xl leading-6 font-medium my-3 px-4 mt-10 ml-4 capitalize-first">
      {{ $t('enums.notification_settings.' + index) }}</h3>
        <div class=" px-12 pb-12 mb-8 grid grid-col-2">
          <div v-for="(item, index) in setting" :class="item.class" class="m-5">
            <div class="mt-2 mb-2 ">
              <template
                  v-if="!item['visible'] || notificationSettings[Object.keys(item['visible'])[0]] == Object.values(item['visible'])[0]">
                {{}}
                <DynamicFormInput
                    :key="index"
                    @questionValue="getQuestionValue"
                    :question="item"
                    :questionIndex="index"
                    :vertical_align="false"
                    :defaultValue="notificationSettings[item.name]"
                    :isDefaultValue="true"
                />
              </template>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import {mapMutations} from "vuex";

// import DynamicFormInput from "../../../../../baseComponents/form/DynamicFormInput"

export default {
  name: 'SingleNotificationSettingComponent',
  // components: {DynamicFormInput},
  props: {
    setting: {
      type: Object,
      required: true
    },
    index: {
      type: String,
      required: true
    },
    notificationSettings: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations({alterNotificationSettings: 'notificationSettings/alterNotificationSettings'}),
    getQuestionValue({attribute, value}) {
      this.alterNotificationSettings({
        notificationSettings: this.notificationSettings,
        attribute: attribute,
        value: value
      })
    }
  },

}
</script>
