import * as featherIcons from '@zhuowenli/vue-feather-icons'
// import BaseDialog from '../dialog/BaseDialog'
// import Dropzone from './dropzone/Main.vue'
import filepond from 'filepond'
// import FullCalendar from './calendar/Main.vue'
// import FullCalendarDraggable from './calendar/Draggable.vue'
// import Highlight from './highlight/Main.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import TomSelect from './tom-select/Main.vue'
import vSelect from 'vue-select'
import VueDragResize from 'vue-drag-resize'

// FontAwesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faFunnelDollar,
  faAlarmExclamation,
  faAnalytics,
  faArrowRight,
  faArrowAltFromLeft,
  faArrowSquareDown,
  faBadgeDollar,
  faBolt,
  faBold,
  faArchive,
  faBookOpen,
  faCalculator,
  faCalendarAlt,
  faCalendarDay,
  faCalendarMinus,
  faCheck,
  faList,
  faCheckCircle,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faClock,
  faCogs,
  faCompressAlt,
  faCopy,
  faCreditCard,
  faDatabase,
  faDigging,
  faEdit,
  faEuroSign,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileAlt,
  faFileCsv,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePlus,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faFont,
  faHotel,
  faFileImage,
  faFileExcel,
  faFilePowerpoint,
  faFileWord,
  faIndent,
  faItalic,
  faLandmark,
  faListUl,
  faListOl,
  faLongArrowLeft,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faMoneyCheckAlt,
  faMoon,
  faPaperclip,
  faPaperPlane,
  faParagraph,
  faPen,
  faPercent,
  faPhoneAlt,
  faPiggyBank,
  faPlus,
  faPrint,
  faQuestionCircle,
  faQuoteLeft,
  faRedo,
  faQuestionSquare,
  faSearch,
  faCheckSquare,
  faSignOut,
  faSlidersH,
  faSort,
  faSortDown,
  faSortUp,
  faSpinnerThird,
  faSun,
  faStrikethrough,
  faSwatchbook,
  faSync,
  faSyncAlt,
  faTasks,
  faTicket,
  faTimes,
  faUndo,
  faUndoAlt,
  faUnlock,
  faUser,
  faUsers,
  faExclamation,
  faInfoSquare,
  faEnvelope,
  faBell,
  faBellOn,
  faLockOpen,
  faLock,
  faPersonDolly,
  faQuestion,
  faSortAlt,
  faLightbulbExclamation,
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faAlarmExclamation,
  faAnalytics,
  faArrowRight,
  faArrowAltFromLeft,
  faArrowSquareDown,
  faArchive,
  faBadgeDollar,
  faBell,
  faBolt,
  faBold,
  faBookOpen,
  faCalculator,
  faCalendarAlt,
  faCalendarDay,
  faCalendarMinus,
  faCheck,
  faList,
  faCheckCircle,
  faChevronDoubleLeft,
  faChevronDoubleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleNotch,
  faClock,
  faCogs,
  faCompressAlt,
  faCopy,
  faCreditCard,
  faDatabase,
  faDigging,
  faEdit,
  faEuroSign,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faFile,
  faFileAlt,
  faFileCsv,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePlus,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faHotel,
  faFileImage,
  faFileExcel,
  faFileWord,
  faFilePowerpoint,
  faFont,
  faFunnelDollar,
  faIndent,
  faItalic,
  faLandmark,
  faListUl,
  faListOl,
  faLongArrowLeft,
  faLightbulbExclamation,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faMinus,
  faMoneyCheckAlt,
  faMoon,
  faPaperclip,
  faPaperPlane,
  faParagraph,
  faPen,
  faPercent,
  faPhoneAlt,
  faPiggyBank,
  faPlus,
  faPrint,
  faQuestionCircle,
  faQuoteLeft,
  faRedo,
  faQuestionSquare,
  faSearch,
  faSignOut,
  faSlidersH,
  faCheckSquare,
  faSort,
  faSortDown,
  faSortUp,
  faSpinnerThird,
  faStrikethrough,
  faSun,
  faSwatchbook,
  faSync,
  faSyncAlt,
  faTasks,
  faTicket,
  faTimes,
  faUndo,
  faUndoAlt,
  faUnlock,
  faUser,
  faUsers,
  faExclamation,
  faUsers,
  faBold,
  faItalic,
  faStrikethrough,
  faFont,
  faParagraph,
  faListOl,
  faInfoSquare,
  faEnvelope,
  faBellOn,
  faLockOpen,
  faLock,
  faPersonDolly,
  faQuestion,
  faSortAlt,
)


export default app => {
  // app.component('Chart', Chart)
  // app.component('GoogleMapLoader', GoogleMapLoader)
  // app.component('Highlight', Highlight)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('TomSelect', TomSelect)
  // app.component('LoadingIcon', LoadingIcon)
  // app.component('TinySlider', TinySlider)
  // app.component('Dropzone', Dropzone)
  app.component('filePond', filepond)
  // app.component('CKEditor', CKEditor)
  // app.component('FullCalendar', FullCalendar)
  // app.component('FullCalendarDraggable', FullCalendarDraggable)
  app.component('font-awesome-icon', FontAwesomeIcon)
  
  // app.component('BaseDialog', BaseDialog)
  app.component('v-select', vSelect)
  app.component('vue-drag-resize', VueDragResize)
  
  
  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
