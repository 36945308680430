<template>

  <BaseSimpleCard name='invoice body' title='' classes='col-start-2 col-span-10 min-h-screen' :borderedHeader='false'>
    <!--    // TODO: trad:  Impayé quand unpaid-->
    <InvoicingIndexTable
      v-if='metadata?.columns'
      :columns='metadata.columns'
      :metadata='metadata'
      :modelList='invoicesList'
      :search='true'
      @rowClick='redirectToModelPage'
      @refreshClick='fetchAllInvoices'
      @deleteModel='deleteModelById'
      :isHoverable='true'
      :isResponsive='true' />
  </BaseSimpleCard>

</template>

<script>

import { mapActions, mapGetters, mapMutations } from 'vuex'

import InvoicingIndexTable from './Table/InvoicingIndexTable'

export default {
  name: 'TheInvoicingIndexPage',
  components: {
    InvoicingIndexTable
  },
  computed: {
    ...mapGetters({ invoicesList: 'invoicing/getInvoiceIndex', metadata: 'invoicing/getInvoiceMetadata' }),
    title() {
      return this.$route.meta.doc_type
    }
  },
  methods: {
    ...mapActions({
      fetchInvoiceIndex:'invoicing/fetchInvoiceIndex',
      fetchUnpaidInvoiceIndex:'invoicing/fetchUnpaidInvoiceIndex',
      fetchPaidInvoiceIndex: 'invoicing/fetchPaidInvoiceIndex',
      deleteInvoiceById:'invoicing/deleteInvoiceById'
    }),
    ...mapMutations({collapse: 'sideMenu/collapse'}),
    redirectToModelPage(id) {
      this.$router.push({ name: 'invoice_info_route', params: { invoicing_id: id } })
    },
    deleteModelById(val) {
      this.deleteInvoiceById({ invoice_id: val })
    },
    fetchAllInvoices() {
        switch (this.$route.name) {

          case 'invoice_unpaid_index_route':
            this.fetchUnpaidInvoiceIndex()
            break
          case 'invoice_paid_index_route':
            this.fetchPaidInvoiceIndex()
            break
          default:
            this.fetchInvoiceIndex(this.$route.meta.doc_type)
        }
    }
  },
  watch: {
    '$route.name'(newValue) {
      this.fetchAllInvoices()
    }
  },
  mounted() {
    this.fetchAllInvoices()
    this.collapse()
  }
}
</script>

