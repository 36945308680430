import apiClient from '@u/apiClient'
import router from '@/router'

import CheckActions from './check/actions'
import InvoiceItemAction from './invoiceItem/actions'
import PaymentActions from './payment/actions'
import fileDownload from 'js-file-download';

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  ...CheckActions,
  ...InvoiceItemAction,
  ...PaymentActions,

  //region fetch
  async fetchInvoiceIndex({ commit }, doc_type) {
    await apiClient.get(`api/invoicing?doc_type=${ doc_type }`)
      .then(async response => {
        await commit('setInvoiceIndex', response.data.invoices)
        await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch(reason => {
        if (reason.response.status === 422) {
          commit('setErrors', reason.response.data.errors)
        }
      })
  },

  async fetchUnpaidInvoiceIndex({ commit }) {
    await apiClient.get(`api/unpaid/invoicing`)
      .then(async response => {
        await commit('setInvoiceIndex', response.data.invoices)
        await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch(async reason => {
        await commit('setErrors', reason.response.data.errors)
      })
  },

  async fetchPaidInvoiceIndex({ commit }) {
    await apiClient.get(`api/paid/invoicing`)
      .then(async response => {
        await commit('setInvoiceIndex', response.data.invoices)
        await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch(async reason => {
        await commit('setErrors', reason.response.data.errors)
      })
  },
  async fetchInvoiceID({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient.get(`api/invoicing/${ payload.id }`)
      .then(async response => {
        await commit('setInvoice', response.data.invoice)
        await commit('setInvoiceMetadata', response.data.metadata)
      })
      .catch(reason => {
        if (reason.response.status === 422) {
          commit('setErrors', reason.response.data.errors)
        }
      })
  },
  async fetchNewInvoice({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })

    await apiClient.get(`api/model/new`, { params: { ...payload, model: 'invoice' } })
      .then(async response => {
        await commit('setInvoiceMetadata', response.data.metadata)
        await commit('setInvoice', response.data.invoice)
      })
      .catch(reason => {
        console.error("fetchNewInvoice reason", reason)
      })
  },
  async fetchInvoiceActivitiesByInvoiceID({ commit }, payload) {
    await apiClient.get(`api/invoice/fetch-activities/${ payload.invoice_id }`)
      .then(async response => {
        await commit('setInvoiceActivity', response.data.activities)
      })
      .catch(async reason => {
        await console.error(reason)
      })
  },

  //endregion

  //region store
  async storeNewInvoice({ commit }, payload) {
    await apiClient.post('api/invoicing', payload.invoice)
      .then(async response => {
        if (response.status === 201) {
          processSuccessCallback(payload)
        }
        await router.push({ name: 'invoice_info_route', params: { invoicing_id: response.data.data.id } })
      })
      .catch(async reason => {
        console.error("storeNewInvoice reason", reason)
      })
  },
  //endregion

  //region update
  async updateInvoice({ commit }, payload) {
    await apiClient.put(`api/invoicing/${ payload.id }`, payload.invoice)
      .then(async response => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
        await router.push({ name: 'invoice_info_route', params: { invoicing_id: response.data.data.id } })
      })
      .catch(async reason => {
        console.error("updateInvoice reason", reason)
      })
  },
  //endregion

  //region delete
  async deleteInvoiceById({ commit }, payload) {

    await apiClient.delete(`invoice/${ payload.invoice_id }/delete`)
      .then(async response => {
        console.log(response)
      })
      .catch(async reason => {
        console.error("deleteInvoiceById reason", reason)
      })
  },
  //endregion

  //region change status invoice
  async changeInvoiceStatus({ commit }, payload) {
    await apiClient.put(`api/invoicing/${ payload.invoice_id }/status`, payload.form_data)
      .then(response => {
        if (response.status === 200) {
          commit('auth/setErrors', {}, { root: true })
          commit('setInvoice', response.data.data)
          processSuccessCallback(payload)
        }
      })
      .catch(reason => {
        console.error("fetchNewPayment reason", reason)
      })
  },
  //endregion

  //region clone invoice
  async cloneInvoice({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient.get(`api/invoicing/clone/${ payload.invoice_id }`)
      .then(response => {
        if (response.status === 201) {
          commit('setInvoice', response.data.data)
          router.push({ name: 'InvoiceSingle', params: { invoicing_id: response.data.data.id } })
          processSuccessCallback(payload)
        }
      })
      .catch(reason => {
        console.error("fetchNewPayment reason", reason)
      })
  },
  //endregion

  //region convert invoice
  async convertInvoice({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient.get(`api/invoicing/clone/${ payload.invoice_id }?new_type=${ payload.doc_type }`)
      .then(response => {
        if (response.status === 201) {
          commit('setInvoice', response.data.data)
          router.push({ name: 'InvoiceSingle', params: { invoicing_id: response.data.data.id } })
          processSuccessCallback(payload)
        }

      })
      .catch(reason => {
        console.error("fetchNewPayment reason", reason)
      })
  },
  //endregion

  //region send invoice
  async sendInvoice({ commit }, payload) {
    apiClient.post(`api/send-invoice`, payload.info)
      .then(async response => {
        if (response.status === 200) {
          processSuccessCallback(payload)
        }
      })
      .catch(async reason => {
        console.error(reason)
      })
  },
  //endregion

  async fecthInvoiceExport({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient.post(`api/invoicing/accounting-export`, payload)
      .then(async response => {
        fileDownload(response.data, 'export_factures_' + payload.period.from + '_' + payload.period.to + '.csv');
      })
      .catch(reason => {
        if (reason.response.status === 422) {
          commit('auth/setErrors', reason.response.data.errors.period, { root: true })
        }
      })
  },
  async fetchInvoicesCSV({ commit }, payload) {
    commit('auth/setErrors', {}, { root: true })
    await apiClient.post(`api/invoicing/export-csv`, payload)
      .then(async response => {
        fileDownload(response.data, 'CENTRE_FUNERAIRE_GRAND_LITTORAL_IMPRESSION_LISTE_FACTURES.csv');
      })
      .catch(reason => {
        console.log('fail', reason.response)
        // if (reason.response.status === 422) {
        //   commit('auth/setErrors', reason.response.data.errors.period, { root: true })
        // }
      })
  },
  async fetchSendInvoiceMessageTextConverted({ commit }, payload) {
    await apiClient.post(`api/convertMessageText`, payload)
      .then(response => {
        if (response.status === 200) {
          commit('setSendInvoiceMessageText', response.data.text)
          processSuccessCallback(payload)
        }
      })
      .catch(reason => {
        console.error('fetchMessageTextConverted', reason)
      })
  },
}
