export default {
  getCustomerList(state) {
      return state.customersList
  },
  getSelectedCustomer(state) {
      return state.customer
  },
  getCustomerMetadata(state) {
      return state.metadata
  },
  getCustomerActivity(state){
      return state.customerActivity
  }
}
