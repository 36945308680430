<template>
  <div class='grid grid-cols-2  gap-5'>
    <workFormUrn :editionMode='editionMode'/>
    <div/>
<!--    Micky - 6/04/22: j'ai mis une div vide ici car mettre une classe col-start-1 à recoverForm est innéficace, et vus que le composant est appeller plusieurs fois... -->
    <RecovererForm :editionMode='editionMode' />
  </div>
</template>

<script>
import WorkFormUrn from '@p/works/form/subpages/informations/components/WorkFormUrn'
import RecovererForm from './RecovererForm'

export default {
  name: 'SelectConForm',
  components:{
    WorkFormUrn,
    RecovererForm,
  },
  props:{
    editionMode: {type:Boolean, required:true}
  }
}
</script>

