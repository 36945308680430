import authenticateTranslations from './partials/authenticate'
import buttonTranslations from './partials/button'
import attributeTranslations from './partials/attributes'
import globalTranslations from './partials/global'
import navigationTranslations from './partials/navigation'
import validationTranslations from './partials/validation'
import worksTranslations from './partials/works'

export default {
  ...authenticateTranslations,
  ...buttonTranslations,
  ...attributeTranslations,
  ...globalTranslations,
  ...navigationTranslations,
  ...validationTranslations,
  ...worksTranslations
}
