import getters from '@/store/modules/content/item/getters'
import mutations from '@/store/modules/content/item/mutations'
import actions from '@/store/modules/content/item/actions'

export default {
  namespaced: true,
  state() {
    return {
      itemsList: [],
      item: {},
      itemActivity: {},
      itemMetadata: {},
    }
  },
  getters,
  actions,
  mutations
}
