<template>
  <SettingsPageFull>

<!--    <template #title>-->
<!--      <div class='flex space-x-3'>-->
<!--        <span class='text-lg font-bold capitalize-first'>{{ $t('global.accounting_code') }}</span>-->
<!--      </div>-->
<!--    </template>-->

    <template #body>
      <div class="grid grid-cols-3 w-full mx-12 gap-x-14 items-enter">

          <BaseInput :label="$t('global.code')"
                     :placeholder="$t('global.code')"
                     name="code"
                     :required="true"
                     v-model="accountingCode.code"
                     />

          <BaseInput :label="$t('global.entitled')"
                     :placeholder="$t('global.entitled')"
                     name="name"
                     v-model="accountingCode.name"
                     :required="true"
                     />

          <BaseButton color="primary"
                      icon='plus'
                      @click="submitAccountingCode"
                      class='mt-10'
                      :title="$t('button.add')"/>
      </div>

      <div class="overflow-x-auto mx-12">
        <table class="table table--sm mt-10">
          <thead class="bg-gray-50">
          <tr class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">

            <th scope="col">{{ $t('global.code') }}</th>
            <th scope="col">{{ $t('global.entitled') }}</th>

          </tr>
          </thead>
          <tbody class="bg-gray-50">
          <tr
              v-for="(accountingCode, idx) in accountingCodesList" :key="idx"
              :class="idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              class="cursor-pointer px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-600">
            <td>{{ accountingCode.code }}</td>
            <td>{{ accountingCode.name }}</td>

          </tr>
          </tbody>
        </table>
      </div>
    </template>

  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TheAccountingCodePage",
  computed: {
    ...mapGetters({
      accountingCodesList: 'accountingCode/getAccountingCodeList',
      accountingCode: 'accountingCode/getAccountingCode'
    })
  },
  methods: {
    ...mapActions({
      fetchAccountingCodes: 'accountingCode/fetchAccountingCodeList',
      storeAccountingCode: 'accountingCode/storeAccountingCode',
    }),
    getInputValue({attribute, value}) {
        this.accountingCode[attribute]=value
    },
    submitAccountingCode() {
      this.storeAccountingCode({accountingCode: this.accountingCode})
      this.accountingCode.name = ''
      this.accountingCode.code = ''
    }
  },
  mounted() {
    this.fetchAccountingCodes();
  }
}
</script>

