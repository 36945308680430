import apiClient from '@u/apiClient'

let base_errors = {}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
    //region fetch
    async fetchMessageTextList({commit}) {
        await apiClient.get('api/messageText')
            .then(async response => {
                commit('setMessageTextList', response.data.message_texts)
                commit('setMessageTextMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchMessageTextList', reason)
            })
    },
    async fetchMessageTextById({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/messageText/${payload.id}`)
            .then(async response => {
                commit('setMessageText', await response.data.message_text)
                commit('setMessageTextMetadata', await response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchMessageTextById', reason)
            })
    },
    async fetchNewMessageText({commit}) {
        commit('auth/setErrors', {}, {root: true})
        await apiClient.get(`api/model/new?model=messageText`)
            .then(async response => {
                commit('setMessageText', response.data.messageText)
                commit('setMessageTextMetadata', response.data.metadata)
            })
            .catch(async reason => {
                await commit('fetchNewMessageText', reason)
            })
    },
    async fetchMessageTextConverted({commit}, payload) {
        await apiClient.post(`/api/convertMessageText`, payload)
            .then(response => {
                if (response.status === 200) {
                    commit('setMessageText', response.data.text)
                    processSuccessCallback(payload)
                }
            })
            .catch(reason => {
                console.error('fetchMessageTextConverted', reason)
            })
    },
    //endregion

    //region store
    async storeMessageText({commit}, payload) {
        await apiClient.post('api/messageText', payload.messageText)
            .then(async response => {
                if (response.status === 200) {
                    commit('setMessageTextList', response.data.message_texts)
                    commit('setMessageTextMetadata', response.data.metadata)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('storeMessageText', reason)
            })
    },
    //endregion

    //region update
    async updateMessageText({commit}, payload) {
        await apiClient.put(`api/messageText/${payload.messageText.id}`, payload.messageText)
            .then(async response => {
                if (response.status === 200) {
                    commit('setMessageTextList', response.data.message_texts)
                    commit('setMessageTextMetadata', response.data.metadata)
                    processSuccessCallback(payload);
                }
            }).catch(async reason => {
                console.error('updateMessageText', reason)
            })
    },
    //endregion

    //region delete
    async deleteMessageTextById({commit}, payload) {
        await apiClient.delete(`api/messageText/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    commit('setMessageTextList', response.data.message_texts)
                    commit('setMessageTextMetadata', response.data.metadata)
                    processSuccessCallback(payload);
                }
            }).catch(async reason => {
                console.error('deleteMessageTextById', reason)
            })
    },
    //endregion

    //region undoDelete
    async undoDeleteMessageTextById({commit}, payload) {
        await apiClient.get(`api/messageText/undo-delete/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    commit('setMessageTextList', response.data.message_texts)
                    commit('setMessageTextMetadata', response.data.metadata)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('undoDeleteMessageTextById', reason)
            })
    },
    //endregion
}
