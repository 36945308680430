<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="modal-content p-0 max-h-screen flex flex-col"  :class="modalClasses" open v-if="isModalVisible">

        <div class='flex'>
          <div class='flex-1 p-5'>
            <h2 class='text-xl font-bold capitalize-first'>
              {{ $t('button.change_item_order') }}
            </h2>
          </div>
          <div class='flex items-center p-5 cursor-pointer' @click='_close'>
            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div>
        </div>

        <section class="flex-1 modal-body overflow-scroll">

              <draggable
                v-if='invoice?.invoice_items'
                class="list-group "
                tag="transition-group"
                :component-data="{
                tag: 'ul',
                type: 'transition-group',
                name: !drag ? 'flip-list' : null
                }"
                v-model="invoice.invoice_items"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
                item-key="id">
                <template #item="{ element }">
                  <div class='grid grid-cols-5'>
                    <div class='col-start-2 col-span-3'>
                      <li class="list-group-item my-1 rounded-md py-2 text-center shadow-md flex">
                        <font-awesome-icon :icon="['fal', 'sort-alt']" class='col-start-1 mx-5 my-auto text-gray-400'/>
                        <div class='col-start-2 text-center select-none'>{{ element.name }}</div>
                      </li>
                    </div>
                  </div>

                </template>
              </draggable>

        </section>

        <div class="flex flex-row justify-end py-3 px-5 bg-gray-100 gap-x-5">
          <BaseButton :title="$t('button.send')" icon='paper-plane' @click='submitForm'/>
          <BaseButton :title="$t('button.close')" icon='times' color='danger' @click="_close"/>
        </div>
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isModalVisible"></div>
    </transition>
  </teleport>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

import draggable from 'vuedraggable'

const message = [
  "vue.draggable",
  "draggable",
  "component",
  "for",
  "vue.js 2.0",
  "based",
  "on",
  "Sortablejs"
];

export default {
  name: 'ChangeItemOrderDialog',
  components:{ draggable },
  emits: ['close', 'backdropClicked'],
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function(value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPulsing: false,
      originalItemList: null,
      drag: false
    }
  },
  computed: {
    ...mapGetters({invoice: 'invoicing/getInvoice'}),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    permissions(){
      return !this.$can('manage_invoicing')
    },
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    }
  },
  methods: {
    ...mapActions({ reorderInvoiceItems: 'invoicing/reorderInvoiceItems'}),
    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },
    getInputValue({attribute, value}){

      this.info[attribute] = value

      if(attribute === 'messageTextType') {
        this.getMessageConverted({model: 'invoice', model_id: this.invoice.id, messageText_id:value})
      }
    },
    submitForm(){

      const ordered_list = this.invoice.invoice_items.reduce((init,item)=>{
        init.push(
          { id: item.id }
        )
        return init
      },[])

      this.reorderInvoiceItems({
        invoice_id: this.invoice.id,
        ordered_list: ordered_list,
        successCallback: () => {
            this.$h.toastify(this.$t('toasts.invoice_updated'), { className: 'toastify-content success' })
            this._close()
          }
      })
    },
    _close() {
      this.isPulsing = false
      this.text = '';
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    }
  },
  watch: {
    isPulsing: function(currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    }
  }
}
</script>

<style scoped>

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index : 1000;
  overflow : hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width : 90%;
  }
}

@media only screen and (min-width:  577px){
  dialog.small {
    width : 20%;
    max-width : 300px;
    scroll-behavior: auto;
  }
  dialog.medium {
    width : 30%;
    max-width : 500px;
  }
  dialog.large {
    width : 50%;
    max-width : 700px;
  }
  dialog.x-large{
    width : 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active{
  animation: modal 0.3s ease-in ;
}

.modal-leave-active{
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active{
  animation: backdrop 0.3s ease-in ;
}
.backdrop-leave-active{
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation : pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform : scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,0.3);
  }
}

 .button {
   margin-top: 35px;
 }
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}
</style>
