<template>
  <!--  <SelectCheckType v-model='check_type' :checkTypes="fakeData.checkType" class='mt-5'/>-->

  <div class="flex flex-row space-x-3" role="none" v-if="checkDepositList">
    <router-link  v-for="(type, index) in checkTypes" :key="index" :to="{name: type.routeName}" v-slot="{isExactActive}"
                 class="relative flex-1 flex ">
      <SelectCheckViewLink  :key='type' v-if="type" :isActive="isExactActive" :type="type" :status="type.status"/>
    </router-link>
  </div>

  <BaseSimpleCard class='mt-5' title="">
    <template #default>
      <div>
        <router-view></router-view>
      </div>
    </template>
  </BaseSimpleCard>

</template>

<script>
import {mapGetters} from 'vuex'
import SelectCheckViewLink from "./SelectCheckViewLink";

export default {
  name: 'CheckPage',
  components: {
    SelectCheckViewLink,
  },
  data() {
    return {
      check_type: this.selectedCheckType
    }
  },
  computed: {
    ...mapGetters({
      checkDepositList: 'invoicing/getCheckDepositList',
      metadata: 'invoicing/getCheckMetadata'
    }),
    selectedCheckType() {
      return this.checkTypes[0]
    },
    checkTypes() {
      return [
        {
          id: 1,
          name: this.$t('global.checks_deposit'),
          count: `${this.$t('attributes.quantity')}: ${this.checkDepositQuantity}`,
          total: `${this.$t('attributes.total_amount')}: ${this.checkDepositTotalAmount}`,
          routeName: 'check_index_route',
          status:'checkdeposits'
        },
        {
          id: 2,
          name: this.$t('global.checks_to_be_deposited'),
          count: `${this.$t('attributes.quantity')}: ${this.checkQuantity('TBDEP')}`,
          total: `${this.$t('attributes.total_amount')}: ${this.checkTotalAmount('TBDEP')}`,
          routeName: 'check_tbdep_index_route',
          status:'TBDEP'
        },
        {
          id: 3,
          name: this.$t('global.checks_to_be_cashed'),
          count: `${this.$t('attributes.quantity')}: ${this.checkQuantity('CAS')}`,
          total: `${this.$t('attributes.total_amount')}: ${this.checkTotalAmount('CAS')}`,
          routeName: 'check_dep_index_route',
          status:'CAS'
        },
        {
          id: 4,
          name: this.$t('global.checks_refused'),
          count: `${this.$t('attributes.quantity')}: ${this.checkQuantity('REF')}`,
          total: `${this.$t('attributes.total_amount')}: ${this.checkTotalAmount('REF')}`,
          routeName: 'check_ref_index_route',
          status:'REF'
        }
      ]

    },
    checkDepositQuantity() {
      return this.checkDepositList.length
    },
    checkDepositTotalAmount() {
      return this.checkDepositList?.reduce((a, b) => a + parseFloat(b.total_amount), 0);
    },
  },
  methods: {
    checkQuantity(status) {
      return this.metadata?.checks_list?.filter(check => check.check_status === status).length
    },
    checkTotalAmount(status) {
      let list = this.metadata?.checks_list?.filter( check => check.check_status === status)
      let temp = []
      list?.map(check => temp.push(parseInt(check.amount)))
      if (temp.length > 0){
        return temp.reduce((previousVal, currentVal) => previousVal + currentVal, 0)
      }
      return 0

    },
  },
  watch: {

    '$route.name'(newValue) {

      switch (newValue) {
        case 'check_index_route':
          this.selectedCheckType = this.checkTypes.filter(el => el.id === 1)[0]
          break
        case 'check_tbdep_index_route':
          this.selectedCheckType = this.checkTypes.filter(el => el.id === 2)[0]
          break
        case 'check_dep_index_route':
          this.selectedCheckType = this.checkTypes.filter(el => el.id === 3)[0]
          break
        case 'check':
          this.$router.push({name: 'check_index_route'})
          break
        case 'check_ref_index_route':
          this.selectedCheckType = this.checkTypes.filter(el => el.id === 4)[0]
          break
        case 'check_deposit_form_route':
          this.selectedCheckType = null
          break
      }
    },
    /* check_type(newValue) {
       if (newValue && newValue !== null) {
         switch (newValue.id) {
           case 1 :
             this.$router.push({name: 'check_index_route'})
             break
           case 2 :
             this.$router.push({name: 'check_tbdep_index_route'})
             break
           case 3 :
             this.$router.push({name: 'check_dep_index_route'})
             break
           case 4 :
             this.$router.push({name: 'check_ref_index_route'})
             break
         }
       }
     }*/
  },
  mounted() {

  }
}
</script>
<style scoped>

</style>
