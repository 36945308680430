<template>
  <div class='grid grid-cols-2'>

    <BaseSelectMultiple v-if="metadata.customers"
                        :placeholder="$t('global.filter')"
                        name="customer_search"
                        :options="metadata.customers"
                        @setSelected="filterCheckList" />

  </div>
  <div class="overflow-x-auto">
  <table class='table mt-5'>
    <thead>
    <tr>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap'>
        <BaseButton v-if="!isCheckboxDisabled && this.$route.name === 'check_deposit_form_route'" icon='chevron-down' color='dark' @click="processButton"/>
      </th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first'>{{ $t('attributes.amount') }}</th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap w-full capitalize-first'>{{$t('attributes.emission_date') }}</th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap w-full capitalize-first'>{{$t('attributes.status') }}</th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first'>{{ $t('attributes.customer') }}</th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first'>{{ $t('attributes.check_number') }}</th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first'>{{ $t('attributes.work') }}</th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first'>{{ $t('attributes.bank_name') }}</th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first'>{{ $t('attributes.remarks') }}</th>
      <th class='border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first'>{{ $t('global.invoice') }}</th>
    </tr>
    </thead>
    <tbody>

    <CheckTableRow v-for='(check, index) in filteredCheckList' :key='index' :check='check'
                   :disabled="isCheckboxDisabled"
                   :baseValue="rowBaseValue"
                   :isCheckBoxVisible='isCheckBoxVisible'
                   @selectCheck='alterCheckDepositPayments'/>
    </tbody>
  </table>
  </div>
</template>
<script>
import CheckTableRow from "./CheckTableRow";
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'CheckTable',
  components: { CheckTableRow },
  props: { model: {type: Object, required: true}, isCheckboxDisabled: {}, isCheckboxVisible: {type: Boolean, required: false, default: true} },
  data() {
    return {
      rowBaseValue: this.$route.params.id !== 'new',
      filters: [],
    }
  },
  computed:{
    ...mapGetters({
      checkDeposit: 'invoicing/getCheckDeposit',
      metadata: 'invoicing/getCheckMetadata'
    }),
  filteredCheckList(){
      let tempChecks = this.model

      if(this.filters.length !== 0){
        tempChecks = tempChecks.filter(row => this.filters.includes(row.customer_name))
      }
      return tempChecks
    }
  },
  methods:{
    ...mapMutations({
      alterCheckDepositPayments: 'invoicing/alterCheckDepositPayments'
    }),
    filterCheckList({attribute, value}){

      this.filters = value.map(el=>el.name)

    },
    processButton(){
// Todo: pas fonctionel, bug quand on sélectionne tout à l'aide du bouton, que l'on en déselectionne manuellement et que l'on rappuie sur le bouton,
//       si on resélectionne les déselectionnés, ca fonctionne

      this.checkDeposit.payments.length !== this.model.length ? this.rowBaseValue = true : this.rowBaseValue = false
    }
  },
}
</script>
