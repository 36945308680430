<template>

  <template v-if='["OD", "CON"].includes(local_event.type)'>
    <FormGenericEvent :scroll-to-date-to='scrollToDateTo'
                      :scroll-to-date-from='scrollToDateFrom'
                      :errors='errors'
                      :metadata='metadata'
                      :reset-event-data='resetEventData'
                      :local_event='local_event'
                      :filtered-event-type='filteredEventType'
                      :edition-mode='editionMode' />
  </template>

  <template v-else-if='local_event.type === "BUR"'>
    <FormBurEvent :cemetery-base-value='cemeteryBaseValue'
                  :scroll-to-date-to='scrollToDateTo'
                  :scroll-to-date-from='scrollToDateFrom'
                  :errors='errors'
                  :metadata='metadata'
                  :reset-event-data='resetEventData'
                  :local_event='local_event'
                  :filtered-event-type='filteredEventType'
                  :edition-mode='editionMode' />
  </template>

  <template v-else-if='local_event.type === "DIS"'>
    <FormDisEvent :cemetery-base-value='cemeteryBaseValue'
                  :scroll-to-date-to='scrollToDateTo'
                  :scroll-to-date-from='scrollToDateFrom'
                  :errors='errors'
                  :metadata='metadata'
                  :reset-event-data='resetEventData'
                  :local_event='local_event'
                  :filtered-event-type='filteredEventType'
                  :edition-mode='editionMode' />
  </template>

  <template v-else>
    <FormAssetEvent :event-kind-id-options='eventKindIdOptions'
                    :event-kind-is-displayed='eventKindIsDisplayed'
                    :number-of-people-is-displayed='numberOfPeopleIsDisplayed'
                    :index='index'
                    :time-slot-is-displayed='timeSlotIsDisplayed'
                    :scroll-to-date-to='scrollToDateTo'
                    :scroll-to-date-from='scrollToDateFrom'
                    :filtered-assets='filteredAssets'
                    :errors='errors'
                    :metadata='metadata'
                    :reset-event-data='resetEventData'
                    :local_event='local_event'
                    :filtered-event-type='filteredEventType'
                    :edition-mode='editionMode' />
  </template>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import FormAssetEvent from '@p/components/Event/components/FormAssetEvent'
import FormBurEvent from '@p/components/Event/components/FormBurEvent'
import FormDisEvent from '@p/components/Event/components/FormDisEvent'
import FormGenericEvent from '@p/components/Event/components/FormGenericEvent'

export default {
  name: 'formEvent',
  components: {
    FormAssetEvent,
    FormBurEvent,
    FormDisEvent,
    FormGenericEvent,
  },
  props: {
    errors: {
      type: Object,
      required: false,
      default: null
    },
    filteredAssets: {
      type: Array,
      required: false,
      default: null
    },
    getInputValue: {},
    afterWorkSelected: {},
    index: {},
    props_event: {},
    metadata: {
      type: Object,
      required: true
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters({ event: 'getEvent' }),
    local_event() {
      if (this.props_event === undefined) {
        return this.event
      }
      return this.props_event
    },
    cemeteryBaseValue() {
      if (this.local_event.cemetery) {
        return {
          id: this.local_event?.cemetery_id,
          title: `${this.$t('global.cemetery_of')} ${this.local_event?.cemetery?.ville} ${this.local_event?.cemetery.nom}`
        }
      } else {
        return {}
      }
    },
    eventKindIdOptions() {
      return this.preSorteventKindsOptions?.filter(option => this.selectedAsset?.event_kind?.includes(option.id))
    },
    selectedAsset() {
      return this.metadata?.assets?.find(asset => asset.id === this.local_event?.asset_id)
    },
    selectedEventTypeHasEventKinds() {
      return this.metadata?.event_kinds?.filter(kind => kind.event_type === this.local_event.type).length > 0
    },
    preSorteventKindsOptions() {
      return this.metadata?.event_kinds?.filter(item => item.event_type === this.local_event?.type)
    },
    numberOfPeopleIsDisplayed(){
      return this.metadata?.formInputs?.nb_peoples?.display && this.metadata?.formInputs?.nb_peoples?.visible?.type?.includes( this.local_event?.type )
    },
    timeSlotIsDisplayed(){
      return this.editionMode && this.metadata?.formInputs?.display_timeslots?.visible.type?.includes(this.local_event.type)
    },
    eventKindIsDisplayed(){
      return this.selectedEventTypeHasEventKinds && this.local_event.asset_id && this.eventKindIdOptions?.length > 0
    },
    filteredEventType(){
      if(this.$route.name === 'planning_page_route'){
        return this.metadata?.event_type
      } else {
        return this.metadata?.event_type?.filter(type => !['MAI', 'FOR', 'SEV', 'OD'].includes(type.id))
      }
    },
  },
  methods: {
    ...mapActions({ fetchEventDisponbilitiesByOption: 'fetchEventDisponbilitiesByOption' }),
    resetEventData(){
      let attributes = [
        'inhumation_type',
        'dispersion_type',
        'other_resource',
        'cemetery_id',
        'asset_id',
        'nb_peoples',
        'event_kind_id',
      ]
      for(let attribute of attributes){
        this.local_event[attribute] = null
      }
    },
    scrollToDateFrom() {
      this.$route.name === 'planning_page_route' ? document.getElementById('scroll_date_from').scrollIntoView() : {}
    },
    scrollToDateTo() {
      this.$route.name === 'planning_page_route' ? document.getElementById('scroll_date_to').scrollIntoView() : {}
    },
  },
}
</script>
