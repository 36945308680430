<template>
  <tr :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'" v-if="item.visible">
    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 "
        @click="updateItem(item)">
     <span class="cursor-pointer">{{ item.label['fr_BE'] ? item.label['fr_BE'] : item.label['fr_FR'] ? item.label['fr_FR'] : '-' }}</span>
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 grid grid-cols-1 place-items-end">
      <BaseButton
        v-if="item.deleteable && editionMode"
        color='danger'
        :isOutline="true"
        :tooltip="$t('button.delete_v', {var: $t('global.list_item')})"
        icon="times"
        class='w-8 mr-5'
        @click="deleteItem(item)"
      />
    </td>
  </tr>
</template>
<script>

  export default {
    name: 'ItemRowComponent',
    props: {
      item :{
        type: Object,
        required : true
      },
      index: {
        type: Number,
        required: true
      },
      updateItem: {
        type: Function,
        required: true
      },
      deleteItem: {
        type: Function,
        required: true
      },
      editionMode: {
        type: Boolean,
        required: true
      },
    }
  }
</script>
