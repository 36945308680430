<template>
  <div class='grid md:grid-cols-2 sm:grid-cols-1 gap-x-5'>
    <div class='col-start-1'>
      <BaseShowEditSelectSimple v-if="metadata?.event_type"
                                :label="$t('attributes.event_type')"
                                :placeholder="$t('attributes.event_type')"
                                name="event_type"
                                :editionMode='editionMode'
                                v-model='local_event.type'
                                @change='resetEventData'
                                :options="filteredEventType"
                                :errors="errors?.event_type"/>
    </div>
    <div class='grid grid-cols-1'>
      <div>
        <BaseShowEditSelectSimple v-if="metadata?.inhumation_type"
                                  :label="$t('attributes.inhumation_type')"
                                  :placeholder="$t('attributes.inhumation_type')"
                                  name="inhumation_type"
                                  :editionMode='editionMode'
                                  v-model='local_event.inhumation_type'
                                  :options="metadata?.inhumation_type"
                                  :errors="errors?.event_type"/>
      </div>
      <div v-if='local_event && local_event.inhumation_type === 18'>
        <BaseShowEditInput :label="$t('attributes.other_resource')"
                           v-model="local_event.other_resource"
                           :errors="errors.other_resource"
                           :placeholder="$t('attributes.other_resource')"
                           :editionMode="editionMode"/>
      </div>
      <div v-else>
        <BaseShowEditSelectAjax v-if='local_event' :key='local_event'
                                :editionMode='editionMode'
                                url='api/cemetery-for-select'
                                :label="$t('attributes.cemetery')"
                                v-model='local_event.cemetery_id'
                                name='cemetery_id'
                                trackBy='id'
                                attributeLabel='title'
                                :defaultAjax='cemeteryBaseValue' />
      </div>
    </div>
    <div class='col-start-1'>
      <BaseShowEditInput :label="$t('attributes.event_title')"
                         v-model="local_event.title"
                         :errors="errors.title"
                         :placeholder="$t('attributes.event_title')"
                         :editionMode="editionMode"/>
    </div>
    <div class='col-start-1 grid grid-cols-2 gap-x-3'>
      <div id='scroll_date_from'>
        <BaseShowEditDatePicker :editionMode='editionMode'
                                :label="local_event.type === 'CON' ? $t('attributes.from'): $t('attributes.date_from')"
                                v-model='local_event.date_from'
                                class='z-10'
                                name='date_from'
                                @click='scrollToDateFrom'
                                :errors='errors.date_from'/>
      </div>
    </div>
    <div class='grid grid-cols-2 gap-x-3'>
      <div>
        <BaseShowEditTimeInput :label="$t('attributes.time_from')"
                               :placeholder="'10:00'"
                               name='time_from'
                               :editionMode='editionMode'
                               v-model='local_event.time_from'
                               :errors='errors?.time_from'/>
      </div>
      <div>
        <BaseShowEditTimeInput :label="$t('attributes.time_to')"
                               :placeholder="'12:00'"
                               name='time_to'
                               :editionMode='editionMode'
                               v-model='local_event.time_to'
                               :errors='errors?.time_to'/>
      </div>
    </div>
    <div class='col-start-1'>
      <BaseShowEditTextArea :label="$t('attributes.remarks')"
                            :placeholder="$t('attributes.remarks')"
                            v-model="local_event.remarks"
                            :errors="errors.remarks"
                            :editionMode="editionMode" />
    </div>
  </div>
</template>

<script>


export default {
  name: 'FormBurEvent',
  props: {
    editionMode: {
      type: Boolean,
      required: true,
    },
    local_event: {
      type: Object,
      required: true,
    },
    resetEventData: {
      type: Function,
      required: true,
    },
    metadata: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    scrollToDateFrom: {
      type: Function,
      required: true
    },
    scrollToDateTo: {
      type: Function,
      required: true
    },
    cemeteryBaseValue: {
      type: Object,
      required: true,
    },
    filteredEventType: {
      type: Array,
      required: true,
    }
  },
}
</script>

<style scoped>

</style>
