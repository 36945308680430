<template>
  <h2 v-if='invoice.company' class='text-lg font-bold'>{{ invoice.company.name }}</h2>
  <div class='capitalize-first'>{{ $t('attributes.reference') }}: <span class='font-bold'>{{ invoice.reference }}</span></div>
  <div class='capitalize-first' v-if="invoice.reference_extern">{{ $t('attributes.reference_extern') }}: <span class='font-bold'>{{ invoice.reference_extern }}</span></div>
  <div class='capitalize-first'>{{ $t('attributes.date') }}: <span class='font-bold'>{{ invoice.emission_date }}</span></div>
  <div class='capitalize-first'>{{ $t('attributes.due_date') }}: <span class='font-bold'>{{ invoice.due_date }}</span></div>
</template>
<script>

export default {
  name: 'InvoicingInvoiceInfosComponent',
  props: {
    invoice: {
      type: Object,
      required: true
    }
  },
}
</script>
