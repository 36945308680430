<template>
  <BasePageFull>
    <template #body>
      <div class='col-span-3 flex flex-col gap-y-4c'>
        <InvoicingNav />
      </div>
      <div class='col-span-9'>
        <router-view></router-view>
      </div>
    </template>
  </BasePageFull>
</template>

<script>
import { mapActions } from 'vuex'

import InvoicingNav from './nav/InvoicingNav'

export default {
  name: 'TheInvoicingPage',
  components: { InvoicingNav },
  methods: {
    ...mapActions('sideMenu', ['collapse'])
  },
  mounted() {
    this.collapse()
  }
}
</script>

