export default {
    getUserList(state) {
        return state.usersList
    },
    getUser(state) {
        return state.user
    },
    getUserMetadata(state) {
        return state.metadata
    },
  getUserActivities(state){
      return state.userActivities
  }
}
