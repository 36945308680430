<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','users']" size='2x' />
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.content_user_title') }}</span>
      </div>
    </template>

    <template #cta>
      <BaseButton color='primary'
                  icon='plus'
                  @click='redirectToForm'
                  :title="$t('button.add_new_m', {var: $t('global.user')})" />


    </template>

    <template #body>
      <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.user')}) "/>
      <BaseContentTable
        v-if='metadata.columns'
        :columns='metadata.columns'
        :modelList='userList'
        :search='true'
        @rowClick='redirectToModelPage'
        @refreshClick='fetchAllUser'
        @deleteModel='deleteUser'
        :isHoverable='true' />

    </template>

  </SettingsPageFull>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheUserIndexPage',
  computed: {
    ...mapGetters({
      userList: 'user/getUserList',
      metadata: 'user/getUserMetadata'
    }),
  },
  methods: {
    // ...mapActions(['fetchAllUser', 'deleteUserById','undoDeleteUserById']),
    ...mapActions({
      fetchAllUser: 'user/fetchAllUser',
      deleteUserById: 'user/deleteUserById',
      undoDeleteUserById: 'user/undoDeleteUserById'
    }),
    redirectToForm() {
      this.$router.push({ name: 'settings_create_user' })
    },
    redirectToModelPage(id) {
      this.$router.push({ name: 'settings_edit_user_route', params: { id: id } })
    },
    deleteUser(id) {
      this.deleteUserById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-content").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteUser(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteUser(id) {
      this.undoDeleteUserById({
        id:  id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_m', {var: this.$t('attributes.user')})  , {className: 'toastify-content success'})
        }
      })
    }
  },
  mounted() {
    this.fetchAllUser()
  }
}
</script>

