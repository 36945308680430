
import buttonTranslations from './partials/button'
import attributeTranslations from './partials/attributes'
import globalTranslations from './partials/global'
import navigationTranslations from './partials/navigation'
import validationTranslations from './partials/validation'
import toastsTranslations from './partials/toasts'
import enumsTranslations from './partials/enums'

export default {
  ...buttonTranslations,
  ...attributeTranslations,
  ...globalTranslations,
  ...navigationTranslations,
  ...validationTranslations,
  ...toastsTranslations,
  ...enumsTranslations
}
