<template >
  <div class='flex p-3 flex-col space-y-3'>
    
    <div class='flex-1 flex flex-row space-x-3'>
      
      <div class='flex-shrink-0 flex items-center justify-center'>
        <DropdownTaskStatusSquare v-if='true'
                                  :options='c_translated_option'
                                  :current="task.status"
                                  :icon='c_actual_status_icon'
                                  label='label'
                                  @select='mChangeStatus'
        />
        
        <BaseLabel v-else :content='c_actual_status_label' :color='c_actual_status_color'
                   :icon='c_actual_status_icon'
        />
      </div >
      
      <div class='flex-1 flex flex-col'>
        <h3 class="text-base font-bold truncate capitalize-first cursor-pointer">{{ task.name }}</h3 >
        <h2 >
          <font-awesome-icon :icon='["fal","user"]' class="mr-1" />
          {{ task.responsable.full_name }}
        </h2 >
      </div >
      <div class="cursor-pointer" @click='_open'>
        <font-awesome-icon :icon='["fal","search"]' />
      </div >
    </div >
    
    <p class='line-clamp-1'>{{ task.description }}</p >
    
    <div v-if='task && c_status_change_activities_ordered_desc && c_status_change_activities_ordered_desc.length '
         class='space-y-2'
    >
      
      <TaskStatusChangeActivityItem v-for="activity in c_status_change_activities_ordered_desc"
                                    :activity='activity'
                                    :metadata='metadata'
                                    :task='task'
                                    :edition-mode="false"
                                    :on-card="true"
      />
    </div >
  
  </div >
</template >

<script >
import TaskStatusChangeActivityItem from './TaskStatusChangeActivityItem'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WorkTaskCard',
  emits: ['click-openModal'],
  components: { TaskStatusChangeActivityItem },
  props: { task: { type: Object } },
  computed: {
    ...mapGetters({ work: 'work/getWork', metadata: 'work/getWorkMetadata' }),
    c_translated_option() {
      return this.metadata.tasks.status.reduce((array, item) => {
        array.push({
          ...item,
          label: this.$t('enums.task.status.' + item.id)
        })
        return array
      }, []);
    },
    c_actual_status_color() {
      return this.metadata.tasks.status.find(status => status.id === this.task.status)?.bg_color
    },
    c_actual_status_icon() {
      return this.metadata.tasks.status.find(status => status.id === this.task.status)?.icon
    },
    c_actual_status_label() {
      return this.metadata.tasks.status.find(status => status.id === this.task.status)?.label
    },
    c_status_change_activities_ordered_desc() {
      return this.task.status_change_activities.sort((activity_1, activity_2) => activity_2.id - activity_1.id).slice(0, 2)
    }
  },
  methods: {
    ...mapActions({ updateWorkTaskStatus: 'work/updateWorkTaskStatus' }),
    mChangeStatus(newStatus) {
      if (newStatus.id === 4) {
        this.$emit('openModal', {
          modal: 'TaskFormDialog',
          task: this.task,
          form: 'reason',
          nextStatus: newStatus.id
        })
        return
      }
      if (newStatus.id === 2 && this.task.starting_form.length > 0) {
        console.log('ici en cours')
        this.$emit('openModal', {
          modal: 'TaskFormDialog',
          task: this.task,
          form: 'starting_form',
          nextStatus: newStatus.id
        })
      } else if (newStatus.id === 2 && this.task.starting_form.length < 1) {
        console.log('here')
        this.updateWorkTaskStatus({
          id: this.task.id,
          new_status: newStatus.id,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.updated_f', { var: this.$t('attributes.task') }), { className: 'toastify-content success' })
          }
        })
      }
      if (newStatus.id === 3 && this.task.ending_form.length > 0) {
        this.$emit('openModal', {
          modal: 'TaskFormDialog',
          task: this.task,
          form: 'ending_form',
          nextStatus: newStatus.id
        })
      } else if(newStatus.id === 3 && this.task.ending_form.length < 1){
        this.updateWorkTaskStatus({
          id: this.task.id,
          new_status: newStatus.id,
        })
      }
      if (newStatus.id === 1 )  {
        this.updateWorkTaskStatus({
          id: this.task.id,
          new_status: newStatus.id,
        })
      }
    },
    
    _open() {
      this.$emit('click-openModal', { workTask: this.task, modal: 'WorkTaskFormDialog' })
    },
    _openUpdate() {
      this.$emit('click-openModal', { workTask: this.task, modal: 'WorkTaskFormDialog', editionMode: true })
    }
  }
}
</script >



<!--/* aurelie: comment pour afficher les forms*/-->
<!--// mChangeStatus(newStatus) {-->
<!--//   if (newStatus.id !== this.task.id) {-->
<!--//     if (!Boolean(this.task.form_on_start) && !Boolean(this.task.form_on_end)) {-->
<!--//       if(newStatus.id === 4){-->
<!--//         this.$emit('openModal', {-->
<!--//           modal: 'TaskFormDialog',-->
<!--//           task: this.task,-->
<!--//           form: 'reason',-->
<!--//           nextStatus: newStatus.id-->
<!--//         })-->
<!--//         return-->
<!--//       }-->
<!--//       this.updateWorkTaskStatus({-->
<!--//         id: this.task.id,-->
<!--//         new_status: newStatus.id,-->
<!--//         successCallback: () => {-->
<!--//           this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('attributes.task')}), {className: 'toastify-content success'})-->
<!--//         }-->
<!--//       })-->
<!--//       return-->
<!--//     }-->
<!--//     if (newStatus.id === 2) {-->
<!--//       if (Boolean(this.task.form_on_start)) {-->
<!--//         this.$emit('openModal', {-->
<!--//           modal: 'TaskFormDialog',-->
<!--//           task: this.task,-->
<!--//           form: 'starting_form',-->
<!--//           nextStatus: newStatus.id-->
<!--//         })-->
<!--//       } else {-->
<!--//         this.updateWorkTaskStatus({-->
<!--//           id: this.task.id,-->
<!--//           new_status: newStatus.id,-->
<!--//           successCallback: () => {-->
<!--//             this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('attributes.task')}), {className: 'toastify-content success'})-->
<!--//           }-->
<!--//         })-->
<!--//       }-->
<!--//       return-->
<!--//     }-->
<!--//     if ((newStatus.id === 3 || newStatus.id === 4) && (Boolean(this.task.form_on_start) && this.task.starting_form[0].value === null )) {-->
<!--//       this.$h.toastify(this.$t('toasts.start_task_first'), {className: 'toastify-settings warning'})-->
<!--//       return-->
<!--//     }-->
<!--//     if ((newStatus.id === 4) && ( !Boolean(this.task.form_on_start) || ( Boolean(this.task.form_on_start) && this.task.starting_form[0].value !== null ) )) {-->
<!--//       this.$emit('openModal', {-->
<!--//         modal: 'TaskFormDialog',-->
<!--//         task: this.task,-->
<!--//         form: 'reason',-->
<!--//         nextStatus: newStatus.id-->
<!--//       })-->
<!--//       return-->
<!--//     }-->
<!--//     if(newStatus.id === 3 &&  !Boolean(this.task.form_on_end)) {-->
<!--//       this.updateWorkTaskStatus({-->
<!--//         id: this.task.id,-->
<!--//         new_status: newStatus.id,-->
<!--//         successCallback: () => {-->
<!--//           this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('attributes.task')}), {className: 'toastify-content success'})-->
<!--//         }-->
<!--//       })-->
<!--//       return-->
<!--//     }-->
<!--//     this.$emit('openModal', {-->
<!--//       modal: 'TaskFormDialog',-->
<!--//       task: this.task,-->
<!--//       form: 'ending_form',-->
<!--//       nextStatus: newStatus.id-->
<!--//     })-->
<!--//   }-->
<!--// },-->
