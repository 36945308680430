export default {
  setCompanyList(state, companyList) {
    state.companiesList = companyList
  },
  setCompany(state, company) {
    state.company = company
  },
  setMetadata(state, metadata) {
    state.metadata = metadata
  },
  setCompanyActivity(state, activity){
    state.companyActivity = activity
  }
}
