<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="modal-content p-0 flex flex-col"  :class="modalClasses" open v-if="isModalVisible" style='height: 90vh'>

        <!--#region header -->
        <div class='flex'>
          <div class='flex-1 p-5'>
          </div>
          <div class='flex items-center p-5 cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div>
        </div>
        <!--#endregion -->

        <!--#region settings -->
        <section class="flex-1 modal-body h-full overflow-scroll">

          <template v-if="['image/jpeg','image/png','image/gif'].includes(mimeType)">
            <img :src="source" :alt='work.attachments[this.attachmentIndex].name' class="mx-auto align-middle">
          </template>

          <template v-else-if="mimeType === 'application/pdf'">
            <iframe :src="source + '#toolbar=0'" class='w-full h-100 ' height='100%' style='height: 90vh'/>
          </template>

          <template v-else>
            <iframe :src="source" class='w-full h-full' />
          </template>

        </section>
        <!--#endregion -->

        <!--#region footer -->
        <div class="flex flex-row justify-end py-3 px-5 bg-gray-100 gap-x-5">
          <BaseButton :title="$t('button.print')" icon='print' @click='this.$emit("printFile")'/>
          <BaseButton :title="$t('button.close')" icon='times' color='danger' @click="_close"/>
        </div>
        <!--#endregion -->

      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isModalVisible"></div>
    </transition>
  </teleport>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WorkAttachmentShowDialog',
  emits: ['close', 'backdropClicked'],
  props: {
    attachmentIndex:{type: Number, required: true},
    isModalVisible: { type: Boolean, required: true },
    headerTitle: { type: String, required: false },
    mimeType: { type: String, required: false },
    url: { type: String, required: false },
    size: { type: String, required: false, default: 'medium', validator: function(value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      } },
    isStatic: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      isPulsing: false,
      docViewer: 'https://view.officeapps.live.com/op/embed.aspx?src='
    }
  },
  computed: {
    ...mapGetters({work: 'work/getWork'}),
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    },
    source() {
      switch (this.mimeType) {
        case 'application/pdf' :
        case 'image/jpeg' :
        case 'image/png' :
        case 'image/gif' :
          return this.url
        default:
          return this.docViewer + this.url
      }
    }
  },
  methods: {
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    }
  },
  watch: {
    isPulsing: function(currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    }
  }
}
</script>

<style scoped>
/*
    - backdrop
    - dialog
    - dialog : responsive
    - animations (usage)
    - animations (declaration)
 */

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index : 1000;
  overflow : hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width : 90%;
  }
}

@media only screen and (min-width:  577px){
  dialog.small {
    width : 20%;
    max-width : 300px;
    scroll-behavior: auto;
  }
  dialog.medium {
    width : 30%;
    max-width : 500px;
  }
  dialog.large {
    width : 50%;
    max-width : 700px;
  }
  dialog.x-large{
    width : 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active{
  animation: modal 0.3s ease-in ;
}

.modal-leave-active{
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active{
  animation: backdrop 0.3s ease-in ;
}
.backdrop-leave-active{
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation : pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform : scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,0.3);
  }
}
</style>
