export default {
    setStatsBlock(state, statsBlock) {
        state.statsBlock = statsBlock
    },
    setStatMetadata(state, metadata) {
        state.metadata = metadata
    },
    setStatsBlockData(state,payload){

        let statsBlockElement = state.statsBlock[payload.index];

        // console.log('statsBlockElement',statsBlockElement)
        statsBlockElement.data = payload.data
    },
    updateFormAttribute(state, payload) {
        console.log('payload',payload)
        let block = state.statsBlock[payload.index]
        let form = block.form
        // console.log('form',form)
        if(form === null ){
            form = {}
        }
        form[payload.attribute] = payload.value;
        state.statsBlock[payload.index].form = form
    }

}
