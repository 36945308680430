<template>
    <div class='m-2'>
      <input :id="`${title}-${formatedTime}`" class="form-check-input" type="radio" :value="formatedTime" name="ceremonyDispo" v-model='inputValue' @click='createNewEvent'>
      <label class="form-check-label" :for="`${title}-${formatedTime}`">{{ formatedTime }}</label>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'ReceptionDisponibilitiesRadio',
  props:{ dispo:{}, title:{}},
  data() { return { inputValue: {}, }},
  computed:{
    formatedTime(){
      return moment(this.dispo.date).lang('fr').format('ddd DD/MM - ') + this.dispo.from
    }
  },
  methods: {
    ...mapMutations({alterWorkEventValuesByEventType: 'work/alterWorkEventValuesByEventType'}),
    async createNewEvent() {
      this.alterWorkEventValuesByEventType({type:'CER', attribute: 'time_from', value: this.dispo.from })
      this.alterWorkEventValuesByEventType({type:'CER', attribute: 'time_to', value: this.dispo.to })
      this.alterWorkEventValuesByEventType({type:'CER', attribute: 'date_from', value: moment(this.dispo.date).format('DD/MM/YY') })
      this.alterWorkEventValuesByEventType({type:'CER', attribute: 'date_to', value: moment(this.dispo.date).format('DD/MM/YY') })
      this.alterWorkEventValuesByEventType({type:'CER', attribute: 'asset_id', value: this.dispo.asset.id })
    }
  },
}
</script>

<style scoped>

</style>
