<template>
  <SettingsPageFull>
    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','cogs']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ $t('global.general_settings') }}</span>
      </div>
    </template>
    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100 '>
        <BaseButton v-if="canSeeSettings && editionMode"
                    :isElevated='true'
                    :title="$t('button.save_plural', {var: $t('global.general_settings')})"
                    icon='paper-plane'
                    @click='submitForm'
        />
        <BaseButton v-if="$can('manage_content')"
                    :icon="editionMode ? 'times' : 'pen'"
                    :title="editionMode ? $t('button.cancel') : $t('button.edit')"
                    color="warning"
                    @click="switchEditMode"
        />
      </div>
    </template>
    <template #body>
      <div class='space-y-8'>
        <BaseCardSection>
          <template #default>
            <div class='grid grid-cols-12 gap-5 mx-12'>
              <div class='col-span-12 xl:col-span-6'>
                <BaseLanguageShowEditSelectMultiple v-model="settings.available_languages"
                                                    :editionMode="editionMode"
                                                    :errors="errors"
                                                    :label="$t('attributes.language')"
                                                    :metadata="metadata"
                                                    name="available_languages"
                />
              </div>
              <div v-if='metadata.languages' class='col-span-12 xl:col-span-6'>
                <BaseLanguageShowEditSelectSimple v-model='settings.default_language'
                                                  :editionMode='editionMode'
                                                  :errors='errors'
                                                  :label="$t('attributes.default_language')"
                                                  :metadata='metadata'
                />
              </div>
              <div v-if='metadata.countries' class='col-span-12 xl:col-span-6'>
                <BaseCountryShowEditSelectSimple v-model='settings.default_country'
                                                 :editionMode='editionMode'
                                                 :errors='errors'
                                                 :label="$t('attributes.default_country')"
                                                 :metadata='metadata'
                />
              </div>
              <div v-if='metadata.companies' class='col-span-12 xl:col-span-6'>
                <BaseCompanyShowEditSelectSimple v-model='settings.default_company'
                                                 :editionMode='editionMode'
                                                 :errors='errors'
                                                 :label="$t('attributes.default_company')"
                                                 :metadata='metadata'
                />

              </div>
              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditInput v-model="settings.archive_after_x_days"
                                   :editionMode="editionMode"
                                   :errors="errors.archive_after_x_days"
                                   :label="$t('attributes.archive_after_x_days')"
                                   :placeholder="$t('attributes.archive_after_x_days')"
                />
              </div>
            </div>
            <div class="mx-12 mt-5">
              <div class="mb-3">
                <h1 class='text-2xl font-bold capitalize-first mt-12 mb-10'>période de conservation de l'urne</h1>
              </div>
              <div class="grid md:grid-cols-12 sm:grid-cols-4 gap-5">
                <ShowEditGeneralSettingsTreeInput
                  v-for="index in 3"
                  :key="index"
                  v-model:firstValue="settings[`period_${index}_duration_start`]"
                  v-model:secondValue="settings[`period_${index}_duration_end`]"
                  v-model:thirdValue="settings[`period_${index}_price`]"
                  v-model:fourthValue="settings[`nb_days_${index}_for_alert`]"
                  :class="editionMode ? 'col-span-4' : 'col-span-3' "
                  :editionMode="editionMode"
                  :firstLabel="$t('global.from')"
                  :firstName="`period_${index}_duration_start`"
                  :legend="`${$t('global.period')}${index}`"
                  :secondLabel="$t('global.to')"
                  :secondName="`period_${index}_duration_end`"
                  :thirdLabel="$t('attributes.price')"
                  :thirdName="`period_${index}_price`"
                  fourthLabel="notifier à "
                />
              </div>
              <div v-if="editionMode" class="grid grid-cols-3 gap-5">
                <BaseShowEditInput
                  v-model="settings.nb_days_1_for_alert"
                  :editionMode="editionMode"
                  label="notifier à (jour + x)"
                  placeholder="jour(s)"
                />
                <BaseShowEditInput
                  v-model="settings.nb_days_2_for_alert"
                  :editionMode="editionMode"
                  label="notifier à (jour + x)"
                  placeholder="jour(s)"
                />
                <BaseShowEditInput
                  v-model="settings.nb_days_3_for_alert"
                  :editionMode="editionMode"
                  label="notifier à (jour + x)"
                  placeholder="jour(s)"
                />
              </div>
            </div>
          </template>
        </BaseCardSection>
      </div>
      <!--      <div v-if='editionMode' class='flex justify-end mt-12 mx-12'>-->
      <!--        <BaseButton v-if="canSeeSettings"-->
      <!--                    :isElevated='true'-->
      <!--                    :title="$t('button.save_plural', {var: $t('global.general_settings')})"-->
      <!--                    icon='paper-plane'-->
      <!--                    @click='submitForm'-->
      <!--        />-->
      <!--      </div >-->
    </template>
  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import ShowEditGeneralSettingsTreeInput
  from "@p/settings/settings/GeneralSettings/components/ShowEditGeneralSettingsTreeInput";

export default {
  name: 'TheGeneralSettingsView',
  components: {ShowEditGeneralSettingsTreeInput},
  data() {
    return {
      editionMode: false
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSettings',
      metadata: 'settings/getSettingsMetadata',
      errors: 'auth/getErrors'
    }),
    permission() {
      return !this.$can('manage_settings')
    },
    canSeeSettings() {
      return this.$can('see_settings')
    }
  },
  methods: {
    ...mapActions({
      fetchGeneralSettings: 'settings/fetchGeneralSettings',
      updateGeneralSettings: 'settings/updateGeneralSettings'
    }),
    switchEditMode() {
      if (this.editionMode) {
        this.fetchGeneralSettings()
      }
      this.editionMode = !this.editionMode
    },
    submitForm() {
      this.updateGeneralSettings({
        generalSettings: this.settings,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.updated_plurals', {var: this.$t('global.general_settings')}), {className: 'toastify-content success'})
          this.editionMode = !this.editionMode
        }
      })
    }
  },
  beforeMount() {
    this.fetchGeneralSettings()
  },
}
</script>

