<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="modal-content p-0 max-h-screen flex flex-col"  :class="modalClasses" open v-if="isModalVisible">

        <div class='flex'>
          <div class='flex-1 p-5'>
            <h2 class="text-xl font-bold capitalize-first">
              {{ this.invoice.work ? `${ invoice.work.title } (${ invoice.reference })` : `${ invoice.reference }`}}
            </h2>
          </div>
          <div class='flex items-center p-5 cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div>
        </div>

        <section class="flex-1 modal-body overflow-scroll">

          <div class='grid grid-cols-2 gap-5'>
            <div>
              <BaseSelectMultiple  v-if='metadata.users'
                                  :label="$t('attributes.receivers')"
                                  :placeholder="$t('attributes.receivers')"
                                  name="receivers"
                                  :options="metadata.users || []"
                                  attributeLabel="name"
                                  :searchable='true'
                                   :defaultValue="cDefautlValue"
                                  @setSelected="getInputValue"
                                  :disabled="permissions"/>
            </div>
            <div>
              <BaseInput
                         :label="$t('attributes.subject')"
                         :placeholder="$t('attributes.subject')"
                         v-model="info.subject"
                         :disabled="permissions"
                        />
            </div>
            <div class='col-span-2'>
              <BaseSelectSimple
                v-if="metadata.message_texts"
                :key="metadata.message_texts.id"
                :label="$t('global.message_text')"
                :placeholder="$t('global.message_text')"
                name="messageConverted"
                :options="metadata.message_texts"
                :required="false"
                @setSelected="getInputValue"/>
            </div>
            <div class='col-span-2'>
<!--              :key="info.messageConverted"-->
              <BaseWysiwyg
                v-model="info.messageConverted"
                :value="info.messageConverted"
                ref="editorForm"
                name="message"
                @change.self="getInputValue"
              />
            </div>
          </div>
        </section>

        <div class="flex flex-row justify-end py-3 px-5 bg-gray-100 gap-x-5">
          <BaseButton :title="$t('button.send')" icon='paper-plane' @click='submitForm'/>
          <BaseButton :title="$t('button.close')" icon='times' color='danger' @click="_close"/>
        </div>
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isModalVisible"></div>
    </transition>
  </teleport>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'


export default {
  name: 'InvoicingSendDialog',
  emits: ['close', 'backdropClicked'],
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function(value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPulsing: false,

    }
  },

  computed: {
    ...mapGetters({invoice: 'invoicing/getInvoice', metadata: 'invoicing/getInvoiceMetadata', info: 'invoicing/getSendInvoiceData'}),
    permissions(){
      return !this.$can('manage_invoicing')
    },
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    },
    cDefautlValue(){
      let customer_users = this.metadata.users.filter(user => this.invoice.customer.users?.map(item =>item.id).includes(user.id) );
      if(customer_users.length){
        this.info.receivers = customer_users
      }
      return customer_users
    },

  },
  methods: {
    ...mapActions({ sendInvoice: 'invoicing/sendInvoice', getMessageConverted: 'invoicing/fetchSendInvoiceMessageTextConverted'}),
    getInputValue({attribute, value}){
      console.log('ok', attribute)
      this.info[attribute] = value
      if(attribute === 'messageConverted') {
        this.getMessageConverted({model: 'invoice', model_id: this.invoice.id, messageText_id:value})
      }
    },
    submitForm(){
      if(this.info.receivers && this.info.subject && this.info.messageConverted){
        this.sendInvoice({
          info: {invoice_id: this.invoice.id, doc_type: this.invoice.doc_type, subject: this.info.subject, receivers: this.info.receivers, message: this.info.messageConverted},
          successCallback: () => {
            this.$h.toastify(this.$t('global.send_message_success'), { className: 'toastify-content success' })
            this._close()
            this.info.receivers = null
            this.info.subject = null
            this.info.messageConverted = null
          }
        })
      }else{
        this.$h.toastify('a trad: faut tout remplir', { className: 'toastify-content warning' })
      }
    },
    _close() {
      this.isPulsing = false
      this.text = '';
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    }
  },
  watch: {
    isPulsing: function(currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    }
  }
}
</script>

<style scoped>

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index : 1000;
  overflow : hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width : 90%;
  }
}

@media only screen and (min-width:  577px){
  dialog.small {
    width : 20%;
    max-width : 300px;
    scroll-behavior: auto;
  }
  dialog.medium {
    width : 30%;
    max-width : 500px;
  }
  dialog.large {
    width : 50%;
    max-width : 700px;
  }
  dialog.x-large{
    width : 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active{
  animation: modal 0.3s ease-in ;
}

.modal-leave-active{
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active{
  animation: backdrop 0.3s ease-in ;
}
.backdrop-leave-active{
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation : pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform : scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,0.3);
  }
}
</style>

