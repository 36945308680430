<template>

  <div class="border border-gray-400 rounded-md p-5">
    <div class="flex justify-end mr-6">
      <BaseButton color='danger'
                  icon='times'
                  v-if="editionMode"
                  :isOutline="true"
                  :tooltip="$t('button.delete_v', {var: $t('attributes.option')})"
                  @click='deleteEventKind'/>
    </div>
    <div class='grid grid-cols-3 px-5 py-8 w-full'>
      <div class="col-span-1 ">
        <BaseShowEditInput
            v-for="(language, index) in systemSettings.general.available_languages"
            :key="index"
            :label="`${$t('attributes.name')} (${language})`"
            v-model="event_kind.name[language]"
            :placeholder="$t('attributes.language')"
            :editionMode="editionMode"
            :required="true"
        />
      </div>
      <div class="col-span-1 mr-6" :class="editionMode ? 'ml-5' : {}">
        <BaseShowEditInput
            :label="$t('attributes.default_duration')"
            v-model="event_kind.default_duration"
            :placeholder="event_kind.default_duration ? event_kind.default_duration : '00'"
            :editionMode="editionMode"
            :required="true"
        />
        <BaseShowEditInput
            :label="$t('attributes.arriving_before')"
            v-model="event_kind.arriving_before"
            :placeholder="event_kind.arriving_before ? event_kind.arriving_before : '00'"
            :editionMode="editionMode"
            :required="true"
        />
      </div>
      <div class="col-span-1 ml-10">
        <div class="mb-2">
          <BaseTrueFalseShowEditRadioGroup
              name="planifiable"
              v-model="event_kind.planifiable"
              :label="$t('attributes.plannable')"
              :defaultValue="event_kind.planifiable"
              :editionMode="editionMode"
              :extra_id="event_kind.id"
          />

        </div>
        <div>
          <BaseTrueFalseShowEditRadioGroup
              name="need_more_infos"
              v-model="event_kind.need_more_infos"
              :label="$t('attributes.need_more_infos')"
              :defaultValue="event_kind.need_more_infos"
              :editionMode="editionMode"
              :extra_id="event_kind.id"
          />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import {mapGetters, mapMutations} from "vuex";


export default {
  name: "EventKindSettingsComponent.vue",
  props: {
    event_kind: {
      type: Object,
      required: true
    },
    event_type: {
      type: Object,
      required: true
    },
    event_typeTitle: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    editionMode: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      track : 1
    }
  },
  computed: {
    ...mapGetters({
      systemSettings: 'settings/getSystemSettings',
    })
  },
  methods: {
    ...mapMutations({
      addNewEventKindToEventType: 'settings/setNewEventKindToEventType',
      RemoveEventKindFromEventType: "settings/deleteEventKindFromEventType",
      updateEventKindInEventType: "settings/alterEventKindInEventType",
      updateEventKindNameInEventType: "settings/alterEventKindNameInEventType"
    }),
    deleteEventKind() {
      let index = this.event_type.indexOf(this.event_kind);
      this.RemoveEventKindFromEventType({index: index, eventType: this.event_typeTitle})
    }
  },

}
</script>

