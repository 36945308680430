<template>
  <base-card-section :section-title='burialType !== "CON" ? $t(`enums.deceased.burial_types.${burialType}`): "" ' >

    <SelectBurForm v-if="burial && burialType === 'BUR'" :edition-mode='editionMode'/>

    <SelectDisForm v-if="burial && burialType === 'DIS'" :edition-mode='editionMode' />

    <SelectAsrForm v-if="burial && burialType === 'ASR'"
                   :edition-mode='editionMode' />

    <SelectOthForm v-if="burial && burialType === 'OTH'"
                   :edition-mode='editionMode' />

<!--    <SelectConForm v-if="burial && burialType === 'CON'"-->
<!--                   :edition-mode='editionMode' />-->

  </base-card-section>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import SelectBurForm from './components/SelectBurForm'
import SelectDisForm from './components/SelectDisForm'
import SelectAsrForm from './components/SelectAsrForm'
import SelectOthForm from './components/SelectOthForm'
import SelectConForm from '@p/works/form/subpages/informations/components/events/burial/components/SelectConForm'

export default {
  name: 'SelectBurialForm',
  components: {
    SelectConForm,
    SelectBurForm,
    SelectDisForm,
    SelectAsrForm,
    SelectOthForm,
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      refresh_radio: 0,
      inhumationType: null,
      cemetery: {id:null},
      yes_no_options: {
        true: true,
        false: false
      }
    }
  },
  computed: {
    ...mapGetters({ work: 'work/getWork', metadata: 'work/getWorkMetadata', errors: 'auth/getErrors',auth:'auth/getAuth' }),
    cemeteryBaseValue(){
      if (this.selectedEvent.cemetery) {
        // return { id: this.selectedEvent.cemetery_id, title: `${this.$t('global.cemetery_of')} ${this.selectedEvent.cemetery.ville} ${this.selectedEvent.cemetery.nom}` }
        return { id: this.selectedEvent.cemetery_id, title: this.selectedEvent.cemetery.nom_formated}
      } else {
        return {}
      }
    },
    permission(){
      return !this.$can('manage_work')
    },
    selectedEvent(){
      return this.work.events && this.work.events.filter(event => event.type === this.burialType)[0]
    },
    auth_role(){
      return this.auth.roles[0];
    },
    burial(){
      return this.work.deceased.burial_type !== null
    },
    burialType(){
      return this.work.deceased.burial_type
    }
  },
  methods: {
    ...mapMutations({
      alterWorkEventValuesByEventType: 'work/alterWorkEventValuesByEventType',
      cleanWorkEventsFromTypes: 'work/cleanWorkEventsFromTypes'
    }),
    getInputValue({ attribute, value }) {
        this.alterWorkEventValuesByEventType({type: this.burialType, attribute: attribute, value: value})
    },
  },
}
</script>


