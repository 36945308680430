import getters from '@/store/modules/content/implantation/getters'
import mutations from '@/store/modules/content/implantation/mutations'
import actions from '@/store/modules/content/implantation/actions'

export default {
  namespaced: true,
  state() {
    return {
      implantationList: [],
      implantation: {},
      implantationMetadata: {},
    }
  },
  getters,
  actions,
  mutations
}
