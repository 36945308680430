<template>
    <ActivityTable v-if='taskActivity && taskActivity.length > 0' :activities="taskActivity" />
  <div v-else class='mt-10 pt-10 flex justify-center font-semibold text-gray-500'>
    {{$t('global.no_activity')}}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheTaskActivityPage',
  computed: { ...mapGetters({taskActivity: 'task/getTaskActivity'}) },
  methods: { ...mapActions({fetch: 'task/fetchTaskActivitiesByTaskID'})},
  beforeMount() {
    this.fetch({task_id: this.$route.params.id})
  }
}
</script>
