<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','badge-dollar']" size='2x' />
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.content_customer_title') }}</span>
      </div>
    </template>

    <template #cta>
      <BaseButton
                  v-if="!$can('manage-settings')"
                  color='primary'
                  icon='plus'
                  @click=" this.$router.push({name: 'settings_create_customer_route'})"
                  :title="$t('button.add_new_m', {var: $t('global.customer')})"/>
    </template>

    <template #body>
      <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.customer')}) "/>
      <BaseContentTable
        v-if="metadata.columns"
        :columns="metadata.columns"
        :modelList="customerList"
        :search="true"
        @rowClick="redirectToModelPage"
        @refreshClick="this.fetchAllCustomer()"
        @deleteModel="deleteCustomer"
        :isHoverable="true"/>
    </template>

  </SettingsPageFull>
</template>

<script>

import {mapGetters,mapActions} from "vuex";

export default {
  name: 'TheCustomerIndexPage',
  computed:{
    ...mapGetters({
      customerList:'customer/getCustomerList',
      metadata:'customer/getCustomerMetadata'
    })
  },
  methods: {
    ...mapActions({
      fetchAllCustomer: 'customer/fetchAllCustomer',
      deleteCustomerById: 'customer/deleteCustomerById',
      undoDeleteCustomerById: 'customer/undoDeleteCustomerById'
    }),
    redirectToModelPage(id){
      this.$router.push({ name: 'settings_edit_customer_route', params: { id: id } })
    },
    deleteCustomer(id){
      this.deleteCustomerById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-content").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteCustomer(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteCustomer(id){
      this.undoDeleteCustomerById({
        id: id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_m', {var: this.$t('attributes.customer')})  , {className: 'toastify-content success'})
        }
      })
    }
  },
  mounted() {
    this.fetchAllCustomer();
  }
}
</script>
