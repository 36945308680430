<template>
  <ActivityTable
      v-if='companyActivity && companyActivity.length > 0'
      :activities="companyActivity"
  />
  <div v-else class='mt-10 pt-10 flex justify-center font-semibold text-gray-500'>
    {{$t('global.no_activity')}}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheCompanyActivityPage',
  computed:{ ...mapGetters({companyActivity: 'company/getCompanyActivity'}) },
  methods:{ ...mapActions({fetch: 'company/fetchCompanyActivitiesByCompanyID'})},
  beforeMount() {
    this.fetch({company_id: this.$route.params.id})
  }
}
</script>
