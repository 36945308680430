<template>
  <BaseSimpleCard :title="$t('global.activities')" name="company-form" classes='col-start-2 col-span-10'>
    <template #default>

      <ActivityTable :activities="workActivities" />

    </template>
  </BaseSimpleCard>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheWorkActivitiesPage',
  computed:{ ...mapGetters({workActivities: 'work/getWorkActivities'}),},
  methods:{ ...mapActions({fetchWorkActivities: 'work/fetchWorkActivities'}), },
  beforeMount() {
    this.fetchWorkActivities({work_id: this.$route.params.id})
  },
}
</script>
