import AccountingCodeTabComponent from "@p/settings/settings/InvoicingSettings/invoicingSettingsTab/TheAccountingCodePage";
import AssetForm from '@p/settings/content/implantation/asset/form/TheAssetFormPage'
import AssetTabActivity from "@p/settings/content/implantation/asset/form/assetTabForm/TheAssetActivityPage";
import AssetTabInfo from "@p/settings/content/implantation/asset/form/assetTabForm/TheAssetInfoPage";
import CompanyForm from '@p/settings/content/company/form/TheCompanyFormPage'
import CompanyTabActivity from "@p/settings/content/company/form/companyTabForm/TheCompanyActivityPage";
import CompanyTabInfo from '@p/settings/content/company/form/companyTabForm/TheCompanyInfoPage'
import CustomerForm from '@p/settings/content/customer/form/TheCustomerFormPage'
import CustomerTabActivity from "@p/settings/content/customer/form/customerTabForm/TheCustomerActivityPage";
import CustomerTabInfos from "@p/settings/content/customer/form/customerTabForm/TheCustomerInfosPage";
import ImplantationForm from '@p/settings/content/implantation/implantation/form/TheImplantationFormPage'
import InvoicingInfosTabComponent from '@p/settings/settings/InvoicingSettings/invoicingSettingsTab/TheInvoicingSettingsPage'
import ItemForm from "@p/settings/content/item/form/TheItemFormPage";
import ItemTabActivity from "@p/settings/content/item/form/ItemTabForm/TheItemActivityPage";
import ItemTabInfo from "@p/settings/content/item/form/ItemTabForm/TheItemInfoPage";
import SettingsMain from '../../components/pages/settings/TheSettingsPage'
import TaskForm from '@p/settings/content/tasks/form/TheTaskFormPage'
import TaskTabActivity from '@p/settings/content/tasks/form/taskTabForm/TheTaskActivityPage'
import TaskTabInfo from '@p/settings/content/tasks/form/taskTabForm/TheTaskInfoPage'
import TheBankAccountFormPage from "@p/settings/content/bankAccount/form/TheBankAccountFormPage";
import TheBankAccountIndexPage from "../../components/pages/settings/content/bankAccount/index/TheBankAccountIndexPage";
import TheCompanyIndexPage from '../../components/pages/settings/content/company/index/TheCompanyIndexPage'
import TheCustomerIndexPage from '../../components/pages/settings/content/customer/index/TheCustomerIndexPage'
import TheEventSettingsView from '../../components/pages/settings/settings/EventsSettings/TheEventSettingsView'
import TheGeneralSettingsView from '../../components/pages/settings/settings/GeneralSettings/TheGeneralSettingsView'
import TheImplantationIndexPage from '../../components/pages/settings/content/implantation/implantation/index/TheImplantationIndexPage'
import TheInvoicingSettingsView from '../../components/pages/settings/settings/InvoicingSettings/TheInvoicingSettingsView'
import TheItemIndexPage from '../../components/pages/settings/content/item/index/TheItemIndexPage'
import TheListItemIndexPage from "../../components/pages/settings/content/listItem/index/TheListItemIndexPage";
import TheMessageTextIndexPage from "../../components/pages/settings/content/messageTexts/index/TheMessageTextIndexPage";
import TheNotificationSettingsIndexPage from "../../components/pages/settings/settings/NotificationSettings/TheNotificationSettingsView";
import TheTaskIndexPage from '../../components/pages/settings/content/tasks/index/TheTaskIndexPage'
import TheUserIndexPage from '../../components/pages/settings/content/user/index/TheUserIndexPage'
import UserForm from '@p/settings/content/user/form/TheUserFormPage'
import UserTabActivity from '@p/settings/content/user/form/userTabForm/TheUserActivityPage'
import UserTabInfo from '@p/settings/content/user/form/userTabForm/TheUserInfoPage'

export const settingsSubPages = [
  {
    path: '/settings',
    name: 'settings_index_route',
    component: SettingsMain,
    children: [
      {
        path: 'general',
        name: 'settings_list_general_route',
        component: TheGeneralSettingsView,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_user_title', active: true }]
        }
      },
      {
        path: 'events',
        name: 'settings_list_events_route',
        component: TheEventSettingsView,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_user_title', active: true }]
        },
      },
      {
        path: 'invoicing',
        name: 'setting_invoicing_tab_route',
        component: TheInvoicingSettingsView,

        children: [
          {
            path: '',
            component: InvoicingInfosTabComponent,
            name: 'settings_list_invoicing_route'
          },
          {
            path: 'accounting_code',
            component: AccountingCodeTabComponent,
            props: true,
            name: 'settings_accounting_code_route'
          }
        ],
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_edit_title', active: true }]
        }
      },
      {
        path: 'notification',
        name: 'settings_notification_route',
        component: TheNotificationSettingsIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_user_title', active: true }]
        }
      },
      {
        path: 'user',
        name: 'settings_list_user_route',
        component: TheUserIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_user_title', active: true }]
        }
      },
      {
        path: 'user/:id',
        name: 'settings_user_profile_route',
        props: true,
        component: UserTabInfo,
        children: [
          {
            path: '',
            component: UserForm,
            name: 'settings_edit_user_route'
          },
          {
            path: 'activities',
            component: UserTabActivity,
            props: true,
            name: 'settings_user_activity_route'
          },
        ],
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_edit_title', active: true }]
        }
      },
      {
        path: 'user/new',
        name: 'settings_create_user',
        component: UserForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_new_title', active: true }]
        }
      },
      {
        path: 'company',
        name: 'settings_list_company_route',
        component: TheCompanyIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: true }]
        }
      },
      {
        path: 'company/:id',
        name: 'settings_company_profile_route',
        props: true,
        component: CompanyTabInfo,
        children: [
          {
            path: '',
            component: CompanyForm,
            name: 'settings_edit_company_route'
          },
          {
            path: 'activities',
            component: CompanyTabActivity,
            props: true,
            name: 'settings_company_activity_route'
          },
        ],
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_edit_title', active: true }]
        }
      },
      {
        path: 'company/new',
        name: 'settings_create_company_route',
        component: CompanyForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_new_title', active: true }]
        }
      },
      {
        path: 'bank',
        name: 'settings_list_bank_route',
        component: TheBankAccountIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: true }]
        }
      },
      {
        path: 'bank/:id',
        name: 'settings_edit_bank_route',
        props: true,
        component: TheBankAccountFormPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_edit_title', active: true }]
        }
      },
      {
        path: 'bank/new',
        name: 'settings_create_bank_route',
        component: TheBankAccountFormPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_new_title', active: true }]
        }
      },
      {
        path: 'customer',
        name: 'settings_list_customer_route',
        component: TheCustomerIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_customer_title', active: true }]
        }
      },
      {
        path: 'customer/:id',
        name: 'settings_customer_profile_route',
        props: true,
        component: CustomerTabInfos,
        children: [
          {
            path: '',
            component: CustomerForm,
            name: 'settings_edit_customer_route'
          },
          {
            path: 'activities',
            component: CustomerTabActivity,
            props: true,
            name: 'settings_customer_activity_route'
          },
        ],
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_customer_title', active: false }, { title: 'content_customer_edit_title', active: true }]
        }
      },
      {
        path: 'customer/:id/new-user',
        name: 'settings_create_customer_user_route',
        props: true,
        component: UserForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_asset_title', active: false }, { title: 'content_asset_edit_title', active: true }]
        }
      },
      {
        path: 'customer/new',
        name: 'settings_create_customer_route',
        component: CustomerForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_customer_title', active: false }, { title: 'content_customer_new_title', active: true }]
        }
      },
      {
        path: 'implantation',
        name: 'settings_list_implantation',
        component: TheImplantationIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: true }]
        }
      },
      {
        path: 'implantation/:id',
        name: 'settings_edit_implantation_route',
        props: true,
        component: ImplantationForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_edit_title', active: true }]
        }
      },
      {
        path: 'implantation/new',
        name: 'settings_create_implantation_route',
        component: ImplantationForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_implantation_title', active: false }, { title: 'content_implantation_new_title', active: true }]
        }
      },
      {
        path: 'asset/:id',
        name: 'settings_asset_profile_route',
        props: true,
        component: AssetTabInfo,
        children: [
          {
            path: '',
            component: AssetForm,
            name: 'settings_edit_asset_route'
          },
          {
            path: 'activities',
            component: AssetTabActivity,
            props: true,
            name: 'settings_asset_activity_route'
          },
        ],
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_asset_title', active: false }, { title: 'content_asset_edit_title', active: true }]
        }
      },
      {
        path: 'asset/:id/new',
        name: 'settings_create_asset_route',
        props: true,
        component: AssetForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_asset_title', active: false }, { title: 'content_asset_edit_title', active: true }]
        }
      },
      {
        path: 'item',
        name: 'settings_list_item_route',
        component: TheItemIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_item_title', active: true }]
        }
      },
      {
        path: 'item/:id',
        name: 'settings_item_profile_route',
        props: true,
        component: ItemTabInfo,
        children: [
          {
            path: '',
            component: ItemForm,
            name: 'settings_edit_item_route'
          },
          {
            path: 'activities',
            component: ItemTabActivity,
            props: true,
            name: 'settings_item_activity_route'
          },
        ],
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_item_title', active: false }, { title: 'content_item_edit_title', active: true }]
        }
      },
      {
        path: 'item/new',
        name: 'settings_create_item_route',
        component: ItemForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_item_new_title', active: true }]
        }
      },
      {
        path: 'tasks',
        name: 'settings_list_tasks_route',
        component: TheTaskIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_item_title', active: true }]
        }
      },
      {
        path: 'tasks/:id',
        name: 'settings_tasks_profile_route',
        props: true,
        component: TaskTabInfo,
        children: [
          {
            path: '',
            component: TaskForm,
            name: 'settings_edit_tasks_route'
          },
          {
            path: 'activities',
            component: TaskTabActivity,
            props: true,
            name: 'settings_task_activity_route'
          },
        ],
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_edit_title', active: true }]
        }
      },
      {
        path: 'tasks/new',
        name: 'settings_create_tasks_route',
        component: TaskForm,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_new_title', active: true }]
        }
      },
      {
        path: 'listItem',
        name: 'settings_add_item_list_route',
        component: TheListItemIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_new_title', active: true }]
        }
      },
      {
        path: 'messageTexts',
        name: 'settings_message_texts_index_route',
        component: TheMessageTextIndexPage,
        meta: {
          breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_company_title', active: false }, { title: 'content_company_new_title', active: true }]
        }
      },
    ]
  }
]
