<template>
  <hr>
  <BaseShowEditTextArea :label="$t('attributes.remarks')"
                        :placeholder="$t('attributes.remarks')"
                        v-model="work.remarks"
                        :errors="errors.remarks"
                        :editionMode="editionMode" />
  <BaseShowEditTextArea v-if="auth_role !== 'customer-user'"
                        :label="$t('attributes.note_intern')"
                        :placeholder="$t('attributes.note_intern')"
                        v-model="work.internal_note"
                        :errors="errors.internal_note"
                        :editionMode="editionMode" />
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: 'WorkFormRemarks',
  props:{
    editionMode: {
      type: Boolean,
      required: true,
    }
  },
  computed:{
    ...mapGetters({ work: 'work/getWork', errors: 'auth/getErrors', auth:'auth/getAuth'}),
    auth_role() {
      return this.auth.roles[0];
    },
  },
}
</script>
