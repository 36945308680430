import apiClient from '@u/apiClient'
import moment from "moment";

let base_error = {}

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {

    //region fetch
    async fetchEvents({commit}, obj) {
        await apiClient.get(`api/planning/fetch-events?start=${moment(obj.startStr).format('DD/MM/YYYY')}&end=${moment(obj.endStr).format('DD/MM/YYYY')}&with_timeslots=true`)
            .then(async response => {
                if (response.data) {
                    await commit('setEvents', response.data)
                }
            })
            .catch(async reason => {
                console.error('fetchEvents', reason)
            })
    },
    async fetchPlanningMetadata({commit}) {
        await apiClient.get(`api/planning/fetch-metadatas`)
            .then(async response => {
                if (response.data) {
                    await commit('setPlanningMetadata', response.data)
                }

            })
            .catch(async reason => {
                console.error('fetchPlanningMetadata', reason)
            })
    },
    async fetchPlanningReport({commit}, payload) {
        await apiClient.get('api/planning/report-planning' + payload)
          .then(async response => {
              await commit('setDocument', response.data.document)
          })
          .catch(async reason => {
              console.error('fetchDocument', reason)
          })
    },
    //endregion

    async updateEventByDrag({commit}, obj) {

        let new_data = {
            date_from: moment(obj.event.startStr).format('DD/MM/YYYY'),
            date_to: moment(obj.event.endStr).format('DD/MM/YYYY'),
            time_from: moment(obj.event.startStr).format('HH:MM'),
            time_to: moment(obj.event.endStr).format('HH:MM'),
        };
        if (obj.newResource != null) {
            new_data['asset_id'] = obj.newResource.id
        }
        await apiClient.put(`api/planning/update-event-time/${obj.event.id}`,
            new_data
        )
            .then(async response => {
                //todo je retourne l'event updated, il suffit de le modifier dans le state.
            })
            .catch(async reason => {
                console.error('updateEventByDrag', reason)
            })
    },

    async fetchEventById({commit}, payload) {
        commit('auth/setErrors', {})
        await apiClient.get(`api/planning/event/${payload.event_id}`)
            .then(async response => {

                await commit('setEvent', response.data.event)
                await commit('setEventMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchEventById', reason)
            })
    },

    async fetchNewEvent({commit}) {
        commit('auth/setErrors', {})
        await apiClient.get(`api/model/new?model=event`)
            .then(async response => {
                await commit('setEvent', response.data.event)
                await commit('setEventMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchNewEvent', reason)
            })
    },

    async postEvent({commit}, obj) {
        commit('auth/setErrors', {})
        await apiClient.post(`api/planning/store-event/`, obj.event)
            .then(async response => {
                if (response.status === 201) {
                    if (obj.successCallback) {
                        obj.successCallback()
                    }
                }
            })
            .catch(async reason => {
                console.error('postEvent', reason)
            })
    },

    async updateEvent({commit}, obj) {
        commit('auth/setErrors', {})
        await apiClient.put(`api/planning/update-event/${obj.event.id}`, obj.event)
            .then(async response => {
                if (response.status === 200) {
                    if (obj.successCallback) {
                        obj.successCallback()
                    }
                }
            })
            .catch(async reason => {
                console.error('updateEvent', reason)
            })
    },
    async deleteEventById({commit}, payload) {
        await apiClient.delete(`api/event/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    processSuccessCallback(payload);
                }
            }).catch(async reason => {
                console.error('deleteEventById', reason)
            })
    },
    async undoDeleteEventById({commit}, payload) {
        await apiClient.get(`api/event/undo-delete/${payload.id}`).then(async response => {
            if (response.status === 200) {
                processSuccessCallback(payload);
            }
        }).catch(async reason => {
            console.error('undoDeleteEventById', reason)
        })
    },
    async fetchEventDisponbilitiesByOption({commit}, payload) {
      console.log('payload',payload)
        await apiClient.get(`api/planning/fetch-timeslots`, {params: payload.params})
            .then(async response => {
                // await commit('setEventDisponibilities', response.data.timeslots
                if ('index' in payload) {
                    commit('work/setEventTimeSlotInWorkEvent', {index: payload.index, timeslots: response.data.timeslots})
                } else {
                    commit('setEventTimeSlotInEvent', response.data.timeslots)
                }
            }).catch(async reason => {
                await console.error('fetchEventDisponbilitiesByOption', reason)
            })
    }
}
