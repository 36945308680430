<template >
  <Menu as="div" class="relative mx-5 text-left overflow-visible z-50">
    <div >
      <MenuButton
        class="btn btn-primary text-sm font-medium text-white btn-primary rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <span class='capitalize-first'>{{ $t('global.action')}}</span >
        <font-awesome-icon
          class="ml-3 w-5 h-5 text-violet-200 hover:text-violet-100"
          aria-hidden="true"
          :icon="['fal', 'chevron-down']"
        />
      </MenuButton >
    </div >
    
    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300"
      >
        
        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click="this.$emit('generateInvoicesPDF')">
              <font-awesome-icon :icon="['fal', 'file-pdf']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">générer un fichier PDF</span>
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click="this.$emit('generateInvoicesCSV')">
              <font-awesome-icon :icon="['fal', 'file-csv']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">générer un fichier CSV</span>
            </button>
          </MenuItem >
          <MenuItem v-slot="{ active }">
            <router-link :to="{name:'invoice_export_route'}"
                         class="flex items-center space-x-2 dark:text-white p-2 rounded-md "
            >
              <font-awesome-icon :icon="['fal', 'arrow-alt-from-left']" size="lg" />&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">export comptable</span >
            </router-link >
          </MenuItem >
        
        </div >
      </MenuItems >
    </transition >
  </Menu >
</template >
<script >
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'

export default {
  name: 'InvoicingIndexActionDropDownComponent',
  components: { Menu, MenuButton, MenuItem, MenuItems},
  
}
</script >
