<template>
    <ActivityTable v-if='customerActivity && customerActivity.length > 0' :activities="customerActivity" />
    <div v-else class='mt-10 pt-10 flex justify-center font-semibold text-gray-500'>
      {{$t('global.no_activity')}}
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheCustomerActivityPage',
  computed: {
    ...mapGetters({customerActivity: 'customer/getCustomerActivity'}),
  },
  methods: { ...mapActions({fetch: 'customer/fetchCustomerActivitiesByCustomerID'}) },
  beforeMount() {
    this.fetch({customer_id: this.$route.params.id})
  }
}
</script>

