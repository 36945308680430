<template>
  <BaseSimpleCard
    name="company-form"
    classes='col-start-2 col-span-10'
    :title="$t('global.urn')"
    :borderedHeader="false"
    :icon="getIcon"
    colorIcon="white"
    classIcon="bg-theme-danger p-1 rounded ml-1 w-5 h-4"
  >
    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 '>
      <BaseButton v-if="editionMode" icon="paper-plane" :title="$t('button.edit_new_f', {var: $t('global.urn')})"
                  @click="submitForm"/>
      <BaseButton
          v-if="$can('manage_content')"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="ChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
      </div>
    </template>
    <template #default>
      <div class='flex flex-col space-y-10'>
        <div>
          <BaseCardSection>
            <template #default>
              <BaseTrueFalseShowEditRadioGroup name='awaiting_at_crema'
                                               :label="$t('attributes.awaiting_at_crema')"
                                               :options='yes_no_options'
                                               v-model="isAwaitingAtCrema"
                                               :editionMode="editionMode"
                                               :getByKey="true"
                                               translationExtention="enums.yes_no"
                                               :translatable='true'
                                               attribute-label='name'
                                               @update="changeConservation"
              @click="change"/>

              <template v-if="isAwaitingAtCrema">
                <div class='grid grid-cols-12 gap-x-5' v-if="work.urn">
                  <div class='col-span-12 xl:col-span-6'>
                    <BaseShowEditRadioGroup name='awaiting_duration_in_month'
                                            v-if="conservationEventInWork"
                                            :label="$t('attributes.presumed_duration')"
                                            :options="metadata.awaiting_duration"
                                            v-model="work.urn.awaiting_duration_in_month"
                                            :editionMode="editionMode"
                                            :isShowingKey="true"
                                            :isReturningKey="true"
                                            attribute-label='name'
                    />
                  </div>
                  <div class='col-span-12 xl:col-span-6'>
                  </div>
                  <div  class='col-span-12 xl:col-span-6' v-if='conservationEventInWork'>
                    <BaseShowEditInput
                        :editionMode="editionMode"
                        :label="$t('attributes.reference')"
                        :placeholder="$t('attributes.reference')"
                        name='reference'
                        v-model='work.urn.reference'
                        :disabled="permission"
                        :errors='errors.reference'
                    />
                  </div>
                  <div class='col-span-12 xl:col-span-6' v-if='conservationEventInWork'>
                    <BaseShowEditInput
                        :editionMode="editionMode"
                        :label="$t('attributes.storage_other')"
                        :placeholder="$t('attributes.storage_other')"
                        name='storage_other'
                        v-model='work.urn.storage_other'
                        :disabled="permission"
                        :errors='errors.storage_other'
                    />
                  </div>
                  <div v-if='conservationEventInWork' class='col-span-12 xl:col-span-6'>
                    <BaseShowEditDatePicker
                        :editionMode=editionMode
                        :label="$t('attributes.storage_start_date')"
                        :placeholder="$t('attributes.storage_start_date')"
                        v-model='conservationEventInWork.date_from'
                        name='storage_start_date'
                        :errors='errors.storage_start_date'
                        :disabled="permission"
                        labelClass="mt-1 text-gray-800"/>
                  </div>
                  <div v-if='conservationEventInWork' class='col-span-12 xl:col-span-6'>
                    <BaseShowEditDatePicker :editionMode=editionMode
                                            :label="$t('attributes.awaiting_dead_line')"
                                            :placeholder="$t('attributes.awaiting_dead_line')"
                                            v-model='conservationEventInWork.date_to'
                                            name='awaiting_dead_line'
                                            :errors='errors.awaiting_dead_line'
                                            :disabled="permission"
                                            labelClass="mt-1 text-gray-800"/>
                  </div>
                  <div  class='col-span-12 xl:col-span-6'>
                    <BaseShowEditInput
                      :editionMode="editionMode"
                      :label="$t('attributes.contact_name')"
                      :placeholder="$t('attributes.contact_name')"
                      name='contact_name'
                      v-model='work.urn.contact_name'
                      :disabled="permission"
                    />
                  </div>
                  <div  class='col-span-12 xl:col-span-6'>
                    <BaseShowEditInput
                      :editionMode="editionMode"
                      :label="$t('attributes.contact_email')"
                      :placeholder="$t('attributes.contact_email')"
                      name='contact_email'
                      v-model='work.urn.contact_email'
                      :disabled="permission"
                    />
                  </div>
                  <div  class='col-span-12 xl:col-span-6'>
                    <BaseShowEditPhoneInput :label="$t('attributes.contact_phone')"
                                            :editionMode='editionMode'
                                            name="phone"
                                            v-model:countryValue="work.urn.contact_phone_country"
                                            v-model:numberValue="work.urn.contact_phone_field"
                                            :errors="errors"
                                            :metadata="metadata?.deceased"/>
                  </div>
                  <div  class='col-span-12 mt-4 w-1/2' v-if="editionMode">
                    <label class="text-sm text-gray-500 capitalize">{{ $t('attributes.remarks') }}</label >
                    <BaseWysiwyg
                      ref="editorForm"
                      v-model="work.urn.contact_remarks"
                      name="contact_remarks"
                      :value="work.urn.contact_remarks"
                      @change.self="getInputValue"
                    />
                  </div>
                  <div v-else class="mt-2 col-span-12 xl:col-span-6">
                    <div class="text-sm font-medium text-gray-500 capitalize">{{ $t('attributes.remarks') }}</div>
                    <div class=" w-full" v-html="work.urn.contact_remarks"></div>
                  </div>
                </div>
              </template>

<!--              <div  class='flex-1 flex flex-row justify-end mt-10' >-->
<!--                <BaseButton v-if="editionMode" icon="paper-plane" :title="$t('button.edit_new_f', {var: $t('global.urn')})"-->
<!--                            @click="submitForm"/>-->
<!--              </div>-->

            </template>
          </BaseCardSection>
        </div>
      </div>
    </template>
  </BaseSimpleCard>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from 'vuex'
import moment from "moment/moment";

export default {
  name: 'TheWorkUrnPage',
  data() {
    return {
      urn: false,
      yes_no_options: {1: true,0: false} ,
      editionMode: false
    }
  },
  computed: {
    ...mapGetters({work: 'work/getWork', metadata: 'work/getWorkMetadata', errors: 'auth/getErrors', conservationEventInWork: 'work/getConEventInWork', asrEventInWork: "work/getAsrEventInWork"}),

    isAwaitingAtCrema() {
      return !!(this.work?.urn?.awaiting_at_crema || this.conservationEventInWork)
    },
    permission() {
      return !this.$can('manage_work')
    },
    getIcon() {
      return this.work?.urn?.condition ? "exclamation" : ''
    }
  },
  methods: {
    ...mapMutations({
      alterWorkUrn: 'work/alterWorkUrn',
      alterWorkDeceased: 'work/alterWorkDeceased',
      alterWorkEventValuesByEventType: 'work/alterWorkEventValuesByEventType',
      removeEventFromWork: 'work/removeEventFromWork',
      alterOrPushEventInWork: 'work/alterOrPushEventInWork'
    }),
    ...mapActions({updateWork: 'work/updateWork', fetchWorkByID: 'work/fetchWorkByID' }),
    submitForm() {
      this.updateWork({
        form_data: { id: this.$route.params.id, work: this.work },
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.updated_m', {var: this.$t('attributes.work')}), {className: 'toastify-content success'})
          this.editionMode = false
        }
      })
    },
    ChangeEditionMode() {
      if (this.editionMode){
        this.fetchWorkByID(this.work.id)
      }
      return this.editionMode = !this.editionMode
    },
    change(value) {
     if(this.editionMode) {
       console.log('ici', this.isAwaitingAtCrema)
       if(this.isAwaitingAtCrema) {
         this.work.urn.awaiting_at_crema = false
         if(this.conservationEventInWork) {
           this.removeEventFromWork({type: 'CON'})
         }
       } else {
         console.log('test else')
         this.work.urn.awaiting_at_crema = true
         if(!this.conservationEventInWork) {
           this.work.deceased.burial_type = 'CON'
           this.alterWorkEventValuesByEventType({
             type: this.work.deceased.burial_type,
             attribute: 'type',
             value: this.work.deceased.burial_type
           })
         }

       }
     }
    },
    getInputValue({attribute, value}) {
      this.work.urn[attribute] = value;
    },

  },
  watch: {
    'conservationEventInWork.date_to'(newValue) {
      if(newValue) {
          if(this.conservationEventInWork) {
            this.alterOrPushEventInWork({
              type: 'ASR',
              date_to: this.conservationEventInWork.date_to,
            });
          }
      }
    },
  },
}
</script>
