export default {
    navigation: {


        agenda_title: 'agenda',
        add_new_note: 'ajouter une nouvelle note',
        check_title: 'chèques',
        components_button_title: 'bouttons',
        components_introduction_title: 'introduction',
        components_overlays_dialogs_title: 'dialogs',
        components_overlays_introduction_title: 'introduction',
        components_overlays_slideovers_title: 'slideover',
        components_overlays_title: 'overlays',
        content_bank_edit_title: 'modifier le comptes bancaires',
        content_bank_new_title: 'créer un comptes bancaires',
        content_bank_title: 'comptes bancaires',
        content_company_edit_title: 'modifier la société',
        content_company_new_title: 'créer une société',
        content_company_title: 'sociétés',
        content_customer_edit_title: 'modifier le client',
        content_customer_new_title: 'créer un client',
        content_customer_title: 'clients',
        content_implantation_edit_title: 'modifier l\' implantation',
        content_implantation_new_title: 'créer une implantation',
        content_implantation_title: 'implantation',
        content_item_title: 'articles',
        content_task_edit_title: 'modifier la tâche',
        content_task_new_title: 'créer une tâche',
        content_task_title: 'tâches',
        content_title: 'contenu',
        content_user_title: 'utilisateurs',

        note: 'note',
        note_book: 'carnet de notes',
        stats: 'statistiques',
        components_title: 'components',
        invoicing_title_main: 'facturation',
        list_item_title: 'Eléments de liste',
        login_title: 'se connecter',
        message_texts: "messages prédéfinis",
        planning_title: 'planning',
        reselling_title: 'point de vente',
        settings: 'paramètres',
        settings_title: 'paramètres',
        stock_title: 'stockage',
        urn_title: 'urnes',
        work_archived: 'dossiers archivés',
        work_new: 'nouveau dossier',
        work_progress: 'dossiers en cours',
        work_title: 'dossiers',


    }
}
