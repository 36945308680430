import TheInvoicingPage from "../../components/pages/invoicing/TheInvoicingPage";
import TheInvoicingIndexPage from "../../components/pages/invoicing/index/TheInvoicingIndexPage";
import InvoicingForm from "@p/invoicing/form/InvoicingForm"
import InvoicingPrintPage from '../../components/pages/invoicing/show/print/InvoicingPrintPage'

import CheckPage from '@p/check/Index'
import CheckDepositIndex from '@p/check/components/CheckDepositIndex'
import CheckDepositForm from '@p/check/components/CheckDepositForm'
import CheckListByStatus from '@p/check/components/CheckListByStatus'
import TheInvoicingInfoPage from "../../components/pages/invoicing/show/TheInvoicingShowPage";
import InvoiceTabActivity from "@p/invoicing/show/invoicingTab/TheInvoiceActivity";
import InvoicingTabInfo from "@p/invoicing/show/invoicingTab/InvoicingInfo";
import TheInvoicingExportPage from '@p/invoicing/export/TheInvoicingExportPage'




export const invoicingRootPages = {
    path: '/invoicing',
    name: 'invoicing_page_route',
    beforeEnter(from, to, next) {
        next({name: 'invoice_index_route'})
    }
}

export const invoicingSubPages = [
    {
        path: '/invoicing',
        name: 'invoicing_index_route',
        component: TheInvoicingPage,
        children: [
            {
                path: 'invoice',
                component: TheInvoicingIndexPage,
                name: 'invoice_index_route',
                meta: {doc_type: 'INV'},
                children: [
                    {
                        path: 'unpaid',
                        component: TheInvoicingIndexPage,
                        name: 'invoice_unpaid_index_route',
                        // meta: {doc_type: 'unpaid'}
                    },
                    {
                        path: 'paid',
                        component: TheInvoicingIndexPage,
                        name: 'invoice_paid_index_route',
                        // meta: {doc_type: 'paid'}
                    },
                ],
            },
            {
                path: 'export',
                component: TheInvoicingExportPage,
                name: 'invoice_export_route',
                // meta: {doc_type: 'export'}
            },
            {
                path: ':invoicing_id',
                component: TheInvoicingInfoPage,
                props: true,
                name: 'invoice_show_route',
                children: [
                    {
                        path: '',
                        component: InvoicingTabInfo,
                        name: 'invoice_info_route'
                    },
                    {
                        path: 'activities',
                        component: InvoiceTabActivity,
                        props: true,
                        name: 'invoice_activity_route'
                    },
                ],
            },
            {
                path: 'print/:invoicing_id',
                component: InvoicingPrintPage,
                props: true,
                name: 'invoice_print_route',
            },
            {
                path: 'invoice/new',
                component: InvoicingForm,
                name: 'invoice_form_new_route',
                meta: {doc_type: 'INV'}
            },
            {
                path: 'invoice/:invoicing_id',
                component: InvoicingForm,
                props: true,
                name: 'invoice_form_edit_route',
                meta: {doc_type: 'INV'}
            },
            {
                path: 'check',
                name: 'check_page_route',
                component: CheckPage,
                children: [
                    {
                        path: '',
                        component: CheckDepositIndex,
                        name: 'check_index_route'
                    },
                    {
                        path: ':id',
                        component: CheckDepositForm,
                        props: true,
                        name: 'check_deposit_form_route'
                    },
                    {
                        path: 'TBDEP',
                        component: CheckListByStatus,
                        name: 'check_tbdep_index_route'
                    },
                    {
                        path: 'DEP',
                        component: CheckListByStatus,
                        name: 'check_dep_index_route'
                    },
                    {
                        path: 'REF',
                        component: CheckListByStatus,
                        name: 'check_ref_index_route'
                    },
                ]
            },
            {
                path: 'purchase-order',
                component: TheInvoicingIndexPage,
                name: 'invoicing_purchase_order_index_route',
                meta: {doc_type: 'PO'}
            },
            {
                path: 'purchase-order/new',
                component: InvoicingForm,
                name: 'invoicing_purchase_order_new_route',
                meta: {doc_type: 'PO'}
            },
            {
                path: 'quote',
                component: TheInvoicingIndexPage,
                name: 'invoicing_quote_index_route',
                meta: {doc_type: 'QUO'}
            },
            {
                path: 'quote/new',
                component: InvoicingForm,
                name: 'invoicing_quote_new_route',
                meta: {doc_type: 'QUO'}
            },
            {
                path: 'credit-note',
                component: TheInvoicingIndexPage,
                name: 'invoicing_credit_note_index_route',
                meta: {doc_type: 'CN'}
            },
            {
                path: 'credit-note/new',
                component: InvoicingForm,
                name: 'invoicing_credit_note_new_route',
                meta: {doc_type: 'CN'}
            },
        ],
    },
]


