import getters from '@/store/modules/urn/getters'
import mutations from '@/store/modules/urn/mutations'
import actions from '@/store/modules/urn/actions'

export default {
  namespaced: true,
  state() {
    return {
      urnIndex: [],
      urnMetadata: [],
    }
  },
  getters,
  actions,
  mutations
}
