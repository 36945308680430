
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state() {
    return {
      sessionExpiration: (localStorage.vuex && JSON.parse(localStorage.vuex).auth.sessionExpiration) || null,
      auth: (localStorage.vuex && JSON.parse(localStorage.vuex).auth.auth) || {},
      errors: []
    }
  },
  getters,
  actions,
  mutations
}
