<template class="px-1.5">
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','list']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first block'>{{ $t('navigation.list_item_title') }}</span>
      </div>
    </template>
    <template #cta>
      <BaseButton
          v-if="$can('manage_content')"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getSettingsAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
    </template>
    <template #body>
      <div class="flex flex-col mb-8 px-3">
        <ItemListComponent
            v-for="(listItems, groupName) in ListItemsGroupByGroupName"
            :listItems="listItems"
            :key="groupName"
            :editionMode="editionMode"
            :groupName="groupName"/>
      </div>
    </template>

  </SettingsPageFull>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

import ItemListComponent from "./components/ListItemComponent";

export default {
  name: 'TheListItemIndexPage',
  components: {
    ItemListComponent,
  },
  data() {
    return {
      editionMode: false
    }
  },
  computed: {
    ...mapGetters({
      ListItemsGroupByGroupName: 'itemList/getListItemsIndex',
    }),
  },
  methods: {
    ...mapActions({
      fetchListItems: 'itemList/fetchListItems',
    }),
    getSettingsAndChangeEditionMode() {
      if(this.editionMode) {
        this.fetchListItems()
      }
      this.editionMode = !this.editionMode
    }
  },
  async mounted() {
    await this.fetchListItems();
  }
}
</script>

