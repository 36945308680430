<template>
    <div class='flex space-x-3 m-6'>
      <font-awesome-icon :icon="['fal','cogs']" size='2x'/>
      <span class='text-2xl font-bold capitalize-first'>{{ $t('global.invoicing_settings') }}</span>
    </div>
  <BaseSimpleTab :tabs="tabs" />
  <div class='mt-5'>
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'TheInvoicingSettingsView',
  data() {
    return {
      tabs: [
        {label: this.$t('global.general'), icon: '', routeName: 'settings_list_invoicing_route'},
        {label: this.$t('global.accounting_code'), icon: '', routeName: 'settings_accounting_code_route'},
      ]
    }
  }
}
</script>

