<template>
  <div class='grid md:grid-cols-2 sm:grid-cols-1 gap-x-5'>

    <BaseShowEditInput :label="$t('attributes.first_name')"
                       :placeholder="$t('attributes.first_name')"
                       v-model="contact.first_name"
                       :editionMode="editionMode"
                       :errors="errors.first_name"
                       :required="true" />

    <BaseShowEditInput :label="$t('attributes.last_name')"
                       :placeholder="$t('attributes.last_name')"
                       v-model="contact.last_name"
                       :editionMode="editionMode"
                       :errors="errors.last_name"
                       :required="true" />
    <div>

      <BaseShowEditInput :label="$t('attributes.address')"
                         :placeholder="$t('attributes.address')"
                         v-model="contact.street"
                         :editionMode="editionMode"
                         :errors="errors.address" />

      <div class='grid grid-cols-3 gap-x-2'>

        <BaseShowEditInput :label="$t('attributes.post_code')"
                           :placeholder="$t('attributes.post_code')"
                           v-model="contact.post_code"
                           :editionMode="editionMode"
                           :errors="errors.post_code" />

        <BaseShowEditInput :label="$t('attributes.city')"
                           :placeholder="$t('attributes.city')"
                           class='col-span-2'
                           v-model="contact.city"
                           :editionMode="editionMode"
                           :errors="errors.city"/>

      </div>
    </div>
    <div class='grid'>
      <BaseShowEditPhoneInput :label="$t('attributes.phone')"
                              :editionMode='editionMode'
                              name="phone"
                              v-model:countryValue="contact.phone_country"
                              v-model:numberValue="contact.phone_field"
                              :errors="errors"
                              :metadata="metadata?.deceased"/>

      <BaseShowEditPhoneInput :label="$t('attributes.mobile')"
                              :editionMode='editionMode'
                              name="mobile"
                              v-model:countryValue="contact.mobile_country"
                              v-model:numberValue="contact.mobile_field"
                              :errors="errors"
                              :required='true'
                              :metadata="metadata?.deceased"/>
    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'


export default {
  name: 'BurialContactForm',
  props:{
    editionMode: {
      type:Boolean,
      required:true
    }
  },
  computed:{
    ...mapGetters({
      contact: 'work/getWorkContact',
      metadata: 'work/getWorkMetadata',
      errors: 'auth/getErrors',
    })
  },
}
</script>
