import apiClient from '@u/apiClient'
import router from '@/router'

let base_errors = {}

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {
    //region fetch
    async fetchCompanyIndex({commit}) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get('api/company')
            .then(async response => {
                await commit('setCompanyList', response.data)
            })
            .catch(async reason => {
                console.error('fetchCompanyIndex', reason)
            })
    },
    async fetchCompanyByID({commit}, id) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/company/${id}`)
            .then(async response => {
                commit('setCompany', response.data.company)
                commit('setMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchCompanyByID', reason)
            })
    },
    async fetchNewCompany({commit}) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get('api/model/new?model=company')
            .then(async response => {
                await commit('setCompany', response.data.company)
                await commit('setMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchNewCompany', reason)
            })
    },
    async fetchCompanyActivitiesByCompanyID({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/company/fetch-activities/${payload.company_id}`)
            .then(async response => {
                await commit('setCompanyActivity', response.data.activities)
            })
            .catch(async reason => {
                await console.error('fetchCompanyActivitiesByCompanyID', reason)
            })
    },
    //endregion

    //region store
    async storeNewCompany({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.post('api/company', payload.company)
            .then(response => {
                if (response.status === 201) {
                    processSuccessCallback(payload)
                }
            })
            .catch(reason => {
                console.error('storeNewCompany', reason)
            })
    },
    //endregion

    //region update
    async updateCompany({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.put(`api/company/${payload.id}`, payload.company)
            .then(response => {
                if (response.status === 200) {
                    processSuccessCallback(payload)
                }
            })
            .catch(reason => {
                console.error('updateCompany', reason)
            })
    },
    //endregion

    //region delete
    async deleteCompanyById({commit}, payload) {
        await apiClient.delete(`api/company/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setCompanyList', response.data)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('deleteCompanyById', reason)
            })
    },
    //endregion

    //region undoDelete
    async undoDeleteCompanyById({commit}, payload) {
        await apiClient.get(`api/company/undo-delete/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setCompanyList', response.data)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('undoDeleteCompanyById', reason)
            })
    },
    //endregion
}
