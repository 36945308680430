export default {
  setItemList(state, itemsList){
    state.itemsList = itemsList
  },
  setItem(state, item){
    state.item = item
  },
  setItemMetadata(state, metadata){
    state.itemMetadata = metadata
  },
  setItemActivity(state, activity){
    state.itemActivity = activity
  }
}
