<template>

  <BaseCardSection :sectionTitle='$t("global.disponibilities")'>
    <template #default>
      <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.timeslot')}) "/>

      <div class='flex space-x-2 mt-5'>
        <div v-for='(day, index) in metadata.days' class='flex-1 flex flex-col space-y-1'>
          <h3 class='text-base font-bold capitalize-first text-center'>{{ day }}</h3>
          <template
              v-if="asset.timeslots"
              v-for='timeslot in asset.timeslots?.filter( item => item.day === parseInt(index) )'>

            <div class='bg-theme-blue-lighter rounded-md pl-2 pr-1 py-1 text-white flex'>
              <div class='flex-1 flex items-center'>
                {{ timeslot.start_time }} - {{ timeslot.end_time }} <br>
                {{ timeslot.event_kind ? metadata.event_kinds?.filter(kind => kind.id === timeslot.event_kind)[0].name : '' }}
              </div>
              <div class='flex-shrink-0' v-if="$can('delete_content')">
                <BaseButton
                    v-if="editionMode"
                    icon='times'
                    size='small'
                    color='danger'
                    :tooltip="$t('button.delete_f', {var: $t('global.disponibility')})"
                    @click="deleteTimeSlot(timeslot.id)"
                />
              </div>
            </div>
          </template>
        </div>
      </div>

    </template>
  </BaseCardSection>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AssetTimeSlotIndexComponent',
  props: {
    editionMode: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      asset: 'asset/getSelectedAsset',
      metadata: 'asset/getAssetMetadata'
    }),
    cEventKind(){
      let tmp = []
      this.asset.timeslots.forEach(el => {
        tmp.push(this.metadata.event_kind?.filter( item => el.includes(item.id)))
      })
      return tmp
    }
  },
  methods:{
    ...mapActions({
      deleteTimeslotById: 'asset/deleteTimeslotById',
      undoDeleteTimeslotById: 'asset/undoDeleteTimeslotById'
    }),
    deleteTimeSlot(id) {
      this.deleteTimeslotById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-settings").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteTimeslot(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteTimeslot(id) {
      this.undoDeleteTimeslotById({
        id:  id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_m', {var: this.$t('attributes.timeslot')})  , {className: 'toastify-content success'})
        }
      })
    }
  }
}
</script>
