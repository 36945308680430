export default {
  setAssetList(state, AssetList) {
    state.assetList = AssetList
  },
  setAsset(state, Asset) {
    state.asset = Asset
  },
  setAssetMetadata(state, metadata) {
    state.assetMetadata = metadata
  },
  setAssetActivity(state, activity){
    state.assetActivity = activity
  }
}
