<template>
  <tr>
    <td class="border-b whitespace-nowrap">
      <input
          v-if="this.$route.name=== 'check_deposit_form_route'"
          id="checkbox-switch-2"
          class="form-check-input"
          type="checkbox"
          v-model='baseValue'
          :disabled="disabled">
    </td>
    <td class="border-b whitespace-nowrap">{{check.amount}}</td>
    <td class="border-b whitespace-nowrap">{{check.emission_date}}</td>
    <td class="border-b whitespace-nowrap capitalize-first">
      <BaseLabel
          :content="$t(`enums.invoicing.check_status.${check.check_status}`)"
          :color="check.check_status_color"/>
    </td>
    <td class="border-b whitespace-nowrap">{{check.customer_name}}</td>
    <td class="border-b whitespace-nowrap">{{check.check_number}}</td>
    <td class="border-b whitespace-nowrap">
      <BaseButton
          v-if='check.work_id && check.work_title'
          :title="check.work_title"
          icon='chevron-right'
          color='secondary'
          @click="processWorkButton"/>
    </td>
    <td class="border-b whitespace-nowrap">{{ bankName }}</td>
    <td class="border-b whitespace-nowrap">{{check.comment}}</td>
    <td class="border-b whitespace-nowrap">
      <BaseButton
          :title="$t('button.to_f', {var: $t('global.invoice')})"
          icon='chevron-right'
          color='secondary'
          @click="processInvoiceButton"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CheckTableRow',
  props:{ check: {}, baseValue: {}, disabled: {}, isCheckBoxVisible: {} },
  computed:{
    ...mapGetters({
      checkDeposit: 'invoicing/getCheckDeposit',
      metadata: 'invoicing/getCheckMetadata'
    }),
    bankName(){
      return this.check?.issuing_bank && this.metadata?.bank ? this.metadata.bank?.filter(el => el.id === parseInt(this.check.issuing_bank))[0]?.name : 'pas de banque'
    }
  },
  methods:{
    processWorkButton(){
      this.$router.push({name: 'work_form_edit_route', params: {id: this.check.work_id}})
    },
    processInvoiceButton(){
      this.$router.push({name: 'invoice_info_route', params: {invoicing_id: this.check.invoice_id}})
    }
  },
  watch:{
    baseValue(){
      this.$emit('selectCheck', this.check)
    }
  }
}
</script>

<style scoped>

</style>
