<template>

  <BaseSimpleCard class='max-w-screen-xl mx-auto' :title="$t('global.invoicing')" name="invoice-form" :button-back-left='true' v-if="invoice">
    <template #cta>
      <div class='flex space-x-2'>
        <BaseButton title="imprimer" type="primary" icon="print"/>
      </div>
    </template>
    <template #default>
      <i-frame-pdf :src='cUrlPdf' />
    </template>
  </BaseSimpleCard>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'InvoicingPrintPage',
  computed: {
    ...mapGetters({auth: 'auth/getAuth'}),
    ...mapGetters('invoicing',{invoice: 'getInvoice'}),
    cUrlPdf(){
      let baseURL;
      if (localStorage.getItem('url') !== null) {
        baseURL = localStorage.getItem('url')
      } else {
        baseURL = window.location.protocol + "//" + window.location.host ;
      }
      return baseURL + 'api/render/invoicing/' + this.$route.params.invoicing_id + '?lang=' + this.auth.language
    }
  },
  methods: {
    ...mapActions('invoicing',['fetchInvoiceID']),
  },
  async beforeMount() {
    await this.fetchInvoiceID({id: this.$route.params.invoicing_id})
  }
}
</script>

