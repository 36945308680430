<template>
  <div class='grid md:grid-cols-2 sm:grid-cols-1 gap-x-5'>
    <div class='col-start-1'>
      <BaseShowEditSelectSimple v-if="metadata?.event_type"
                                :label="$t('attributes.event_type')"
                                :placeholder="$t('attributes.event_type')"
                                name="event_type"
                                :editionMode='editionMode'
                                v-model='local_event.type'
                                @change='resetEventData'
                                :options="filteredEventType"
                                :errors="errors?.event_type"/>
    </div>
    <div class='col-start-1 grid grid-cols-2 gap-x-3'>
      <div id='scroll_date_from'>
        <BaseShowEditDatePicker :editionMode='editionMode'
                                :label="local_event.type === 'CON' ? $t('attributes.from'): $t('attributes.date_from')"
                                v-model='local_event.date_from'
                                class='z-10'
                                name='date_from'
                                @click='scrollToDateFrom'
                                :errors='errors.date_from'/>
      </div>
      <div v-if="[ 'CON','MAI','FOR','SEV','OTH' ].includes(local_event.type)" id='scroll_date_to'>
        <BaseShowEditDatePicker :editionMode='editionMode'
                                :label="local_event.type === 'CON' ? $t('attributes.to'): $t('attributes.date_to')"
                                v-model='local_event.date_to'
                                class='z-10'
                                name='date_to'
                                @click='scrollToDateTo'
                                :errors='errors.date_from'/>
      </div>
    </div>
    <div class='grid grid-cols-2 gap-x-3'>
      <div v-if="local_event.type !== 'CON'">
        <BaseShowEditTimeInput :label="$t('attributes.time_from')"
                               :placeholder="'10:00'"
                               name='time_from'
                               :editionMode='editionMode'
                               v-model='local_event.time_from'
                               :errors='errors?.time_from'/>
      </div>
      <div v-if="local_event.type !== 'CON'">
        <BaseShowEditTimeInput :label="$t('attributes.time_to')"
                               :placeholder="'12:00'"
                               name='time_to'
                               :editionMode='editionMode'
                               v-model='local_event.time_to'
                               :errors='errors?.time_to'/>
      </div>
    </div>
    <div class='col-start-1'>
      <BaseShowEditTextArea :label="$t('attributes.remarks')"
                            :placeholder="$t('attributes.remarks')"
                            v-model="local_event.remarks"
                            :errors="errors.remarks"
                            :editionMode="editionMode" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'FormGenericEvent',
  components: {
  },
  props: {
    editionMode: {
      type: Boolean,
      required: true,
    },
    local_event: {
      type: Object,
      required: true,
    },
    resetEventData: {
      type: Function,
      required: true,
    },
    metadata: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      required: true,
    },
    scrollToDateFrom: {
      type: Function,
      required: true
    },
    scrollToDateTo: {
      type: Function,
      required: true
    },
    filteredEventType: {
      type: Array,
      required: true,
    }
  }
}
</script>
