<template>
  <BaseSimpleCard name="company-form" classes='col-start-2 col-span-10' title="">
    <template #default>
      <div class='flex flex-col space-y-7'>
        <div class='flex space-x-3 mb-5'>
          <span class='flex-1 text-2xl font-bold capitalize-first'>{{ $t('global.invoicing') }}</span>
          <!--#region + button for creating new invoice -->
          <Menu as="div" class="relative mx-5 text-left">
              <Tippy tag="a"
                     href="javascript:;"
                     class="tooltip  flex"
                     :content="$t('button.add')" >
              <MenuButton
                  class="inline-flex justify-center w-full px-2 py-1 text-sm font-medium text-white btn-primary rounded-md
                  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <font-awesome-icon
                    class="w-5 h-5 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                    :icon="['fal', 'plus']" />
              </MenuButton>
              </Tippy>

            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-from-class="transform scale-95 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-from-class="transform scale-100 opacity-100"
                leave-to-class="transform scale-95 opacity-0">

              <MenuItems v-if="storageSystemSettingsAvailableDocs"
                         class="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }" v-if="Object.values(storageSystemSettingsAvailableDocs).indexOf('INV') > -1">
                    <InvoicingNavLink
                        :class="[active ? 'btn-primary text-white' : 'text-gray-900', 'group flex rounded-md items-center w-full px-2 py-2 text-sm',]"
                        route-name='work_form_invoice_new_route'
                        icon='file-plus'
                        :label="$t('global.invoice')" />
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="Object.values(storageSystemSettingsAvailableDocs).indexOf('PO') > -1">
                    <InvoicingNavLink
                        :class="[active ? 'btn-primary text-white' : 'text-gray-900','group flex rounded-md items-center w-full px-2 py-2 text-sm',]"
                        route-name='work_form_purchase_order_new_route'
                        icon='file-plus'
                        :label="$t('global.purchase_order')" />
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="Object.values(storageSystemSettingsAvailableDocs).indexOf('QUO') > -1">
                    <InvoicingNavLink
                        :class="[active ? 'btn-primary text-white' : 'text-gray-900','group flex rounded-md items-center w-full px-2 py-2 text-sm',]"
                        route-name='work_form_quote_new_route'
                        icon='file-plus'
                        :label="$t('global.quote')" />
                  </MenuItem>
                  <MenuItem v-slot="{ active }" v-if="Object.values(storageSystemSettingsAvailableDocs).indexOf('CN') > -1">
                    <InvoicingNavLink
                        :class="[active ? 'btn-primary text-white' : 'text-gray-900','group flex rounded-md items-center w-full px-2 py-2 text-sm',]"
                        route-name='work_form_credit_note_new_route'
                        icon='file-plus'
                        :label="$t('global.credit_note')" />
                  </MenuItem>

                </div>
              </MenuItems>
            </transition>
          </Menu>
          <!--#endregion -->
        </div>

        <div v-for='type in invoicingTypeList'>
          <h3 class='text-xl font-bold capitalize-first mb-2'>{{ $t('global.invoicing_type.' + type) }}</h3>
          <ul v-if='work.invoices?.filter( invoice => invoice.doc_type === type ).length > 0' role="list" class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            <li v-for='(invoice, index) in work.invoices?.filter( invoice => invoice.doc_type === type )' :key="index" class="col-span-1 border-gray-300 bg-gradient-to-br from-gray-100 to-gray-50 rounded-lg shadow-md divide-y divide-gray-200">
              <WorkFormInvoicingCard :invoice="invoice"/>
            </li>
          </ul>
          <div v-else>{{ $t('validation.there_is_no_yet_for_m', {content: $t('global.invoicing_type.' + type), container: $t('attributes.work')}) }}</div>
        </div>

      </div>
    </template>
  </BaseSimpleCard>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

import InvoicingNavLink from "../../../../invoicing/nav/InvoicingNavLink";
import WorkFormInvoicingCard from '@p/works/form/subpages/invoicing/components/WorkFormInvoicingCard'

import { mapGetters } from 'vuex'

export default {
  name: 'TheWorkInvoicingPage',
  components: { WorkFormInvoicingCard, InvoicingNavLink, Menu, MenuButton, MenuItems, MenuItem },
  data() {
    return { invoicingTypeList: ['INV','PO','QUO','CN'] }
  },
  computed:{
    ...mapGetters({work: 'work/getWork', settings: 'settings/getSystemSettings'}),
    storageSystemSettingsAvailableDocs(){
      return this.settings.invoicing.available_docs
    }
  },
}
</script>
