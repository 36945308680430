export default {
  setEvents(state, eventsList) {
    state.eventsList = eventsList
  },
  setEvent(state, event){
    state.event = event
  },
  setPlanningMetadata(state, metadata) {
    state.planningMetadata = metadata
  },
  setEventMetadata(state, metadata){
    state.eventMetadata = metadata
  },
  setEventDisponibilities(state, timeslots){
    state.eventDisponibilities = timeslots
  },
  setEventTimeSlotInEvent(state, timeslots){
    state.event.timeslots = timeslots
  },
  alterEvent(state, payload){
    state.event[payload.attribute] = payload.value
  },
  deleteEventKey(state, attribute){
    delete state.event[attribute]
  },
}
