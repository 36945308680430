<template>
  <div>
    <BaseShowEditRadioGroup name='recoverer_type'
                            :label="$t('attributes.recoverer_type')"
                            :options='metadata?.ash_recoverer_type'
                            v-model="work.ash_recovered_by"
                            :editionMode="editionMode"
                            trackBy='id'
                            :translatable='false'
                            attribute-label='name'/>
  </div>
  <div v-if='work.ash_recovered_by === "PF"'>
    <div>
      <BaseShowEditInput :label="$t('attributes.hash_recovered_by')"
                         :placeholder="$t('attributes.hash_recovered_by')"
                         v-model="work.ash_recovered_by_other"
                         :editionMode="editionMode"
                         :errors="errors.hash_recovered_by_other"
                         :required="true" />
    </div>
  </div>

  <div v-else class='col-start-1 col-span-2'>
    <BurialContactForm v-if='work.ash_recovered_by === "FAMILY"'
                       :editionMode='editionMode'/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BurialContactForm from './BurialContactForm'

export default {
  name: 'RecovererForm',
  components:{
    BurialContactForm,
  },
  props: {
    editionMode: {type:Boolean, required: true},
  },
  computed: {
    ...mapGetters({
      work: 'work/getWork',
      metadata: 'work/getWorkMetadata',
      errors: 'auth/getErrors'
    })
  }
}
</script>

<style scoped>

</style>
