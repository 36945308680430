<template>
  <table class='table my-12' v-if='metadata.permissions'>

    <thead >
      <tr class='bg-gray-700 dark:bg-dark-1 text-white'>
        <th class="whitespace-nowrap cursor-default">Group</th>
      </tr>
    </thead>

    <tbody>
      <tr v-for='(values, permission_group) in metadata.permissions' :key="permission_group">
        <td class="border-b dark:border-dark-5 font-medium capitalize-first" :class="!editTable ? 'cursor-default' : 'cursor-pointer' " @click="editTable ? fullRowClick(values) : {}">{{ $t(`enums.config.permissions.permissions_group.${permission_group}`) }}</td>
        <td v-for='(val, key) in values' :key='key' class="border-b dark:border-dark-5">
          <div v-if='val.name != null' class="flex flex-col sm:flex-row mt-2">
            <div class="form-check mr-2" >
              <input v-if='editTable' :id="`${val.name}-${val.label}`" class="form-check-input" type="checkbox" name="permission" v-model='selectedPermissions' :value="val.name" :disabled="val.disabled" @click='inputClick(val.name)'>
              <label class="form-check-label" :class="!editTable ? !selectedPermissions.includes(val.name) ? 'text-gray-400 cursor-default' : 'cursor-default' : {} " :for="`${val.name}-${val.label}`">{{ $t(`enums.config.permissions.types.${val.label}`)}}</label>
            </div>
          </div>
        </td>
      </tr>
    </tbody>

  </table>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'UserPermissionTable',
  data() {
    return {
      data_permission: [],
    }
  },
  props:{
    editTable: {type: Boolean, required: true},
  },
  computed:{
    ...mapGetters({
      user: 'user/getUser',
      metadata: 'user/getUserMetadata'
    }),
    selectedPermissions(){
      let permissions = this.user.permissions
      if(this.disabledPermissions){
        this.disabledPermissions.forEach(permission => permissions.push(permission))
        this.data_permission = permissions
      }
      return permissions
    },
  disabledPermissions(){
    return this.metadata.permissions && Object.values(this.metadata.permissions).reduce((array, item) => {
        item.forEach(item2 => {
          if (item2.disabled) {
            array.push(item2.name);
          }
        })
        return array;
      }, [])
    }
  },
  methods: {
    ...mapMutations({
      setUserPermissions: 'user/setUserPermissions'
    }),
    fullRowClick(values){
      let rowPermission = values.map(item => item.name);
      let present = this.data_permission.filter(value => rowPermission.includes(value));
      if( present.length !== rowPermission.length ){
        rowPermission.forEach(item => {
          if( this.data_permission.indexOf( item ) < 0 ){
            this.data_permission.push( item );
          }
        });
      }
      else{
        rowPermission.forEach(item => {
          let index = this.data_permission.indexOf( item );
          let isDisabled = this.disabledPermissions.includes(item)
          if( index >= 0 && !isDisabled){
            this.data_permission.splice(index, 1);
          }
        });
      }
      this.setUserPermissions(this.data_permission)
    },
    inputClick(val){
      let indexVal = this.data_permission.indexOf(val)
      if(indexVal === -1){
        this.data_permission.push(val)
      }else{
        this.data_permission.splice(indexVal, 1)
      }
      this.setUserPermissions(this.data_permission)
    },
  },
  watch: {
    data_permission(newValue){
      if(newValue){ this.setUserPermissions(newValue) }
    },
  }
}
</script>
