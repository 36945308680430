import getters from '@/store/modules/content/notification/getters'
import mutations from '@/store/modules/content/notification/mutations'
import actions from '@/store/modules/content/notification/actions'

export default {
  namespaced: true,
  state() {
    return {
      notificationList: [],
      notification: {},
      metadata: {},
    }
  },
  getters,
  actions,
  mutations
}
