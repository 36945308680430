<template>
  <BaseSimpleCard name='invoice body' :title='cTitle' classes='col-start-2 col-span-10 h-screen'
                  :button-back-left='true' v-if="invoice">
    <template #default>
      <div v-if='metadata'
           class='grid md:grid-cols-2 sm: grid-cols-1 gap-x-5 mb-10'>
        <BaseShowEditSelectAjax :key='invoice'
                                :edition-mode='!permissions'
                                url='api/work-for-select'
                                :label="$t('global.work')"
                                v-model='invoice.work_id'
                                name='work_id'
                                trackBy='id'
                                attributeLabel='title'
                                :defaultAjax='workDefaultValue'
                                :fullModelResponse='true'
                                @workSelect='afterWorkSelected'
        />
        <BaseCompanyShowEditSelectSimple v-model='invoice.company_id'
                                         :label="$t('attributes.company')"
                                         :metadata='metadata'
                                         :errors='errors'
                                         :editionMode='!permissions'
                                         :required='true'
        />
        <BaseCustomerShowEditSelectSimple v-model='invoice.customer_id'
                                          :metadata='metadata'
                                          :errors='errors'
                                          :edition-mode='!permissions'
                                          :required="true"
        />
        <BaseShowEditDateInput :label="$t('attributes.fiscal_year')"
                               :placeholder="$t('attributes.fiscal_year')"
                               name='fiscal_year'
                               format='##'
                               :editionMode='!permissions'
                               v-model='invoice.fiscal_year'
                               :errors='errors.fiscal_year'
        />
        <BaseShowEditInput v-if='metadata && metadata.next_number'
                           :label="$t('attributes.invoice_number')"
                           v-model="invoice.number"
                           :errors="errors.number"
                           :placeholder="$t('attributes.invoice_number')"
                           :editionMode="!permissions"
        />
        <BaseShowEditInput v-if='metadata && metadata.next_reference'
                           :label="$t('attributes.reference')"
                           v-model="invoice.reference"
                           :errors="errors.reference"
                           :placeholder="$t('attributes.reference')"
                           :editionMode="!permissions"
        />
        <BaseShowEditInput :label="$t('attributes.reference_quote')"
                           v-model="invoice.reference_quote"
                           :errors="errors.reference_quote"
                           :placeholder="$t('attributes.reference_quote')"
                           :editionMode="!permissions"
        />
        <BaseShowEditInput :label="$t('attributes.reference_extern')"
                           v-model="invoice.reference_extern"
                           :errors="errors.reference_extern"
                           :placeholder="$t('attributes.reference_extern')"
                           :editionMode="!permissions"
        />
        <BaseShowEditDatePicker :editionMode='!permissions'
                                :label="$t('attributes.emission_date')"
                                v-model='invoice.emission_date'
                                name='emission_date'
                                :errors='errors.emission_date'
        />
        <BaseShowEditDatePicker :editionMode='!permissions'
                                :label="$t('attributes.due_date')"
                                v-model='invoice.due_date'
                                name='due_date'
                                :errors='errors.due_date'
        />
        <BaseLanguageShowEditSelectSimple v-model='invoice.language'
                                          :metadata='metadata'
                                          :errors='errors'
                                          :edition-mode='!permissions'
        />
        <BaseShowEditTextArea class='col-start-1 md:col-span-2'
                              :label="$t('attributes.remarks')"
                              :placeholder="$t('attributes.remarks')"
                              v-model="invoice.remarks"
                              :errors="errors.remarks"
                              :editionMode="!permissions"
        />
        <BaseShowEditTextArea class='col-start-1 md:col-span-2'
                              :label="$t('attributes.note_intern')"
                              :placeholder="$t('attributes.note_intern')"
                              v-model="invoice.note_intern"
                              :errors="errors.note_intern"
                              :editionMode="!permissions"
        />
      </div>
      <div class='flex justify-end'>
        <BaseButton v-if='invoiceIsNotEstablished'
                    :icon="buttonIcon"
                    :title="buttonTitle"
                    @click='submitForm'
        />
      </div>
    </template>
  </BaseSimpleCard>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import apiClient from "@u/apiClient";
import moment from 'moment'


export default {
  name: 'InvoicingForm',
  props: {
    id: {
      type: [String, Number],
      required: false,
      default: null
    },
  },
  data() {
    return {
      company_id: '',
      work_id: ''
    }
  },
  computed: {
    ...mapGetters({
      invoice: 'invoicing/getInvoice',
      metadata: 'invoicing/getInvoiceMetadata',
      errors: 'auth/getErrors'
    }),
    permissions() {
      if (this.$route.name !== 'invoice_form_edit_route') {
        return false
      } else {
        if (!this.invoiceIsNotEstablished) {
          return true
        } else {
          return !this.$can('manage_invoicing')
        }
      }
    },
    invoiceIsNotEstablished() {
      return !(this.invoice.status === 'EST')
    },
    cTitle() {
      return this.$route.name === 'invoice_form_edit_route' ? (this.$t('global.invoicing_type.' + this.$route.meta.doc_type) + ' ' + this.invoice.reference) : this.$t('global.invoicing_title.' + this.$route.meta.doc_type)
    },
    fiscal_year() {
      if (this.invoice.fiscal_year == null) {
        return this.invoice.fiscal_year = moment().format('YY')
      }
      return this.invoice.fiscal_year
    },
    workDefaultValue() {
      if (this.invoice.work) {
        return {
          id: this.invoice.work_id,
          title: `${this.invoice.work.title} ${this.invoice.work.reference ? `(${this.invoice.work.reference})` : ''}`
        }
      } else {
        return {}
      }
    },
    buttonTitle() {
      return this.$route.name === 'invoice_form_edit_route' ? this.$t('button.edit') : this.$t('button.add')
    },
    buttonIcon() {
      return this.$route.name === 'invoice_form_edit_route' ? 'paper-plane' : 'plus'
    }
  },
  methods: {
    ...mapActions({
      fetchNewInvoice: 'invoicing/fetchNewInvoice',
      fetchInvoiceID: 'invoicing/fetchInvoiceID',
      postNewInvoice: 'invoicing/storeNewInvoice',
      putInvoice: 'invoicing/updateInvoice'
    }),
    submitForm() {
      this.$route.name === 'invoice_form_edit_route' ?
        this.putInvoice({
          id: this.$route.params.invoicing_id,
          invoice: this.invoice,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.updated_f', {var: this.$t('global.invoice')}) , {className: 'toastify-content success'})
          },
      }) :
        this.postNewInvoice({
          invoice: this.invoice,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.created_f', {var: this.$t('global.invoice')}) , {className: 'toastify-content success'})
          },
        })
    },
    afterWorkSelected(work) {
      this.invoice.work_id = work.id
      this.company_id = work.company_id
      this.invoice.customer_id = work.customer_id
      this.invoice.language = work.language
      this.invoice.reference_extern = work.reference
      this.invoice.reference = this.metadata.next_reference[this.company_id]
      this.invoice.number = this.metadata.next_number[this.company_id]
    }
  },
  watch: {
    '$route'(newValue) {
      if (!newValue.params.id) {
        this.fetchNewInvoice({doc_type: this.$route.meta.doc_type})
      }
    },
    company_id(newValue) {
      if (newValue && this.metadata.next_reference) {
        this.invoice.company_id = newValue
        this.invoice.reference = this.metadata.next_reference[newValue]
        this.invoice.number = this.metadata.next_number[newValue]
      }
    },
  },
  async beforeMount() {
    this.$route.name === 'invoice_form_edit_route' ?
      this.fetchInvoiceID({id: this.$route.params.invoicing_id}) : await this.fetchNewInvoice({
      doc_type: this.$route.meta.doc_type,
      work_id: this.id
    })
  },

}
</script>

