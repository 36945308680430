export default {
  setBanksList(state, banksList){
    state.banksList = banksList
  },
  setBank(state, bank){
    state.bank = bank
  },
  setBankMetadata(state, metadata){
    state.bankMetadata = metadata
  },
  alterBank(state, payload){
    state.bank[payload.attribute] = payload.value
  }
}
