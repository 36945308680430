
import getters from '@/store/modules/content/user/getters'
import mutations from '@/store/modules/content/user/mutations'
import actions from '@/store/modules/content/user/actions'

export default {
  namespaced: true,
  state() {
    return {
      usersList: [],
      user: {},
      userActivities: [],
      metadata: { columns:[] },
    }
  },
  getters,
  actions,
  mutations
}
