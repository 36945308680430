<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','cogs']" size='2x' />
        <span class='text-2xl font-bold capitalize-first'>{{ $t('global.notification_settings') }}</span>
      </div>
    </template>

    <template #cta>
      <div class='flex justify-end mt-12 mx-12 mb-4 fixed right-20 top-20 z-100'>
        <BaseButton v-if="$can('see_settings')" :isElevated='true' icon='paper-plane' @click='submitNotificationSettings'
                    :title="$t('button.save_plural', {var: $t('global.notification_settings')})"/>
      </div>
    </template>

    <template #body  v-if="notificationSettings">

      <SingleNotificationSettingComponent class='mx-12'
          v-for="(setting, index) in metadata"
          :setting="setting"
          :key="index"
          :index="index"
          :notificationSettings="notificationSettings"/>

<!--      <div class='flex justify-end mt-12 mx-12'>-->
<!--        <BaseButton v-if="$can('see_settings')" :isElevated='true' icon='paper-plane' @click='submitNotificationSettings'-->
<!--                    :title="$t('button.save_plural', {var: $t('global.notification_settings')})"/>-->
<!--      </div>-->
    </template>
  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import SingleNotificationSettingComponent from "./components/SingleNotificationSettingComponent";


export default {
  name: "TheNotificationSettingsIndexPage",
  components: {
    SingleNotificationSettingComponent,
  },
  computed: {
    ...mapGetters({
      metadata: 'notificationSettings/getNotificationMetadata' ,
      notificationSettings: 'notificationSettings/getNotificationSettingsList',
    }),
  },
  methods: {
    ...mapActions({
      fetchNotificationList:'notificationSettings/fetchNotificationList',
      updateNotificationsSettings: 'notificationSettings/updateNotificationsSettings'
    }),
    submitNotificationSettings() {
      this.updateNotificationsSettings({
        notificationSettings: this.notificationSettings,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.updated_plurals', {var: this.$t('global.notification_settings')}), {className: 'toastify-content success'})
        }
      })
    }
  },
  async mounted() {
    await  this.fetchNotificationList()
  },
}
</script>

