export default {
  global: {
    item_type:'type d\'article',
    display:'afficher',
    table:'tableau de données',
    chart:'graphique',
    stats_bloc:'bloc de statistiques',
    listItems: 'élément de liste',
    placeholder: {
      search: 'recherche'
    },
    action: 'action',
    activities: 'registre',
    add_event: 'ajouter un nouvel évènement',
    add_note: 'ajouter une nouvelle note',
    an_event: 'un évènement',
    annex: 'annexe',
    anatomicalPieces:'pieces anatomiques',
    asset: 'ressource',
    asset_type: 'type de ressource',
    assets: 'ressources',
    attachments: 'annexes',
    bank: 'banque',
    bank_account: 'compte bancaire',
    bank_accounts: 'comptes bancaires',
    Bank: 'virement bancaire',
    billing: 'facturation',
    Card: 'carte',
    Cash: 'espèce',
    check: 'chèque',
    checks: 'chèques',
    check_deposit: 'bordereau de remise',
    checks_deposit: 'bordereaux de remise',
    check_to_be_deposited: 'chèque à déposer',
    checks_to_be_deposited: 'chèques à déposer',
    check_to_be_cashed: 'chèque en attente d\'encaissement',
    checks_to_be_cashed: 'chèques en attente d\'encaissement',
    check_refused: 'chèque refusé',
    checks_refused: 'chèques refusés',
    code: 'code',
    company: 'société',
    customers: 'clients',
    accounting_code: 'codes comptables',
    credit_note: 'note de crédit',
    cremation_date: 'date de crémation',
    cremation_type: {
      classic: 'crémation classique',
      extra_size: 'crémation hors mesure',
      technic: 'crémation technique'
    },
    filter_work: {
      cremation_jour_j: 'aujourd\'hui',
      cremation_j_plus_1: 'aujourd\'hui et demain',
      cremation_j_plus_2: 'aujourd\'hui > après demain',
      cremation_custom_range: 'personnalisé',
    },
    availabilities: 'disponibilités',
    availability: 'disponibilité',
    cemetery_of: 'Cimetière de',
    customer: 'client',
    deceased: 'défunt',
    death: 'décès',
    disponibility: 'disponibilité',
    disponibilities: 'disponibilités',
    document: 'document',
    document_form: 'formulaire du document',
    documents: 'documents',
    dropzone_text: 'c\'est une dropzone demo qui ne fait rien pour le moment',
    dropzone_title: 'glissez un fichier ici ou cliquez',
    email_address: 'adresse e-mail',
    email_predefined: "email prédéfini",
    entitled: 'intitulé',
    event:'évènement',
    events: 'évènements',
    events_settings: 'paramètres d\'évènements',
    excluding_vat: 'htva',
    export_items: 'exporter la liste d\'articles',
    export: 'export',
    export_invoices: 'Exporter des factures',
    filter: 'filtrer',
    first_name: 'prénom',
    from_to: 'De - À',
    general: 'général',
    general_information: 'informations générales',
    general_settings: 'paramètres généraux',
    informations: 'informations',
    invoicing_settings: 'paramètres de facturation',
    implantation: 'implantation',
    Insurance: 'assurance',
    note: 'note',
    notification: 'notification',
    notification_settings: 'paramètres de notification',
    invoice: 'facture',
    invoicing: 'facturation',
    invoice_range: 'période de facturation',
    item: 'article',
    item_list: 'liste des articles',
    last_name: 'nom',
    list_item: 'élément de liste',
    loading: 'chargement ...',
    mark_exported: 'marquer comme exporté',
    message_text: "message prédéfini",
    message: "message",
    multiselect_placeholder: 'tapez votre recherche',
    my_profile: 'mon profile',
    new_event: 'nouvel évènement',
    new_value: 'nouvelle valeur',
    new_task: 'nouvelle tâche',
    next_week: 'semaine suivante',
    no: 'non',
    no_activity: 'aucune activité enregistrée',
    of: 'de',
    old_value: 'ancienne valeur',
    paid: 'payées',
    payment: 'Versements',
    add_payment: 'Ajouter un versement',
    permissions: 'permissions',
    previous_page: 'page précédente',
    purchase_order: 'bon de commande',
    previous_week: 'semaine précédente',
    product: 'produit',
    print: 'imprimer',
    question: 'question',
    quantity: 'quantité',
    quantity_short: 'qté',
    quote: 'devis',
    responsible: 'responsable',
    reset_filter: 'annuler le filtre',
    roles: 'roles',
    room: 'salle',
    search: 'recherche...',
    subject_to_confirmation: 'sous réserve',
    subtotal: 'sous-total',
    status: 'status',
    task: 'tâche',
    tasks: 'tâches',
    task_start_form: 'formulaire de début de tâche',
    task_end_form: 'formulaire de fin de tâche',
    tax_included: 'tvac',
    timeslot: 'créneau horraire',
    today: 'aujourd\'hui',
    tomorrow: 'demain',
    day_after_tomorrow: 'après demain',
    total: 'total',
    total_all_tax_included: 'total ttc',
    type: 'type',
    unit_price_excluding_vat: 'pu htva',
    unit_price_tax_included: 'pu ttc',
    unpaid: 'impayées',
    urn: 'urne',
    user: 'utilisateur',
    users_list: 'liste des utilisateurs',
    username: 'identifiant',
    value_name: 'champs',
    vat: 'tva',
    version: 'version',
    week: 'semaine',
    with_exported: 'inclure les factures déjà exportées',
    work: 'dossier',
    work_status: 'status du dossier',
    yes: 'oui',
    asset_created:'ressource créée',
    asset_updated:'ressource modifiée',
    send_message_success: 'le message a bien été envoyé',
    implantation_created:'implantation créée',
    implantation_updated:'implantation modifiée',
    company_created:'société créée',
    company_updated:'société modifiée',
    when:'quand',
    who:'qui',
    from:'de ',
    to:'à ',
    period: 'période',
    event_type: "type d'évènement",
    event_types: "types d'évènements",
    invoicing_title: {
      INV: 'nouvelle facture',
      PO: 'nouveau bon de commande',
      QUO: 'nouveau devis',
      CN: 'nouvelle note de crédit',
      CR: 'générer le compte rendu'
    },
    invoicing_type: {
      INV: 'facture',
      PO: 'bon de commande',
      QUO: 'devis',
      CN: 'note de crédit'
    },
    urn_returned: 'urnes restituées'

  }
}

