<template>
  <BaseSimpleCard v-if="metadata" name='info' :borderedHeader="false" :title='$t("global.general_information")'>
    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100 '>
        <div v-if='editionMode'>
          <BaseButton v-if="customerCanEditWork"
                      :isElevated="true" icon="paper-plane" :title="$t(buttonText, { var: $t('global.work')} )"
                      @click='submitForm'/>
        </div>
        <BaseButton
          v-if='this.$route.name === "work_form_edit_route"'
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getTaskAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
        <BaseButton color="dark" icon='long-arrow-left' @click="this.$router.go(-1)"
                    :title="$t('button.back') "/>
      </div>
    </template>

    <template #default>
      <template v-if='auth_role === "crema-user" || auth_role === "super-admin"'>
        <div class='flex flex-col gap-y-10'>
          <SelectCremationForm v-if="!isVueEdit" @cremaTypeSelected="setCremationType"/>
          <SelectCeremonyForm v-if="!isVueEdit && cremationType != 5"/>
          <SelectReceptionForm v-if="!isVueEdit && cremationType != 5"/>
          <WorkFormInformations :editionMode='editionMode'/>
          <WorkFormDeceased v-if="(work.events && (workHasCremation || workHasCeremony) && !isCremationTechnique)"
                            :editionMode='editionMode'/>
          <WorkFormAnatomicalPieces v-if="(work.events && workHasCremation && isCremationTechnique)"
                                    :editionMode='editionMode'/>
          <SelectBurialForm v-if="work && work.deceased.burial_type"
                            :editionMode='editionMode'/>
          <WorkFormRemarks :editionMode='editionMode'/>
        </div>
      </template>

      <template v-if='auth_role === "customer-user"'>
        <div class='flex flex-col gap-y-10'>
          <WorkFormInformations v-if="isVueEdit" :editionMode='editionMode'/>
          <SelectCremationForm v-else/>
          <SelectCeremonyForm v-if="!isVueEdit"/>
          <SelectReceptionForm v-if="!isVueEdit"/>
          <template v-if="!isVueEdit && (work.events && workHasCremation) ">
            <WorkFormDeceased v-if='isVueEdit || (work.events )' :editionMode='editionMode'/>
            <SelectBurialForm v-if="showSelectBurial" :editionMode='editionMode'/>
          </template>
          <WorkFormRemarks :editionMode='editionMode'/>
        </div>
      </template>

      <!--#region button 2-->

      <!--#endregion -->

    </template>
  </BaseSimpleCard>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

import WorkFormInformations from "./components/WorkFormInformations";

import WorkFormDeceased from "./components/WorkFormDeceased";
import WorkFormAnatomicalPieces from "./components/WorkFormAnatomicalPieces";
import SelectCremationForm from '@p/works/form/subpages/informations/components/events/cremation/SelectCremationForm'

import SelectCeremonyForm from '@p/works/form/subpages/informations/components/events/ceremony/SelectCeremonyForm'
import SelectReceptionForm from '@p/works/form/subpages/informations/components/events/reception/SelectReceptionForm'
import SelectBurialForm from '@p/works/form/subpages/informations/components/events/burial/SelectBurialForm'

import WorkFormRemarks from "./components/WorkFormRemarks";

export default {
  name: 'TheWorkInformationPage',
  components: {
    SelectBurialForm,
    SelectCeremonyForm,
    SelectCremationForm,
    SelectReceptionForm,
    WorkFormAnatomicalPieces,
    WorkFormDeceased,
    WorkFormInformations,
    WorkFormRemarks,
  },
  data() {
    return {
      editionMode: false,
      cremationType: null
    }
  },
  computed: {
    ...mapGetters({
      work: 'work/getWork',
      metadata: 'work/getWorkMetadata',
      events: 'work/getWorkEvents',
      errors: 'auth/getErrors',
      auth: 'auth/getAuth',
      authRole: 'auth/getAuthRole'
    }),
    customerCanEditWork() {
      if (this.authRole === 'customer-user' && this.work?.status !== 'UCON') return false
      if (this.authRole === 'customer-user' && this.$route.name === 'work_create_route') return true
      if (this.$can('manage_work')) return true
      if (!this.$can('manage_work')) return false
    },
    workHasCremation() {
      return this.events && this.events.filter(event => event.type === 'CRE').length !== 0
    },
    workHasCeremony() {
      return this.events && this.events.filter(event => event.type === 'CER').length !== 0
    },
    workHasConservationEvent() {
      return this.events && this.events.filter(event => event.type === 'CON').length !== 0
    },
    buttonText() {
      return this.$route.name === 'work_form_new_route' ? 'button.add_new_m' : 'button.edit_new_m'
    },
    auth_role() {
      if (this.auth?.roles) {
        return this.auth?.roles[0];
      }
      return null
    },
    isVueEdit() {
      return this.$route.name === 'work_form_edit_route'
    },
    workHasAshContainer() {
      return this.work?.work_items && this.work?.work_items.filter(item => item.type === 'ASH').length !== 0
    },
    showSelectBurial() {
      return !this.isVueEdit && this.work?.deceased?.burial_type && this.work?.deceased?.burial_type !== 'CON'
    },
    isCremationTechnique() {
      if (!this.isVueEdit) {
        return this.cremationType == 5
      }
      return this.events
        && this.events.filter(event => event.type === 'CRE').length
        && this.events.find(event => event.type === 'CRE').event_kind_id == 5
    }
  },
  methods: {
    ...mapActions({
      storeNewWork: 'work/storeNewWork',
      updateWork: 'work/updateWork',
      fetchWorkById: 'work/fetchWorkByID',
    }),
    getTaskAndChangeEditionMode() {
      if (this.editionMode) {
        this.fetchWorkById(this.$route.params.id)
      }
      this.editionMode = !this.editionMode
    },
    submitForm() {
      this.$route.name === 'work_form_new_route' ?
        this.storeNewWork({
          form_data: this.work,
        }) :
        this.updateWork({
          form_data: {
            id: this.$route.params.id,
            work: this.work
          },
          successCallback: () => {
            this.editionMode = !this.editionMode
            this.$h.toastify(this.$t('toasts.updated_m', {var: this.$t('global.work')}), {className: 'toastify-content success'})
          }
        })
    },
    setCremationType(value) {
      console.log('setCremationType', value)
      this.cremationType = value
      this.work.cremationType = value
    }
  },
  beforeMount() {
    if (this.$route.name === "work_form_new_route") {
      this.editionMode = true
    }
  }
}
</script>

