<template>
  <div class="bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="w-full flex items-center justify-between p-6 space-x-6 cursor-pointer" @click='open( implantation.id )'>
      <div class="flex-1 truncate">
        <div class="flex items-center space-x-3">
          <h3 class="text-xl text-gray-900 text-sm font-medium truncate">
            <a href='#'>
              {{ implantation.name }}
            </a>
          </h3>
        </div>
        <p class="mt-1 text-gray-500 text-sm truncate">
          {{ implantation.street }}<br>
          {{ implantation.city }}
        </p>
      </div>
    </div>
    <div>
      <div class="-mt-px flex divide-x divide-gray-200">
        <div class="w-0 flex-1 flex">
          <div class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg">
            <font-awesome-icon :icon="['fal','phone-alt']" />
            <span class="ml-3">{{ implantation.phone_field }}</span>
          </div>
        </div>
        <div class="-ml-px w-0 flex-1 flex">
          <a :href="'mailto:' + implantation.email" class="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
            <font-awesome-icon :icon="['fal','paper-plane']" />
            <span class="ml-3">{{ implantation.email }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ImplantationCardComponent',
  props: {
    implantation: {
      type: Object,
      required: true
    },
    open: {
      type: Function,
      required: true
    }
  }
}
</script>
