import getters from '@/store/modules/content/task/getters'
import mutations from '@/store/modules/content/task/mutations'
import actions from '@/store/modules/content/task/actions'

export default {
  namespaced: true,
  state() {
    return {
      tasksList: [],
      task: {},
      taskActivity: {},
      taskMetadata: {},
    }
  },
  getters,
  actions,
  mutations
}
