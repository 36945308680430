import apiClient from '@u/apiClient'

export default{

  //region fetch
  async fetchNotifications({commit}) {
    await apiClient.get(`api/fetch-notifications`)
        .then(async response => {
          console.log(response.data)
          await commit('setNotifications', response.data.notifications)
        })
        .catch(async reason => {
          await console.error('fetchNotifications', reason)
        })
  }
  //endregion
}
