export default {
  getItemList(state) {
    return state.itemsList
  },
  getSelectedItem(state) {
    return state.item
  },
  getItemMetadata(state) {
    return state.itemMetadata
  },
  getItemActivity(state){
    return state.itemActivity
  }
}
