<template>

  <CheckTable :model="checkByTypes" :isCheckboxVisible="false"/>
</template>

<script>
import CheckTable from "./CheckTable";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CheckListByStatus',
  components:{ CheckTable },
  computed:{
    ...mapGetters({metadata: 'invoicing/getCheckMetadata', checkByTypes: 'invoicing/getCheckListByType'}),
  },
  methods:{
    ...mapActions({fetchCheckByStatus: 'invoicing/fetchCheckByStatus'}),
    fetchNeededChecks(){
      switch(this.$route.name){
        case 'check_tbdep_index_route':
          this.fetchCheckByStatus({status: 'TBDEP'})
          break
        case 'check_dep_index_route':
          this.fetchCheckByStatus({status: 'CAS'})
          break
        case 'check_ref_index_route':
          this.fetchCheckByStatus({status: 'REF'})
          break
      }
    }
  },
  watch:{
    '$route.name'(newValue) {
      this.fetchNeededChecks()
    }
  },
  beforeMount() {
    this.fetchNeededChecks()
  }
}
</script>

<style scoped>

</style>
