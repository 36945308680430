import apiClient from '@u/apiClient'

function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  //region fetch
  async fetchListItems({commit}) {
    await apiClient.get('api/listItem')
        .then(async response => {
          commit('setListItemsIndex', response.data.list_items)
        })
        .catch(async reason => {
          console.error('fetchListItems', reason)
        })
  },
  //endregion

  //region store
  async storeListItem({commit}, payload) {
    await apiClient.post('api/listItem', payload.listItem)
        .then(async response => {
          if (response.status === 200) {
            commit('setListItemsIndex', response.data.list_items)
          }
        })
        .catch(async reason => {
          console.error('storeListItem', reason)
        })
  },
  //endregion

  //region update
  async updateListItem({commit}, payload) {
    await apiClient.put(`api/listItem/${payload.listItem.id}`, payload.listItem)
        .then(async response => {
          if (response.status === 200) {
            commit('setListItemsIndex', response.data.list_items)
          }
        })
        .catch(async reason => {
          console.error('updateListItem', reason)
        })
  },
  //endregion

  //region delete
  async deleteListItemById({commit}, payload) {
    await apiClient.delete(`api/listItem/${payload.listItem}`)
        .then(async response => {
          if (response.status === 200) {
            commit('setListItemsIndex', response.data.list_items)
          }
        })
        .catch(async reason => {
          console.error('deleteListItemById', reason)
        })
  },
  //endregion

  //region undodelete
  async undoDeleteListItemById({commit}, payload) {
    await apiClient.get(`api/listItem/undo-delete/${payload.id}`)
        .then(async response => {
          if (response.status === 200) {
            commit('setListItems', response.data.tasks)
            commit('setlistItemMetadata', response.data.metadata)
            processSuccessCallback(payload);
          }
        })
        .catch(async reason => {
          console.error('undoDeletelistItemById', reason)
        })
  },
  //endregion
}

