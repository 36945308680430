import apiClient from '@/utils/apiClient'
import fileDownload from 'js-file-download';
import moment from "moment";

let base_errors = {};
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  //region fetch
  async fetchAllItem({commit}) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get('api/item')
        .then(response => {
          commit('setItemList', response.data.items)
          commit('setItemMetadata', response.data.metadata)
        })
        .catch(async reason => {
          console.error('fetchAllItem', reason)
        })
  },

  async fetchItemByID({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get(`api/item/${payload.id}`)
        .then(async response => {
          await commit('setItem', response.data.item)
          await commit('setItemMetadata', response.data.metadata)
        })
        .catch(async reason => {
          console.error('fetchItemByID', reason)
        })
  },
  async fetchNewItem({commit}) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get('api/model/new?model=item')
        .then(response => {
          commit('setItem', response.data.item)
          commit('setItemMetadata', response.data.metadata)
        })
        .catch(async reason => {
          await console.error(reason)
        })
  },
  async fetchItemActivitiesByItemID({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get(`api/item/fetch-activities/${payload.item_id}`)
        .then(async response => {
          await commit('setItemActivity', response.data.activities)
        })
        .catch(async reason => {
          await console.error(reason)
        })
  },
    async fetchItemsExport({commit}) {
        await apiClient.post(`api/item/export`)
            .then(async response => {
                let date = new Date()
                let month = date.getMonth()+1 <= 9 ? '0' + (date.getMonth()+1) : (date.getMonth()+1)
                let today = date.getDate() + " - " + month + " - " + date.getFullYear()
                fileDownload(response.data, 'Tarif du crématorium au ' + today +'.csv')
            })
            .catch(reason => {
                console.log('errors')
                    commit('auth/setErrors', reason.response, {root: true})
            })
    },
  //endregion

  //region store
  async storeNewItem({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.post('api/item', payload.item)
        .then(response => {
          if (response.status === 201) {
            processSuccessCallback(payload)
          }
        })
        .catch(reason => {
          console.error('storeNewItem', reason)
        })
  },
  //endregion

  //region update
  async updateItem({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.put(`api/item/${payload.id}`, payload.item)
        .then(response => {
          if (response.status === 200) {
            processSuccessCallback(payload)
          }
        })
        .catch(reason => {
          console.error('updateItem', reason)
        })
  },
  //endregion

  //region delete
  async deleteItemById({commit}, payload) {
    await apiClient.delete(`api/item/${payload.id}`)
        .then(async response => {
          if (response.status === 200) {
            commit('setItemList', response.data.items)
            commit('setItemMetadata', response.data.metadata)
            processSuccessCallback(payload);
          }
        })
        .catch(async reason => {
          console.error('deleteItemById', reason)
        })
  },
  //endregion

  //region undoDelete
  async undoDeleteItemById({commit}, payload) {
    await apiClient.get(`api/item/undo-delete/${payload.id}`)
        .then(async response => {
          if (response.status === 200) {
            commit('setItemList', response.data.items)
            commit('setItemMetadata', response.data.metadata)
            processSuccessCallback(payload);
          }
        })
        .catch(async reason => {
          console.error('undoDeleteItemById', reason)
        })
  },
  //endregion
}
