<template>
  <div class='flex flex-col space-y-5'>

    <div class='flex'>
      <h1 class='flex-1 text-2xl font-bold capitalize-first'>{{ tableTitle }}</h1>
      <InvoicingIndexActionDropDownComponent
          @generateInvoicesPDF="handleGenerateInvoicesPDF"
          @generateInvoicesCSV="handleGenerateInvoicesCSV"
      />
    </div>
    <div class='flex space-x-5'>
      <div class='flex-1 flex flex-col space-y-2'>

        <BaseSelectMultiple v-if="metadata?.companies"
                            :label="$t('attributes.company')"
                            :placeholder="$t('attributes.company')"
                            name='company_id'
                            :value='null'
                            :options='metadata.companies'
                            @setSelected.self='handleFilterTable'
        />
        <BaseSelectMultiple v-if="metadata?.status"
                            :label="$t('attributes.status')"
                            :placeholder="$t('attributes.status')"
                            name='status_code'
                            :value='null'
                            :options='metadata.status'
                            @setSelected.self='handleFilterTable'/>

        <BaseSelectMultiple v-if="metadata.customers"
                            :label="$t('attributes.customer')"
                            :placeholder="$t('attributes.customer')"
                            name="customer_search"
                            :options="metadata.customers"
                            :searchable='true'
                            @setSelected="handleFilterTable" />

        <BaseSearchInput v-if="this.search"
                         groupClass="mt-3"
                         @typeInput="filterList"
        />
      </div>
      <div class='flex-1 flex flex-col space-y-2'>
        <div class='flex flex-col'>
          <label class="form-label mt-3 capitalize-first">{{ $t('attributes.emission_date') }}&nbsp;</label>
          <litepie-datepicker
              i18n="fr"
              :placeholder="$t('global.from_to')"
              separator=" - "
              :formatter="date_formatter"
              :shortcuts="false"
              v-model="emission_date"
          />
        </div>
        <div class='flex flex-col'>
          <label class="form-label mt-3 capitalize-first">{{ $t('attributes.due_date') }}&nbsp;</label>
          <litepie-datepicker
              i18n="fr"
              :placeholder="$t('global.from_to')"
              separator=" - "
              :formatter="date_formatter"
              :shortcuts="false"
              v-model="due_date"
          />
        </div>
        <div class='flex justify-end pt-3 space-x-3'>

          <ColumnVisibilityDropDownComponent v-model='this.columnVisibility'/>

          <div class="dropdown">
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1">
                <div class="p-2">
                  <a href=""
                     class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                    <i data-feather="printer" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> Print </a>
                  <a href=""
                     class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                    <i data-feather="external-link" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> Excel
                  </a>
                  <a href=""
                     class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                    <i data-feather="file-text" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> CSV </a>
                  <a href=""
                     class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                    <i data-feather="archive" class="w-4 h-4 text-gray-700 dark:text-gray-300 mr-2"></i> PDF </a>
                </div>
              </div>
            </div>
          </div>

          <BaseButton color="secondary"
                      :isoutline="true"
                      icon="sync"
                      @click="this.$emit('refresh-click')"
                      scopedClasses=""
                      :tooltip="$t('button.refresh')"
                      :title="null"/>
        </div>

      </div>
    </div>

    <div :class="divClasses">

      <table class="table" :class="tableClasses">
        <!--#region header -->
        <thead>
        <tr :class="theadRowClasses">
          <BaseThComponent v-for="column in columnVisibility.filter(item => !item.hidden)"
                           :key="column.field"
                           :column="column"
                           :th-classes="thClasses"
                           :width="column.width"
                           :currentSort="currentSort"
                           @sortColumn="handleSortColumn"/>
        </tr>
        </thead>
        <!--#endregion -->

        <tbody>
        <tr
            v-for="(model,index) in filteredModels"
            :key="index"
            @click="this.$emit('rowClick', model.id )"
            :class="[trClasses, generateStripedClass(index)]"
        >
          <td
              v-for="(column,index) in columnVisibility?.filter(item => !item.hidden)"
              :key="index"
              class="dark:border-dark-5 whitespace-nowrap"
              :class="tdClasses"
          >
            <BaseLabel v-if="column.type === 'label'"
                       :icon='column.metadata.icon'
                       :content="model[column.metadata.value]"
                       :color="model[column.metadata.color]"/>
            <ContentTableCell v-else :model="model" :column="column"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>

<script >
import {mapActions } from "vuex";
import moment from 'moment';

import LitepieDatepicker from 'litepie-datepicker';
import InvoicingIndexActionDropDownComponent from "@p/invoicing/index/Table/components/InvoicingIndexActionDropDownComponent";
import printJS from "print-js";
import apiClient from "@u/apiClient";


export default {
  name: 'InvoicingIndexTable',
  components: {
    InvoicingIndexActionDropDownComponent,
    LitepieDatepicker
  },
  props: {
    columns: {
      type: Array,
      required: true
    },
    rowClick: {
      type: String,
      required: false,
      default: ''
    },
    search: {
      type: String,
      default: false
    },
    metadata: {
      type: Array,
      required: true
    },
    modelList: {
      type: Array,
      required: true
    },
    isBordered: {
      type: Boolean,
      required: false,
      default: false
    },
    isHoverable: {
      type: Boolean,
      required: false,
      default: false
    },
    isSmallTable: {
      type: Boolean,
      required: false,
      default: false
    },
    isResponsive: {
      type: Boolean,
      required: false,
      default: false
    },
    isStriped: {
      type: Boolean,
      required: false,
      default: false
    },
    theadRowClasses: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: [
    'refresh-click',
  ],
  data() {
    return {
      searchTerms: '',
      sortDirection: null,
      sortField: '',
      currentSort: '',
      emission_date: '',
      due_date: '',
      date_formatter: {
        date: 'DD/MM/YYYY',
        month: 'MM'
      },
      filter: {},
      // columnVisibility: {}
    }
  },
  computed: {
    filteredCheckList(){
      let tempChecks = this.model

      if(this.filters.length !== 0){
        tempChecks = tempChecks.filter(row => this.filters.includes(row.customer_name))
      }
      return tempChecks
    },
    tableTitle() {
      return this.$route.name === 'invoice_unpaid_index_route' ? this.$t('global.unpaid') : this.$route.name === 'invoice_paid_index_route' ? this.$t('global.paid') : this.$t('enums.invoicing.type.' + this.$route.meta.doc_type)
    },
    tdClasses() {
      return this.isBordered ? 'border' : 'border-b'
    },
    thClasses() {
      return this.isBordered ? 'border' : ''
    },
    trClasses() {
      return this.isHoverable ? 'hover:bg-gray-200 dark:hover:bg-gray-600' : ''
    },
    tableClasses() {
      return this.isSmallTable ? 'table--sm' : ''
    },
    divClasses() {
      return this.isResponsive ? 'overflow-x-auto' : ''
    },

    filteredModels() {

      let tempModels =  this.modelList
      if (this.searchTerms.length >= 1) {
        tempModels = tempModels.filter((model) => {
          let search = this.searchTerms.length ? this.searchTerms.toLowerCase() : '';
          var values = this.filtrableColumns;
          var flag = false
          values.forEach((val) => {
            if (model[val] && model[val].toLowerCase().indexOf(search.toLowerCase()) > -1) {
              flag = true;
              return;
            }
          })
          if (flag) return model
        });
      }
      if(this.filter.company_id) {
        tempModels = tempModels?.filter(model => {
          return model.company_id === this.filter.company_id[0].id
        })
      }
      if(this.filter.status_code) {
        // console.log(this.filter.status_code)
        // tempModels = tempModels?.filter(model => {
        //   return model.status_code === this.filter.status_code[0].id
        //
        // })
          let arrayOfAllStatusIdInFilter = []
          this.filter.status_code.map((status) => {
            arrayOfAllStatusIdInFilter.push(status.id)
          })
          tempModels = tempModels?.filter( model => arrayOfAllStatusIdInFilter.includes(model.status_code))
      }
      else{
        if(this.$route.name !== 'invoice_paid_index_route'){

          tempModels = tempModels?.filter( model => model.status_code !== 'PAI')
        }
      }
      if(this.filter.due_date) {
        const search_start = moment(this.filter.due_date[0], 'DD/MM/YYYY').startOf('day')
        const search_end = moment(this.filter.due_date[1], 'DD/MM/YYYY').endOf('day')
        tempModels = tempModels?.filter(model => {
          const model_date = moment(model.due_date, 'DD/MM/YYYY').add(1, 'minutes')
          return model_date.isBetween(search_start, search_end)
        })
      }
      if(this.filter.emission_date) {
        const search_start = moment(this.filter.emission_date[0], 'DD/MM/YYYY').startOf('day')
        const search_end = moment(this.filter.emission_date[1], 'DD/MM/YYYY').endOf('day')
        tempModels = tempModels?.filter(model => {
          const model_date = moment(model.emission_date, 'DD/MM/YYYY').add(1, 'minutes')
          return model_date.isBetween(search_start, search_end)
        })
      }
      if(this.filter.customer_search){
        let arrayOfAllCustomersIdInFilter = []
        this.filter.customer_search.map((customer) => { arrayOfAllCustomersIdInFilter.push(customer.id)})
        tempModels = tempModels?.filter( model => arrayOfAllCustomersIdInFilter.includes(model.customer_id))
      }
      return tempModels;
    },
    filtrableColumns() {
      return this.columns.filter(function (column) {
        return _.has(column, 'searchable') && column.searchable === true
      }).map(obj => {
        return obj.field
      })
    },
    columnVisibility() {
      if (localStorage.getItem('InvoiceColumnsVisibility') === null) {
        let modelValueInJson = JSON.stringify(this.columns)
        localStorage.setItem('InvoiceColumnsVisibility', modelValueInJson);
        return this.columns
      } else {
        return  JSON.parse(localStorage.getItem('InvoiceColumnsVisibility'))
      }
    }
  },
  methods: {
    ...mapActions({
      fetchInvoicesCSV: 'invoicing/fetchInvoicesCSV'
    }),
    getValue(newValue) {
      if (newValue.length) {
        return newValue.split(' - ');
      }
      return [];
    },
    generateStripedClass(index) {
      return this.isStriped && index % 2 !== 0 ? 'bg-gray-200 dark:bg-dark-1' : ''
    },
    async handleGenerateInvoicesPDF() {
      let baseURL;
      if (localStorage.getItem('url') !== null) {
        baseURL = localStorage.getItem('url')
      }
      else {
        baseURL = window.location.protocol + "//" + window.location.host + '/';
      }
      this.filter['doc_type'] = this.$route.meta.doc_type;

      if (process.env.NODE_ENV !== 'development') {
      printJS(baseURL + "api/render/invoicing/?filter=" + JSON.stringify( this.filter ) )
      }else{
      window.open( baseURL + "api/render/invoicing?filter=" + JSON.stringify( this.filter ) );
      }

    },
    handleGenerateInvoicesCSV() {
      this.fetchInvoicesCSV({
        doc_type: this.$route.meta.doc_type,
        company_id: this.filter.company_id,
        customer_search: this.filter.customer_search,
        status_code: this.filter.status_code,
        emission_date : this.filter.emission_date,
        due_date: this.filter.due_date
      })
    },
    rowClick(val) {
      if (this.rowClick !== '') {
        this.$emit(this.rowClick, val)
      }
    },
    filterList(terms) {
      this.searchTerms = terms
    },
    handleSortColumn({column, direction}) {
      if (direction === '-') {
        this.$emit('refresh-click')
        return
      }
      this.currentSort = column

      let sortedModels = this.filteredModels;

      sortedModels = sortedModels.sort((a, b) => {
        let fa = a[column].toLowerCase(), fb = b[column].toLowerCase();
        if (fa < fb) {
          return direction === 'up' ? -1 : 1
        }
        if (fa > fb) {
          return direction === 'up' ? 1 : -1
        }
        return 0
      })

      this.filteredModels = sortedModels
    },
    handleFilterTable({attribute, value}) {
      let temp_filter = this.filter
      if (value.length === 0) {
        delete temp_filter[attribute];
        this.filter = temp_filter
      } else {
        this.filter = {...this.filter, [attribute]: value}
      }
    }
  },
  watch: {
    emission_date(newValue, oldValue) {
      this.handleFilterTable({'attribute': 'emission_date', 'value': this.getValue(newValue)})
    },
    due_date(newValue, oldValue) {
      this.handleFilterTable({'attribute': 'due_date', 'value': newValue.split(' - ')})
    },
  }
}
</script>

