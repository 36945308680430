export default {
  getImplantationIndex(state) {
    return state.implantationList
  },
  getSelectedImplantation(state) {
    return state.implantation
  },
  getImplantationMetadata(state) {
    return state.implantationMetadata
  },
}
