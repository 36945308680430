<template>
  <teleport to='body'>
    <transition name='modal'>
      <dialog class='modal-content p-0 max-h-screen flex flex-col' :class='modalClasses' open v-if='isModalVisible'>
        <!--#region header -->
        <div class='flex p-5 items-center space-x-2'>

          <DropdownTaskStatusSquare v-if='true'
                                    :options='c_translated_option'
                                    :current='workTask?.status'
                                    :icon='c_actual_status_icon'
                                    label='label'
                                    @select='mChangeStatus'
          />
          <div class='flex-1 text-2xl font-bold capitalize-first'>
            {{ title }}
          </div>
          <div class='flex-shrink-0 flex items-center cursor-pointer'>
            <BaseButton
              :icon="editionMode ? 'times' : 'pen'"
              :title="editionMode ? $t('button.cancel') : $t('button.edit')"
              @click='changeEditionMode'
              color="warning"
            />
            <font-awesome-icon :icon="['fal', 'times']" size='lg' class="ml-3 mr-1"  @click='_close' />
          </div>
        </div>
        <!--#endregion -->

        <section class='flex-1 modal-body overflow-scroll pt-0'>
          <div class='overflow-x-auto'>

            <dl class='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2'>

              <div class='sm:col-span-1'>
                <BaseShowEditInput :label="$t('global.task')" v-model='workTask.name' :editionMode='editionMode' />
              </div>

              <div class='sm:col-span-1'>
                <BaseShowEditFilteredUsersComponent
                  :label="$t('global.responsible')"
                  v-model='workTask.responsable_id'
                  :editionMode='editionMode'
                  name='responsable'
                  :metadata='metadata'
                />
<!--                <BaseShowEditSelectSimple-->
<!--                  :label="$t('global.responsible')"-->
<!--                  v-model='workTask.responsable_id'-->
<!--                  :editionMode='editionMode'-->
<!--                  name='responsable'-->
<!--                  :options='metadata.users'-->

<!--                />-->
              </div>

              <div class='sm:col-span-2'>
                <BaseShowEditTextArea
                  :label="$t('global.informations')"
                  :placeholder="$t('global.informations')"
                  v-model='workTask.description'
                  :editionMode='editionMode'
                />
              </div>

              <!--#region registre -->
              <div v-if='c_status_change_activities_ordered_desc && c_status_change_activities_ordered_desc.length'
                   class='sm:col-span-2'>
                <dt class='text-sm font-medium text-gray-500 capitalize-first'>
                  {{ $t('global.activities') }}
                </dt>
                    <div v-for="activity in c_status_change_activities_ordered_desc" class='px-2 py-1'>
                      <TaskStatusChangeActivityItem
                        :activity='activity'
                        :metadata='metadata'
                        :editionMode='editionMode'
                        :task="workTask"
                      />
                      <hr class="mt-4 mb-2">
                    </div>
              </div>
              <!--#endregion -->
              <div>

              </div>
            </dl>

          </div>
        </section>

        <!--#region footer-->
        <div class='flex flex row justify-end py-3 px-5 bg-gray-100 gap-x-5'>
          <BaseButton v-if='editionMode' :icon='submitButtonIcon' @click='submitForm' :title='submitButtonTitle' />
          <BaseButton :title="$t('button.close')" icon='times' color='danger' @click='_close' />

        </div>
        <!--#endregion -->
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class='backdrop' @click='_backdropClicked' v-if='isModalVisible'></div>
    </transition>
  </teleport>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import TaskStatusChangeActivityItem from './TaskStatusChangeActivityItem'

import { mapFields } from 'vuex-map-fields';
import BaseShowEditFilteredUsersComponent from "@c/GlobalFormComponents/BaseShowEditFilterdUsersComponent.vue";

export default {
  name: 'WorkTaskFormDialog',
  emits: ['close', 'backdropClicked'],
  components: {
    BaseShowEditFilteredUsersComponent,
    // DropdownTaskStatusSquare,
    TaskStatusChangeActivityItem
  },
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function(value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    taskId: {
      type: [String, Number]
    },
    workId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      isPulsing: false,
      modalVisible: this.isModalVisible,
      editionMode: false,
    }
  },
  computed:  {
    ...mapGetters({ errors: 'auth/getErrors', metadata: 'work/getWorkMetadata' }),
    ...mapFields('work', { workTask: 'selectedWorkTask' }),
    c_translated_option(){
      return this.metadata.tasks.status.reduce((array, item) => {
        array.push({
          ...item,
          label: this.$t('enums.task.status.' + item.id)
        })
        return array
      }, []);
    },
    c_actual_status_color() {
      return this.metadata.tasks.status.find(status => status.id === this.workTask.status).bg_color
    },
    c_actual_status_icon() {
      return this.metadata.tasks.status.find(status => status.id === this.workTask.status).icon
    },
    c_actual_status_label() {
      return this.metadata.tasks.status.find(status => status.id === this.workTask.status).label
    },
    c_status_change_activities_ordered_desc() {
      return this.workTask.status_change_activities
    },
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    },
    title() {
      return this.workTask?.id ? this.workTask?.name : this.$t('global.new_task')
    },
    submitButtonTitle() {
      return this.workTask.id ? this.$t('button.edit_new_mv', { var: this.$t('global.event') }) : this.$t('button.add_new_m', { var: this.$t('global.event') })
    },
    submitButtonIcon() {
      return this.workTask.id ? 'edit' : 'plus'
    },
    titleUpdateOrCancel() {
      return this.editionMode ? this.$t('button.cancel') :  this.$t('button.update')
    }
  },
  methods: {
    ...mapActions({
      updateWorkTaskStatus:'work/updateWorkTaskStatus',
      storeWorkTask: 'work/storeWorkTask',
      updateWorkTask: 'work/updateWorkTask',
      fetchWorkById: 'work/fetchWorkByID'
    }),
    mChangeStatus(newStatus) {

      if (newStatus.id !== this.workTask.id) {
        if (!Boolean(this.workTask.form_on_start) && !Boolean(this.workTask.form_on_end)) {
          if(newStatus.id === 4){
            this.$emit('openModal', {
              modal: 'TaskFormDialog',
              task: this.workTask,
              form: 'reason',
              nextStatus: newStatus.id
            })
            return
          }
          this.updateWorkTaskStatus({
            id: this.workTask.id,
            new_status: newStatus.id,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('attributes.task')}), {className: 'toastify-content success'})
            }
          })
          return
        }

        if (newStatus.id === 2) {
          if (Boolean(this.workTask.form_on_start)) {
            this.$emit('openModal', {
              modal: 'TaskFormDialog',
              task: this.workTask,
              form: 'starting_form',
              nextStatus: newStatus.id
            })
          } else {
            this.updateWorkTaskStatus({
              id: this.workTask.id,
              new_status: newStatus.id,
              successCallback: () => {
                this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('attributes.task')}), {className: 'toastify-content success'})
              }
            })
          }
          return
        }

        //si le nouveau status est 4 =>"AWAITING" ou 3 => "COMPLETED" et que la tâche a un starting_form on affiche une popup (text = veuillez d'abord démarrer la tâche)
        if ((newStatus.id === 3 || newStatus.id === 4) && (Boolean(this.workTask.form_on_start) && this.workTask.starting_form[0].value === null )) {
          this.$h.toastify(this.$t('toasts.start_task_first'), {className: 'toastify-settings warning'})
          return
        }

        if ((newStatus.id === 4) && ( !Boolean(this.workTask.form_on_start) || ( Boolean(this.workTask.form_on_start) && this.workTask.starting_form[0].value !== null ) )) {
          this.$emit('openModal', {
            modal: 'TaskFormDialog',
            task: this.workTask,
            form: 'reason',
            nextStatus: newStatus.id
          })
          return
        }

        if(newStatus.id === 3 &&  !Boolean(this.workTask.form_on_end)) {
          this.updateWorkTaskStatus({
            id: this.workTask.id,
            new_status: newStatus.id,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('attributes.task')}), {className: 'toastify-content success'})
            }
          })
          return
        }

        //si le nouveau status est 3 => "COMPLETED", que le status actuel est 4 ou 2 (ou que la tâche n'a pas de starting_form) et que la tache à un ending_form on affiche une popup avec le ending_form
        this.$emit('openModal', {
          modal: 'TaskFormDialog',
          task: this.workTask,
          form: 'ending_form',
          nextStatus: newStatus.id
        })
      }
    },
    getInputValue({ attribute, value }) {
      this.event[attribute] = value
    },
    afterWorkSelected(work) {
      this.event.work_id = work.id
    },
    _close() {
      this.isPulsing = false
      this.$emit('close')
      this.editionMode=false
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.editionMode=false
      this.$emit('backdropClicked')
    },
    async submitNewTask() {
      await this.storeWorkTask({
        task: this.workTask, successCallback: () => {
          this._close();
          this.editionMode = false;
        }
      })
    },
    async submitUpdateTask() {
      console.log(this.workTask)
      await this.updateWorkTask({
        id: this.workTask.id, task: this.workTask,
        successCallback: () => {
          this.editionMode = false;
        }
      })
    },
    submitForm() {
      this.workTask.id ? this.submitUpdateTask() : this.submitNewTask()
    },
    changeEditionMode() {
      if(this.editionMode) {
        this.fetchWorkById(this.workId)
      }
      this.editionMode = !this.editionMode
    }
  },
}
</script>

<style scoped>
/*
    - backdrop
    - dialog
    - dialog : responsive
    - animations (usage)
    - animations (declaration)
 */

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 577px) {
  dialog.small {
    width: 20%;
    max-width: 300px;
    scroll-behavior: auto;
  }

  dialog.medium {
    width: 30%;
    max-width: 500px;
  }

  dialog.large {
    width: 50%;
    max-width: 700px;
  }

  dialog.x-large {
    width: 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active {
  animation: modal 0.3s ease-in;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active {
  animation: backdrop 0.3s ease-in;
}

.backdrop-leave-active {
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation: pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
