<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','ticket']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{
            this.$route.name === 'settings_create_item_route' ? $t('button.add_new_m', {var: $t('global.item')}) : pageTitle
          }}</span>
      </div>
    </template>

    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100 '>

        <div v-if="editionMode">
          <BaseButton
            :isElevated='true'
            icon='paper-plane'
            @click='submitForm'
            :title="$t(buttonText, { var: $t('global.item')} )"/>
        </div>
        <BaseButton
          v-if="this.$route.name !== 'settings_create_item_route' && !$can('manage-settings')"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getItemAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>

        <BaseButton
          type="dark"
          icon='long-arrow-left'
          @click="this.$router.push({name: 'settings_list_item_route'})"
          :title="$t('button.back')"/>
      </div>

    </template>

    <template #body>
      <template v-if='item'>
        <div class="grid grid-cols-2 gap-20 space-x-10 mb-5">
          <!--#region col left -->
          <div class="col-start-1 my-5">

            <BaseShowEditInput :label="$t('attributes.name')"
                               v-model="item.name"
                               :errors="errors.name"
                               :placeholder="$t('attributes.name')"
                               :editionMode="editionMode"
                               :required="true"/>

            <BaseShowEditSelectSimple v-if="metadata?.accounting_codes"
                                      :label="$t('attributes.accounting_code')"
                                      name='accounting_code'
                                      :placeholder="$t('attributes.accounting_code')"
                                      :editionMode='editionMode'
                                      v-model='item.accounting_code'
                                      :defaultValue="metadata?.accounting_codes?.filter(el => el.id === item.accounting_code)"
                                      :options="metadata.accounting_codes"/>
            <BaseShowEditSelectSimple
              v-if="metadata.item_types"
              :options="metadata?.item_types"
              :name="item.type"
              v-model="item.type_id"
              :label="$t('attributes.item_type')"
              :placeholder="$t('attributes.item_type')"
              :editionMode="editionMode"

            />
            <BaseShowEditSelectSimple
              v-if="metadata?.groups"
              :options="metadata.groups"
              :name="item.groupe"
              v-model="item.groupe"
              :label="$t('attributes.item_group')"
              :placeholder="$t('attributes.item_group')"
              :editionMode="editionMode"
              :required="true"
              :errors="errors.groupe"
            />

            <BaseShowEditTextArea :label="$t('attributes.description')"
                                  :placeholder="$t('attributes.description')"
                                  v-model="item.description"
                                  :errors="errors.description"
                                  :editionMode="editionMode"/>

          </div>
          <!--#endregion -->

          <!--#region col right -->
          <div class="col-start-2 my-5">

            <BaseShowEditInput :label="$t('attributes.vat_rate')"
                               v-model="item.vat_rate"
                               :placeholder="$t('attributes.vat_rate')"
                               :errors="errors.vat_rate"
                               :editionMode="editionMode"
                               :required="true"/>

            <BaseShowEditInput :label="$t('attributes.price_excluding_vat')"
                               v-model="item.price_excluding_vat"
                               :errors="errors.price_excluding_vat"
                               :editionMode="editionMode"
            />

            <BaseShowEditInput :label="$t('attributes.buy_price')"
                               v-model="item.buy_price"
                               :errors="errors.buy_price"
                               :editionMode="editionMode"
            />

          </div>
          <!--#endregion -->
        </div>
        <!--#region submit button -->
        <!--        <div class='flex justify-end pt-5' v-if="editionMode">-->
        <!--          <BaseButton-->
        <!--              :isElevated='true'-->
        <!--              icon='paper-plane'-->
        <!--              @click='submitForm'-->
        <!--              :title="$t(buttonText, { var: $t('global.item')} )"/>-->
        <!--        </div>-->
        <!--#endregion -->
      </template>

    </template>

  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import SelectSettingAccountingCode from '../../../../../GlobalFormComponents/SelectSettingAccountingCode'

export default {
  name: 'TheItemFormPage',
  components: {
    SelectSettingAccountingCode,
  },
  data() {
    return {
      editionMode: false
    }
  },
  computed: {
    ...mapGetters({
      item: 'item/getSelectedItem',
      metadata: 'item/getItemMetadata',
      errors: 'auth/getErrors'
    }),
    buttonText() {
      return this.$route.name === 'settings_create_item_route' ? 'button.add_new_m' : 'button.edit_new_mv'
    },
    pageTitle() {
      return this.item.name
    }
  },
  methods: {
    ...mapActions({
      fetchNewItem: 'item/fetchNewItem',
      fetchItemByID: 'item/fetchItemByID',
      postNewItem: 'item/storeNewItem',
      putUpdateItem: 'item/updateItem'
    }),
    getItemAndChangeEditionMode() {
      if (this.editionMode) {
        this.fetchItemByID({id: this.$route.params.id})
      }
      this.editionMode = !this.editionMode
    },
    getInputValue({attribute, value}) {
      this.item[attribute] = value
    },
    submitForm() {
      this.$route.name === "settings_create_item_route" ?
        this.postNewItem({
          item: this.item,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.created_m', {var: this.$t('global.item')}), {className: 'toastify-content success'})
            this.editionMode = false
          }
        }) :
        this.putUpdateItem({
          id: this.$route.params.id,
          item: this.item,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.updated_m', {var: this.$t('global.item')}), {className: 'toastify-content success'})
            this.editionMode = false
          }
        })
    }
  },
  beforeMount() {
    this.$route.name === 'settings_create_item_route' ?
      (this.fetchNewItem(), this.editionMode = true) : (this.fetchItemByID({id: this.$route.params.id}))
  }
}
</script>
