<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="flex justify-center items-center text-base border-2 border-white ring-1 ring-gray-500 shadow-2xl font-medium text-white bg-theme-red rounded-full w-12 h-12 hover:text-opacity-100 focus:outline-none"
    >
      <font-awesome-icon :icon='["fal","sliders-h"]' />
    </PopoverButton>
    <PopoverOverlay
      class="bg-black z-100"
      :class='open ? "opacity-10 fixed inset-0" : "opacity-0"'
    />
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel class="absolute bottom-14 right-0 z-100 w-screen max-w-sm px-4 mt-3 transform sm:px-0 lg:max-w-3xl">
        <div class="overflow-visible rounded-lg shadow-2xl ring-1 ring-black ring-opacity-5">
          <div class="relative bg-white p-5 space-y-5">
            <div>
<!--               TODO: trad-->
              <h2 class='text-xl font-bold mb-2 capitalize-first'>{{ $t('global.cremation_date') }}</h2>
              <div class='flex flex-wrap space-x-3'>
                <div
                  v-for='(filter, index) in filters'
                  :key="index"
                  :class="filter.selected ? 'bg-blue-500 text-white' : ''"
                  class='bg-gray-300 text-black rounded-md px-2 py-1 capitalize-first cursor-pointer shadow-md'
                  @click='setFilter(index)'>
                  {{ filter.title }}
                </div>

              </div>
              <h2 class='text-xl font-bold mb-2 mt-3 capitalize-first'>{{ $t('global.work_status') }}</h2>
              <span
                  v-for='(item, index) in metadata.status'
                  :key="index"
                  @click='setOtherFilter(item)'
                  :class="status.includes(item.id) ? 'bg-blue-500 text-white' : ''"
                  class="inline-flex items-center bg-gray-300 text-black rounded-md px-2 py-1 mt-2 mr-2 capitalize-first cursor-pointer shadow-md">
                <svg :class="`mr-1.5 h-2 w-2 text-theme-${item.bg_color}`" fill="currentColor" viewBox="0 0 8 8">
                  <circle cx="4" cy="4" r="3"/>
                </svg>
                {{ item.name }}
              </span>
              <div class="flex flex-row-reverse mt-3">
                <BaseButton color='danger'
                            icon='times'
                            :title="$t('global.reset_filter')"
                            @click="resetFilter"
                />
              </div>
              <h2 class='text-xl font-bold mt-4 capitalize-first'>{{ $t('global.customers') }}</h2>
              <BaseSelectMultiple
                  v-if="metadata.customers"
                  label=""
                  :placeholder="$t('global.customer')"
                  name='customer'
                  :value="null"
                  :options="metadata.customers"
                  selectClass=" mx-4"
                  @setSelected.self='handleFilterTable'
              />
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>

import {Popover, PopoverButton, PopoverOverlay, PopoverPanel} from '@headlessui/vue'

import WorkFilterModelDropdown from "@p/works/index/components/WorkFilterModelDropdown";
import {mapGetters} from "vuex";

export default {
  name: 'TheWorkIndexSettingsComponent',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    PopoverOverlay,
    WorkFilterModelDropdown,
  },
  emits: ['update:arrayFilter'],
  props: {
    arrayFilter: { type: Array, required: false ,default:[] },
  },
  data() {
    return {
      filters: [
          { title: this.$t('global.today'), value: 'today', selected: false },
          { title: this.$t('global.tomorrow'), value: 'tomorrow', selected: false },
          { title: this.$t('global.day_after_tomorrow'), value: 'day_after_tomorrow', selected: false },
      ],
      status: [],
      filter: {}
    }
  },
  computed: {
    ...mapGetters({metadata: 'work/getWorkMetadata'}),
  },
  methods: {
    setFilter(index){
      this.filters[index].selected = !this.filters[index].selected
      this.$emit('filterChange', this.filters[index])
    },
    setOtherFilter(payload){
      if(this.status.includes(payload.id)) {
        this.status = this.status.filter(status => status !== payload.id)
        this.$emit('otherFilterChange', this.status)
      } else {
        this.status.push(payload.id)
        this.$emit('otherFilterChange', this.status)
      }

    },
    handleFilterTable({attribute, value}) {
      this.filter[attribute] = value
      this.$emit('customerFilter', this.filter)
    },
    resetFilter(attribute){
      this.filters.forEach(filter => filter.selected = false)
      this.status = []
      this.$emit('resetFilter')
    },
  },
}
</script>
