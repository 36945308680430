<template>
  <tr>
    <td class='border-b whitespace-nowrap'>
      <div class="form-check mt-2">
        <input id="checkbox-switch-2" class="form-check-input" type="checkbox" v-model='checkboxValue' @click='switchCheckboxValue'>
      </div>
    </td>
    <td class='border-b whitespace-nowrap'>

      <BaseInput :placeholder="$t('attributes.quantity')"
                 name='quantity'
                 v-model="item.quantity"
                 type="number"
                  :min="1"
                  :max="999999"
                 :disabled='!checkboxValue'
                 input-class='form-control w-24 text-xs py-1.5 px-2'
                 parseType='int'
                 />
    </td>
    <td class='border-b whitespace-nowrap'>
      <BaseInput :placeholder="$t('attributes.name')"
                 name='name'
                 v-model='item.name'
                 input-class='form-control w-full text-xs py-1.5 px-2'
                 :disabled='!checkboxValue'/>
    </td>
    <td class='border-b whitespace-nowrap'>

      <BaseButtonInput :placeholder="$t('attributes.price_excluding_vat')"
                 name='price_excluding_vat'
                 :value="item.price_excluding_vat"
                 type="text"
                 parse-type='int'
                 :min="0"
                 :max="999999"
                 :disabled='!checkboxValue'
                 icon='euro-sign'
                 input-class='form-control w-32 text-xs py-1.5 px-2'
                 :is-clickable="false"
                 parseType='float'
                 @change="getInputValue"/>
    </td>
    <td class='border-b whitespace-nowrap'>
      <BaseButtonInput :placeholder="$t('attributes.reduction')"
                 name='reduction'
                 :value='reduction'
                 type="number"
                 :min="0"
                 :max="100"
                 :disabled='!checkboxValue'
                 :is-clickable='false'
                 input-class='form-control w-24 text-xs py-1.5 px-2'
                 icon='percent'
                 parseType='float'
                 @change="getInputValue"/>
    </td>
  </tr>
</template>
<script>
// import BaseButtonInput from '@ui/form/input/BaseButtonInput'

export default {
  name: 'InvoiceItemRowComponent',
  // components: {  BaseButtonInput },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkboxValue: false,
      quantity: 1,
      reduction: 0,
    }
  },
  methods: {
    emitToParent(){
      const objectToPush = {
        item_id: this.item.id,
        name: this.item.name,
        price_excluding_vat: this.item.price_excluding_vat,
        quantity: this.quantity,
        reduction: this.reduction
      }
      this.$emit('changeInItem', objectToPush)
    },
    switchCheckboxValue() {
      this.checkboxValue = !this.checkboxValue
      const objectToPush = {
        item_id: this.item.id,
        name: this.item.name,
        price_excluding_vat: this.item.price_excluding_vat,
        quantity: this.quantity,
        reduction: this.reduction
      }
      this.$emit('selectItem', objectToPush)
    },
    getInputValue({attribute, value}){
      if(value){
        this.item[attribute] = value
        this.emitToParent()
      }
    },
    quantityChange({attribute, value}){
      if (value != null && value != undefined && value != ''){
        this.quantity = value
        this.emitToParent()
      }
    }
  },
}
</script>
<style scoped>

/* dialog */
dialog {
  position: fixed;
  top: 20vh;
  z-index: 1000;
  overflow: hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 577px) {
}

/* animations : usage */

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
