<template>
  <SettingsPageFull>
    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','landmark']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ pageTitle }}</span>
      </div>
    </template>
    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100'>
        <div v-if='editionMode'>
          <BaseButton :isElevated='true' icon='paper-plane' @click='submitForm' :title="buttonText"/>
        </div>
        <BaseButton v-if="this.$route.name !== 'settings_create_user' && this.$can('manage_content')"
                    color="warning"
                    :icon="editionMode ? 'times' : 'pen'"
                    @click="switchEditMode"
                    :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
        <BaseButton type="dark"
                    icon='long-arrow-left'
                    @click="this.$router.go(-1)"
                    :title="$t('button.back') "/>
      </div>
    </template>
    <template #body>
      <div class='space-y-8'>
        <BaseCardSection v-if='user' :sectionTitle="$t('global.general_information')">
          <template #default>
            <div class='grid grid-cols-12 gap-x-5'>
              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditInput :label="$t('attributes.first_name')"
                                   v-model="user.first_name"
                                   :errors="errors.first_name"
                                   :placeholder="$t('attributes.first_name')"
                                   :editionMode="editionMode"
                                   :required="true"/>
              </div>
              <div class='col-span-12 xl:col-span-6'>
                <BaseLanguageShowEditSelectSimple v-model='user.language'
                                                  :metadata='metadata'
                                                  :errors='errors'
                                                  :edition-mode="editionMode"/>
              </div>
              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditInput :label="$t('attributes.last_name')"
                                   v-model="user.last_name"
                                   :errors="errors.last_name"
                                   :placeholder="$t('attributes.last_name')"
                                   :editionMode="editionMode"
                                   :required="true"/>
              </div>
              <div class='col-span-12 xl:col-span-6'>
                <BaseCompanyShowEditSelectMultiple v-if='!isAuthCustomerUserOrNewCustomerUserView && metadata.companies'
                                                   v-model="user.companies_id"
                                                   :editionMode="editionMode"
                                                   :metadata="metadata"
                                                   :errors="errors"
                                                   :required="true"/>
              </div>
              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditInput :label="$t('attributes.username')"
                                   v-model="user.user_name"
                                   :errors="errors.user_name"
                                   :placeholder="$t('attributes.username')"
                                   :editionMode="editionMode"
                                   :required="true"/>
              </div>
              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditPhoneInput :label="$t('attributes.mobile')"
                                        :editionMode='editionMode'
                                        name="mobile"
                                        v-model:countryValue="user.mobile_country"
                                        v-model:numberValue="user.mobile_field"
                                        :errors="errors"
                                        :metadata="metadata"/>
              </div>
              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditInput :label="$t('attributes.email')"
                                   v-model="user.email"
                                   :errors="errors.email"
                                   :placeholder="$t('attributes.email')"
                                   :editionMode="editionMode"
                                   :required="true"/>
              </div>
              <div class='col-span-12 xl:col-span-6'>
                <BaseShowEditPhoneInput :label="$t('attributes.phone')"
                                        :editionMode='editionMode'
                                        name="phone"
                                        v-model:countryValue="user.phone_country"
                                        v-model:numberValue="user.phone_field"
                                        :errors="errors"
                                        @change.self="getInputValue"
                                        :metadata="metadata"/>
              </div>
              <div id='scroll_entry_date' class='col-span-12 xl:col-span-6'>
                <BaseShowEditDatePicker :editionMode='editionMode'
                                        label="date d'entrée"
                                        v-model='user.entry_date'
                                        class='z-10'
                                        name='entry_date'
                                        @click='scrollToDateFrom'
                                        :errors='errors.entry_date'/>
              </div>
              <div id='scroll_exit_date' class='col-span-12 xl:col-span-6'>
                  <BaseShowEditDatePicker :editionMode='editionMode'
                                          label="date de sortie"
                                          v-model='user.exit_date'
                                          class='z-10'
                                          name='exit_date'
                                          @click='scrollToDateFrom'
                                          :errors='errors.exit_date'/>
              </div>
              <div class='col-span-12 xl:col-span-6' v-if='editionMode'>
                <div class="mt-6">
                  <BaseButton v-if="this.$route.name === 'settings_edit_user_route'"
                              :type="editPasswordType" :icon='editPasswordIcon'
                              @click='this.editPassword = !this.editPassword'
                              :title="$t(editPasswordText, { var: $t('global.user')})"/>
                </div>
                <div class="" v-if="editPassword">
                  <BaseShowEditInput :label="$t('attributes.password')"
                                     v-model="user.password"
                                     :errors="errors.password"
                                     :placeholder="$t('attributes.password')"
                                     :editionMode="editionMode"
                                     :required="true"/>
                  <BaseShowEditInput :label="$t('attributes.password_confirmation')"
                                     v-model="user.password_confirmation"
                                     :errors="errors.password_confirmation"
                                     :placeholder="$t('attributes.password_confirmation')"
                                     :editionMode="editionMode"
                                     :required="true"/>
                </div>
              </div>
            </div>
          </template>
        </BaseCardSection>
        <BaseCardSection v-if='!isAuthCustomerUserOrNewCustomerUserView && user.roles'
                         :sectionTitle="$t('global.roles')">
          <template #default>
            <BaseRadioGroup :key='user'
                            groupClass="w-1/3 space-x-2 mt-6 "
                            :options="metadata.roles"
                            :value="cUserRoles"
                            :disabled="this.$route.name === 'settings_create_user' ? false : !editionMode"
                            name="roles"
                            @change.self="getInputValue"/>

            <div v-if='errors.roles' v-for='(error, index) in errors.roles' :key='index'
                 class="form-help text-red-600 mt-2">
              {{ $t(`${error}`, {'attribute': $t('attributes.roles')}) }}
            </div>
          </template>
        </BaseCardSection>
        <BaseCardSection v-if='!isAuthCustomerUserOrNewCustomerUserView' :sectionTitle="$t('global.permissions')">
          <template #default>
            <UserPermissionTable @change.self="setPermissions" :editTable="editionMode" :key='editionMode'/>
          </template>
        </BaseCardSection>
      </div>
      <!--      <div class='flex justify-end' v-if='editionMode'>-->
      <!--        <BaseButton :isElevated='true' icon='paper-plane' @click='submitForm' :title="buttonText"/>-->
      <!--      </div>-->
    </template>
  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import UserPermissionTable from './components/UserPermissionTable'

export default {
  name: 'TheUserFormPage',
  components: {
    UserPermissionTable,
  },
  data() {
    return {
      editionMode: false,
      flag: null,
      editPassword: false,
      selectedPermissions: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
      metadata: 'user/getUserMetadata',
      errors: 'auth/getErrors',
      authRole: 'auth/getAuthRole'
    }),
    cUserRoles() {
      return this.user.roles ? this.user.roles[0] : ''
    },
    pageTitle() {
      if (this.user.first_name) {
        return `${this.user.last_name} ${this.user.first_name}`
      } else {
        switch (this.$route.name) {
          case 'settings_create_user':
            return this.$t('button.add_new_m', {var: this.$t('global.user')})
            break
          case 'settings_create_customer_user_route':
            return this.$t('button.add_new_m', {var: this.$t('global.customer')})
            break
        }
      }
      return `${this.user.first_name} ${this.user.last_name}`
    },
    buttonText() {
      switch (this.$route.name) {
        case 'settings_edit_user_route':
          return this.user.customer_id ? this.$t('button.edit_new_mv', {var: this.$t('global.user')}) : this.$t('button.edit_new_mv', {var: this.$t('global.user')})
          break
        case 'settings_create_user':
          return this.$t('button.add_new_m', {var: this.$t('global.user')})
          break
        case'settings_create_customer_user_route':
          return this.$t('button.add_new_m', {var: this.$t('global.user')})
          break
      }
      return this.$route.name === 'settings_create_user' ? 'button.add_new_m' : 'button.edit_new_mv'
    },
    isAuthCustomerUserOrNewCustomerUserView() {
      return this.user.customer_id || this.$route.name === 'settings_create_customer_user_route'
    },
    editPasswordText() {
      return !this.editPassword ? 'modifier le mot de passe' : 'annuler la modification'
    },
    editPasswordIcon() {
      return !this.editPassword ? 'unlock' : 'times'
    },
    editPasswordType() {
      return !this.editPassword ? 'warning' : 'secondary'
    }
  },
  methods: {
    ...mapActions({
      fetchNewUser: 'user/fetchNewUser',
      fetchUserByID: 'user/fetchUserByID',
      postNewUser: 'user/storeNewUser',
      updateUser: 'user/updateUser'
    }),
    getInputValue({attribute, value}) {
      this.user[attribute] = value
    },
    switchEditMode() {
      this.editionMode === true ? this.fetchUserByID({id: this.$route.params.id}) : {}
      this.editionMode = !this.editionMode
    },
    scrollToDateFrom() {
      this.$route.name === 'planning_page_route' ? document.getElementById('scroll_date_from').scrollIntoView() : {}
    },
    submitForm() {
      switch (this.$route.name) {
        case 'settings_edit_user_route':
          if (this.user.customer_id) {
            this.updateUser({
              user: this.user, id: this.$route.params.id,
              successCallback: () => {
                this.$h.toastify(this.$t('toasts.updated_m', {var: this.$t('global.customer')}), {className: 'toastify-content success'})
                this.editionMode = false
              },
            })
          } else {
            this.updateUser({
              user: this.user, id: this.$route.params.id,
              successCallback: () => {
                this.$h.toastify(this.$t('toasts.updated_m', {var: this.$t('global.user')}), {className: 'toastify-content success'})
                this.editionMode = false
              },
            })
          }
          break
        case 'settings_create_user':
          this.postNewUser({
            user: this.user,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.created_m', {var: this.$t('global.user')}), {className: 'toastify-content success'})
              this.editionMode = false
            }
          })
          break
        case 'settings_create_customer_user_route':
          this.postNewUser({
            user: this.user,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.created_m', {var: this.$t('global.customer')}), {className: 'toastify-content success'})
              this.editionMode = false
            }
          })
          break
      }
    },
    setPermissions({attribute, value}) {
      this.selectedPermissions[attribute] = value
    }
  },
  watch: {
    selectedPermissions(newValue) {
      for (const permissionType in newValue) {
        if (newValue[permissionType] === []) {
          delete newValue[permissionType]
        }
      }
    }
  },
  async beforeMount() {
    switch (this.$route.name) {
      case 'settings_edit_user_route':
        await this.fetchUserByID({id: this.$route.params.id})
        break
      case 'settings_create_user':
        await this.fetchNewUser()
        break
      case 'settings_create_customer_user_route':
        await this.fetchNewUser()
        this.user.customer_id = this.$route.params.id
        this.user.roles = ['customer-user']
        break
    }
  },
  mounted() {
    switch (this.$route.name) {
      case 'settings_edit_user_route':
        this.editPassword = false
        break
      case 'settings_create_user':
        this.editPassword = true
        this.editionMode = true
        break
      case 'settings_create_customer_user_route':
        this.editPassword = true
        this.editionMode = true
        break
    }
  }
}
</script>
