export default {
  navigation: {
    login_title: 'inloggen',
    work_title: 'bestanden',
    work_new: 'nieuw bestand',
    work_progress: 'lopenden',
    work_archived: 'gearchiveerde bestanden',
    agenda_title: 'agenda',
    planning_title: 'planning',
    reselling_title: 'verkooppunt',
    invoicing_title: 'facturatie',
    stock_title: 'opslag',
    settings_title: 'instellingen',
    components_title: 'components',

    content_title: 'content',
    content_user_title: 'gebruikers',
    content_company_title: 'firmas',
    content_company_new_title: 'firma toevoegen',
    content_company_edit_title: 'firma wijzigen',
    content_customer_title: 'klanten',
    content_customer_new_title: 'klant toevoegen',
    content_customer_edit_title: 'klant wijzigen',
    content_implantation_title: 'plaats',
    content_implantation_new_title: 'plaats toevoegen',
    content_implantation_edit_title: 'plaats wijzigen',
    content_item_title: 'artikelen',

  }
}
