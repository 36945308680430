
<template>
  <BasePageFull>

    <!--#region header -->
    <template #cta>
      <BaseButton
          type="dark"
          icon='long-arrow-left'
          @click="this.$router.push({name: 'works'})"
          :title="$t('button.back') "/>
    </template>
    <!--#endregion -->

    <!--#region settings -->
    <template #body>

      <div v-if='work' class='col-span-3 flex flex-col gap-y-4'>
        <TheWorkNav/>
        <WorkPreviewEvent v-for="(event, index) in cEventsorted" :key="index" :event='event'/>
      </div>

      <div v-if='work' class='col-span-9'>
        <router-view @refreshWork="handleRefreshWork"></router-view>
      </div>

    </template>
    <!--#endregion -->

  </BasePageFull>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import moment from 'moment'

import TheWorkNav from '@p/works/form/sideBar/nav/TheWorkNav'
import WorkPreviewEvent from '@/components/pages/works/form/sideBar/events/WorkPreviewEvent'

export default {
  name: 'TheWorkFormEditPage',
  components: { TheWorkNav, WorkPreviewEvent },
  data() {
    return { pageTitle: null, }
  },
  computed: {
    ...mapGetters({work: 'work/getWork'}),
    cEventsorted() {
      let sorted_events = this.work?.events ? [...this.work.events] : []
      sorted_events = sorted_events.sort((a, b) => Date.parse(moment(a.date_from + ' ' + a.time_from, 'DD/MM/YYYY HH:mm')) - Date.parse(moment(b.date_from + ' ' + b.time_from, 'DD/MM/YYYY HH:mm')))
      return sorted_events
    }
  },
  methods: {
    ...mapActions({fetchWorkByID: 'work/fetchWorkByID'}),
    handleRefreshWork() {
      this.fetchWorkByID(this.$route.params.id)
    }
  },
  beforeMount() {
    this.fetchWorkByID(this.$route.params.id)
  }
}
</script>
