<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','ticket']" size='2x' />
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.content_item_title') }}</span>
      </div>
    </template>

    <template #cta>
      <div class="flex gap-x-3">
        <BaseButton
          v-if="!$can('manage-settings')"
          color='primary'
          icon='plus'
          @click=" this.$router.push({name: 'settings_create_item_route'})"
          :title="$t('button.add_new_m', {var: $t('global.item')})"
        />
        <BaseButton
          icon="arrow-square-down"
          :title="$t('global.export_items')"
          @click="submitExportForm"
        />
      </div>
    </template>

    <template #body>
      <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.item')}) "/>
      <BaseContentTable
        v-if='metadata.columns'
        :columns='metadata.columns'
        :modelList='itemsList'
        :search='true'
        @rowClick='redirectToModelPage'
        @refreshClick='this.fetchAllItem'
        @deleteModel='deleteItem'
        :isHoverable='true' />
    </template>

  </SettingsPageFull>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheItemIndexPage',
  computed: {
    ...mapGetters({
      itemsList: 'item/getItemList',
      metadata: 'item/getItemMetadata'
    })
  },
  methods: {
    ...mapActions({
      fetchAllItem: 'item/fetchAllItem',
      deleteItemById: 'item/deleteItemById',
      undoDeleteItemById: 'item/undoDeleteItemById',
      processFetchItemsExportAction: 'item/fetchItemsExport'
    }),
    redirectToModelPage(id) {
      this.$router.push({ name: 'settings_edit_item_route', params: { id: id } })
    },
    deleteItem(id) {
      this.deleteItemById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-settings").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteItem(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteItem(id){
      this.undoDeleteItemById({
        id: id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_m', {var: this.$t('attributes.item')})  , {className: 'toastify-content success'})
        }
      })
    },
    submitExportForm(){
      console.log('export')
      this.processFetchItemsExportAction()
    },
  },
  beforeMount() {
    this.fetchAllItem()
  }
}
</script>

