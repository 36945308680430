import apiClient from '@u/apiClient'

export default {

    //region fetch
    async fetchDocumentsList({commit}) {
        await apiClient.get('api/documents')
            .then(async response => {
                await commit('setDocumentsList', response.data.documents)
            })
            .catch(async reason => {
                console.error('fetchDocumentsList', reason)
            })
    },
    async fetchDocument({commit}, payload) {
        await apiClient.get('api/document/' + payload.document_id)
            .then(async response => {
                await commit('setDocument', response.data.document)
            })
            .catch(async reason => {
                console.error('fetchDocument', reason)
            })
    },
    //endregion

    //region save as attachment
    async saveDocumentAsAttachment({commit}, payload) {
        await apiClient.get(`api/${payload.url}`)
            .then(async response => {
                if (payload.successCallback) {
                    payload.successCallback()
                }
            })
            .catch(async reason => {
                console.error('saveDocumentAsAttachment', reason)
            })
    },
    //endregion

}
