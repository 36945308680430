<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="modal-content p-0 max-h-screen flex flex-col" :class="modalClasses" open v-if="isOpen">
        <!--#region header -->
        <div class='flex p-5 items-center'>
          <div class='w-2/5 text-2xl font-bold'>
            {{  titleNoteForm }}
          </div>
          <div class='w-3/5 flex justify-end cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg'/>
          </div>
        </div>
        <!--#endregion -->
        <section class="flex-1 modal-body overflow-scroll pt-0">

        <div class="space-y-6 mb-8 px-12 sm:space-y-5 ">
          <div class="flex justify-around">
              <span class="block w-full mr-1">
                <BaseSimpleDatePicker :key="cNoteDate"
                                      :label="$t('attributes.date')"
                                      :defaultValue="cNoteDate"
                                      :disabled='false'
                                      :required='true'
                                      name='date'
                                      :errors='errors.date'
                                      @change='getInputValue'/>
              </span>

              <span class="block w-full ml-1">
                <BaseTimeInput
                  :key="note.time"
                  :label="$t('attributes.duration')"
                  placeholder="00:00"
                  name="time"
                  required="true"
                  :value="note.time"
                  :errors="errors.time"
                  :get-input-value="getInputValue"
                />

              </span>
          </div>

          <BaseInput
            :label="$t('attributes.title')"
            :placeholder="$t('attributes.title')"
            name="title"
            :required="true"
            v-model="note.title"
            :errors="errors.title"
           />

          <BaseSelectSimpleAjax
            :key="note"
            url='api/work-for-select'
            :label="$t('global.work')"
            name="work_id"
            v-model="note.work_id"
            trackBy="id"
            attributeLabel="title"
            :errors="errors.work_id"
            :default-ajax="computedWork"
            @setSelected="getInputValue"
          />

          <BaseSelectSimple
            :v-if="metadata.note_types"
            :key="note.type"
            :label="$t('attributes.type')"
            :placeholder="$t('attributes.type')"
            name="type"
            :options="metadata.note_types"
            :defaultValue="this.metadata.note_types.find(type => type.id === note.type)"
            :errors="errors.type"
            :required="true"
            @setSelected="getInputValue"/>
<!--          -->
          <BaseWysiwyg
            :label="$t('attributes.description')"
            v-model="note.description"
            :value="note.description"
            name="description"
            :required="true"
            :errors="errors.description"
            @change.self="getInputValue"
          />
<!--          -->
          <div class="flex justify-center ">
            <BaseButton color='primary'
                        icon='paper-plane'
                        :title="buttonText"
                        @click="submitNote"
            />
          </div>
        </div>
        </section>
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isOpen"></div>
    </transition>
  </teleport>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from 'moment'

export default {
  name: "NoteFormDialog",
  props: {
    isOpen:{
      type:Boolean,
      required: true,
    },
    note_load_id: {
      required: false
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'x-large',
      validator: function (value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPulsing: false,
      editor: null,
    }
  },
  computed:{
    ...mapGetters({
      auth: "auth/getAuth",
      note: "note/getNote",
      metadata: "note/getNoteMetadata",
      errors:'auth/getErrors'
    }),
    cNoteDate(){
      return this.note.date ? new Date(moment(this.note.date, 'DD/MM/YYYY')) : null
    },
    buttonText(){
      if(isNaN(this.note_load_id)){
        return this.$t('button.add', {var: ''})
      }
      return this.$t('button.edit', {var: ''})
    },
    titleNoteForm(){
      if(isNaN(this.note_load_id)){
        return this.$t('global.add_note')
      }
      return this.$t('button.edit', {var: this.$t('global.note')})
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    },
    computedWork() {
      return this.note && this.note.work_id && this.note.work? {
        id: this.note.work_id,
        title: `${this.note.work.title} (${this.note.work.reference})`
      } : {}
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    },
  },
  methods: {
    ...mapActions({
      fetchNewNote: 'note/fetchNewNote',
      fetchNoteById: 'note/fetchNoteById',
      addNote: 'note/storeNote',
      editNote: 'note/updateNote',
    }),
    getInputValue({attribute, value}) {
      this.note[attribute] = value;
    },
    submitNote() {
      if(!isNaN(this.note_load_id)) {
        this.editNote({note: this.note, successCallback: this._close})
      } else {
        this.note.reporter_id = this.auth.id;
        this.addNote({note: this.note, successCallback: this._close});
      }
    },
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
  },
  watch: {
    isPulsing: function (currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    },
    isOpen(newValue, oldValue){
        if(newValue && newValue !== oldValue) {
            if(!isNaN(this.note_load_id)) {
              this.fetchNoteById({id: this.note_load_id});
            } else {
              this.fetchNewNote()
            }
        }
    },
  }
}
</script>

<style scoped>
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 577px) {
  dialog.small {
    width: 20%;
    max-width: 300px;
    scroll-behavior: auto;
  }

  dialog.medium {
    width: 30%;
    max-width: 500px;
  }

  dialog.large {
    width: 50%;
    max-width: 700px;
  }

  dialog.x-large {
    width: 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active {
  animation: modal 0.3s ease-in;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active {
  animation: backdrop 0.3s ease-in;
}

.backdrop-leave-active {
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation: pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
