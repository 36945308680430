import getters from './getters'
import actions from './actions'
import mutations from './mutations'


export default {
  namespaced: true,
  state() {
    return {
      collapsed: localStorage.getItem('collapsed') || false,
      menu: [
        {
          icon: 'folder-open',
          pageName: 'works',
          title: 'work_title',
          subMenu: [
            {
              icon: 'folder-plus',
              pageName: 'work_form_new_route',
              title: 'work_new'
            },
            {
              icon: 'folder-open',
              pageName: 'work_progress_route',
              title: 'work_progress',
              meta: {
                breadcrumb: [{ title: 'Content', active: false }, { title: 'Company', active: true }]
              }
            },
            {
              icon: 'archive',
              pageName: 'work_archived_route',
              title: 'work_archived',
              props: true,
              meta: { breadcrumb: [{ title: 'Content', active: false }, { title: 'Customer', active: true }] }
            }
          ]
        },
        {
          icon: 'database',
          pageName: 'urn_index_route',
          title: 'urn_title',
          gate: 'see_planning',
        },
       {
          icon: 'calendar-day',
          pageName: 'planning_page_route',
          title: 'planning_title',
          gate: 'see_planning',
        },
        {
          icon: 'file-invoice-dollar',
          pageName: 'invoicing_page_route',
          title: 'invoicing_title_main',
          gate: 'see_invoicing',
          meta:{open_for_customer : true}
        },
        {
          icon: 'book-open',
          pageName: 'note_index_route',
          title: 'note',
          gate: 'see_content',
        }, {
          icon: 'analytics',
          pageName: 'stats_index_route',
          title: 'stats',
          gate: 'see_content',
        },
        {
          icon: 'cogs',
          pageName: 'settings_list_user_route',
          title: 'settings',
          gate: 'see_content',
        },
      ]
    }
  },
  mutations,
  actions,
  getters
}
