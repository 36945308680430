<template>

  <base-card-section :section-title="$t('enums.event.type.CRE')">
    <div class='flex flex-col space-y-5'>
      <BaseSimpleRadioGroup name="cremation"
                            :defaultValue="false"
                            :isShowingKey='false'
                            :isReturningKey='true'
                            :options="yes_no_options"
                            translationExtention="enums.yes_no"
                            @change.self="getCremationValue" />

      <SelectCremaType v-model='selectedCremaType' :metadata='metadata' v-if="withCremation" />

      <SelectEventTimeslot v-if='is_plannifiable && withCremation && eventCRE'
                           class="my-5"
                           :asset="eventCRE.asset_id ? eventCRE.asset_id : null"
                           :type="eventCRE.type"
                           :eventKind="eventCRE.event_kind_id"
                           :options='eventCRE.timeslots'
                           :index='this.work.events.indexOf(this.eventCRE)'
                           v-model='selectedEventTimeslot'/>
    </div>
    <div v-if='errors.events' v-for='(index) in errors.events' :key='index' class="form-help text-red-600">
      {{ $t('validation.give_valid', {value: $t('global.timeslot')}) }}
    </div>

  </base-card-section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from "moment";

import SelectCremaType from './SelectCremaType'
import SelectEventTimeslot from '@p/components/Event/SelectEventTimeslot'

export default {
  name: 'SelectCremationForm',
  components: {
    SelectCremaType,
    SelectEventTimeslot,
  },
  data() {
    return {
      is_plannifiable: false,
      selectedCremaTimeslot: null,
      selectedCremaType: null,
      selectedEventTimeslot: null,
      withCremation:false,
      yes_no_options: { true: true , false: false},
    }
  },
  computed: {
    ...mapGetters({work: 'work/getWork', metadata: 'work/getWorkMetadata', errors: 'auth/getErrors' }),

    eventCRE(){
      return this.work.events && this.work.events.filter(event => event.type === 'CRE')[0]
    },
  },
  methods: {
    ...mapActions({fetchEventDisponbilitiesByOption: 'fetchEventDisponbilitiesByOption'}),
    ...mapMutations({
      alterOrPushEventInWork: 'work/alterOrPushEventInWork',
      alterWorkDeceased: 'work/alterWorkDeceased',
      removeEventFromWork: 'work/removeEventFromWork',
      alterWorkEventValuesByEventType: 'work/alterWorkEventValuesByEventType',
      setWorkFieldFromDotNotation: 'work/setWorkFieldFromDotNotation',
    }),

    getCremationValue({value}){
      this.withCremation = value === 'true';
    },
    HandleCremaSelect() {
        this.fetchEventDisponbilitiesByOption({ params: {
          asset_id: this.eventCRE?.asset_id ? this.eventCRE.asset_id : null,
          type: this.eventCRE?.type,
            week_from: moment().locale('fr').week(),
            year: moment().format('YYYY'),
          event_kind_id: this.eventCRE.event_kind_id ? this.eventCRE.event_kind_id : null
          }, index: this.work.events.indexOf(this.eventCRE)
        })
    },
  },
  watch: {
    selectedCremaTimeslot(newValue) {
      if (newValue) {
        this.alterOrPushEventInWork({
          type: 'CRE',
          date_from: moment(newValue.datetime_from).locale('fr').format('DD/MM/Y'),
          date_to: moment(newValue.datetime_from).locale('fr').format('DD/MM/Y'),
          time_from: newValue.string_from,
          time_to: newValue.string_to,
          asset_id: newValue.asset.id,
          event_kind_id: this.selectedCremaType,
        });

      } else {
        this.removeEventFromWork({type: 'CRE'})
      }
    },
    selectedCremaType(newValue, oldValue) {
      let event_kind = this.metadata.event_kinds.find(item => item.id == newValue)
      if (newValue !== oldValue) {
        this.removeEventFromWork({type: 'CRE',event_kind_id:oldValue})

        this.alterOrPushEventInWork({
          type: 'CRE',
          event_kind_id: event_kind.id,
        })

        this.HandleCremaSelect()

        this.is_plannifiable = this.metadata?.event_kinds?.filter(item => item.id == newValue)[0]['planifiable']

        if (event_kind.form_callback) {
          this.setWorkFieldFromDotNotation(event_kind.form_callback)
        }

        this.$emit('cremaTypeSelected',newValue)

        if(newValue) {
          this.work.deceased.burial_type = 'ASR'
          // alterOrPushEventInWork
          this.alterWorkEventValuesByEventType({
            type: this.work.deceased.burial_type,
            attribute: 'type',
            value: this.work.deceased.burial_type
          })

          this.alterWorkEventValuesByEventType({
            type: 'CON',
            attribute: 'type',
            value: 'CON'
          })
        }
      }
    },
    selectedEventTimeslot(newValue, oldValue){
      if(newValue !== oldValue && newValue !== null){
        this.alterWorkEventValuesByEventType({type: 'CRE', attribute: 'date_from', value: newValue.string_date})
        this.alterWorkEventValuesByEventType({type: 'CRE', attribute: 'date_to', value: newValue.string_date})
        this.alterWorkEventValuesByEventType({type: 'CRE', attribute: 'time_from', value: newValue.string_from})
        this.alterWorkEventValuesByEventType({type: 'CRE', attribute: 'time_to', value: newValue.string_to})
        this.alterWorkEventValuesByEventType({type: 'CRE', attribute: 'asset_id', value: newValue.asset.id})
      }
    }
  },
}
</script>
