<template>
  <BasePageFull>
    <template #body>
      <div class='col-span-12'>
        <div class='fixed right-10 bottom-10 z-100 '>
          <filter-planning
            v-model:event-type='selected_event_type'
            v-model:assets='selected_asset'
            :assetList='cAssetList'
            :eventTypeList='cEventTypeList'
          />
        </div>
        <BaseSimpleCard classes='col-start-2 col-span-10 max-h-full h-full' title="">
          <template #default>
            <FullCalendar :options="config" ref="calendar"/>
          </template>
        </BaseSimpleCard>
      </div>
    </template>
  </BasePageFull>


  <!--#region dialog -->
  <EventFormDialog ref="eventModal"
                   :isModalVisible="isModalVisible['event_form']"
                   size="x-large"
                   @close="closeAllModals"
                   @backdropClicked="closeAllModals"
                   @refetchCalendarEvents="refetchEvents"
  />
  <!--#endregion -->
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import EventFormDialog from './components/dialogs/EventFormDialog'

import '@fullcalendar/core/vdom' // solves problem with Vite
import frLocale from '@fullcalendar/core/locales/fr'
import nlLocale from '@fullcalendar/core/locales/nl'
import enLocale from '@fullcalendar/core/locales/en-gb'

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import resourceTimeLinePlugin from '@fullcalendar/resource-timeline'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import moment from 'moment'
import FilterPlanning from './components/FilterPlanning'
var refreshTimeout = {}
export default {
  name: 'PlanningPage',
  components: {
    FilterPlanning,
    FullCalendar,
    EventFormDialog
  },
  data() {
    return {
      isModalVisible: {
        event_form: false,
      },
      eventId: null,
      eventTime: '',
      resource_id: null,
      start: '',
      end: '',
      selected_event_type: [],
      selected_asset: [],
      calendar_state :{}
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getAuth',
      events: 'getEvents',
      metadata: 'getPlanningMetadata'
    }),
    cAssetList(){
      return this.metadata.asset_list || []
    },
    cEventTypeList(){
      return this.metadata.event_type_list || []
    },
    cFilteredEvents(){
      return this.events.reduce((finalArray, item) => {
        if ( !Array.isArray(this.selected_event_type) || this.selected_event_type.length <= 0 || this.selected_event_type.includes(item.extendedProps.type)) {
          finalArray.push(item);
        }
        return finalArray
      }, []);

    },
    cFilteredAsset() { // resource envoyée dans fullcalendar

      return this.cAssetList.reduce((finalArray, item) => {
        if ( !Array.isArray(this.selected_asset) || this.selected_asset.length <= 0 || this.selected_asset.includes(item.id)) {
          finalArray.push({
            label: item.name,
            id: item.id,
            title: item.name,
            type: this.$t('enums.asset.type.' + item.type)
          });
        }
        return finalArray
      }, []);

    },
    config() {
      return {
        ...this.calendarOptions,
        ...this.eventHandler
      }
    },
    calendarOptions() {
      return {
        locale: this.getFullcalendarLocal,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'resourceTimelineDay,resourceTimelineWeek,dayGridMonth,timeGridWeek,timeGridDay'
        },
        views: {
          resourceTimelineDay: {
            type: 'resourceTimelineDay',
            duration: {days: 1},
            buttonText: 'Planning jour',
            titleFormat: {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              weekday: 'long'
            }
          },
          resourceTimelineWeek: {
            type: 'resourceTimelineWeek',
            duration: {days: 5},
            buttonText: 'Planning semaine',
            titleFormat: {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              weekday: 'long'
            }
          },
          timeGridWeek: {
            type: 'timeGridWeek',
            minTime: '07:00:00',
            maxTime: '21:00:00',
            buttonText: 'Semaine',
            titleFormat: {
              month: 'long',
              year: 'numeric',
              day: 'numeric',
              weekday: 'long'
            }
          }
        },
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, resourceTimeLinePlugin],
        initialView: 'timeGridWeek',
        resourceAreaWidth: '15%',
        height: '85vh',
        nowIndicator: true,
        firstDay: '1',
        slotMinTime: '07:00:00',
        slotMaxTime: '23:00:00',
        slotDuration: '00:15:00',
        editable: true,
        selectable: true,
        droppable: true,
        resourceGroupField: 'type',
        resources: this.cFilteredAsset,
        events: this.cFilteredEvents,
        eventContent: function (arg) {

          var event = arg.event;

          var customHtml = '';

          customHtml += '<span class="text-xs capitalize-first"> '+event.title+'</span>' ;

          if(event.extendedProps.customer_name){
                    customHtml += "<br/><span style='font-size: 0.6rem'> " + event.extendedProps.customer_name +"</span>";
          }


          return { html: customHtml }
        },

        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives'
      }
    },
    eventHandler() {
      return {
        datesSet: this.handleDateRangeChange,
        dateClick: this.handleDateClick,
        select: this.handleDateSelect,

        eventClick: this.handleEventClick,
        eventDrop: this.handleEventDrop,
        eventResize: this.handleEventDrop,

        viewDidMount: this.handleViewRender
      }
    },
    eventRender(info,e){
    },
    getFullcalendarLocal() {
      switch (this.$i18n.locale) {
        case 'nl_BE':
          return nlLocale
        case 'fr_BE':
        case 'fr_FR':
          return frLocale
        default:
          return enLocale
      }

    },

  },
  methods: {
    ...mapActions(['fetchEvents', 'fetchPlanningMetadata', 'updateEventByDrag']),
    ...mapMutations({collapse: 'sideMenu/collapse'}),
    refetchEvents(){
      this.fetchEvents(this.calendar_state)
    },
    handleDateRangeChange(event) {
      this.calendar_state = event
      if (moment(event.startStr).format('DD/MM/YYYY') !== this.start) {
        this.start = moment(event.startStr).format('DD/MM/YYYY')
        this.fetchEvents(this.calendar_state)
      }
    },
    handleDateSelect(event) {
      this.eventTime = `${event.startStr} _ ${event.endStr}`
      this.resource_id = event.resource ? {id:event.resource.id,name:event.resource.title} : null
      this.eventId = null
      this.openModal("event_form")
    },
    handleDateClick(event) {
      this.eventTime = event.dateStr
      this.resource_id = event.resource ? event.resource.id : null
      this.eventId = null
      this.openModal("event_form")
    },
    handleEventClick({ event }) {
      console.log(event)
      this.eventId = event.id
      this.resource_id = null
      this.eventTime = ''
      this.openModal("event_form")
    },
    handleEventDrop(event) {
      this.updateEventByDrag(event)
    },

    openModal(property) {
      this.isModalVisible[property] = true
      this.$refs['eventModal'].loadEvent(this.eventId,this.eventTime,this.resource_id)

    },
    closeAllModals() {
      for (const property in this.isModalVisible) {
        this.isModalVisible[property] = false
      }
    },
    refreshEvents() {
      var v = this;
     refreshTimeout =  setTimeout(()=>{
        v.fetchEvents(v.calendar_state)
        v.refreshEvents()
      }, 30000)
    }
  },
  beforeMount() {
    this.fetchPlanningMetadata()
    this.collapse()
  },
  mounted(){
    this.refreshEvents()
  },
  unmounted() {
    clearTimeout(refreshTimeout)
  }


}
</script>

<style>
.fc .fc-event{
  overflow: hidden;
}

/*.fc-header-toolbar{*/
/*  position: sticky;*/
/*  z-index: 100;*/
/*  top: 0;*/
/*  !*background-color: rebeccapurple;*!*/
/*}*/
/*.fc-col-header {*/
/*  position: sticky;*/
/*  z-index: 100;*/
/*  top: 555px !important;*/
/*  background-color: green;*/
/*}*/
</style>
