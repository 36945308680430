<template>
  <div>
    <BaseCardSection>
      <template #default>
        <div class='grid grid-cols-12 gap-x-5'>
          <div class='col-span-12 xl:col-span-6'>
            <BaseShowEditSelectSimple v-if="metadata?.work_types"
                                      :label="$t('attributes.work_type')"
                                      :placeholder="$t('attributes.work_type')"
                                      name="work_type_id"
                                      :editionMode='editionMode'
                                      v-model='work.work_type_id'
                                      :defaultValue="metadata?.work_types?.filter(el => el.id === work.work_type_id)"
                                      :options="metadata?.work_types"
                                      :errors="errors?.work_type_id"/>
          </div>
          <div class='col-span-12 xl:col-span-6'>
          </div>
          <div class='col-span-12 xl:col-span-6'>
            <BaseShowEditInput :label="$t('attributes.work_title')"
                               v-model="work.title"
                               :errors="errors.title"
                               :placeholder="$t('attributes.work_title')"
                               :editionMode="editionMode"/>
          </div>
          <div class='col-span-12 xl:col-span-6'>
            <BaseShowEditInput :label="$t('attributes.cremation_number')"
                               v-model="work.reference"
                               :errors="errors.reference"
                               :placeholder="$t('attributes.cremation_number')"
                               :editionMode="editionMode"/>
          </div>
          <div class='col-span-12 xl:col-span-6'>
            <BaseShowEditInput :label="$t('attributes.death_certificate_number')"
                               v-model="work.death_certificate_number"
                               :errors="errors.death_certificate_number"
                               :placeholder="$t('attributes.death_certificate_number')"
                               :editionMode="editionMode"/>
          </div>
          <div class='col-span-12 xl:col-span-6'>
            <BaseShowEditInput :label="$t('attributes.cremation_authorization_number')"
                               v-model="work.cremation_authorization_number"
                               :errors="errors.cremation_authorization_number"
                               :placeholder="$t('attributes.cremation_authorization_number')"
                               :editionMode="editionMode"/>
          </div>
          <div class='col-span-12 xl:col-span-6'>
<!--            <BaseUserShowEditSelectSimple v-model='work.responsable_id'-->
<!--                                          name='responsable_id'-->
<!--                                          :label="$t('attributes.responsable')"-->
<!--                                          :metadata='metadata'-->
<!--                                          :errors='errors'-->
<!--                                          :edition-mode='editionMode'/>-->
            <BaseShowEditFilteredUsersComponent
              :label="$t('global.responsible')"
              v-model='work.responsable_id'
              :editionMode='editionMode'
              name='responsable_id'
              :metadata='metadata'
              :errors='errors'
            />
          </div>
          <div class='col-span-12 xl:col-span-6'>
            <BaseCustomerShowEditSelectSimple v-model='work.customer_id'
                                              :metadata='metadata'
                                              :errors='errors'
                                              :edition-mode='editionMode'
                                              :required="true"/>
          </div>
          <div class='col-span-12 xl:col-span-6'>
            <BaseCompanyShowEditSelectSimple v-model='work.company_id'
                                              :metadata='metadata'
                                              :errors='errors'
                                              :edition-mode='editionMode'
                                              :required="true"/>
          </div>
        </div>
      </template>
    </BaseCardSection>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseShowEditFilteredUsersComponent from "@c/GlobalFormComponents/BaseShowEditFilterdUsersComponent.vue";

export default {
  name: 'WorkFormInformations',
  components: {BaseShowEditFilteredUsersComponent},
  props: {
    editionMode: { type: Boolean, required: true },
  },
  computed: {
    ...mapGetters({work: 'work/getWork', errors: 'auth/getErrors', metadata: 'work/getWorkMetadata', authRole: 'auth/getAuthRole'}),
    computedForDisabled() {
      return !this.$can('manage_work') || (this.$can('manage_work') && this.$route.name === 'work_form_edit_route' && this.authRole === 'customer-user')
    }
  },
}
</script>

