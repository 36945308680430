<template>
  <div class='grid grid-cols-2 gap-x-5' v-if="worksEventOfTypeAsr">

    <div class='col-start-1'>
        <BaseShowEditRadioGroup name='operator_type'
                                :label="$t('attributes.operator_type')"
                                :options='metadata?.events?.operator_type'
                                v-model="worksEventOfTypeAsr.operator_type"
                                :editionMode="editionMode"
                                :translatable="true"
                                :isReturningKey="true"
                                :isShowingKey="true"
                                :getByKey="true"
                                translationExtention='enums.deceased.operator_types'
        />
    </div>

    <div>
          <BaseShowEditTextArea v-if="work?.events"
                                :label="$t('attributes.additional_info')"
                                :placeholder="$t('attributes.additional_info')"
                                v-model="worksEventOfTypeAsr.operator_infos"
                                :errors="errors.operator_infos"
                                :editionMode="editionMode" />
    </div>

    <RecovererForm :editionMode='editionMode' />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BurialContactForm from './BurialContactForm'
import RecovererForm from './RecovererForm'

export default {
  name: 'SelectAsrForm',
  components:{
    RecovererForm,
    BurialContactForm,
  },
  props:{
    editionMode: {type: Boolean, required: true},
  },
  computed:{
    ...mapGetters({
      work: 'work/getWork',
      metadata: 'work/getWorkMetadata',
      errors: 'auth/getErrors',
      worksEventOfTypeAsr: 'work/getAsrEventInWork',
    }),
  }
}
</script>
