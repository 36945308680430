<template>
  <SettingsPageFull>
    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','piggy-bank']" size='2x' />
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.content_bank_title') }}</span>
      </div>
    </template>

    <template #cta>
      <BaseButton
                  v-if="$can('manage_content')"
                  color='primary'
                  icon='plus'
                  :title="$t('button.add_new_m', {var: $t('global.bank_account')})"
                  @click="this.$router.push({name: 'settings_create_bank_route'})"/>
    </template>

    <template #body>
      <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.bank')}) "/>
      <BaseContentTable
        v-if='metadata.columns'
        :columns='metadata.columns'
        :modelList='banksList'
        :search='true'
        @rowClick='redirectToModelPage'
        @refreshClick='fetchAllTasks'
        @deleteModel='deleteBank'
        :isHoverable='true' />
    </template>
  </SettingsPageFull>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheBankAccountIndexPage',
  computed:{
    ...mapGetters({
      banksList: 'bank/getBanksList',
      metadata: 'bank/getBankMetadata'
    }),
  },
  methods:{
    ...mapActions({
      fetchBankList: 'bank/fetchBankList',
      deleteBankById: 'bank/deleteBankById',
      undoDeleteBankById: 'bank/undoDeleteBankById'
    }),
    redirectToModelPage(id){
      this.$router.push({name: 'settings_edit_bank_route', params: {id: id}})
    },
    fetchAllTasks(){
      this.fetchBankList()
    },
    deleteBank(id){
      this.deleteBankById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-content").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteBank(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteBank(id){
      this.undoDeleteBankById({
        id: id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_m', {var: this.$t('attributes.bank')})  , {className: 'toastify-content success'})
        }
      })
    }
  },
  beforeMount() {
    this.fetchBankList()
  }
}
</script>
