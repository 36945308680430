<template>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.spouse_first_name')"
                       v-model="computedDeceased.spouse_first_name"
                       :errors="errors.deceased ? errors.deceased.spouse_first_name : []"
                       :placeholder="$t('attributes.spouse_first_name')"
                       :editionMode="editionMode"
                        />

  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.spouse_usual_first_name')"
                       v-model="computedDeceased.spouse_usual_first_name"
                       :errors="errors.deceased ? errors.deceased.spouse_usual_name : []"
                       :placeholder="$t('attributes.spouse_usual_first_name')"
                       :editionMode="editionMode"
                       />

  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditInput :label="$t('attributes.spouse_last_name')"
                       v-model="computedDeceased.spouse_last_name"
                       :errors="errors.deceased ? errors.deceased.spouse_last_name : []"
                       :placeholder="$t('attributes.spouse_last_name')"
                       :editionMode="editionMode"
                       />

  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WorkFormDeceasedSpouse',
  props: {
    computedDeceased: {type: Object, required: true},
    editionMode: {type: Boolean, required: true},
  },
  computed: {
    ...mapGetters({metadata: 'work/getWorkMetadata', errors: 'auth/getErrors'}),
  }
}
</script>
