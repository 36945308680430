import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'

import MainLayout from '../components/layouts/main-layout/MainLayout'

import { workRootPages, workSubPages} from './works'
import { urnRootPages} from '@/router/urn'
import { loginRootPages } from './login/index'
import { planningRootPages, planningSubPages } from './planning'
import { invoicingRootPages, invoicingSubPages } from './invoicing'
import { noteRootPages }  from '@/router/note'
import { statsRootPages }  from '@/router/stats'
import { settingsSubPages } from './settings/index'
import { profileRootPages } from './profile/index'

const routes = [
  { ...loginRootPages },
  {
    path: '/',
    name: 'root',
    component: MainLayout,
    beforeEnter(from, to, next) {

      store.getters['auth/isAuthenticated'] ? next() : next({name: 'login'})
    },
    children: [
      ...[
        { ...workRootPages },
        { ...urnRootPages },
        { ...planningRootPages },
        { ...invoicingRootPages },
        {...noteRootPages},
        {...statsRootPages},
        { ...profileRootPages },
      ],
      ...settingsSubPages,
      ...workSubPages,
      ...invoicingSubPages,
      ...planningSubPages
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

export default router
