import getters from '@/store/modules/note/getters'
import mutations from '@/store/modules/note/mutations'
import actions from '@/store/modules/note/actions'

export default {
  namespaced: true,
  state() {
    return {
      noteIndex: [],
      note: {},
      noteMetadata: [],
    }
  },
  getters,
  actions,
  mutations
}
