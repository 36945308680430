export default {
  getListItemsIndex(state){
    return state.listItemsIndex
  },
  getListItem(state){
    return state.listItem
  },
  getListItemMetadata(state){
    return state.listItemMetadata
  }
}
