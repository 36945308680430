<template >
  <div class='flex flex-col'>
    <div class='flex justify-between'>
      <div class="w-full mr-7">
        <BaseUserShowEditSelectSimple v-model='activity.causer_id'
                                      name='causer_id'
                                      label="responsable"
                                      :metadata='metadata'
                                      :edition-mode='editionMode && permission'
        />
      </div >
      <div class="flex-shrink-0 whitespace-nowrap px-2 rounded-md mt-8 " :class="cNewStatusTextColor">
        {{ $t('enums.task.status.' + cNewStatus?.id) }}
      </div >
    </div >
    <div class='text-gray-500 text-sm mr-24'>
      <BaseShowEditIsoDateTimeInput
        v-model="activity.created_at"
        :label="$t('attributes.date')"
        :edition-mode="editionMode && permission"
      />
    </div >
    <div >
      <div v-if='cNewStatus?.id === 2'>
        <div v-if='task?.starting_form && task.starting_form.length > 0'>
          <div v-for='(question, index) in task.starting_form' :key='index'>
            <template v-if="!editionMode" ><p v-if='question.value ' class='font-medium text-gray-500'><span
              class='text-gray-600'
            >{{ question.question }}</span >: {{ question.value }}</p ></template >
            <DynamicFormInput
              v-if="editionMode && !onCard"
              :question="question"
              :questionIndex='index'
              :defaultValue="question.value"
              class="overflow-visible"
              @questionValue='getStartQuestionValue'
              :is-required="false"
            />
          </div >
        </div >
      </div >
      <div v-if='cNewStatus?.id === 3'>
        <div v-if='task.ending_form && task.ending_form.length > 0'>
          <div v-for='(question, index) in task.ending_form' :key='index'>
            <template v-if="!editionMode" ><p v-if='question.value' class='font-medium text-gray-500'><span class='text-gray-600'
            >{{ question.question }}</span >: {{ question.value }}</p ></template >
            <DynamicFormInput
              v-if="editionMode && !onCard"
              :question="question"
              :questionIndex='index'
              :defaultValue="question.value"
              class="overflow-visible"
              @questionValue='getEndQuestionValue'
              :is-required="false"
            />
          </div >
        </div >
      </div >
      <div v-if='cNewStatus?.id === 4'>
        <div v-if='task.awaiting_reason' class='mb-2 mr-24'>
          <BaseShowEditTextArea
            :label="$t('attributes.awaiting_reason')"
            :placeholder="$t('attributes.awaiting_reason')"
            v-model='task.awaiting_reason'
            :editionMode='editionMode'
            name="awaiting_reason"
          />
        </div >
      </div >
    </div >
  </div >
</template >
<script >
import moment from "moment";
import BaseShowEditIsoDateTimeInput from "@p/works/form/subpages/tasks/components/BaseShowEditIsoDateTimeInput";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: 'TaskStatusChangeActivityItem',
  components: { BaseShowEditIsoDateTimeInput },
  props: {
    activity: {},
    metadata: {},
    task: {},
    editionMode: {},
    onCard: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    ...mapGetters({
      auth: 'auth/getAuth'
    }),
    cNewStatus() {
      return this.metadata?.tasks?.status.find(status => status.id === this.activity?.properties?.new_status[0]);
    },
    cNewStatusTextColor() {
      const style_button = this.editionMode ? 'pt-2' : ''
      return 'text-white bg-theme-' + this.cNewStatus?.bg_color + ' ' + style_button
    },
    permission() {
      return Object.values(this.auth.roles).includes('super-admin') ||Object.values(this.auth.roles).includes('manager') || this.activity.causer_id === this.auth.id
    }
    
  },
  methods: {
    ...mapMutations({alterWorkTaskQuestionValue: 'work/alterWorkTaskQuestionValue'}),
    getStartQuestionValue({ value, index}){
      this.alterWorkTaskQuestionValue({form: 'starting_form', index: index, value: value})
    },
    getEndQuestionValue({ value, index}){
      this.alterWorkTaskQuestionValue({form: 'ending_form', index: index, value: value})
    },
  },
}
</script >
