export default {
  setSettings(state, settingsList) {
    state.settings = settingsList
  },
  setSystemSettings(state, systemSettings) {
    state.systemSettings = systemSettings
  },
  setSettingsMetadata(state, settingsMetadata) {
    state.settingsMetadata = settingsMetadata
  },
  setSettingsEventKinds(state, settingsEventKinds) {
    state.settingsEvent_kinds = settingsEventKinds
  },
  alterSettings(state, payload) {
    state.settings[payload.attribute] = payload.value
  },
  setNewEventKindToEventType(state, payload) {
    state.settingsEvent_kinds[payload.eventType].push(payload.eventKind)
  },
  deleteEventKindFromEventType(state, payload) {
    state.settingsEvent_kinds[payload.eventType].splice(payload.index, 1)

  },
  alterEventKindInEventType(state, payload) {
    state.settingsEvent_kinds[payload.event_type][payload.index][payload.attribute] = payload.value;
  },
  alterEventKindNameInEventType(state, payload) {
    state.settingsEvent_kinds[payload.event_type][payload.index]['name'][payload.attribute] = payload.value;
  },

}
