import getters from '@/store/modules/content/asset/getters'
import mutations from '@/store/modules/content/asset/mutations'
import actions from '@/store/modules/content/asset/actions'

export default {
  namespaced: true,
  state() {
    return {
      assetList: [],
      asset: {},
      assetActivity: {},
      assetMetadata: {},
    }
  },
  getters,
  actions,
  mutations
}
