<template>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditTextArea :label="$t('attributes.coffin_putting_info')"
                          :placeholder="$t('attributes.coffin_putting_info')"
                          v-model="computedDeceased.coffin_putting_info"
                          :errors="errors.coffin_putting_info"
                          :editionMode="editionMode" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'WorkFormDeceasedCoffinPuttingInfo',
  props: {
    computedDeceased: {type: Object, required: true},
    editionMode: {type: Boolean, required: true},
  },
  computed: {
    ...mapGetters({errors: 'auth/getErrors'})
  }
}
</script>
