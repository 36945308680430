<template>
    <ActivityTable v-if='itemActivity && itemActivity.length > 0' :activities="itemActivity" />
  <div v-else class='mt-10 pt-10 flex justify-center font-semibold text-gray-500'>
    {{$t('global.no_activity')}}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheItemActivityPage',
  computed: { ...mapGetters({itemActivity: 'item/getItemActivity'}) },
  methods: { ...mapActions({fetch: 'item/fetchItemActivitiesByItemID'})},
  beforeMount() {
    this.fetch({item_id: this.$route.params.id})
  }
}
</script>

