import apiClient from '@u/apiClient'

export default {
    //region fetch
    fetchUrnIndex({commit}) {
        apiClient.get('api/fetch-urns')
            .then(async response => {
                await commit('setUrnsIndex', response.data.urns)
                await commit('setUrnMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchUrnIndex', reason)
            })
    }
    //endregion
}
