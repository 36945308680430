import getters from '@/store/modules/global_search/getters'
import actions from '@/store/modules/global_search/actions'
import mutations from '@/store/modules/global_search/mutations'

export default {
  namespaced: true,
  state() {
    return {
      searchResult: {},
      searchValue: '',
      searchDropdown: false,
    }
  },
  getters,
  actions,
  mutations
}
