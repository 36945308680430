import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'


import sideMenuModule from './modules/side-menu/index'
import darkModeModule from './modules/dark-mode/index'

import authModule from './modules/auth/index'

import contentCompany from './modules/content/company/index'
import contentCustomer from './modules/content/customer/index'
import contentUser from './modules/content/user/index'
import contentImplantation from './modules/content/implantation/index'
import contentAsset from './modules/content/asset/index'
import contentItem from './modules/content/item/index'
import contentBank from './modules/content/bankAccount/index'
import contentTask from './modules/content/task/index'
import workModule from './modules/works/index'
import invoicingModule from './modules/invoicing/index'
import eventModule from './modules/events/index'
import documentModule from './modules/document/index'
import urnModule from './modules/urn/index'
import settingsModule from './modules/settings/index'
import itemListModule from './modules/content/itemList/index'
import messageTextModule from './modules/content/messageText/index'
import noteModule from './modules/note/index'
import statsModule from './modules/stats/index'
import accountingCodeModule from './modules/content/accountingCode/index'
import notificationSettingsModule from './modules/content/notification/index'
import globalSearchModule from './modules/global_search/index'
import notificationModule from './modules/notification/index'

const dataState = createPersistedState({
  paths: ['auth', 'sessionExpiration', 'settings.systemSettings']
})

const store = createStore({
  modules: {
    accountingCode: accountingCodeModule,
    asset: contentAsset,
    auth: authModule,
    bank: contentBank,
    company: contentCompany,
    customer: contentCustomer,
    darkMode: darkModeModule,
    document: documentModule,
    event: eventModule,
    globalSearch: globalSearchModule,
    implantation: contentImplantation,
    invoicing: invoicingModule,
    item: contentItem,
    itemList: itemListModule,
    messageText: messageTextModule,
    note: noteModule,
    stats: statsModule,
    notification: notificationModule,
    notificationSettings: notificationSettingsModule,
    settings: settingsModule,
    sideMenu: sideMenuModule,
    task: contentTask,
    urn: urnModule,
    user: contentUser,
    work: workModule,
  },
  plugins: [dataState]
})

export function useStore() {
  return store
}

export default store
