<template>
  <Popover v-slot="{ open }" class="relative">
    <PopoverButton
      :class="open ? '' : 'text-opacity-90'"
      class="flex justify-center items-center text-base border-2 border-white ring-1 ring-gray-500 shadow-2xl font-medium text-white bg-theme-red rounded-full w-12 h-12 hover:text-opacity-100 focus:outline-none"
    >
      <font-awesome-icon :icon='["fal","sliders-h"]' />
    </PopoverButton>
    <PopoverOverlay
      class="bg-black z-100"
      :class='open ? "opacity-10 fixed inset-0" : "opacity-0"'
    />
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel class="absolute bottom-14 right-0 z-100 w-screen max-w-sm px-4 mt-3 transform sm:px-0 lg:max-w-3xl">
        <div class="overflow-hidden rounded-lg shadow-2xl ring-1 ring-black ring-opacity-5">
          <div class="relative bg-white p-5 space-y-5">
            <filter-planning-event-type v-model:event-type='dEventType' :eventTypeList='eventTypeList' />
            <filter-planning-assets v-model:assets='dAssets' :assetList='assetList' />
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script>

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import FilterPlanningEventType from './FilterPlanningEventType'
import FilterPlanningAssets from './FilterPlanningAssets'

export default {
  name: 'FilterPlanning',
  props: {
    eventType: Array,
    assets: Array,
    assetList: Array,
    eventTypeList: Array
  },
  data() {
    return {
      dEventType: this.eventType,
      dAssets: this.assets,
    }
  },
  emits: ['update:eventType', 'update:assets'],
  components: {
    FilterPlanningAssets,
    FilterPlanningEventType,
    Popover,
    PopoverButton,
    PopoverPanel
  },
  created() {
    let planningFilterEventType = localStorage.getItem( 'planning-filter-event-type' )
    if( planningFilterEventType && planningFilterEventType !== 'undefined' ){
      this.dEventType = JSON.parse( planningFilterEventType )
      this.$emit('update:eventType', this.dEventType)
    }
    let planningFilterAssets = localStorage.getItem( 'planning-filter-assets' )
    if( planningFilterAssets && planningFilterAssets !== 'undefined' ){
      this.dAssets = JSON.parse( planningFilterAssets )
      this.$emit('update:assets', this.dAssets)
    }
  },
  watch: {
    eventType(newValue) {
      this.$emit('update:eventType',newValue)
    },
    assets(newValue) {
      this.$emit('update:assets',newValue)
    }
  }
}
</script>
