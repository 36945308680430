<template>
  <base-card-section :section-title='$t("attributes.ceremony")'>

    <BaseSimpleRadioGroup name="ceremony"
                          :defaultValue="false"
                          :isShowingKey='false'
                          :isReturningKey='true'
                          :disabled="permission"
                          :options="yes_no_options"
                          translationExtention="enums.yes_no"
                          @change.self="getCeremonyValue"/>

    <div v-if="ceremony === 'true'" :key='ceremony'>

      <!--#region ask_nb_people_when_work_creating true -->
      <div v-if='settings?.events?.ask_nb_people_when_work_creating'>
        <div class='flex w-1/4 mb-4'>
          <BaseInput :label="$t('attributes.nb_peoples')"
                     :placeholder="$t('attributes.nb_peoples')"
                     name='nb_peoples'
                     type="number"
                     parseType="int"
                     :min='0'
                     :max='999999'
                     v-model='eventCER.nb_peoples'
                     :disabled="permission"
                     />
        </div>
        <div class='mt-3'>

          <div v-if='auth_role === "crema-user" || auth_role === "super-admin"' class='col-span-2'>
            <template v-if='eventCER.nb_peoples'>
              <label>{{ $t('attributes.ceremony_place') }}</label>
              <div class="flex flex-col sm:flex-row mt-2">
                <div v-if='metadata && eventCER.nb_peoples' class="form-check mr-2"
                     v-for="(room, index) in metadata.events?.assets?.filter(asset => asset.type === 'ROM' && asset.nb_places >= this.eventCER.nb_peoples)"
                     :key="index">
                  <input :id="`${room.name}-${index}`" class="form-check-input" type="radio" :name="room.name"
                         :value="room.id" :disabled="permission" v-model="data_room">
                  <label class="form-check-label capitalize-first" :for="`${room.name}-${index}`">{{
                      room.name
                    }}</label>
                </div>
              </div>
            </template>
          </div>


          <div v-if="eventCER.nb_peoples && auth_role === 'customer-user'" class='col-span-2'>
            <div>
              <label>{{ $t('global.room') }}</label>
              <div class="flex flex-col sm:flex-row mt-2">
                <div class="form-check mr-2" v-for="(val, key) in qualifiedRooms" :key="key">
                  <input :id="`room-${key}`" class="form-check-input" type="radio" :name="val" :disabled="permission"
                         :value="key" v-model="customer_user_room">
                  <label class="form-check-label capitalize-first" :for="`room-${key}`"> {{ val }}</label>
                </div>
              </div>
            </div>

          </div>
          <div>
            <BaseSimpleRadioGroup v-if='eventCER.nb_peoples'
                                  name="event_kind_id"
                                  :label="$t('attributes.ceremony_type')"
                                  :options="metadata?.event_kinds?.filter(item => selectedAsset?.event_kind.includes(item.id))"
                                  :is-returning-key='true'
                                  :is-showing-key='true'
                                  trackBy="id"
                                  :disabled="permission"
                                  :translatable="false"
                                  @change.self="getInputValue"/>
          </div>

          <div v-if="need_more_infos" class='col-start-2 my-2'>
            <BaseInput :label="$t('attributes.funeral_date_and_place')"
                       :placeholder="$t('attributes.funeral_date_and_place')"
                       name='remarks'
                       v-model='eventCER.remarks'
                       :required='need_more_infos'
                       :disabled="permission"
                       />
          </div>

          <div
              v-if='metadata.events.formInputs.display_timeslots.visible.type.includes(eventCER.type) && eventCER.event_kind_id '
              class='mt-7 grid grid-cols-5'>
            <BaseButton class="col-start-3" :tooltip="dispoButtonText" :icon="dispoButtonIcon"
                        @click='HandleDispoButton'/>
          </div>
        </div>
      </div>
      <!--#endregion -->

      <!--#region else -->
      <div v-else>
        <div class='grid grid-cols-3'>

          <div v-if='auth_role === "crema-user" || auth_role === "super-admin"' >
            <div class="mt-3">
              <label class="form-label capitalize-first">{{ $t('attributes.ceremony_place') }}</label>
              <div class="flex flex-col sm:flex-row mt-2">
                <div v-if='metadata' class="form-check mr-2"
                     v-for="(room, index) in superUserRoomSelectOption" :key="index">
                  <input :id="`${room.name}-${index}`" class="form-check-input" type="radio" :name="room.name"
                         :value="room.id" :disabled="permission" v-model="data_room">
                  <label class="form-check-label capitalize-first" :for="`${room.name}-${index}`">{{
                      room.name
                    }}</label>
                </div>
              </div>
            </div>
          </div>

          <div v-if="auth_role === 'customer-user' && this.metadata.events" >
            <div class="mt-3">
              <label class="form-label capitalize-first">{{ $t('global.room') }}</label>
              <div class="flex flex-col sm:flex-row mt-2">
                <div class="form-check mr-2" v-for="(val, key) in qualifiedRoomsSettingsEventFalse" :key="key">
                  <input :id="`room-${key}`" class="form-check-input" type="radio" :name="val" :disabled="permission"
                         :value="key" v-model="customer_user_room">
                  <label class="form-check-label capitalize-first" :for="`room-${key}`"> {{ val }}</label>
                </div>
              </div>
            </div>

          </div>
          <div>
            <BaseSimpleRadioGroup v-if='eventCER.asset_id'
                                  name="event_kind_id"
                                  :label="$t('attributes.ceremony_type')"
                                  :options="metadata.event_kinds.filter(item => selectedAsset.event_kind.includes(item.id))"

                                  :is-returning-key='true'
                                  :is-showing-key='true'
                                  :disabled="permission"
                                  trackBy="id"
                                  :translatable="false"
                                  @change.self="getInputValue"/>

          </div>

          <div
              v-if='metadata.events.formInputs.display_timeslots.visible.type.includes(eventCER.type) && eventCER.event_kind_id && auth_role !== "customer-user"'
              class='mt-7 grid grid-cols-5'>
            <div class="col-start-4">

            <BaseButton :tooltip="dispoButtonText" :icon="dispoButtonIcon"
                        @click='HandleDispoButton'/>
            </div>
          </div>
        </div>
      </div>
      <!--#endregion -->

      <div v-if="auth_role !== 'customer-user'" class='mt-5'>
        <SelectEventTimeslot v-if='dispoVisible % 2 === 0'
                             class="my-5"
                             :asset="eventCER.asset_id"
                             :type="eventCER.type"
                             :eventKind="eventCER.event_kind_id"
                             :options='eventCER.timeslots'
                             :index='work.events.indexOf(eventCER)'
                             v-model='selectedEventTimeslot'/>
      </div>

    </div>
  </base-card-section>
</template>

<script>
import CeremonyDisponibilitiesRadio from "./CeremonyDisponibilitiesRadio";

import SelectEventTimeslot from '@p/components/Event/SelectEventTimeslot'

import {mapActions, mapGetters, mapMutations} from 'vuex'
import moment from 'moment'

export default {
  name: 'SelectCeremonyForm',
  components: {
    CeremonyDisponibilitiesRadio,
    SelectEventTimeslot
  },
  data() {
    return {
      ceremony: null,
      data_room: [],
      customer_user_room: null,
      yes_no_options: {true: true, false: false},
      need_more_infos: false,
      selectedEventTimeslot: null,
      dispoVisible: 1,
      today: null,
      date_to_fetch: null,
    }
  },
  computed: {
    ...mapGetters({
      work: 'work/getWork',
      metadata: 'work/getWorkMetadata',
      events: 'work/getWorkEvents',
      auth: 'auth/getAuth',
      settings: 'settings/getSystemSettings'
    }),

    selectedAsset() {
      return this.metadata.events.assets.find(asset => asset.id === this.eventCER.asset_id)
    },
    permission() {
      return !this.$can('manage_work')
    },
    dispoButtonIcon() {
      return this.dispoVisible % 2 !== 0 ? 'calendar-alt' : 'compress-alt'
    },
    dispoButtonText() {
      return this.dispoVisible % 2 !== 0 ? this.$t('button.show_plural', {var: this.$t('global.availabilities')}) : this.$t('button.hide_plural', {var: this.$t('global.availabilities')})
    },
    eventCER() {
      return this.events && this.events.filter(event => event.type === 'CER')[0]
    },
    auth_role() {
      return this.auth.roles[0];
    },
    qualifiedRooms() {
      let rooms = this.metadata.events.assets.filter(asset => asset.type === 'ROM' && asset.nb_places >= this.eventCER.nb_peoples)
      let qualifiedRooms = {}
      for (let i = 0; i < rooms.length; i++) {
        qualifiedRooms[rooms[i].id] = rooms[i].name
      }
      return qualifiedRooms
    },
    qualifiedRoomsSettingsEventFalse() {
      let rooms = this.metadata.events.assets.filter(asset => asset.type === 'ROM')
      let qualifiedRooms = {}
      for (let i = 0; i < rooms.length; i++) {
        qualifiedRooms[rooms[i].id] = rooms[i].name
      }
      return qualifiedRooms
    },
    superUserRoomSelectOption() {
      return this.metadata?.events?.assets?.filter(asset => asset.type === 'ROM')
    }
  },
  methods: {
    ...mapActions({fetchEventDisponbilitiesByOption: 'fetchEventDisponbilitiesByOption'}),
    ...mapMutations({alterWorkEventValuesByEventType: 'work/alterWorkEventValuesByEventType', removeEventFromWork: 'work/removeEventFromWork', removeEventKeyByEventType: 'work/removeEventKeyByEventType'}),
    getCeremonyValue({value}) {
      this.ceremony = value
      if (value === 'false') {
        this.dispoVisible = 1
        this.data_room = null
        this.customer_user_room = null
      }
    },
    getInputValue({attribute, value}) {
      if (attribute === 'event_kind_id') {
        this.removeEventKeyByEventType({type: 'CER', key_to_delete: 'remarks'})
        this.dispoVisible = 1
      }
      if (attribute === 'nb_peoples') {
        this.dispoVisible = 1
      }
      this.alterWorkEventValuesByEventType({type: 'CER', attribute: attribute, value: value})
    },
    HandleDispoButton() {
      this.dispoVisible += 1
      if (this.dispoVisible % 2 === 0) {
        this.fetchEventDisponbilitiesByOption({
          params: {
            asset_id: this.eventCER.asset_id,
            type: this.eventCER.type,
            week_from: moment().locale('fr').week(),
            year: moment().format('YYYY'),
            event_kind_id: this.eventCER.event_kind_id ? this.eventCER.event_kind_id : null
          }, index: this.work.events.indexOf(this.eventCER)
        })
      }
    },
  },
  watch: {
    ceremony(newValue) {
      if (newValue === 'true') {
        this.alterWorkEventValuesByEventType({type: 'CER', attribute: 'type', value: 'CER'})
      } else {
        this.removeEventFromWork({type: 'CER'})
      }
    },
    customer_user_room(newValue) {
      this.dispoVisible = 1
      this.alterWorkEventValuesByEventType({type: 'CER', attribute: 'asset_id', value: parseInt(newValue)})
    },
    data_room(newValue) {
      this.dispoVisible = 1
      this.alterWorkEventValuesByEventType({type: 'CER', attribute: 'asset_id', value: parseInt(newValue)})
    },
    selectedEventTimeslot(newValue, oldValue) {
      if (newValue !== oldValue && newValue !== null) {
        this.alterWorkEventValuesByEventType({type: 'CER', attribute: 'date_from', value: newValue.string_date})
        this.alterWorkEventValuesByEventType({type: 'CER', attribute: 'date_to', value: newValue.string_date})
        this.alterWorkEventValuesByEventType({type: 'CER', attribute: 'time_from', value: newValue.string_from})
        this.alterWorkEventValuesByEventType({type: 'CER', attribute: 'time_to', value: newValue.string_to})
        this.alterWorkEventValuesByEventType({type: 'CER', attribute: 'asset_id', value: newValue.asset.id})
      }
    }
  }
}
</script>

<style scoped>

</style>
