<template>
  <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.user')}) "/>
  <div class="border border-gray-300 rounded-md p-5" :class="editionClass">
    <div class='flex'>
      <div class='flex-1'>
        <h3 class="text-xl font-medium">
          {{ user.first_name }}&nbsp;{{ user.last_name }}
        </h3>
        <p>
          {{ user.email }}
        </p>
      </div>
      <div class='flex-shrink'>
        <BaseButton color='secondary'
                    :icon='buttonIcon'
                    :tooltip="$t('button.show_v', {var: $t('global.user')})"
                    @click='showForm'/>
      </div>

      <BaseButton color='danger'
                  v-if="editionMode"
                  icon='times'
                  :tooltip="$t('button.delete_v', {var: $t('global.user')})"
                  @click='deleteUser(user.id)'
                  class='ml-2'/>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'CustomerUserCardComponent',
  props: {
    user: {
      type: Object,
      required: true
    },
    focusUser: {
      type: Object,
      required: false
    },
    editionMode: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      editionClass: '',
      focus: '',
      editable: false,
      errors: {},
      user: this.user,
    }
  },
  computed:{
    ...mapGetters({
      metadata: 'user/getUserMetadata',
      errors: 'auth/getErrors'
    }),
    buttonIcon(){
      return !this.editable ? 'search' : 'times'
    },
    editPasswordText() {
      return !this.editPassword ? 'modifier le mot de passe' : 'annuler la modification'
    },
    editPasswordIcon() {
      return !this.editPassword ? 'unlock' : 'times'
    },
    editPasswordType() {
      return !this.editPassword ? 'warning' : 'secondary'
    },
    editPassword(){
      return this.focusUser == null
    },
  },
  methods: {
    ...mapActions({
      fetchUserByID: 'user/fetchUserByID',
      deleteUserById: 'user/deleteUserById',
      undoDeleteUserById: 'user/undoDeleteUserById',
      storeNewCustomerUser : 'customer/storeNewCustomerUser',
      updateCustomerUser: 'customer/updateCustomerUser'
    }),
    async fetchUser(id) {
      await this.fetchUserByID({id: id})
      this.user = this.$store.getters.getUser
      this.metadata = this.$store.getters.getUserMetadata
    },
    showForm() {
      this.$router.push({ name: 'settings_edit_user_route', params: { id: this.user.id } })
    },
    getInputValue({attribute, value}) {
      this.user[attribute] = value
    },
    submitForm() {
      if (this.user.id !== null) {
        this.user.customer_id = this.$route.params.id
        this.user.companies_id = null
        this.updateCustomerUser({
          user: this.user,
          id: this.user.id,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.updated_m', {var: this.$t('attributes.user')}) , {className: 'toastify-content success'})
          },
        })
      } else {
        this.user.customer_id = this.$route.params.id
        this.user.companies_id = null
        this.user.roles = ['customer-user']
        this.storeNewCustomerUser({
          user: this.user, successCallback: () => {
            this.$h.toastify( this.$t('toasts.created_m', {var: this.$t('attributes.user')}) , {className: 'toastify-content success'})
          },
        })
      }
    },

    deleteUser(id){
      this.deleteUserById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-settings").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteUser(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteUser(id){
      this.undoDeleteUserById({
        id:  id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_m', {var: this.$t('attributes.user')})  , {className: 'toastify-content success'})
        }
      })
    }
  },
  watch: {
    editable(newValue, oldValue) {
      this.editionClass = this.editable ? 'col-span-2' : ''
    },
    focusUser(newValue, oldValue) {
      this.editable = newValue === this.user.id
    }
  },
  mounted() {
    if (this.user.id === null) {
      this.editable = true
    }
  },
}
</script>
