import apiClient from '@u/apiClient'

let base_errors = {deceased:{},events:[]}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default{
  //region general

  //region fetch
  async fetchGeneralSettings({commit}) {
    commit('auth/setErrors', base_errors, {root:true})
    await apiClient.get('api/fetch-general-settings')
        .then(async response => {
          commit('setSettings', response.data.settings)
          commit('setSettingsMetadata', response.data.metadata)
        })
        .catch(async reason => {
          await console.error('fetchGeneralSettings', reason)
        })
  },
  //endregion

  //region update
  async updateGeneralSettings({commit}, payload) {
    await apiClient.put('api/update-general-settings', payload.generalSettings)
        .then(async response => {
          if (response.status === 200) {
            commit('auth/setErrors', base_errors, {root:true})
            processSuccessCallback(payload)
          }
        })
        .catch(async reason => {
          await console.error('updateSettings', reason)
        })
  },
  //endregion

  //endregion

  //region event

  //region fetch
  async fetchEventSettings({commit}) {
    commit('auth/setErrors', base_errors, {root:true})
    await apiClient.get('api/fetch-event-settings')
        .then(async response => {
          await commit('setSettings', response.data.settings)
          await commit('setSettingsMetadata', response.data.metadata)

          let sortableEvents = [];
          for (let event in response.data.event_kinds) {
            sortableEvents.push([event, response.data.event_kinds[event]]);
          }
          sortableEvents.sort(function (a, b) {
            return Object.values(b)[1].length - Object.values(a)[1].length;
          });
          let SortedEvents = {}
          sortableEvents.forEach(function (item) {
            SortedEvents[item[0]] = item[1]
          })

          await commit('setSettingsEventKinds', SortedEvents)
        })
        .catch(async reason => {
          await console.error('fetchGeneralSettings', reason)
        })
  },
  //endregion

  //region update
  async updateEventSettings({commit}, payload) {
    await apiClient.put('api/update-events-settings', payload.eventSettings)
        .then(async response => {
          if (response.status === 200) {
            commit('auth/setErrors', base_errors, {root:true})
            processSuccessCallback(payload)
          }
        })
        .catch(async reason => {
          await console.error('updateSettings', reason)
        })
  },
  async updateEventKindsSettings({commit}, payload) {
    await apiClient.post('api/eventKind', payload.event_kinds)
        .then(async response => {
          if (response.status === 200) {
            commit('auth/setErrors', base_errors, {root:true})
            if (payload.successCallback) {
              processSuccessCallback(payload)
            }
          }
        })
        .catch(async reason => {
          await console.error('updateSettings', reason)
        })
  },
  //endregion

  //endregion

  //region invoice
  //region fetch
  async fetchInvoicingSettings({commit}) {
    commit('auth/setErrors', base_errors, {root:true})
    await apiClient.get('api/fetch-invoicing-settings')
        .then(async response => {
          await commit('setSettings', response.data.settings)
          await commit('setSettingsMetadata', response.data.metadata)
        })
        .catch(async reason => {
          await console.error('fetchGeneralSettings', reason)
        })
  },
  //endregion

  //region update

  async updateInvoicingSettings({commit}, payload) {
    await apiClient.put('api/update-invoicing-settings', payload.invoicingSettings)
        .then(async response => {
          if (response.status === 200) {
            commit('auth/setErrors', base_errors, {root: true})
            processSuccessCallback(payload)
          }
        })
        .catch(async reason => {
          await console.error('updateSettings', reason)
        })
  },
  //endregion
  //endregion


}
