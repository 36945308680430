
<template>
  <BaseSelectSimple
      v-if="options"
      :label="label"
      :placeholder="placeholder"
      name='name'
      :defaultValue="options?.filter(el=> el.id === model.accounting_code)"
      :options='options'
      :disabled="false"
      :errors="{}"
      :required="required"
      @setSelected='getInputValue'
  />
</template>
<script>

export default {
  name: 'SelectSettingAccountingCode',
  props: {
    errors: {},
    getInputValue: {},
    options: {},
    label: '',
    placeholder: {
      required: false
    },
    required:{
      default: true
    },
    model: {},
  }
}
</script>
