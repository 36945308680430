import moment from "moment";

export function addCoffinArrivalIfWorkDoesntHave(state) {
    if (!state.work.events.filter((obj => obj.type === 'CAR')).length) {
        state.work.events.push({type: 'CAR'})
    }
}

export function getArrivingBeforeValue(first_event, state) {
    let first_event_kind = state.workMetadata.event_kinds.filter(item => item.id == first_event.event_kind)[0]

    if (first_event_kind !== undefined) {
        return first_event_kind.arriving_before
    } else {
        return 20
        //todo settings default arriving_before
    }
}

export function setCoffinArrivingValues(state, index, first_event) {
    if (first_event.date_from !== undefined && first_event.time_from !== undefined) {
        state.work.events[index]['date_from'] = first_event.date_from
        state.work.events[index]['date_to'] = first_event.date_to
        state.work.events[index]['time_to'] = first_event.time_from
        let duration = getArrivingBeforeValue(first_event, state)
        state.work.events[index]['time_from'] = moment(first_event.date_from + ' ' + first_event.time_from, 'DD/MM/YYYY hh:mm').subtract(duration, 'minutes').format('HH:mm')
    }
}

export function getFirstEvent(state) {
    return state.work.events
        .sort((a, b) => Date.parse(moment(a.date_from + ' ' + a.time_from, 'DD/MM/YYYY HH:mm')) - Date.parse(moment(b.date_from + ' ' + b.time_from, 'DD/MM/YYYY HH:mm')))
        .filter(item => item.type !== 'CAR')[0]
}

export function updateCoffinArrival(state) {
    sortEvents(state)
    let first_event = getFirstEvent(state)
    let index = state.work.events.findIndex(obj => obj.type === 'CAR')
    setCoffinArrivingValues(state, index, first_event)
}

export function deleteCoffinArrival(state) {
  sortEvents(state)
  state.work.events.splice(0, 1)
}

export function sortEvents(state) {
    state.work.events = state.work.events.sort((a, b) => Date.parse(moment(a.date_from + ' ' + a.time_from, 'DD/MM/YYYY HH:mm')) - Date.parse(moment(b.date_from + ' ' + b.time_from, 'DD/MM/YYYY HH:mm')))
}

