<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','landmark']" size="2x"/>
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.content_company_title') }}</span>
      </div>
    </template>

    <template #cta>
      <BaseButton color="primary"
                  :title="$t('button.add_new_f', {var: $t('global.company')})"
                  icon='plus'
                  @click="this.$router.push({name: 'settings_create_company_route'})"
                  v-if="$can('manage_content')"/>
    </template>

    <template #body>
      <ToastUndoComponent :text="$t('toasts.deleted_f', {var: $t('attributes.company')}) "/>

      <CompanyCardComponent v-for="company in  companies"
                   :company='company'
                   :key="company.id"
                   @click="this.$router.push({ name: 'settings_edit_company_route', params: { id: company.id } })"/>
    </template>

  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import CompanyCardComponent from './components/CompanyCardComponent'

export default {
  name: 'TheCompanyIndexPage',
  components: {
    CompanyCardComponent,
  },
  computed: {
    ...mapGetters({companies: 'company/getCompaniesList'})
  },
  methods: {
    // ...mapActions(['', '', '']),
    ...mapActions({
      fetchCompanyIndex: "company/fetchCompanyIndex",
      deleteCompanyById: 'company/fetchCompanyByID',
      undoDeleteCompanyById: 'company/undoDeleteCompanyById'
    }),
    deleteCompany(id){
      this.deleteCompanyById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-settings").clone()
          toast.find('#undo_btn').on('click',()=>{
            this.undoDeleteCompany(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteCompany(id){
      this.undoDeleteCompanyById({
        id:  id,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.reseted_f', {var: this.$t('attributes.company')})  , {className: 'toastify-content success'})
        }
      })
    }
  },
  async mounted() {
    await this.fetchCompanyIndex()
  }
}
</script>
