import {createApp} from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import store from './store'
import 'vue-select/dist/vue-select.css';
import '@addf/addf-vue-base-components/dist/index.css';


import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

import {abilitiesPlugin} from '@casl/vue'
import ability from '@u/ability'

import globalComponents from './components/baseComponents/theme-global-components'

import utils from './utils'
import './libs'
let user = store.getters['getAuth']
import './assets/sass/app.scss'


Bugsnag.start({
    apiKey: '96d97d9c41fc8f4f50deeb0de706acdb',
    plugins: [new BugsnagPluginVue()],
    enabledReleaseStages: ['production', 'staging', 'development'],

    appVersion:process.env.PACKAGE_VERSION,
    onError: function (event) {
        if(typeof user === 'object' && user.length){
        event.setUser(user.id, user.email, user.full_name)
        }
    }
    // otherOptions: value
})

const bugsnagVue = Bugsnag.getPlugin('vue')

import baseComponents from '@addf/addf-vue-base-components'

const app = createApp(App)
    .use(baseComponents)
    .use(i18n)
    .use(store)
    .use(router)
    .use(bugsnagVue)
    // .use(PusherPlugin, { apiKey, cluster: 'eu' })
    .use(abilitiesPlugin, ability, {
        useGlobalProperties: true
    });
// .use(RayPlugin, { interceptErrors: true, host: '127.0.0.1', port: 23517 });

globalComponents(app)
utils(app)

app.mount('#app')
