<template>
  <Menu as="div" class="relative mx-5 text-left">
    <div>
      <MenuButton
          class="btn btn-secondary font-light text-black btn-primary rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <span class='capitalize-first'>{{ name }}</span>
        <font-awesome-icon
            class="ml-3 w-5 h-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
            :icon="['fal', 'chevron-down']"/>
      </MenuButton>
    </div>

    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0">
      <MenuItems
          class="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300">

        <div class="p-1">
          <template v-for="(el, index) in model" :key="index">
            <MenuItem v-slot="{ active }">
              <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md "
                      @click="$emits('change', el.id)">
                <font-awesome-icon :icon="['fal', 'arrow-right']" size="lg"/>&nbsp;
                <span class="cursor-pointer text-sm capitalize-first">
                  {{ el.name }}
                </span>
              </button>
            </MenuItem>
          </template>
        </div>

      </MenuItems>


    </transition>
  </Menu>
</template>
<script>
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'

import InvoicingNavLink from '../../../invoicing/nav/InvoicingNavLink'

export default {
  name: 'WorkFilterModelDropdown',
  components: {InvoicingNavLink, Menu, MenuButton, MenuItem, MenuItems},
  props:{
    model: {type: Array, required: true},
    name: {type: String, required: true},
  },
  computed: {
  },
  methods: {}
}
</script>
