export default {
  button: {
    add: 'toevoegen',
    add_new_m: 'toevoegen een {var}',
    add_new_f: 'toevoegen een {var}',
    back: 'terug',
    cancel: 'annuleren',
    close: 'sluiten',
    create: 'aanmaken',
    edit: 'edit',
    edit_new_m: 'edit deze {var}',
    edit_new_mv: 'edit deze {var}',
    edit_new_f: 'edit deze {var}',
    print: 'print',
    save_new_m: 'aanmelden deze {var}',
    save_new_mv: 'aanmelden deze {var}',
    save_new_f: 'aanmelden deze {var}',
    send: 'verzenden'
  }
}
