import getters from '@/store/modules/content/bankAccount/getters'
import mutations from '@/store/modules/content/bankAccount/mutations'
import actions from '@/store/modules/content/bankAccount/actions'

export default {
  namespaced: true,
  state() {
    return {
      banksList: [],
      bank: {},
      bankMetadata: {},
    }
  },
  getters,
  actions,
  mutations
}
