import apiClient from '@u/apiClient'
import router from '@/router'

let base_errors = {}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  //region fetch
  async fetchBankList({commit}){
    await apiClient.get('api/fetch-bank-account')
      .then(async response => {
        await commit('setBanksList', response.data.bank_accounts)
        await commit('setBankMetadata', response.data.metadata)
      })
      .catch(async reason => {
      console.error('fetchBankList', reason)
    })
  },
  async fetchBank({commit}, payload){
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get(`api/bank-account/${payload.id}`)
      .then(async response => {
        await commit('setBank', response.data.bank_account)
        await commit('setBankMetadata', response.data.metadata)
      })
      .catch(async reason => {
        console.error('fetchBank', reason)
      })
  },
  async fetchNewBank({commit}){
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get('api/model/new?model=BankAccount')
      .then(async response => {
        await commit('setBank', response.data.BankAccount)
        await commit('setBankMetadata', response.data.metadata)
      })
      .catch(async reason => {
        console.error('fetchNewBank', reason)
      })
  },
  //endregion

  //region store
  async storeBank({commit}, payload){
    await apiClient.post('api/bank-account', payload.bank)
      .then(async response => {
        if(response.status === 201){
          processSuccessCallback(payload)
        }
    })
      .catch(async reason => {
      console.error('storeBank', reason)
    })
  },
  //endregion

  //region update
  async updateBank({commit}, payload){
    await apiClient.put(`api/bank-account/${payload.id}`, payload.bank)
      .then(async response => {
        if(response.status === 200){
          processSuccessCallback(payload)
        }
      })
      .catch(async reason => {
        console.error('updateBank', reason)
      })
  },
  //endregion

  //region delete
  async deleteBankById({commit}, payload) {
    await apiClient.delete(`api/bank-account/${payload.id}`)
      .then(async response => {
        if (response.status === 200) {
          await commit('setBanksList', response.data.bank_accounts)
          await commit('setBankMetadata', response.data.metadata)
          processSuccessCallback(payload);
        }
      })
      .catch(async reason => {
        console.error('deleteBankById', reason)
      })
  },
  //endregion

  //region undoDelete
  async undoDeleteBankById({commit}, payload) {
    await apiClient.get(`api/bank-account/undo-delete/${payload.id}`)
      .then(async response => {
        if(response.status  === 200 ){
          await commit('setBanksList', response.data.bank_accounts)
          await commit('setBankMetadata', response.data.metadata)
          processSuccessCallback(payload);
        }
      })
      .catch(async reason => {
      console.error('undoDeleteBankById', reason)
    })
  },
  //endregion
}
