<template>

  <div class="top-bar -mx-4 px-4 md:mx-0 md:px-6 w-full  print:hidden">
    <div class='flex flex-row w-full'>
      <div class='flex-shrink-0 flex flex-row items-center gap-4'>
        <QuickAdd />
        <SearchAllComponent/>
      </div>
      <div class='flex-1 flex flex-row justify-end'>
        <div class='flex'>
          <div v-if='notification'>
            <NotificationIconComponent v-for='(notif, index) in notification'
                                       :icon='notif.icon'
                                       :count='notif.count'
                                       :color='notif.color'
                                       :tooltip='notif.tooltip'/>
          </div>
        <BaseButton color="secondary" icon="sync" :tooltip="$t('button.refresh')" size='small' class=" space-x-2" :isOutline="true"
                     @click="this.$emit('askForRefresh')"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import QuickAdd from "@c/layouts/top-bar/components/QuickAdd";
import SearchAllComponent from './components/SearchAllComponent'
import NotificationIconComponent from './NotificationIconComponent'
import InfoUserModalComponent from './InfoUserModalComponent'
import TheDarkModeSwitcher2 from '@c/layouts/dark-mode-switcher/TheDarkModeSwitcher2'

export default {
  components: {
    QuickAdd,
    TheDarkModeSwitcher2,
    InfoUserModalComponent,
    NotificationIconComponent,
    SearchAllComponent,
  },

  data() {
    return {
      breadcrumb: [],
    }
  },
  computed: {
    ...mapGetters({notification:'notification/getNotifications'}),
    appTitle() {
      return process.env.VUE_APP_APP_NAME
    }
  },
  methods: {
    ...mapActions({fetchNotifications: 'notification/fetchNotifications'}),
  },
  watch: {
    '$route'(to, from) {
      this.breadcrumb = to.meta.breadcrumb
    }
  },
  mounted() {
    this.fetchNotifications()
  }
}
</script>
