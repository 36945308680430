import apiClient from '@u/apiClient'
import router from '@/router'

let base_errors = {}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {
  //region fetch
  async fetchTasksList({commit}) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get('api/fetch-task')
        .then(async response => {
          commit('setTasksList', response.data.tasks)
          commit('setTaskMetadata', response.data.metadata)
        })
        .catch(async reason => {
          console.error('fetchTasksList', reason)
        })
  },
  async fetchTaskById({commit}, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get(`api/task/${payload.id}`)
        .then(async response => {
          commit('setTask', response.data.task)
          commit('setTaskMetadata', response.data.metadata)
        })
        .catch(async reason => {
          console.error('fetchTaskById', reason)
        })
  },
  async fetchNewTask({commit}) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.get('api/model/new?model=Task')
        .then(async response => {
          await commit('setTask', response.data.Task)
          await commit('setTaskMetadata', response.data.metadata)
        })
        .catch(async reason => {
          console.error('fetchNewTask', reason)
        })
  },
  async fetchTaskActivitiesByTaskID({commit}, payload) {
    await apiClient.get(`api/task/fetch-activities/${payload.task_id}`)
        .then(async response => {
          await commit('setTaskActivity', response.data.activities)
        })
        .catch(async reason => {
          await console.error('fetchTaskActivitiesByTaskID', reason)
        })
  },
  //endregion

  //region store
  async storeTask({commit}, payload) {
    await apiClient.post('api/task', payload.task)
        .then(async response => {
          if (response.status === 201) {
            processSuccessCallback(payload)
          }
        })
        .catch(async reason => {
          console.error('storeTask', reason)
        })
  },
  //endregion

  //region update
  async updateTask({commit}, payload) {
    await apiClient.put(`api/task/${payload.id}`, payload.task)
        .then(async response => {
          if (response.status === 200) {
            processSuccessCallback(payload)
          }
        })
        .catch(async reason => {
          console.error(reason)
        })
  },
  //endregion

  //region delete
  async deleteTaskById({commit}, payload) {
    await apiClient.delete(`api/task/${payload.id}`)
        .then(async response => {
          if (response.status === 200) {
            commit('setTasksList', response.data.tasks)
            commit('setTaskMetadata', response.data.metadata)
            processSuccessCallback(payload);
          }
        })
        .catch(async reason => {
          console.error('deleteTaskById', reason)
        })
  },
  //endregion

  //region undoDelete
  async undoDeleteTaskById({commit}, payload) {
    await apiClient.get(`api/task/undo-delete/${payload.id}`)
        .then(async response => {
          if (response.status === 200) {
            commit('setTasksList', response.data.tasks)
            commit('setTaskMetadata', response.data.metadata)
            processSuccessCallback(payload);
          }
        })
        .catch(async reason => {
          console.error('undoDeleteTaskById', reason)
        })
  },
  //endregion
}
