export default {
  toasts: {
    new_update_1 : 'Une mise à jour est disponible. Veuillez sauvegarder tout le travail en cours et cliquer sur le bouton de mise à jour.',
    new_update_2 :'Vous pouvez également accepter ces mises à jour en rafraîchissant votre navigateur à tout moment.',
    update: 'mettre à jour',
    created_f: 'une {var} à bien été créée',
    created_m: 'un {var} à bien été créé',
    created_plurals: 'les {var} ont bien été créé',
    deleted_f: 'cette {var} à bien été supprimée',
    deleted_f_plurals: 'ces {var} ont bien été supprimées',
    deleted_m: 'ce {var} à bien été supprimé',
    deleted_m_plurals: 'ces {var} ont bien été supprimés',
    form_error: 'des erreurs sont présentes dans le formulaire',
    no_selected_f: 'aucune {var} n\'a été sélectionnée',
    no_selected_m: 'aucun {var} n\'a été sélectionné',
    cloned_f: 'cette {var} à bien été dupliquée',
    reseted_f: 'cette {var} à bien été récupérée',
    reseted_m: 'ce {var} à bien été récupéré',
    cloned_m: 'ce {var} à bien été dupliqué',
    converted_m: 'Ce {var} à bien été converti',
    converted_f: 'Cette {var} à bien été convertie',
    server_error: 'erreur serveur',
    start_task_first: 'veuillez d\'abord démarrer la tâche',
    updated_f: 'cette {var} à bien été mise à jour',
    updated_m: 'un {var} à bien été mis à jour',
    updated_plurals: 'ces {var} ont bien été mis à jour',
    auth_updated_m: 'votre profile a bien été mis à jour',
    invoice_updated: 'la facture a été modifiée',
    save_document_as_attachment: 'le document généré a bien été ajouté aux annexes',
  }
}
