import apiClient from '@u/apiClient'
import router from '@/router'

let base_errors = {}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}

export default {

    //region fetch
    async fetchCheckDepositList({commit}) {
        await apiClient.get('api/fetch-check-deposit')
            .then(async response => {
                await commit('setCheckDepositList', response.data.check_deposits)
                await commit('setCheckMetadata', response.data.metadata)
            })
            .catch(async reason => {
                await console.error('fetchChecksList', reason)
            })
    },
    async fetchCheckDepositById({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/check-deposit/${payload.check_deposit}`)
            .then(async response => {
                await commit('setCheckDeposit', response.data.check_deposit)
                await commit('setCheckMetadata', response.data.metadata)
            })
            .catch(async reason => {
                await console.error('fetchCheckDepositById', reason)
            })
    },
    async fetchCheckByStatus({commit}, payload) {
        await apiClient.get(`api/fetch-checks-by-status/${payload.status}`)
            .then(async response => {
                commit('setCheckListByType', response.data.checks)
                commit('setCheckMetadata', response.data.metadata)
            })
            .catch(async reason => {
                await console.error('fetchCheckByStatus', reason)
            })
    },
    async fetchNewCheckDeposit({commit}) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get('api/model/new?model=CheckDeposit')
            .then(async response => {
                await commit('setCheckDeposit', response.data.CheckDeposit)
                await commit('setCheckMetadata', response.data.metadata)
            })
            .catch(async reason => {
                await console.error('fetchNewCheckDeposit', reason)
            })
    },
    //endregion

    //region store
    async storeCheckDeposit({commit}, payload) {
        await apiClient.post('api/check-deposit', payload.checkDeposit)
            .then(async response => {
                if (response.status === 201) {
                    processSuccessCallback(payload)
                    await router.push({name: 'check_index_route'})
                }
            })
            .catch(async reason => {
                await console.error('storeCheckDeposit', reason)
            })
    },
    //endregion

    //region update
    async updateCheckDeposit({commit}, payload) {
        await apiClient.put(`api/check-deposit/${payload.id}`, payload.checkDeposit)
            .then(async response => {
                if (response.status === 200) {
                    processSuccessCallback(payload)
                    await router.push({name: 'check_index_route'})
                }
            })
            .catch(async reason => {
                console.error('updateCheckDeposit', reason)
            })
    },
    //endregion
}
