<template>
  <div class='mt-5 grid grid-cols-5'  v-if='invoice'>
    <div class='col-start-1 col-span-2 flex gap-x-1 '>
      <BaseButton
          icon='chevron-left'
          color='danger'
          :tooltip="$t('button.back')"
          class='mr-3 w-8'
          @click="$router.go(-1)"/>

      <h2 class="text-2xl font-bold capitalize-first" v-if='invoice.doc_type'>{{ $t('global.invoicing_type.' + invoice.doc_type ) }}</h2>
    </div>

    <div class='col-start-3 col-span-3'>
      <div class='flex space-x-2 justify-end' >
<!--        DropDownColoredStatus-->
        <DropDownColoredStatus
            v-if="auth_role !== 'customer-user'"
            :options='metadata.status'
            :current='invoice.status'
            :btnStyle="true"
            @select='mChangeStatus'
        />

        <BaseLabel
            v-else
            :big="true"
            :content="labelContent"
            :color="labelColor"
        />

        <BaseButton
            v-if="auth_role !== 'customer-user'"
            :title="$t('global.add_payment')"
            type='primary'
            icon='credit-card'
            @click="openModal('InvoiceAddPaiment')"
        />

        <BaseButton
            :title="$t('global.print')"
            type='primary'
            icon='print'
            @click="this.$router.push({name:'invoice_print_route', params: { id: invoice.id }})"
        />

        <BaseButton
            v-if="invoice.work"
            :title="$t('button.to_m', {var: $t('global.work')})"
            type='primary'
            icon='folder-open'
            @click="this.$router.push({name:'work_form_edit_route', params: { id: invoice.work_id }})"
        />

        <InvoicingActionDropDownComponent
            v-if="auth_role !== 'customer-user'"
            @buttonSend='openModal("InvoicingSendDialog")'
            @changeItemOrder='openModal("ChangeItemOrderDialog")'
        />

      </div>
    </div>
  </div>

      <div class='flex flex-col space-y-10'>
        <!--#region invoiceINFOS -->
        <div class='flex flex-row mt-16'>
          <div class='w-1/2'>
            <InvoicingInvoiceInfosComponent :invoice='invoice'  />
          </div>
          <div class='w-1/2'>
            <InvoicingCustomerInfosComponent
                v-if='invoice.customer'
                :invoice='invoice'
            />
          </div>
        </div>
        <!--#endregion -->
        <!--#region invoiceItemsTable -->
        <InvoicingItemsTable
            v-if='invoice.invoice_items'
            :invoice_items='invoice.invoice_items'
            :invoiceIsNotEstablished="invoiceIsNotEstablished"
            @eventClick='editInvoiceItem' />
        <!--#endregion -->
        <!--#region bottomOfpage -->

        <div class='flex flex-row'>
          <div class='w-1/2'>
            <div class='flex flex-row space-x-3 pr-3'>
              <div v-if="invoiceIsNotEstablished && auth_role !== 'customer-user'" class='flex-1'>
                <BaseSelectSimpleAjax :group-class='"mt-0"'
                                      url='api/item-for-select'
                                      :key='selectKey'
                                      v-model='ajoutRapide'
                                      selectLabel='id' />
              </div>

              <div v-if="invoiceIsNotEstablished && auth_role !== 'customer-user'">
                <BaseButton :title="$t('button.browse_f', {var: $t('global.item_list')})" icon='list-ul' @click="openModal('InvoiceItemAddDialog')" />
              </div>
            </div>
          </div>

          <div class='w-1/2'>
            <InvoicingTotalTable
              v-if='invoice.vat_distribution'
              :vat_distribution='invoice.vat_distribution'
              :already_paid='invoice.total_paid'
              @clickPaiments="openModal('InvoiceShowPaiments')"
            />
            <div class='flex justify-end mt-2'>
              <h2 class='text-2xl font-bold'>TOTAL A PAYER: {{ invoice.solde  }} €</h2>
            </div>
          </div>
        </div>
        <!--#endregion -->
      </div>
  <!--#region Dialogs -->
  <InvoiceItemEditDialog @backdropClicked='closeAllModals'
                         :invoiceItemId='dataModal'
                         @close='closeAllModals'
                         :is-modal-visible="isModalVisible['InvoiceItemEdit']"
                         size='x-large'>

  </InvoiceItemEditDialog>
  <InvoicingShowPaimentsDialog @backdropClicked='closeAllModals'
                               @close='closeAllModals'
                               @switchToPayment='switchToPayment'
                               :is-modal-visible="isModalVisible['InvoiceShowPaiments']"
                               :has-close-icon='true'
                               size='x-large'>
  </InvoicingShowPaimentsDialog>
  <InvoicingAddPaimentDialog @backdropClicked='closeAllModals'
                             @close='closeAllModals'
                             :is-modal-visible="isModalVisible['InvoiceAddPaiment']"
                             :has-close-icon='true'
                             size='x-large'>
  </InvoicingAddPaimentDialog>
  <invoiceItemAddDialog @backdropClicked='closeAllModals'
                        @close='closeAllModals'
                        :is-modal-visible="isModalVisible['InvoiceItemAddDialog']"
                        :has-close-icon='true'
                        size='x-large' />
  <InvoicingSendDialog @backdropClicked='closeAllModals'
                        @close='closeAllModals'
                        :is-modal-visible="isModalVisible['InvoicingSendDialog']"
                        :has-close-icon='true'
                        size='x-large' />
  <ChangeItemOrderDialog v-if='invoice' @backdropClicked='closeAllModals'
                       @close='closeAllModals'
                       :is-modal-visible="isModalVisible['ChangeItemOrderDialog']"
                       size='x-large' />

  <!--#endregion -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ChangeItemOrderDialog from '../dialogs/ChangeItemOrderDialog'
import invoiceItemAddDialog from '../dialogs/invoiceItemAddDialog'
import InvoiceItemEditDialog from '../dialogs/InvoiceItemEditDialog'
import InvoicingActionDropDownComponent from './components/InvoicingActionDropDownComponent'
import InvoicingAddPaimentDialog from '../dialogs/InvoicingAddPaimentDialog'
import InvoicingCustomerInfosComponent from './components/InvoicingCustomerInfosComponent'
import InvoicingInvoiceInfosComponent from './components/InvoicingInvoiceInfosComponent'
import InvoicingItemsTable from '../tables/InvoicingItemsTable'
import InvoicingSendDialog from '../dialogs/InvoicingSendDialog'
import InvoicingShowPaimentsDialog from '../dialogs/InvoicingShowPaimentsDialog'
import InvoicingTotalTable from '../tables/InvoicingTotalTable'

export default {
  name: 'InvoicingInfo',
  components: {
    ChangeItemOrderDialog,
    invoiceItemAddDialog,
    InvoiceItemEditDialog,
    InvoicingActionDropDownComponent,
    InvoicingAddPaimentDialog,
    InvoicingCustomerInfosComponent,
    InvoicingInvoiceInfosComponent,
    InvoicingItemsTable,
    InvoicingSendDialog,
    InvoicingShowPaimentsDialog,
    InvoicingTotalTable,
  },
  props: {
    invoicing_id: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false
    }
  },
  emits: ['refreshWork'],
  data() {
    return {
      fakeItemToReplaceByMetadata: { un: 1, deux: 2, trois: 3 },
      dataModal: null,
      selectKey: 0,
      ajoutRapide: null,
      isModalVisible: {
        InvoiceItemEdit: false,
        InvoiceShowPaiments: false,
        InvoiceAddPaiment: false,
        InvoicingSendDialog: false,
        ChangeItemOrderDialog: false,
      }
    }
  },
  computed: {
    ...mapGetters({
      invoice: 'invoicing/getInvoice',
      metadata:'invoicing/getInvoiceMetadata',
      auth: 'auth/getAuth'
    }),
    labelContent(){
      return this.$t(`enums.invoicing.status.${this.invoice.status}`)
    },
    labelColor(){
      return this.metadata && this.metadata?.status?.find( status => status.id === this.invoice.status).bg_color
    },
    auth_role() {
      return this.auth.roles[0];
    },
    invoiceIsNotEstablished(){
      return !(this.invoice.status === 'EST')
    }
  },
  methods: {
    ...mapActions({
      fetchInvoiceID:'invoicing/fetchInvoiceID',
      postInvoiceItem:'invoicing/storeInvoiceItem',
      changeInvoiceStatus:'invoicing/changeInvoiceStatus'
    }),
    mChangeStatus(newStatus){
      this.changeInvoiceStatus({
        invoice_id: this.invoice.id,
        form_data: {
          status: newStatus.id,
        },
        successCallback: () =>
            this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('global.invoice')}), { className: 'toastify-content success' }),
      });
    },
    switchToPayment() {
      this.closeAllModals()
      this.isModalVisible.InvoiceAddPaiment = true
    },
    editInvoiceItem(id) {
      this.dataModal = id
      this.openModal('InvoiceItemEdit')
    },
    addNewItem(item_id) {
      this.postInvoiceItem({ invoiceId: this.invoice.id, item_id: item_id })
      this.selectKey++
    },
    openModal(property) {
      this.isModalVisible[property] = true
    },
    closeAllModals() {
      for (const property in this.isModalVisible) {
        this.isModalVisible[property] = false
      }
    }
  },
  watch: {
    ajoutRapide(newValue) {
      this.addNewItem( newValue )
    }
  },
  async beforeMount() {
    await this.fetchInvoiceID({ id: this.$route.params.invoicing_id })
  }

}
</script>

