import store from '../../index'
export default {
  setSearchResult(state, result){
    state.searchResult = result
    state.searchDropdown = Object.keys(state.searchResult).length > 0
  },
  resetSearchResult(state){
    state.searchResult = {}
  },
  setSearchValue(state, searchValue){
    state.searchValue = searchValue
    state.searchDropdown = Object.keys(state.searchResult).length > 0

    if(state.searchValue.length > 2){
      store.dispatch('globalSearch/getGlobalSearchResult', state.searchValue)
    }else{
      state.searchDropdown = false
      state.searchResult = {}
    }
  },
  resetSearchValue(state){
    state.searchValue = ''
    state.searchDropdown = false
  },
  setSearchDropdown(state){
    state.searchDropdown = true
  },
  unsetSearchDropdown(state){
    state.searchDropdown = false
  },
}
