import moment from 'moment'
import  {sortEvents} from "./helpers/helpers";
import WorkTaskMutations from './work_task/mutations'
import WorkEventsMutations from './work_events/mutations'
import WorkContactMutations from './contact/mutations'

import _ from 'lodash'


export default {

  ...WorkTaskMutations,
  ...WorkEventsMutations,
  ...WorkContactMutations,

  //#region setters
  setWorkMetadata(state, metadata) {
    state.workMetadata = metadata
  },
  setWorkList(state, companyList) {
    state.worksList = companyList
  },
  setWork(state, work) {
    state.work = work
  },
  setWorkActivities(state, activities){
    state.workActivities = activities
  },
  setWorkFieldFromDotNotation(state, payload) {
    Object.entries(payload).forEach((item) => {
      _.set(state.work, item[0], item[1])
    })
  },
  setWorkUrn(state){
    state.work.urn = {}
    state.work.urn.awaiting_at_crema = 1
  },
  //#endregion

  //#region modifiers

  alterWork(state, payload) {
    state.work[payload.attribute] = payload.value
  },

  alterWorkDeceased(state, payload) {
    console.log('state.work.deceased[payload.attribute]', state.work.deceased[payload.attribute])
    state.work.deceased[payload.attribute] = payload.value
    if(payload.attribute === 'CON'){
      console.log('state.work.urn.awaiting_at_crema avant' , state.work.urn.awaiting_at_crema)
      state.work.urn.awaiting_at_crema = 1
      console.log('state.work.urn.awaiting_at_crema après' , state.work.urn.awaiting_at_crema)
    }
  },

  alterWorkUrn(state, payload){
    console.log(payload)
    state.work.urn[payload.attribute] = payload.value
  },

  alterWorkItemsValuesByWorkItemType(state, payload) {
    let objIndex = state.work.work_items.findIndex((item => item.type === payload.item_type))
    state.work.work_items[objIndex][payload.attribute] = payload.value
    sortEvents(state)
  }

  //#endregion

}
