<template>
  <!--#region delete button-->
  <div class="flex flex-row  items-center">
      <span class='flex items-center mr-2'>
        <span class="w-2 h-2 rounded-full self-center" :class='buttletClass'>&nbsp;</span>
      </span>
    <div class="flex-1">
        <h2 class="text-xl font-bold capitalize-first">{{ eventTitleFormated }}</h2>
    </div>
    <div class='mr-5'>

    </div>
    <div v-if="editionMode && isDeletable" class="flex-shrink-0">
      <BaseButton icon='times' :tooltip="$t('button.delete_v', {var: $t('global.event')})" color='danger' @click="emitToDelete"/>
    </div>
  </div>
  <!--#endregion -->

  <formEvent :errors="errors"
             :props_event="propsEvent"
             :filtered-assets="filteredAssets"
             :get-input-value="getInputValue"
             :after-work-selected="afterWorkSelected"
             :index="index"
             :metadata="metadata.events"
             :withPermission="$can('manage-work')"
             :editionMode='editionMode'/>

</template>

<script>
import { mapActions, mapMutations } from 'vuex'

import FormEvent from "../../../../components/Event/FormEvent";

export default {
  name: 'WorkEvent',
  emits: ['eventDeleteButtonPressed'],
  components:{
    FormEvent,
  },
  props:{
    metadata: { type: Object, required: true },
    errors: { type: Object, required: true },
    index: { type: Number, required: true },
    isDeletable: {type: Boolean, required: false, default: true },
    workSelectable: { type: Boolean, required: false, default: true },
    editionMode: {type: Boolean, required: true },
    propsEvent: {type: Object, required: false, default:null}
  },
  computed:{
    buttletClass(){
      return 'bg-theme-' + this.propsEvent?.type
    },
    eventTitleFormated(){
      return this.propsEvent?.title ?  ( this.propsEvent?.type ? ( this.$t(`enums.event.type.${this.propsEvent.type}`)) : '' ) : this.$t('global.new_event') + ( this.propsEvent?.type ? ( ' - ' + this.$t(`enums.event.type.${this.propsEvent.type}`)) : '' )
    },
    filteredAssets() {
      let tempModels = this.metadata?.events?.assets
      if(typeof this.propsEvent?.type !== 'undefined'){
        tempModels = tempModels?.filter((asset) => {
          return this.metadata?.events?.assets_type[this.propsEvent.type]?.includes(asset.type)
        })
      }
      return tempModels;
    }
  },
  methods:{
    ...mapMutations({alterEventByIndex: 'work/alterWorkEventAttributeByIndex', deleteWorkEventKeyByIndex: 'work/deleteWorkEventKeyByIndex'}),
    emitToDelete(){
      this.$emit('eventDeleteButtonPressed',this.index)
    },
    getInputValue({attribute, value}) {
      if(attribute === 'type'){
        this.deleteWorkEventKeyByIndex({index: this.index, attribute: 'asset_id'})
      }
      this.alterEventByIndex({index: this.index, attribute: attribute, value: value})
    },
    afterWorkSelected(work){
      this.propsEvent.work_id = work.id
    },

  },
}
</script>

