export default {
  setTasksList(state, tasksList){
    state.tasksList = tasksList
  },
  setTask(state, task){
    state.task = task
  },
  setTaskMetadata(state, metadata){
    state.taskMetadata = metadata
  },
  alterTask(state, payload){
    state.task[payload.attribute] = payload.value
  },
  setTaskActivity(state, activity){
    state.taskActivity = activity
  }
}
