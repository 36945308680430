import TheWorkIndexPage from '../../components/pages/works/index/TheWorkIndexPage'
import TheWorkFormEditPage from '@p/works/form/TheWorkFormEditPage'
import TheWorkFormNewPage from '@p/works/form/TheWorkFormNewPage'
import TheWorkInformationPage from '@p/works/form/subpages/informations/TheWorkInformationPage'
import TheWorkUrnPage from '@p/works/form/subpages/urn/TheWorkUrnPage'
import TheWorkAttachmentsPage from "@p/works/form/subpages/attachments/TheWorkAttachmentsPage";
import TheWorkTasksPage from '@p/works/form/subpages/tasks/TheWorkTasksPage'
import TheWorkActivitiesPage from '@p/works/form/subpages/activities/TheWorkActivitiesPage'
import TheWorkEventsPage from '@p/works/form/subpages/events/TheWorkEventsPage'
import WorkFormDocuments from "@p/works/form/subpages/documents/TheWorkDocumentsPage";
import WorkDocumentRenderPage from '@p/works/form/subpages/documents/WorkDocumentRenderPage'
import TheWorkDocumentRouter from '../../components/pages/works/form/subpages/documents/TheWorkDocumentRouter'
import TheWorkInvoicingRouter from '../../components/pages/works/form/subpages/invoicing/TheWorkInvoicingRouter'
import TheWorkInvoicingPage from "@p/works/form/subpages/invoicing/TheWorkInvoicingPage";
import InvoicingInfo from '@p/invoicing/show/invoicingTab/InvoicingInfo'
import InvoicingForm from "../../components/pages/invoicing/form/InvoicingForm"

export const workRootPages = {
    path: '/',
    name: 'works',
    component: TheWorkIndexPage,
}

export const workSubPages = [
    {
        path: 'work/new',
        name: 'work_create_route',
        component: TheWorkFormNewPage,
        children: [
            {
                path: '',
                component: TheWorkInformationPage,
                name: 'work_form_new_route'
            }
        ]
    },
    {
        path: 'work/in-progress',
        name: 'work_progress_route',
        component: TheWorkIndexPage,
    },
    {
        path: 'work/archived',
        name: 'work_archived_route',
        component: TheWorkIndexPage,
    },
    {
        path: 'work/:id',
        name: 'work_edit_route',
        props: true,
        component: TheWorkFormEditPage,
        children: [
            {
                path: '',
                component: TheWorkInformationPage,
                name: 'work_form_edit_route'
            }, {
                path: 'events',
                component: TheWorkEventsPage,
                name: 'work_form_event_list_route'
            }, {
                path: 'tasks',
                component: TheWorkTasksPage,
                name: 'work_form_tasks_route'
            },
            {
                path: 'urn',
                component: TheWorkUrnPage,
                name: 'work_form_urn_route'
            },
            {
                path: 'attachments',
                component: TheWorkAttachmentsPage,
                name: 'work_form_attachments_route'
            }, {
                path: 'documents',
                component: TheWorkDocumentRouter,
                name: 'work_documents_router_route',
                children: [
                    {
                        path: '',
                        component: WorkFormDocuments,
                        name: 'work_form_documents_route',
                    },
                    {
                        path: ':document_id',
                        component: WorkDocumentRenderPage,
                        name: 'work_form_view_document_route',
                        props: true
                    }
                ]
            },
            {
                path: 'invoicing',
                component: TheWorkInvoicingRouter,
                name: 'work_invoicing_router_route',
                children: [
                    {
                        path: '',
                        component: TheWorkInvoicingPage,
                        name: 'work_form_invoicing_route'
                    },
                    {
                        path: ':invoicing_id',
                        component: InvoicingInfo,
                        name: 'work_form_invoicing_show_route',
                        props: true
                    },
                    {
                        path: 'invoice/new',
                        component: InvoicingForm,
                        name: 'work_form_invoice_new_route',
                        meta: {doc_type: 'INV'},
                        props: true
                    },
                    {
                      path: 'purchase-order/new',
                      component: InvoicingForm,
                      name: 'work_form_purchase_order_new_route',
                      meta: {doc_type: 'PO'},
                      props: true
                    },
                    {
                      path: 'quote/new',
                      component: InvoicingForm,
                      name: 'work_form_quote_new_route',
                      meta: {doc_type: 'QUO'},
                      props: true
                    },
                    {
                      path: 'credit-note/new',
                      component: InvoicingForm,
                      name: 'work_form_credit_note_new_route',
                      meta: {doc_type: 'CN'},
                      props: true
                    },
                ]
            },
            {
                path: 'activities',
                component: TheWorkActivitiesPage,
                name: 'work_form_activities_route',
            },
        ]
    },
]
