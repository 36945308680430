import {getField, updateField} from "vuex-map-fields";

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    namespaced: true,
    state() {
        return {
            invoiceIndex: [],
            invoice: {},
            invoiceItem: {},
            invoiceActivity: [],
            invoiceMetadata: [],
            checkDepositList: [],
            checkListByType: [],
            checkDeposit: {},
            checkMetadata: {},
            payment: {},
            paymentMetadata: {},
            sendInvoiceData: {
                receivers: null,
                subject: null,
                messageConverted: ''
            },
        }
    },
    getters: {
        ...getters,
        getField
    },
    actions,
    mutations: {
        ...mutations,
        updateField
    }
}
