export default {
  setUserList(state, usersList){
    state.usersList = usersList
  },
  setUser(state, user){
    state.user = user
  },
  setUserPermissions(state, permissions){
    state.user.permissions = permissions
  },
  setUserMetadata(state, metadata){
    state.metadata = metadata
  },
  setUserActivities(state, activities){
    state.userActivities = activities
  }
}
