
import getters from '@/store/modules/events/getters'
import mutations from '@/store/modules/events/mutations'
import actions from '@/store/modules/events/actions'

export default {
  state() {
    return {
      eventsList: [],
      event: {},
      eventMetadata: {},
      planningMetadata: [],
      eventDisponibilities: [],
    }
  },
  getters,
  actions,
  mutations
}
