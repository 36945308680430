export default {
  setDocumentsList(state, documentsList) {
    state.documentsList = documentsList
  },
  setDocument(state, document) {
    state.document = document
  },
  alterDocumentQuestionValue(state, payload){
    state.document.form[payload.index]['value'] = payload.value;
  },
}
