import WorkTaskGetters from './work_task/getters'
import WorkEventGetters from './work_events/getters'
import WorkContactGetters from './contact/getters'

export default {

  ...WorkTaskGetters,
  ...WorkEventGetters,
  ...WorkContactGetters,

  getWorkIndex(state) {
    return state.worksList
  },

  getWork(state) {
    return state.work
  },

  getWorkActivities(state){
    return state.workActivities
  },

  getWorkMetadata(state) {
    return state.workMetadata
  },

  getWorkEvents(state){
    return state.work.events
  },

}
