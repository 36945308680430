<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','tasks']" size='2x' />
        <span class='text-2xl font-bold capitalize-first'>{{ $t('navigation.content_task_title') }}</span>
      </div>
    </template>

    <template #cta>
      <BaseButton color='primary'
                  icon='plus'
                  :title="$t('button.add_new_f', {var: $t('global.task')})"
                  @click="this.$router.push({name: 'settings_create_tasks_route'})"/>
    </template>

    <template #body>
      <ToastUndoComponent :text="$t('toasts.deleted_f', {var: $t('attributes.task')}) "/>

      <BaseContentTable
        v-if='metadata.columns'
        :columns='metadata.columns'
        :modelList='tasksList'
        :search='true'
        @rowClick='redirectToModelPage'
        @refreshClick='fetchAllTasks'
        @deleteModel='deleteTask'
        :isHoverable='true' />

    </template>

  </SettingsPageFull>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheTaskIndexPage',
  computed: {
    ...mapGetters({
      tasksList: 'task/getTasksList',
      metadata: 'task/getTaskMetadata',
      errors: 'auth/getErrors'
    }),
  },
  methods: {
    ...mapActions({
      fetchTasksList: 'task/fetchTasksList',
      deleteTaskById: 'task/deleteTaskById',
      undoDeleteTaskById: 'task/undoDeleteTaskById'
    }),
    redirectToModelPage(id) {
      this.$router.push({
        name: 'settings_edit_tasks_route',
        params: { id: id }
      })
    },
    deleteTask(id) {
      this.deleteTaskById({
        id: id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-content").clone()
          toast.find('#undo_btn').on('click', () => {
            this.undoDeleteTask(id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 5000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteTask(id) {
      this.undoDeleteTaskById({
        id: id,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.reseted_f', { var: this.$t('attributes.task') }), { className: 'toastify-content success' })
        }
      })
    },
    fetchAllTasks() {
      this.fetchTasksList()
    },
  },
  async mounted() {
    await this.fetchTasksList()
  }
}
</script>

