<template>
  <div>
    <TheMobileMenu :dashboard-layout="dashboardLayout" layout="side-menu"/>
    <div class="flex h-screen overflow-hidden">
      <!-- BEGIN: Side Menu -->
      <div class='flex flex-col print:hidden'>
        <nav class="side-nav flex-1 transition-width ease-in-out duration-300" :style='sideNavStyles'>
          <a href="" class="flex items-center pl-5 pt-4 mt-3">
            <svg aria-hidden="true" class='w-8 h-8' data-icon="flame" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 384 512">
              <g class="fa-group">
                <path fill="#FFF"
                      d="M192 0C79.7 101.33 0 220.92 0 300.55 0 425.05 79 512 192 512s192-86.95 192-211.45C384 220.6 303.78 100.86 192 0zm0 448c-70.58 0-128-52.89-128-117.89 0-44.11 25.84-71.51 34.34-79.76A8 8 0 0 1 112 256v40a56 56 0 0 0 112 0c0-72-112.64-64.77-39.43-164.33a9.37 9.37 0 0 1 10.58-3.17c1.62.53 5.38 2.24 5.38 6.78 0 33.55 25 55 51.57 77.63 33.38 28.54 67.9 58 67.9 117.21C320 395.11 262.58 448 192 448z"
                      class="fa-secondary"></path>
                <path fill="currentColor"
                      d="M252.09 212.92c33.38 28.54 67.9 58 67.9 117.21 0 65-57.41 117.87-128 117.87S64 395.11 64 330.11c0-44.11 25.84-71.51 34.34-79.76A8 8 0 0 1 112 256v40a56 56 0 0 0 112 0c0-72-112.64-64.77-39.43-164.33a9.37 9.37 0 0 1 10.58-3.17c1.62.53 5.38 2.24 5.38 6.78 0 33.56 25 55 51.56 77.64z"
                      class="fa-primary"></path>
              </g>
            </svg>
            <span class="hidden xl:block text-white text-lg ml-3 transition-all ease-in-out delay-200"
                  :class='collapsedMenu ? "opacity-0 w-0" : "opacity-100 w-auto"'>
            {{ appTitle }}
          </span>
          </a>
          <div class="side-nav__devider my-6"></div>
          <ul>
            <!-- BEGIN: First Child -->
            <template v-for="(menu, menuKey) in formattedMenu">
              <li
                  v-if="menu === 'devider'"
                  :key="menu + menuKey"
                  class="side-nav__devider my-6"
              ></li>
              <li v-else :key='menu + menuKey' v-if='menu.gate === undefined  || $can(menu.gate) || (menu.meta && menu.meta.open_for_customer)'>
                <SideMenuTooltip
                    tag="a"
                    :content="menu.title"
                    :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                    class="side-menu"
                    :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                    @click="linkTo(menu, router, $event)"
                >
                  <div class="side-menu__icon">
                    <font-awesome-icon :icon="['fal', menu.icon]" :fixed-width='true' size="lg"/>
                  </div>
                  <div class="side-menu__title transition-opacity duration-100 ease-linear"
                       :class='collapsedMenu ? "opacity-0" : ""'>
                    <div class='inline-block capitalize-first'>{{ $t(`navigation.${menu.title}`) }}</div>
                    <div
                        v-if="menu.subMenu"
                        class="side-menu__sub-icon"
                        :class="{ 'transform rotate-180': menu.activeDropdown }"
                    >
                      <font-awesome-icon :icon="['fal', 'chevron-down']" :fixed-width='true' size='sm'/>
                    </div>
                  </div>
                </SideMenuTooltip>
                <!-- BEGIN: Second Child -->
                <transition @enter="enter" @leave="leave">
                  <ul v-if="menu.subMenu && menu.activeDropdown">
                    <li
                        v-for="(subMenu, subMenuKey) in menu.subMenu"
                        :key="subMenuKey"
                    >
                      <SideMenuTooltip
                          tag="a"
                          :content="subMenu.title"
                          :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                          class="side-menu"
                          :class="{ 'side-menu--active': subMenu.active }"
                          @click="linkTo(subMenu, router, $event)"
                      >
                        <div class="side-menu__icon">
                          <font-awesome-icon :icon="['fal', subMenu.icon]" :fixed-width='true'/>
                        </div>
                        <div class="side-menu__title transition-opacity duration-100 ease-linear"
                             :class='collapsedMenu ? "opacity-0" : ""'>
                          <div class='inline-block capitalize-first'>{{ $t(`navigation.${subMenu.title}`) }}</div>
                          <div
                              v-if="subMenu.subMenu"
                              class="side-menu__sub-icon"
                              :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                          >
                            <font-awesome-icon :icon="['fal', 'chevron-down']" :fixed-width='true' size='sm'/>
                          </div>
                        </div>
                      </SideMenuTooltip>
                      <!-- BEGIN: Third Child -->
                      <transition @enter="enter" @leave="leave">
                        <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                          <li
                              v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                              :key="lastSubMenuKey"
                          >
                            <SideMenuTooltip
                                tag="a"
                                :content="lastSubMenu.title"
                                :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({
                                    name: lastSubMenu.pageName
                                  }).path
                            "
                                class="side-menu"
                                :class="{
                              'side-menu--active': lastSubMenu.active
                            }"
                                @click="linkTo(lastSubMenu, router, $event)"
                            >
                              <div class="side-menu__icon">
                                <font-awesome-icon :icon="['fal', 'bolt']" size='sm'/>
                              </div>
                              <div class="side-menu__title transition-opacity duration-100 ease-linear"
                                   :class='collapsedMenu ? "opacity-0" : ""'>
                                <div class='inline-block capitalize-first'>{{
                                    $t(`navigation.${lastSubMenu.title}`)
                                  }}
                                </div>
                              </div>
                            </SideMenuTooltip>
                          </li>
                        </ul>
                      </transition>
                      <!-- END: Third Child -->
                    </li>
                  </ul>
                </transition>
                <!-- END: Second Child -->
              </li>
            </template>
            <!-- END: First Child -->
          </ul>
        </nav>
        <div class='flex-shrink-0'>
          <div class='pl-5 pb-5 cursor-pointer'>
            <font-awesome-icon :icon='menuCollapseIcon' size='lg' class='text-white' @click='toggleCollapsed'/>
          </div>
          <div class='flex border-t p-4'>
            <InfoUserModalComponent/>
          </div>
        </div>
      </div>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div :class="{ 'content--dashboard': dashboardLayout }" class="content flex flex-col">
        <TheTopBar @askForRefresh='incrementKeyForRefresh'/>
        <div class='flex-1 overflow-scroll px-6 pb-6'>
          <router-view :key='keyForRefresh'/>
        </div>
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>

import {computed, onMounted, provide, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from '@/store'
import {helper as $h} from '@/utils/helper'

import TheMobileMenu from '../mobile-menu/TheMobileMenu'
import TheTopBar from '../top-bar/TheTopBar'
import SideMenuTooltip from './tooltip/SideMenuTooltip'

import {enter, leave, linkTo, nestedMenu} from './index'
import InfoUserModalComponent from '../top-bar/InfoUserModalComponent'
import {mapActions, mapGetters} from 'vuex'

export default {
  components: {
    TheTopBar,
    TheMobileMenu,
    // TheDarkModeSwitcher,
    InfoUserModalComponent,
    SideMenuTooltip
  },
  setup() {
    const keyForRefresh = ref(0)
    const dashboardLayout = ref(false)
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const sideMenu = computed(() => {
          return nestedMenu(store.state.sideMenu.menu, route)
        }
    )

    provide('setDashboardLayout', newVal => {
      dashboardLayout.value = newVal
    })

    watch(
        computed(() => route.path),
        () => {
          formattedMenu.value = $h.toRaw(sideMenu.value)
          dashboardLayout.value = false
        }
    )
    const incrementKeyForRefresh = () => { keyForRefresh.value++ }

    onMounted(() => {
      cash('body')
          .removeClass('error-page')
          .removeClass('login')
          .addClass('main')
          .find('#app > div').removeClass('content')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })
    return {
      dashboardLayout,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      keyForRefresh,
      incrementKeyForRefresh
    }
  },
  computed: {
    ...mapGetters('sideMenu', ['collapsedMenu']),
    menuCollapseIcon() {
      return ['fal', this.collapsedMenu ? 'chevron-double-right' : 'chevron-double-left'];
    },
    appTitle() {
      
      return process.env.VUE_APP_APP_NAME
    },
    sideNavStyles() {
      return this.collapsedMenu ? {width: '105px'} : {}
    },
    currentRouteName() {
      return this.$router
    },
  },
  methods: {
    ...mapActions('sideMenu', ['toggleCollapsed']),
    ...mapActions({
      refreshAuth: 'auth/refreshAuth',
      initPusher: 'auth/initPusher',
      refreshNotificationCenter: 'notification/fetchNotifications'
    }),

  },
  beforeMount() {
    this.initPusher(
        {
          successCallback: (key)=>{
            const Pusher = require('pusher-js')
          let that = this
          this.$pusher = new Pusher(key, {cluster: 'eu'})
          let channel = this.$pusher.subscribe('main')

          // Pusher.logToConsole = true
          channel.bind('notification', function (payload) {
            let doc = new DOMParser().parseFromString(payload.toastify.node, 'text/html');
            that.$h.toastifyHtml({
              close: false,
              duration: 4000,
              node: doc.body.firstChild
            })
          })
          channel.bind('auth/refreshAuth', function () {
            that.refreshAuth()
          })
          channel.bind('refreshNotificationCenter', function () {
            that.refreshNotificationCenter()
          })
          }
        }
    )
  },
  mounted() {
    
    this.$store.commit('auth/setPermissions')
    let vue_helper = this.$h

    vue_helper.initTheme()

  },
  unmounted() {
    this.$pusher.unsubscribe('main')
  }

}
</script>
