<template>
  <div class='flex flex-col gap-y-2 border rounded-lg p-2'>
    <div class='flex flex-row justify-end mb-3'>
      <BaseButton icon='times' color='danger' @click='deleteThisPayment'
                  :tooltip="$t('button.delete_m', {var: $t('attributes.payment')})"/>
    </div>

    <div class='flex flex-row justify-between'>
      <p>{{ payment.emission_date }}</p>
      <div>
        <p class='capitalize-first'>{{ $t(`enums.invoicing.mode.${payment.payment_mode}`) }}:
        {{ parseFloat(payment.amount).toFixed(2) }}</p>
        <p class="capitalize-first inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{ $t('global.invoicing_type.' + payment.invoice_type ) }}</p>
      </div>
    </div>

    <p class='capitalize-first' v-if="payment.recipient">{{ $t('attributes.received_by') }}: <span
      class="font-bold">{{ payment.recipient.full_name }}</span></p>
    <p class='capitalize-first'>{{ $t('attributes.complementary_info') }}: <span class="font-bold">{{
        payment.comment
      }}</span></p>

    <div v-if="payment.payment_mode === 'CHE'">
      <p class='capitalize-first'>{{ $t('attributes.check_number') }}: <span class="font-bold">{{
          payment.check_number
        }}</span></p>
      <p class='capitalize-first'>{{ $t('attributes.bank_name') }}: <span class="font-bold">{{ cBankName }}</span></p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'InvoiceItemEditDialogItem',
  props: {
    payment: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({metadata: 'invoicing/getInvoiceMetadata'}),
    cBankName() {
      return this.payment.issuing_bank ? this.metadata.bank.find(el => el.id === parseInt(this.payment.issuing_bank)).name : ''
    }
  },
  methods: {
    ...mapActions({deletePayment: 'invoicing/deletePayment'}),
    deleteThisPayment() {
      this.deletePayment({payment_id: this.payment.id})
    }
  }
}
</script>

