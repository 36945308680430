export default {
  getSearchResult(state){
    return state.searchResult
  },
  getSearchValue(state){
    return state.searchValue
  },
  getSearchDropdown(state){
    return state.searchDropdown
  },
}
