<template>

  <router-link :to="{name:routeName}" class="flex items-center space-x-2 dark:text-white p-2 rounded-md" :class="activeClasses" v-if='!disabled'>
    <font-awesome-icon :icon="['fal', icon]" size="lg" />&nbsp;
    <span class="cursor-pointer text-sm capitalize-first">{{ label }}</span>
  </router-link>

  <span  :class="activeClasses" class="flex items-center space-x-2 dark:text-white text-gray-500 p-2 rounded-md" v-else>
    <font-awesome-icon :icon="['fal', icon]" size="lg" />&nbsp;
    <span class="cursor-pointer text-sm capitalize-first">{{ label }}</span>
  </span>

</template>

<script>
export default {
  name: 'InvoicingNavLink',
  props:{
    routeName:{
      type: String,
      required: true
    },
    icon:{
      type: String,
      required: true
    },
    label:{
      type: String,
      required: true
    },
  },
  data(){
    return{
      disabled: null
    }
  },
  computed:{
    isActive(){
      return this.$route.name === this.routeName
    },
    activeClasses(){
      return  this.isActive ? 'flex items-center text-theme-25 dark:text-theme-22 font-medium' : 'flex items-center'
    }
  },
  beforeCreate() {
    this.disabled = this.$route.name === this.routeName
  }
}
</script>

