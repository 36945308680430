<template>
  <BasePageFull>
    <template #body>
      <BaseSimpleCard classes='col-start-2 col-span-10'>
        <template #default>
          <div class=''>
            <!--            <form class="space-y-8 divide-y divide-gray-200">-->
            <div class="space-y-8 divide-y divide-gray-200">

              <div>
                <h1 class='font-bold text-2xl capitalize mb-6'>{{ $t('global.my_profile') }}</h1>
                <div>
                  <h3 class="text-lg leading-6 font-medium">
                    Personal Information
                  </h3>
                </div>
                <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

                  <div class="sm:col-span-4">
                    <label for="first-name" class="form-label capitalize-first">
                      {{ $t('global.first_name') }}
                    </label>
                    <div class="mt-1">
                      <input
                          type="text"
                          v-model='user.first_name'
                          id="first-name"
                          autocomplete="given-name"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                  </div>

                  <div class="sm:col-span-4">
                    <label for="last-name" class="form-label capitalize-first">
                      {{ $t('global.last_name') }}
                    </label>
                    <div class="mt-1">
                      <input
                          type="text"
                          v-model='user.last_name'
                          id="last-name"
                          autocomplete="family-name"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                  </div>

                  <div class="sm:col-span-4">
                    <label for="email" class="form-label capitalize-first">
                      {{ $t('global.email_address') }}
                    </label>
                    <div class="mt-1">
                      <input
                          id="email"
                          v-model='user.email'
                          type="email"
                          autocomplete="email"
                          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"/>
                    </div>
                  </div>
                </div>
                <div class='col-span-12 xl:col-span-6'>
                  <div class="mt-6">
                    <BaseButton
                        :type="editPasswordType" :icon='editPasswordIcon'
                        @click='this.editPassword = !this.editPassword'
                        :title="$t(editPasswordText, { var: $t('global.user')})"/>
                  </div>
                </div>
                <div class="flex" v-if="editPassword">
                  <div class="w1/5">
                    <BaseInput :label="$t('attributes.password')"
                               :placeholder="$t('attributes.password')"
                               name='password'
                               v-model='user.password'
                               :disabled="false"
                               :required="true"
                               :errors="errors.password"
                               @change.self='getInputValue'/>

                    <BaseInput :label="$t('attributes.password_confirmation')"
                               :placeholder="$t('attributes.password_confirmation')"
                               name='password_confirmation'
                               v-model='user.password_confirmation'
                               :disabled="false"
                               :required="true"
                               :errors="errors.password_confirmation"
                               @change.self='getInputValue'/>
                  </div>
                </div>

              </div>
            </div>
              <div class="flex flex row justify-end py-3 px-5 gap-x-5">
                <BaseButton
                    color="dark"
                    icon='long-arrow-left'
                    @click="this.$router.go(-1)"
                    :title="$t('button.back') "/>

                 <BaseButton
                    color="primary"
                    icon='paper-plane'
                    @click="submitForm"
                    :title="$t('button.send') "/>
              </div>
          </div>
        </template>
      </BaseSimpleCard>
    </template>
  </BasePageFull>
</template>

<script>

import {mapGetters, mapMutations, mapActions} from 'vuex'
export default {
  name: 'ProfilePage',
  data() {
    return {
      editPassword: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/getAuth',
      errors: 'auth/getErrors'
    }),
    editPasswordText() {
      return !this.editPassword ? 'modifier le mot de passe' : 'annuler la modification'
    },
    editPasswordIcon() {
      return !this.editPassword ? 'unlock' : 'times'
    },
    editPasswordType() {
      return !this.editPassword ? 'warning' : 'secondary'
    }
  },
  methods: {
    ...mapMutations({setAuth: 'auth/setAuth'}),
    ...mapActions({updateUser: 'user/updateUser'}),
    getInputValue({attribute, value}) {
      this.user[attribute] = value
    },
    submitForm() {
      let user = this.user
      if(!user.password?.length){
        delete user.password
        delete user.password_confirmation
      }
      this.updateUser({
        id:user.id,
        user:user,
        successCallback: () => {
          this.$h.toastify( this.$t('toasts.auth_updated_m', {var: this.$t('global.user')}) , {className: 'toastify-content success'})
        },
      })
    }
  },
  watch: {
    // user: {
    //   handler(newValue) {
    //     if (typeof newValue === 'object') {
    //       this.setAuth({auth: JSON.parse(JSON.stringify(newValue))})
    //     }
    //   },
    //   deep: true
    // }
  },

}
</script>

<style scoped>

</style>
