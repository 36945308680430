<template>
  <div

    class='mx-12 box px-6 py-5 my-4'>
    <div class="flex justify-between sm:flex-row items-center mb-5">
      <h1 class="text-xl font-bold capitalize-first my-10'">{{ $t('enums.event.type.' + event_typeTitle) }}</h1>
      <div>
        <BaseButton color='primary'
                    v-if="editionMode && $can('manage_content')"
                    icon='plus'
                    :tooltip="$t('button.add_new_m', {var: $t('global.event_type')})"
                    @click="addNewEventKind"
        />
      </div>
    </div>
    <div v-if="event_type.length > 0">
      <EventKindSettingsComponent
        v-for="(item_event_kind, index) in event_type"
        :key="index"
        :editionMode="editionMode"
        :event_kind="item_event_kind"
        :event_type="event_type"
        :event_typeTitle="event_typeTitle"
        :errors="errors"
        class="my-1.5"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

import EventKindSettingsComponent from "./EventKindSettingsComponent";

export default {
  name: "EventTypeSettingsComponent",
  components: {
    EventKindSettingsComponent,
  },
  props: {
    event_type: {
      type: Object,
      required: true
    },
    event_typeTitle: {
      type : String,
      required: true
    },
    errors: {
      type : Object,
      required: false
    },
    editionMode: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      availableLabels: 'settings/getSystemSettings',
    })
  },
  methods: {
    ...mapMutations({addNewEventKindToEventType:'settings/setNewEventKindToEventType'}),
    addNewEventKind() {
      let newEventKind = {
        'arriving_before': null,
        'default_duration': null,
        'event_type': this.event_typeTitle,
        'name': this.getNameArrayByLanguages(),
        'planifiable': false,
        'need_more_infos': false,
        'validation_rules': null,
        'visible' : 1,
        'form_callback': null,
      }

      this.addNewEventKindToEventType({eventKind: newEventKind, eventType: this.event_typeTitle})
    },
    getNameArrayByLanguages() {
      let name_array_by_languages = {};
      for(let label in this.availableLabels.general.available_languages) {
         name_array_by_languages[this.availableLabels.general.available_languages[label]] = "";
      }
      return name_array_by_languages
    }
  }
}
</script>

