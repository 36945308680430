export default {
  getEvents(state) {
    return state.eventsList
  },
  getEvent(state) {
    return state.event
  },
  getPlanningMetadata(state) {
    return state.planningMetadata
  },
  getEventMetadata(state){
    return state.eventMetadata
  },
  getEventDisponibilities(state){
    return state.eventDisponibilities
  }
}
