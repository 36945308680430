<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="modal-content p-0 max-h-screen flex flex-col"  :class="modalClasses" open v-if="isModalVisible">
        <!--#region header -->
        <div class='flex place-content-end'>
          <div class='flex items-center p-5 cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div>
        </div>
        <!--#endregion -->

        <section class="flex-1 modal-body overflow-scroll">
          <div class="overflow-x-auto">
            <div v-if='errors' v-for='(error,index) in errors' :key='index' class="form-help text-red-600">
              {{ $t(error[0], {attribute: $t('attributes.' + index.substring(8)), min: min[index.substring(8)], max: max[index.substring(8)]}) }}
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap"></th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first">{{ $t('attributes.quantity') }}</th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap w-full capitalize-first">{{ $t('attributes.name') }}</th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first">{{ $t('attributes.price') }}</th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap capitalize-first">{{ $t('attributes.reduction') }}</th>
                </tr>
              </thead>
              <tbody>
              <InvoiceItemRowComponent v-for='(item, index) in itemList' :key='index'
                                       :item="item"
                                       @changeInItem='updatePushedItem'
                                       @selectItem="setItem" />
              </tbody>
            </table>
          </div>
        </section>

        <!--#region footer-->
        <div class="flex flex row justify-end py-3 px-5 bg-gray-100 gap-x-5">
          <BaseButton :title="$t('button.add')" icon='plus' @click="submitForm"/>
          <BaseButton :title="$t('button.close')" icon='times' color='danger' @click="_close"/>

        </div>
        <!--#endregion -->
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isModalVisible"></div>
    </transition>
  </teleport>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import InvoiceItemRowComponent from './components/InvoiceItemRowComponent'

export default {
  name: 'invoiceItemAddDialog',
  components:{ InvoiceItemRowComponent },
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium',
      validator: function(value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['close', 'backdropClicked'],
  data() {
    return {
      isPulsing: false,
      editeditemList: [],
      min: { quantity: 0, price_excluding_vat: 0, reduction: 0},
      max: { quantity: 999999, price_excluding_vat: 999999, reduction: 100},
    }
  },
  computed: {
    ...mapGetters({
      itemList:'item/getItemList',
      errors: 'auth/getErrors'
    }),
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    }
  },
  methods: {
    ...mapActions({
      fetchAllItem: 'item/fetchAllItem',
      putMultipleInvoiceItems: 'invoicing/updateMultipleInvoiceItems'
    }),
    setItem(item){
      if( ! this.editeditemList.some( element => element.item_id === item.item_id )){
        this.editeditemList.push(item)
      }else{
        this.editeditemList = this.editeditemList.filter( element => element.item_id !== item.item_id )
      }
    },
    updatePushedItem(item){
      (this.editeditemList = this.editeditemList.filter( element => element.item_id !== item.item_id )).push( item )
    },
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    },
    submitForm(){
      this.putMultipleInvoiceItems({invoiceId: this.$route.params.invoicing_id, items: {items:this.editeditemList},successCallback:this._close})
      this.editeditemList=[];
    },
  },
  watch: {
    isPulsing: function(currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    }
  },
  mounted(){this.fetchAllItem()}
}
</script>

<style scoped>

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index : 1000;
  overflow : hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width : 90%;
  }
}

@media only screen and (min-width:  577px){
  dialog.small {
    width : 20%;
    max-width : 300px;
    scroll-behavior: auto;
  }
  dialog.medium {
    width : 30%;
    max-width : 500px;
  }
  dialog.large {
    width : 50%;
    max-width : 700px;
  }
  dialog.x-large{
    width : 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active{
  animation: modal 0.3s ease-in ;
}

.modal-leave-active{
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active{
  animation: backdrop 0.3s ease-in ;
}
.backdrop-leave-active{
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation : pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform : scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0,0,0,0);
  }
  to {
    background-color: rgba(0,0,0,0.3);
  }
}
</style>

