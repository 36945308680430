<template>
  <table class='table table--sm'>
    <thead>
    <tr>
      <th class='border uppercase'>{{ $t('global.subtotal') }}</th>
      <th class='border text-center uppercase'>{{ $t('global.excluding_vat') }}</th>
      <th class='border text-center uppercase'>{{ $t('global.vat') }}</th>
      <th class='border text-center uppercase'>{{ $t('global.tax_included') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for='distribution in vatDistributionOrdered'>
      <td class='border'>{{ distribution.vat_rate }}%</td>
      <td class='border text-right'>{{ formatPrice( distribution.total_vat_excluded ) }}</td>
      <td class='border text-right'>{{ distribution.vat_rate !== 0 ? formatPrice( distribution.vat_amount ) : '' }}</td>
      <td class='border text-right'>{{ formatPrice( distribution.total_vat_included ) }}</td>
    </tr>
    <tr>
      <td class='border'>Total</td>
      <td class='border text-right'>{{ sumExcludedPriceFormated }}</td>
      <td class='border text-right'>{{ sumVatAmountFormated }}</td>
      <td class='border text-right'>{{ sumIncludedPriceFormated }}</td>
    </tr>
    <tr>
      <td colspan='3' class='border text-right'>
        <a
            v-if="auth_role !== 'customer-user'"
            href='#'
            @click.prevent="this.$emit('click-paiments')"
            class="text-indigo-600 cursor-pointer"
        >{{ $t('global.payment') }}</a>
        <p v-else>{{ $t('global.payment') }}</p>
      </td>
      <td class='border text-right'> {{ alreadyPaidFormated }}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InvoicingTotalTable',
  props: {
    already_paid: {
      required: false
    },
    vat_distribution: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({auth: 'auth/getAuth'}),
    auth_role(){
      return this.auth.roles[0];
    },
    alreadyPaidFormated(){
      return Number(parseFloat( this.already_paid) ).toLocaleString('fr-FR', {style: "currency", currency: "EUR"})
    },
    vatDistributionOrdered(){
      return this.vat_distribution.sort((a, b) => a.vat_rate > b.vat_rate ? 1 : -1);
    },
    sumExcludedPriceFormated(){
      return Number( this.sumExcludedPrice() ).toLocaleString('fr-FR', {style: "currency", currency: "EUR"})
    },
    sumIncludedPriceFormated(){
      return Number( this.sumIncludedPrice() ).toLocaleString('fr-FR', {style: "currency", currency: "EUR"})
    },
    sumVatAmountFormated(){
      return Number( this.sumVatAmount() ).toLocaleString('fr-FR', {style: "currency", currency: "EUR"})
    },
  },
  methods: {
    formatPrice( price ){
      return Number( price ).toLocaleString('fr-FR', {style: "currency", currency: "EUR"});
    },
    sumExcludedPrice(){
      return this.vat_distribution.reduce((amount, item) => amount + item.total_vat_excluded, 0 );
    },
    sumIncludedPrice(){
      return this.vat_distribution.reduce((amount, item) => amount + item.total_vat_included, 0 );
    },
    sumVatAmount(){
      return this.vat_distribution.reduce((amount, item) => amount + item.vat_amount, 0 );
    },
  }
}
</script>
