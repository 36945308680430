export default {
  setImplantationList(state, implantationList) {
    state.implantationList = implantationList
  },
  setImplantation(state, implantation) {
    state.implantation = implantation
  },
  setImplantationMetaData(state, metadata) {
    state.implantationMetadata = metadata
  },
}
