<template>
  <div class='grid grid-cols-2 gap-x-5' v-if="worksEventOfTypeDis">

    <div>
      <div >
        <BaseShowEditSelectSimple v-if="work?.events"
                                  :label="$t('attributes.dispersion_type')"
                                  :placeholder="$t('attributes.dispersion_type')"
                                  name="dispersion_type"
                                  :editionMode='editionMode'
                                  v-model="worksEventOfTypeDis.dispersion_type"
                                  :defaultValue="metadata?.dispersion_type?.filter(el => el.id === worksEventOfTypeDis.dispersion_type)"
                                  :options="metadata?.dispersion_type"
                                  :errors="errors?.dispersion_type"/>
      </div>
    </div>
    <div>
      <div v-if="worksEventOfTypeDis.inhumation_type === 18" class='col-start-2'>
        <BaseShowEditInput
          v-if="work?.events"
          :label="$t('attributes.other_resource')"
          :placeholder="$t('attributes.other_resource')"
          v-model="worksEventOfTypeDis.operator_infos"
          :editionMode="editionMode"
          :required="true"
        />
      </div>
      <div v-else class='col-start-2'>

        <BaseShowEditSelectAjax v-if="worksEventOfTypeDis" :key='worksEventOfTypeDis'
                                :editionMode='editionMode'
                                url='api/cemetery-for-select'
                                :label="$t('attributes.cemetery')"
                                v-model='worksEventOfTypeDis.cemetery_id'
                                name='cemetery_id'
                                trackBy='id'
                                attributeLabel='title'
                                :defaultAjax="cemeteryBaseValue"/>
      </div>
    </div>

    <RecovererForm :editionMode='editionMode' />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RecovererForm from '@p/works/form/subpages/informations/components/events/burial/components/RecovererForm'

export default {
  name: 'SelectDisForm',
  components:{
    RecovererForm,
  },
  props: {
    editionMode: {type: Boolean, required: true},
  },
  computed: {
    ...mapGetters({
      work: 'work/getWork',
      metadata: 'work/getWorkMetadata',
      errors: 'auth/getErrors',
      worksEventOfTypeDis: 'work/getDisEventInWork'
    }),
    cemeteryBaseValue(){
      if (this.worksEventOfTypeDis.cemetery) {
        return { id: this.worksEventOfTypeDis.cemetery_id, title: this.worksEventOfTypeDis.cemetery?.nom_formated}
      } else {
        return {}
      }
    },
  },
}
</script>
