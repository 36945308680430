<template>
  <div class='grid grid-cols-2 gap-x-5 ' v-if="checkDeposit && metadata">

    <div class='col-span-2 xl:col-span-1'>
      <BaseInput :label="$t('attributes.deposit_number')"
                 :placeholder="$t('attributes.deposit_number')"
                 name="deposit_number"
                 v-model="checkDeposit.deposit_number"
                 :required="true"
                 :errors="errors.deposit_number"
                 :disabled="permission"
                 />
    </div>

    <div class='col-span-2 xl:col-span-1'>
      <BaseSimpleDatePicker :key="cDepositDate"
                            :label="$t('attributes.deposit_date')"
                            :defaultValue="cDepositDate"
                            :disabled='permission'
                            :required='true'
                            name='deposit_date'
                            :errors='errors.deposit_date'
                            @change='getInputValue'/>
    </div>

    <div class='col-span-2 xl:col-span-1'>
      <BaseInput
                 :label="$t('attributes.nb_checks')"
                 :placeholder="$t('attributes.nb_checks')"
                 name="nb_checks"
                 v-model="amountOfCheckSelected"
                 :errors="errors.nb_checks"
                 :disabled="true"
                 @change.self="getInputValue"/>
    </div>

    <div class='col-span-2 xl:col-span-1'>
      <BaseSimpleDatePicker :key="cCreationDate"
                            :label="$t('attributes.creation_date')"
                            :defaultValue="cCreationDate"
                            :disabled='permission'
                            name='created_at'
                            :errors='errors.created_at'
                            @change='getInputValue'/>
    </div>

    <div class='col-span-2 xl:col-span-1'>
      <BaseInput :label="$t('attributes.total')"
                 :placeholder="$t('attributes.total')"
                 name="total_amount"
                 v-model="checksTotalAmount"
                 :errors="errors.total_amount"
                 :disabled="true"
                 @change.self="getInputValue"/>
    </div>

    <div class='col-span-2 xl:col-span-1'>
      <BaseSelectSimple v-if='metadata && metadata.checkDepositStatus'
                        :label="$t('attributes.status')"
                        :placeholder="$t('attributes.status')"
                        name='status'
                        :defaultValue="metadata.checkDepositStatus.find(status => status.id === checkDeposit.status)"
                        :options='metadata.checkDepositStatus'
                        :disabled="permission"
                        :required="true"
                        :errors='errors.status'
                        @setSelected.self='getInputValue' />

<!--      <dropdown-status :options='metadata.checkDepositStatus'-->
<!--                       :current='metadata.checkDepositStatus.filter(status => status.id === checkDeposit.status)[0]'-->
<!--                       color='200'-->
<!--                       @select='mSelect' />-->

    </div>

    <div class='col-span-2 xl:col-span-1  '>
      <BaseSelectSimple v-if='metadata?.bank_account'
                        :label="$t('attributes.bank')"
                        :placeholder="$t('attributes.bank')"
                        name='bank_account_id'
                        :defaultValue="metadata.bank_account?.filter(account => account.id === parseInt(checkDeposit.bank_account_id))[0]"
                        :options='metadata.bank_account'
                        :required='true'
                        :disabled="permission"
                        :errors='errors.bank_account_id'
                        @setSelected.self='getInputValue' />
    </div>

    <div class='col-span-2 xl:col-span-1'>
      <BaseSelectSimple v-if='metadata?.bank'
                        :key="bankDefaultValue"
                        :label="$t('attributes.bank_name')"
                        :placeholder="$t('attributes.bank_name')"
                        name='bank_id'
                        :defaultValue="bankDefaultValue"
                        :options='metadata.bank'
                        :disabled="permission"
                        :required="true"
                        :searchable='true'
                        :errors='errors.bank_id'
                        @setSelected.self='getInputValue' />
    </div>

  </div>

  <div class='flex justify-end mt-10 gap-x-5  '>
    <BaseButton v-if='!permission && checkDeposit.id' icon='print' :title="$t('button.print')" @click='printCheckDeposit' />
    <BaseButton v-if='!permission' icon='paper-plane' :title="$t('button.send')" @click='submitForm' />
  </div>

  <BaseCardSection :sectionTitle="$t('global.check_to_be_deposited')" class="mt-10">

    <CheckTable :model="this.$route.params.id === 'new' ? metadata?.checks_list : initialPaymentList"
                :isCheckboxDisabled="this.$route.params.id === 'new' ? false : initialCheckDepositStatus !== 'TBDEP'"
                @change="updateEditList"/>

  </BaseCardSection>
</template>

<script>


// import BaseSelectSimple from '@c/baseComponents/form/select/BaseSelectSimple'

import CheckTable from '@p/check/components/CheckTable'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import printJS from "print-js";

export default {
  name: 'CheckDepositForm',
  components:{ CheckTable },
  data() {
    return {
      initialCheckDepositStatus: null,
      initialPaymentList: null
    }
  },
  computed:{
    ...mapGetters({
      checkDeposit: 'invoicing/getCheckDeposit',
      metadata: 'invoicing/getCheckMetadata',
      errors: 'auth/getErrors',
      editedCheckList: 'invoicing/getEditedCheckList'
    }),
    cCreationDate(){
      return this.checkDeposit.created_at ? new Date(moment(this.checkDeposit.created_at, 'DD/MM/YYYY')) : null
    },
    cDepositDate(){
      return this.checkDeposit.deposit_date ? new Date(moment(this.checkDeposit.deposit_date, 'DD/MM/YYYY')) : null
    },
    bankDefaultValue(){
      return this.metadata?.bank.filter(bank => bank.id === parseInt(this.checkDeposit.bank_id))[0]
    },
    permission(){
      return false
    },
    amountOfCheckSelected(){
      return this.checkDeposit.nb_checks = this.checkDeposit.payments ? this.checkDeposit.payments.length : 0
    },
    checksTotalAmount(){
      let totalAmount = 0
      if(this.checkDeposit.payments !== [] && this.checkDeposit.payments !== null && this.checkDeposit.payments !== undefined){
        this.checkDeposit.payments.forEach(check => totalAmount +=parseFloat(check.amount))
      }
      this.checkDeposit.total_amount = totalAmount
      return totalAmount.toFixed(2)
    }
  },
  methods:{
    ...mapActions({
      getNewCheckDeposit: 'invoicing/fetchNewCheckDeposit',
      getCheckDepositById: 'invoicing/fetchCheckDepositById',
      postCheckDeposit: 'invoicing/storeCheckDeposit',
      updateCheckDeposit: 'invoicing/updateCheckDeposit'
    }),
    ...mapMutations({ alterCheckDeposit: 'invoicing/alterCheckDeposit' }),
    printCheckDeposit(){
      let baseURL;
      if (localStorage.getItem('url') !== null) {
        baseURL = localStorage.getItem('url')
      } else {
        baseURL = window.location.protocol + "//" + window.location.host + '/';
      }
      printJS(baseURL + 'api/render/check-deposit/' + this.$route.params.id + '?check-deposit=true')
    },
    getInputValue({attribute, value}){
      this.alterCheckDeposit({attribute, value})

      if(attribute === 'bank_account_id'){
        this.alterCheckDeposit({ attribute: 'bank_id', value: this.metadata.bank_account.filter(account => account.id === this.checkDeposit.bank_account_id)[0].bank_id})
      }
    },
    submitForm(){
      if(this.$route.params.id === 'new'){
        this.postCheckDeposit({checkDeposit: this.checkDeposit,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.created_m', {var: this.$t('global.check_deposit')}) , {className: 'toastify-content success'})
          }
        })
      }else{
        this.updateCheckDeposit({id: this.checkDeposit.id, checkDeposit: this.checkDeposit,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.updated_m', {var: this.$t('global.check_deposit')}) , {className: 'toastify-content success'})
          }
        })
      }
    }
  },
  watch: {
    errors(newValue) {
      if(newValue.payments){
        this.$h.toastify( this.$t('toasts.no_selected_m', {var: this.$t('global.check')}) , {className: 'toastify-settings warning'})
      }
    }
  },
  async beforeMount() {
    this.$route.params.id === 'new' ? await this.getNewCheckDeposit() : await this.getCheckDepositById({check_deposit: this.$route.params.id})
    this.$route.params.id === 'new' ? this.checkDeposit.payments = [] : this.initialPaymentList = this.checkDeposit.payments
    this.initialCheckDepositStatus = this.checkDeposit.status
  }
}
</script>

