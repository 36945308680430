<template>
  <SettingsPageFull>
    <!--#region header -->
    <template #title>

      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','piggy-bank']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ pageTitle }}</span>
      </div>
    </template>

    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100 '>
        <div >
          <BaseButton
            v-if="editionMode"
            :icon="buttonIcon"
            :title="buttonTitle"
            @click="submitForm"
          />
        </div>
      <BaseButton
          v-if="this.$route.name === 'settings_edit_bank_route' && permission"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getBankAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
      <BaseButton
                  color='dark'
                  icon='long-arrow-left'
                  :title="$t('button.back')"
                  @click="this.$router.go(-1)"/>
      </div>
    </template>
    <!--#endregion -->

    <!--#region form -->
    <template #body>

      <div class='grid grid-cols-12 gap-x-5'>
        <div class='col-span-12 xl:col-span-6'>
          <BaseShowEditInput
              :label="$t('attributes.name')"
              v-model="bank.name"
              :errors="errors.name"
              :placeholder="$t('attributes.name')"
              :editionMode="editionMode"
              :required="true"
          />
        </div>
        <div class='col-span-12 xl:col-span-6'>
          <BaseShowEditInput
              :label="$t('attributes.iban')"
              v-model="bank.iban"
              :errors="errors.iban"
              :placeholder="$t('attributes.iban')"
              :editionMode="editionMode"
              :required="true"
          />
        </div>
        <div class='col-span-12 xl:col-span-6'>

          <BaseBankShowEditSelectSimple
              :errors='errors'
              v-model='bank.bank_id'
              :metadata='metadata'
              :edition-mode='editionMode'
              :required="true"
          />



        </div>
        <div class='col-span-12 xl:col-span-6'>
          <BaseShowEditTextArea
              :label="$t('attributes.remarks')"
              :placeholder="$t('attributes.remarks')"
              v-model="bank.remarks"
              :errors="errors.remarks"
              :editionMode="editionMode"
          />
        </div>

      </div>
      <!--#endregion -->

    <!--#region submit -->
<!--      <div class='flex-1 flex flex-row justify-end mt-10'>-->
<!--        <BaseButton-->
<!--            v-if="editionMode"-->
<!--            :icon="buttonIcon"-->
<!--            :title="buttonTitle"-->
<!--            @click="submitForm"-->
<!--        />-->
<!--      </div>-->
      <!--#endregion -->

    </template>
  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
  name: 'TheBankAccountFormPage',
  data(){
    return {
      editionMode:false
    }
  },
  computed: {
    ...mapGetters({bank: 'bank/getBank', metadata: 'bank/getBankMetadata', errors: 'auth/getErrors'}),
    permission() {
      return this.$can('manage_content')
    },
    pageTitle() {
      return this.$route.name === 'settings_edit_bank_route' ? this.$t('navigation.content_bank_edit_title') : this.$t('navigation.content_bank_new_title')
    },
    buttonTitle() {
      return this.$route.name === 'settings_edit_bank_route' ? this.$t('button.edit_new_m', {var: this.$t('global.bank_account')}) : this.$t('button.add_new_m', {var: this.$t('global.bank_account')})
    },
    buttonIcon() {
      return this.$route.name === 'settings_edit_bank_route' ? 'paper-plane' : 'plus'
    }
  },
  methods: {
    ...mapActions({
      fetchBank: 'bank/fetchBank',
      fetchNewBank: 'bank/fetchNewBank',
      postBank: 'bank/storeBank',
      updateBank: 'bank/updateBank'
    }),
    ...mapMutations({alterBank: 'bank/alterBank'}),
    getBankAndChangeEditionMode() {
      this.fetchBank({id: this.$route.params.id })
      this.editionMode = !this.editionMode
    },
    getInputValue({attribute, value}) {
      this.alterBank({attribute: attribute, value: value})
    },
    submitForm() {
      if(this.$route.name === 'settings_edit_bank_route') {
        this.updateBank({
          id: this.$route.params.id,
          bank: this.bank,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.updated_m', {var: this.$t('global.bank_account')}), {className: 'toastify-content success'})
            this.editionMode = false
          }})

      } else {
        this.postBank({
          bank: this.bank,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.add_m', {var: this.$t('global.bank_account')}), {className: 'toastify-content success'})
            this.$router.push({name: 'settings_list_bank_route'})
          }
        })

      }
    }
  },
  beforeMount() {
    this.$route.name !== 'settings_edit_bank_route' ?
        (this.fetchNewBank(), this.editionMode = true)  : (this.fetchBank({id: this.$route.params.id}))
  }
}
</script>
