import apiClient from '@u/apiClient'
import router from '@/router'

let baseErrors = []
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}
export default {

  //region fetch
  async fetchImplantationIndex({ commit }) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient.get('api/implantation')
      .then(async response => {
        await commit('setImplantationList', response.data)
      })
      .catch(async reason => {
        await commit('fetchImplantationIndex', reason)
      })
  },
  async fetchImplantationByID({ commit }, payload) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient.get(`api/implantation/${payload.id}`)
      .then(async response => {
        await commit('setImplantation',response.data.implantation )
        await commit('setImplantationMetaData', response.data.metadata )
      })
      .catch(async reason => {
        await commit('fetchImplantationByID', reason)
      })
  },
  async fetchNewImplantation({ commit }) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient.get('api/model/new?model=implantation')
      .then(async response => {
        await commit('setImplantation', response.data.implantation )
        await commit('setImplantationMetaData',response.data.metadata )
      })
      .catch(async reason => {
        await commit('fetchNewImplantation', reason)
      })
  },
  //endregion

  //region store
  async storeNewImplantation({ commit }, payload) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient.post('api/implantation', payload.implantation)
        .then(response => {
          if(response.status === 201){
            commit('auth/setErrors', baseErrors, {root: true})
            processSuccessCallback(payload)
          }
        })
        .catch(reason => {
          console.error('storeNewImplantation', reason)
        })
  },
  //endregion

  //region update
  async updateImplantation({ commit }, payload) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient.put(`api/implantation/${payload.id}`, payload.implantation)
        .then(response => {
          if(response.status === 200) {
            commit('auth/setErrors', baseErrors, {root: true})
            processSuccessCallback(payload)
            router.push({ name: 'settings_list_implantation' })
          }
        })
        .catch(reason => {
          console.error('updateImplantation', reason)
        })
  },
  //endregion

  //region delete
  async deleteImplantationById({commit}, payload) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient.delete(`api/implantation/${payload.id}`)
      .then(async response => {
        if (response.status === 200) {
          await commit('setImplantationList', response.data)
          processSuccessCallback(payload);
        }
      })
      .catch(async reason => {
        console.error('deleteImplantationById', reason)
      })
  },
  //endregion

  //region undoDelete
  async undoDeleteImplantationById({commit}, payload) {
    commit('auth/setErrors', baseErrors, {root: true})
    await apiClient.get(`api/implantation/undo-delete/${payload.id}`)
        .then(async response => {
          if(response.status  === 200 ){
            await commit('setImplantationList', response.data)
            processSuccessCallback(payload);
          }
        })
        .catch(async reason => {
          console.error('undoDeleteImplantationById', reason)
        })
  },
  //endregion
}
