<template>
  <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">

    <div class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">

      <h2 class="font-bold text-2xl xl:text-3xl text-center xl:text-left capitalize-first">
        {{ $t('navigation.login_title') }}
      </h2>

      <form @submit.prevent='submitForm'>

        <div class="mt-8 space-y-8">

          <div v-if='isShowingUrlInput'>
            <input
              type="text"
              class="login__input form-control py-3 px-4 border-gray-300 block"
              placeholder="https://..."
              v-model.trim="url" />
          </div>

          <div>
            <input
              type="text"
              class="login__input form-control py-3 px-4 border-gray-300 block"
              :placeholder="$t('global.username')"
              v-model.trim="user_name" />

            <div v-if='errors.user_name' v-for='(error,index) in errors.user_name' :key='index' class="form-help text-red-600">
              {{ $t(error, {attribute: $t('attributes.user_name')}) }}
            </div>
          </div>

          <div>
            <div class='relative'>
              <input
                :type="passwordShowType"
                class="login__input form-control py-3 px-4 border-gray-300 block"
                :placeholder="$t('attributes.password')"
                v-model.trim="password" />
              <div class='absolute right-0 top-0 inset-y-0 mr-4 flex items-center'>
                <font-awesome-icon class='cursor-pointer dark:bg-dark-2 text-gray-500' :icon="['fal', 'eye']" size='lg' @click='switchPasswordVisibility'/>
              </div>
            </div>

            <div v-if='errors && errors.password' class="form-help text-red-600 capitalize-first">
              {{ $t('validation.wrong_password') }}
            </div>
          </div>

        </div>

        <div
          class="flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
        >
          <div class="flex items-center mr-auto">
            <input
              id="remember-me"
              type="checkbox"
              class="form-check-input border mr-2"
              v-model='remember_me'
            />
            <label class="cursor-pointer select-none capitalize-first" for="remember-me">{{ $t('attributes.remember_me') }}</label
            >
          </div>

          <div class='cursor-pointer text-theme-primary' @click='isShowingUrlInput = !isShowingUrlInput'>
            <font-awesome-icon :icon="['fal', 'cogs']"/>
          </div>

        </div>
        <div class="mt-5 xl:mt-8 text-center xl:text-left">
          <button class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top capitalize-first">
            {{ $t('button.login') }}
          </button>
        </div>

      </form>

    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginForm',
  data() {
    return {
      user_name: null,
      password: null,
      url: null,
      remember_me: false,
      passwordShowType: 'password',
      isShowingUrlInput: false
    }
  },
  computed: {
    ...mapGetters({
      errors:'auth/getErrors',
      isAuthenticated: 'auth/isAuthenticated'
    }),
    cIsUrlValid(){
      return localStorage.getItem('url') !== null || ( window.location.host !== '127.0.0.1' && window.location.host !== 'localhost' && this.mIsValidHttpUrl( localStorage.getItem('url') ) );
    }
  },
  methods: {
    ...mapActions({
      login: 'auth/login'
    }),
    redirectToMainPage(){
      this.$router.push({ name: 'work_progress_route' })
    },
    async submitForm() {
      this.login({ auth_form: { user_name: this.user_name, password: this.password, remember_me: this.remember_me ? 'on' : undefined }, successCallback: this.redirectToMainPage });
    },
    switchPasswordVisibility() {
      this.passwordShowType = this.passwordShowType === 'password' ? 'text' : 'password'
    },
    mIsValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    }
  },
  watch: {
    url(newValue) {
      localStorage.setItem('url', newValue)
    }
  }
}
</script>
