<template>
  <div class='col-span-12 flex justify-between mt-3'>

    <BaseButton color="primary"
                :is-outline="true"
                icon="funnel-dollar"
                @click="this.showForm = !this.showForm"
                :tooltip="$t('button.remove')"
                :title="null"/>
    <BaseButton color="danger"
                :is-outline="true"
                icon="times"
                @click="handleClickRemove"
                :tooltip="$t('button.remove')"
                :title="null"/>
  </div>
  <div class="flex formandresult" >
  <StatsBlockForm v-if="showForm"
                  :index="index"
                  :block_form="block.form"
                  @updateFormField="handleUpdateValues"
                  @closeForm="showForm = false"
  />
  <div class="w-1/2 mt-12 px-6" v-if="block?.data?.no_data">
    <div class="flex content-center bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <font-awesome-icon :icon="['fal', 'exclamation-triangle']" size="2x" class='text-yellow-700' color="warning"/>
        </div>
        <div class="ml-3 h-full">
          <h1 class="text-yellow-400">{{$t('validation.no_data_in_stats')}}</h1>
        </div>
      </div>
    </div>
  </div>
    <div class="w-1/2 mt-12 px-6" v-if="block?.data?.no_display">
    <div class="flex content-center bg-yellow-50 border-l-4 border-yellow-400 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <font-awesome-icon :icon="['fal', 'exclamation-triangle']" size="2x" class='text-yellow-700' color="warning"/>
        </div>
        <div class="ml-3 h-full">
          <h1 class="text-yellow-400">{{$t('validation.no_display_selected_in_stats')}}</h1>
        </div>
      </div>
    </div>
  </div>
    <div class="w-1/2 mt-12 px-6" v-if="block?.data?.development">
    <div class="flex content-center bg-danger-100 border-l-4 border-yellow-400 p-4">
      <div class="flex">
        <div class="flex-shrink-0">
          <font-awesome-icon :icon="['fal', 'exclamation-triangle']" size="2x" class='text-danger-700' color="danger"/>
        </div>
        <div class="ml-3 h-full">
          <h1 class="text-danger-500">Development in progress..... </h1>
        </div>
      </div>
    </div>
  </div>

  <div class="w-1/2" v-if="block?.data?.table || block?.data?.chart">
      <div class="flex justify-end  m-2 mt-4 p-9">
          <Menu as="div" class="relative inline-block text-left">
              <div>
                  <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                     Actions
                      <font-awesome-icon :icon="['fal', 'chevron-down']"/>
                  </MenuButton>
              </div>

              <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                  <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div class="py-1">
                          <MenuItem v-slot="{ active }">
                              <button @click="handelClickExport" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Export excel</button>
                          </MenuItem>
<!--                          <MenuItem v-slot="{ active }">
                              <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Support</a>
                          </MenuItem>
                          <MenuItem v-slot="{ active }">
                              <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">License</a>
                          </MenuItem>
                          <form method="POST" action="#">
                              <MenuItem v-slot="{ active }">
                                  <button type="submit" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block w-full px-4 py-2 text-left text-sm']">Sign out</button>
                              </MenuItem>
                          </form>-->
                      </div>
                  </MenuItems>
              </transition>
          </Menu>
      </div>
  <div class="border border-dark m-2 mt-4 p-9" v-if="block?.data?.table">
    <BaseContentTable
      :columns="block.data?.table?.metadata?.columns"
      :modelList="block.data?.table?.data"
      />
  </div>
  <div class="border border-dark m-2 mt-4 p-9"  v-if="block?.data?.chart" >
    <Bar
      v-if="['CRE_PER'].includes(block?.form?.type)"
      :chart-options="chartOptions"
      :chart-data="block?.data?.chart"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <Pie
    v-else
      :chart-options="chartOptions"
      :chart-data="block?.data?.chart"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"

    />
  </div>
  </div>
  </div>
</template>
<script>
import {mapMutations, mapActions, mapGetters} from "vuex";
import StatsBlockForm from "@p/stats/components/StatsBlockForm";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Pie,Bar } from 'vue-chartjs'
import {
  Chart,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LinearScale,
  CategoryScale
} from 'chart.js'

Chart.register(Title, Tooltip, Legend, ArcElement, CategoryScale, BarElement,
  LinearScale, BarElement, CategoryScale, LinearScale,)

export default {
  name: 'StatsBlockComponent',
  components: {StatsBlockForm, Pie,Bar,Menu,MenuItem,MenuItems,MenuButton},
  data() {
    return {
      showForm: true,
      chartData: {
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
          {
            // backgroundColor: ['#41B883', '#E46651', '#00D8FF', '#DD1B16'],
            data: [40, 20, 80, 10]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
      }
  },
  props: {
    index: {},
    block: {},
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { return {} }
    },
    plugins: {
      type: Object,
      default: () => { return {} }
    }
  },
    computed: {
        ...mapGetters({statsBlock:'stats/getStatsBlock'})
    },
  methods: {
    ...mapMutations({processUpdateFormMutation:'stats/updateFormAttribute'}),
    ...mapActions({processExportStatsBlockDataToExcel:'stats/exportStatsBlockDataToExcel'}),
    handleClickRemove() {
      // console.log('in child')
      this.$emit('clickRemove', this.index)
    },
      handelClickExport(){
        this.processExportStatsBlockDataToExcel({
                ...this.statsBlock[this.index].form,
                action:'export',
                index: this.index,
                successCallback: () => {
                    // this.$emit('closeForm')
                }
            })

      },
    handleUpdateValues(values) {
    this.processUpdateFormMutation(values)
    }
  },
  mounted() {
    console.log("block?.data?.chart", this.block?.data?.chart)
  }
}
</script>
