<template>
  <div class="hidden xl:flex flex-col justify-center items-center min-h-screen z-100">
    <div class='flex flex-col items-center space-y-6'>
      <div>
        <svg class='w-24 h-24' viewBox="0 0 384 512">
          <g class="fa-group">
            <path fill="#FFF" d="M192 0C79.7 101.33 0 220.92 0 300.55 0 425.05 79 512 192 512s192-86.95 192-211.45C384 220.6 303.78 100.86 192 0zm0 448c-70.58 0-128-52.89-128-117.89 0-44.11 25.84-71.51 34.34-79.76A8 8 0 0 1 112 256v40a56 56 0 0 0 112 0c0-72-112.64-64.77-39.43-164.33a9.37 9.37 0 0 1 10.58-3.17c1.62.53 5.38 2.24 5.38 6.78 0 33.55 25 55 51.57 77.63 33.38 28.54 67.9 58 67.9 117.21C320 395.11 262.58 448 192 448z" class="fa-secondary"></path>
            <path fill="transparent" d="M252.09 212.92c33.38 28.54 67.9 58 67.9 117.21 0 65-57.41 117.87-128 117.87S64 395.11 64 330.11c0-44.11 25.84-71.51 34.34-79.76A8 8 0 0 1 112 256v40a56 56 0 0 0 112 0c0-72-112.64-64.77-39.43-164.33a9.37 9.37 0 0 1 10.58-3.17c1.62.53 5.38 2.24 5.38 6.78 0 33.56 25 55 51.56 77.64z" class="fa-primary"></path>
          </g>
        </svg>
      </div>
      <h1 class='text-4xl font-medium text-white'>Crema Manager</h1>
    </div>
  </div>
<!--  <div>-->
<!--    <svg aria-hidden="true" class="w-8 h-8" data-icon="flame" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><g class="fa-group"><path fill="#FFF" d="M192 0C79.7 101.33 0 220.92 0 300.55 0 425.05 79 512 192 512s192-86.95 192-211.45C384 220.6 303.78 100.86 192 0zm0 448c-70.58 0-128-52.89-128-117.89 0-44.11 25.84-71.51 34.34-79.76A8 8 0 0 1 112 256v40a56 56 0 0 0 112 0c0-72-112.64-64.77-39.43-164.33a9.37 9.37 0 0 1 10.58-3.17c1.62.53 5.38 2.24 5.38 6.78 0 33.55 25 55 51.57 77.63 33.38 28.54 67.9 58 67.9 117.21C320 395.11 262.58 448 192 448z" class="fa-secondary"></path><path fill="currentColor" d="M252.09 212.92c33.38 28.54 67.9 58 67.9 117.21 0 65-57.41 117.87-128 117.87S64 395.11 64 330.11c0-44.11 25.84-71.51 34.34-79.76A8 8 0 0 1 112 256v40a56 56 0 0 0 112 0c0-72-112.64-64.77-39.43-164.33a9.37 9.37 0 0 1 10.58-3.17c1.62.53 5.38 2.24 5.38 6.78 0 33.56 25 55 51.56 77.64z" class="fa-primary"></path></g></svg>-->
<!--  </div>-->
</template>

<script>
export default {
  name: 'LoginLeftSide'
}
</script>
