export default {

  setCustomerList(state, customersList){
    state.customersList = customersList
  },
  setCustomer(state, customer){
    state.customer = customer
  },
  setCustomerMetadata(state, metadata){
    state.metadata = metadata
  },
  setCustomerActivity(state, activity){
    state.customerActivity = activity
  }
}
