<template>
  <Menu as="div" class="relative mx-5 text-left">
    <div>
      <MenuButton class="btn btn-outline-secondary text-sm font-medium text-gray-300 rounded-md hover:text-white
                        focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 z-0">
        <span class='capitalize-first'>{{ $t('global.action')}}</span>
        <font-awesome-icon class="ml-3 w-5 h-5" aria-hidden="true" :icon="['fal', 'chevron-down']"/>
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0">

      <MenuItems class="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300 z-10">
        <div class="px-1 py-1">

          <MenuItem v-slot="{ active }">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click.prevent="this.$emit('changeEditMode')">
              <font-awesome-icon :icon="['fal', 'edit']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.rename') }}</span>
            </button>
          </MenuItem>

          <MenuItem v-slot="{ active }">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click="this.$emit('showFile')">
              <font-awesome-icon :icon="['fal', 'eye']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.show') }}</span>
            </button>
          </MenuItem>

<!--          <MenuItem v-slot="{ active }">-->
<!--            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click="this.$emit('sendFile')">-->
<!--              <font-awesome-icon :icon="['fal', 'paper-plane']" size="lg"/>&nbsp;-->
<!--              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.send') }}</span>-->
<!--            </button>-->
<!--          </MenuItem>-->

<!--          Micky - 23/02/2022: On cache le bouton car les clients ne nous ont pas encore demander cette fonctionalité -->

          <MenuItem v-slot="{ active }">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click.prevent="this.$emit('printFile')">
              <font-awesome-icon :icon="['fal', 'print']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.print') }}</span>
            </button>
          </MenuItem>

          <MenuItem v-slot="{ active }" class="text-red-500">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click="this.$emit('deleteFile')">
              <font-awesome-icon :icon="['fal', 'times']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.delete') }}</span>
            </button>
          </MenuItem>

        </div>
      </MenuItems>

    </transition>
  </Menu>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'

export default {
  name: 'WorkAttachmentsDropDown',
  components: { Menu, MenuButton, MenuItem, MenuItems },
  props: {
    attachmentIndex: {type: Number, required: true},
  },
}
</script>
