<template>
  <RadioGroup v-model='modelValue'>

    <div class='flex flex-row space-x-3'>
      <RadioGroupOption
        as='template'
        v-for='(dispo, type) in metadata.crema_dispo'
        :key='type'
        :value='type'
        v-slot='{ active, checked }'
      >
        <div
          :class="[
            active ? 'ring-2 ring-offset-2 ring-offset-red-300 ring-white ring-opacity-60' : '',
            checked ? 'bg-theme-red text-white ' : 'bg-gray-300 text-white ',
          ]"
          class='relative flex-1 flex px-5 py-4 rounded-lg shadow-md cursor-pointer focus:outline-none'
        >
          <div class='flex items-center justify-between w-full'>
            <div class='flex items-center'>
              <div class='text-sm'>
                <RadioGroupLabel as='p'
                                 :class="checked ? 'text-white' : 'text-gray-900'"
                                 class='font-medium capitalize-first'
                >
                  
                  {{ metadata.event_kinds && metadata.event_kinds.filter(item => item.id == type)[0]?.name}}
                </RadioGroupLabel>
                <RadioGroupDescription as='span'
                                       :class="checked ? 'text-white' : 'text-black'"
                                       class='inline'>
                </RadioGroupDescription>
              </div>
            </div>
            <div v-show='checked' class='flex-shrink-0 text-white'>
              <svg class='w-6 h-6' viewBox='0 0 24 24' fill='none'>
                <circle cx='12' cy='12' r='12' fill='#fff' fill-opacity='0.2'/>
                <path d='M7 13l3 3 7-7' stroke='#fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' />
              </svg>
            </div>
          </div>
        </div>
      </RadioGroupOption>
      
   
    </div>
  </RadioGroup>
</template>

<script>

import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption
} from '@headlessui/vue'

export default {
  name: 'SelectCremaType',
  emits: ['update:modelValue'],
  props: [
    'modelValue',
    'metadata'
  ],
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupDescription,
    RadioGroupOption
  },
  watch: {
    modelValue(newValue) {
      console.log('newValue',newValue)
      this.$emit('update:modelValue', newValue );
    }
  },
}

</script>
