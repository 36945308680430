export default {
  attributes: {
          valeur:"valeur (€)",
    stats_type: 'type de statistiques',
    postCode: 'code postal',
    generate: 'générer',
    period: 'période',
    anatomic_boxes: 'boîte anatomique',
    num_cerfa: 'Numéro de CERFA',
    transporter: 'Transporteur/collecteur',
    box_number: 'Num. de boîte',
    delivery_date: 'date de remise',
    accounting_code: 'code comptable',
    additional_info: 'informations complémentaires',
    address: 'adresse',
    age: 'age',
    all_day: 'journée entière',
    amount: 'montant',
    app: 'url',
    arriving_before: 'arrivée avant (en minutes)',
    ask_nb_people_when_work_creating: 'demander le nombre de personne presentes lors de la création d\'un dossier',
    asset: 'ressource',
    asset_id: 'id de la ressource',
    asset_type: 'type de ressource',
    available: 'disponible',
    available_docs: 'type de documents de facturation disponible',
    available_languages: 'langues disponibles',
    awaiting_at_crema: 'urne en attente au crématorium',
    awaiting_dead_line: 'date de fin de stockage',
    awaiting_duration_in_month: 'durée d\'attente (en mois)',
    awaiting_reason: 'raison de la mise en attente',
    bank: 'compte bancaire',
    bank_account_id: 'compte bancaire',
    bank_id: 'banque',
    bank_name: 'nom de la banque',
    birth_date: 'date de naissance',
    birth_place: 'lieu de naissance',
    burial_date: 'date des funérailles',
    burial_place: 'lieu des funérailles',
    burial_time: 'heure des funérailles',
    burial_type: 'destination des cendres',
    buy_price: 'prix d\'achat',
    cemetery: 'cimetière',
    ceremony: 'cérémonie',
    ceremony_place: 'salle de cérémonie',
    ceremony_type: 'type de cérémonie',
    check_deposit: 'bordereaux de remise',
    check_number: 'numéro de chèque',
    check_status: 'statut du chèque',
    church: 'église',
    city: 'ville',
    civility: 'civilité',
    coffin_putting_info: 'infos mise en bière',
    comment: 'commentaire',
    companies: 'sociétés',
    company: 'société',
    company_id: 'id de la société',
    complementary_info: 'informations complémentaires',
    contact_name: 'personne de contact',
    contact_email: 'personne de contact - Adresse email',
    contact_phone: 'personne de contact - Téléphone',
    content: 'contenu',
    country: 'pays',
    created_at: 'création',
    creation_date: 'date de création',
    cremation: 'crémation',
    cremation_authorization_number: 'numéro d\'imagerie radioscopique',
    cremation_number: 'n. crémation	',
    current_password: 'mot de passe courant',
    customer: 'client',
    customer_id: 'client',
    customer_type: 'type de client',
    date: 'date',
    date_from: 'date',
    date_to: 'date de fin',
    day: 'jour',
    days: 'jours',
    death_certificate_number: 'numéro d\'acte de décès',
    death_city: 'ville du décès',
    death_date: 'date du décès',
    death_place: 'lieu du décès',
    death_post_code: 'code postal du lieu du décès',
    deceased: 'défunt',
    deceased_birth_date: 'date de naissance défunt',
    deceased_birth_place: 'lieu de naissance défunt',
    deceased_burial_date: 'date des funérailles défunt',
    deceased_burial_place: 'lieu des funérailles défunt',
    deceased_burial_time: 'heure des funérailles défunt',
    deceased_burial_type: 'destination des cendres défunt',
    deceased_city: 'ville défunt',
    deceased_civility: 'civilité défunt',
    deceased_coffin_putting_info: 'infos mise en bière défunt',
    deceased_country: 'pays défunt',
    deceased_created_at: 'création défunt',
    deceased_death_date: 'date du décès défunt',
    deceased_death_place: 'lieu du décès défunt',
    deceased_deleted_at: 'suppression défunt',
    deceased_first_name: 'prénom défunt',
    deceased_fullname: 'nom défunt',
    deceased_id: 'id défunt',
    deceased_last_name: 'nom défunt',
    deceased_maiden_name: 'nom de jeune fille défunt',
    deceased_national_number: 'numéro national défunt',
    deceased_post_code: 'code postal défunt',
    deceased_spouse_first_name: 'prénom de l\'époux(se) défunt',
    deceased_spouse_last_name: 'nom de famille de l\'époux(se) défunt',
    deceased_spouse_usual_first_name: 'prénom usuel de l\'époux(se) défunt',
    deceased_street: 'adresse défunt',
    deceased_updated_at: 'mise à jour défunt',
    deceased_usual_first_name: 'prénom usuel défunt',
    deceased_weight: 'corpulence défunt',
    default_bank_id: 'banque par défaut',
    default_coffin_arriving_in_minutes: 'intervalle de l\'arrivée du cercueil en minutes',
    default_company: 'société par défaut',
    default_country: 'pays par défaut',
    archive_after_x_days: "jours d'inactivité avant archivage",
    default_deadLine: 'échéance par défaut',
    default_duration: 'durée par défaut (en minutes)',
    default_language: 'langue par défaut',
    deleted_at: 'suppression',
    deposit_date: 'date de dépot',
    deposit_number: 'numéro de dépot',
    description: 'description',
    dispersion_type: 'type de dispersion',
    display_dashboard: 'afficher sur le tableau de bord',
    due_date: 'échéance',
    duration: 'durée',
    email: 'adresse e-mail',
    emission_date: 'date d\'émission',
    end_task_survey: 'questionnaire en fin de tâche',
    end_time: 'heure de fin',
    event: 'évènement',
    event_kind: 'type d\'évènement',
    event_kind_id: 'id du type d\'évènement',
    event_kinds: 'types d\'évenements',
    event_title: 'titre l\'évènement',
    event_type: 'type d\'évènement',
    excerpt: 'extrait',
    extra_size: 'extra size',
    first_name: 'prénom',
    fiscal_year: 'année fiscale',
    form_field_type: 'type de champ',
    from: 'du',
    funeral_date_and_place: 'date et lieu de la cérémonie',
    gender: 'genre',
    group: 'groupe',
    group_tasks: 'groupe de tâche',
    hash_recovered_by: 'nom assitant funéraire',
    height: 'hauteur',
    hospital: 'hopital',
    hour: 'heure',
    hour_from: 'heure de début',
    hour_to: 'heure de fin',
    iban: 'iban',
    id: 'identifiant',
    inhumation_type: 'type d\'inhumation',
    internal_note: 'note interne',
    invoice: 'facture',
    invoice_number: 'numéro de facture',
    issuer_name: 'nom de l\'émetteur',
    issuing_bank: 'banque émettrice',
    item: 'article',
    item_type: 'type d\'article',
    item_group: 'groupe d\'article',
    language: 'langue',
    last_name: 'nom',
    maiden_name: 'nom de jeune fille',
    messageText: 'message predéfini',
    minute: 'minute',
    minutes_after: 'délai ultérieur (en minutes)',
    minutes_before: 'délai antérieur (en minutes)',
    mobile: 'portable',
    mobile_country: 'pays(mobile)',
    mobile_field: 'mobile',
    month: 'mois',
    name: 'nom',
    national_number: 'numéro national',
    nb_checks: 'nombre de chèques',
    nb_peoples: 'nombre de personnes',
    nb_place: 'nombre de places',
    need_more_infos: 'précision requise',
    next_invoice_status: 'prochain statut',
    note_intern: 'note interne (visible uniquement par le personel du crématorium)',
    operator_infos: ' information de l\'opérateur',
    operator_type: 'type d\'opérateur',
    option: 'option',
    other: 'autre',
    other_resource: 'autre ressource',
    paid_by: 'payé par',
    paid_by_names: 'payé par (nom + prénom)',
    password: 'mot de passe',
    password_confirmation: 'confirmation du mot de passe',
    payment: 'versement',
    payment_mode: 'mode de versement',
    payments: 'versements',
    phone: 'téléphone',
    phone_country: 'pays(telephone)',
    phone_field: 'telephone',
    plannable: 'planifiable',
    post_code: 'code postal',
    presumed_duration: 'durée éventuelle',
    price: 'prix',
    price_excluding_vat: 'prix hors taxes',
    price_including_vat: 'prix ttc',
    purchaser: 'payé par',
    quantity: 'quantité',
    question: 'question',
    received_by: 'reçu par',
    receivers: 'destinataire(s)',
    reception: 'réception',
    reception_place: 'salle de réception',
    reception_type: 'type de réception',
    recoverer_type: 'cendres repris par',
    reduction: 'réduction',
    reference: 'référence',
    reference_extern: 'n. crémation	',
    reference_quote: 'référence du devis',
    remarks: 'remarques',
    remember_me: 'se souvenir de moi',
    reporter: 'constatataire',
    responsable: 'responsable',
    responsable_id: 'id du responsable',
    rest_home: 'maison de repos',
    roles: 'roles',
    scattering: 'dispertion',
    second: 'seconde',
    sex: 'sexe',
    size: 'taille',
    slug: 'slug',
    solde: 'solde',
    solded: 'soldé',
    spouse_first_name: 'prénom de l\'époux(se)',
    spouse_last_name: 'nom de famille de l\'époux(se)',
    spouse_usual_first_name: 'prénom usuel de l\'époux(se)',
    start_task_survey: 'questionnaire au commencement de la tâche',
    start_time: 'heure de début',
    status: 'statut',
    storage_other: 'lieu de stockage',
    storage_start_date: 'date de début de stockage',
    street: 'adresse',
    subject: 'objet',
    task: 'tâche',
    task_name: 'nom de la tâche',
    time: 'heure',
    time_from: 'heure de début',
    time_to: 'heure de fin',
    timeslot: 'créneau',
    title: 'titre',
    to: 'au',
    total: 'total',
    total_amount: 'montant total',
    total_htva: 'total hors taxe',
    total_paid: 'total des versements',
    total_tvac: 'total ttc',
    TVA: 'TVA',
    type: 'type',
    undo: 'annuler',
    updated_at: 'mise à jour',
    urn_pending_at_the_crematorium: 'urne en attente au crématorium',
    user: 'utilisateur',
    user_name: "nom d'utilisateur",
    username: "nom d'utilisateur",
    usual_first_name: 'prénom usuel',
    uuid: 'clé',
    vat_rate: 'taux de la tva',
    website: 'site internet',
    weight: 'corpulence',
    work: 'dossier',
    work_id: 'identifiant du dossier',
    work_title: 'titre du dossier',
    work_type: 'type de dossier',
    work_type_id: 'id du type de dossier',
    year: 'année',
    parent_type: 'tâche liée à/aux',
    short_name: 'nom court',
    simultaneous_cremation: 'crémations simultanées',
    task_priority: 'priorité de la tâche',
  }
}
