<template>
  <teleport to='body'>
    <transition name='modal'>
      <dialog class='modal-content p-0 max-h-screen flex flex-col' :class='modalClasses' open v-if='isModalVisible'>
        <!--#region header -->
        <div class='flex'>
          <div class='flex-1 p-5'>
            <h2 class='text-xl font-bold capitalize-first'>
              {{ $t('button.add_new_m', { var: $t('attributes.payment') }) }}
            </h2>
          </div>
          <div class='flex items-center p-5 cursor-pointer' @click='_close'>
            <font-awesome-icon :icon="['fal', 'times']" size='lg' />
          </div>
        </div>
        <!--#endregion -->

        <div class='flex flex row justify-end py-3 px-5 bg-gray-100 gap-x-5'>
          <BaseButton
              :title="$t('button.save_new_m', {var: $t('attributes.payment')} )"
              icon='plus'
              @click='submitForm' />
          <BaseButton
              :title="$t('button.close')"
              @click='_close'
              icon='times'
              color='danger' />

        </div>

        <!--#region footer-->
        <section class='flex-1 modal-body overflow-scroll'>
          <!--          <slot name="settings"></slot>-->
          <div class='flex flex-col gap-3'>

            <div class='flex flex-row gap-x-3'>
              <BaseButtonInput class='w-1/2'
                               :label="$t('attributes.payment')"
                               :placeholder="$t('attributes.payment')"
                               name='amount'
                               :value='payment.amount'
                               :disabled="permission"
                               :errors='errors.amount'
                               :is-clickable='false'
                               type='number'
                               :min='0'
                               :max='invoice.solde'
                               icon='euro-sign'
                               @change.self='getInputValue' />
              <div class='w-1/2'>
                <BaseSimpleDatePicker :key="cEmissionDate"
                                      :label="$t('attributes.date')"
                                      :defaultValue="cEmissionDate"
                                      :disabled='permission'
                                      name='emission_date'
                                      :errors='errors.emission_date'
                                      @change='getInputValue'/>
              </div>
            </div>

            <SelectCustomer
              :key='invoice.customer_id'
              :errors='errors'
              :get-input-value='getInputValue'
              :model='payment'
              :permission="permission"
              :metadata='metadata' />

            <BaseInput :label="$t('attributes.paid_by_names')"
                       :placeholder="$t('attributes.paid_by_names')"
                       name='purchaser'
                       v-model='payment.purchaser'
                       :disabled="permission"
                       :errors='errors.purchaser'
                       />

            <BaseSimpleRadioGroup name='payment_mode'
                                  :label="$t('attributes.payment_mode')"
                                  :defaultValue=null
                                  :options='metadata.mode'
                                  :is-showing-key='true'
                                  :is-returning-key='true'
                                  :errors="errors.payment_mode"
                                  :disabled='permission'
                                  translationExtention='enums.invoicing.mode'
                                  @change.self='getInputValue' />

            <div v-if="payment.payment_mode === 'CHE'" class='grid grid-cols-2 gap-5 my-3'>
              <div>
                <BaseInput :label="$t('attributes.check_number')"
                           :placeholder="$t('attributes.check_number')"
                           name="check_number"
                           v-model="payment.check_number"
                           :required="true"
                           :errors="errors.check_number"
                           :disabled="permission"
                />
              </div>
              <div>
                <div class='col-span-2 xl:col-span-1'>
                  <BaseSelectSimple v-if='metadata && metadata.bank'
                                    :label="$t('attributes.bank_name')"
                                    :placeholder="$t('attributes.bank_name')"
                                    name='issuing_bank'
                                    :defaultValue="metadata.bank?.find(bank => bank.id === invoice?.customer?.default_bank_id)"
                                    :options='metadata.bank'
                                    :disabled="permission"
                                    :required="true"
                                    :searchable='true'
                                    :errors='errors.issuing_bank'
                                    @setSelected.self='getInputValue' />
                </div>
              </div>
            </div>

<!--            <SelectUserSimple-->
<!--              :errors='errors'-->
<!--              :get-input-value='getInputValue'-->
<!--              :metadata='metadata'-->
<!--              :model='payment'-->
<!--              name='recipient_id'-->
<!--              :label="$t('attributes.received_by')"-->
<!--              :permission="permission" />-->
            <BaseShowEditFilteredUsersComponent
              :label="$t('attributes.received_by')"
              v-model='payment.recipient_id'
              :editionMode='true'
              name='recipient_id'
              :metadata='metadata'
              :permission="permission"
              :errors='errors?.recipient_id'
            />


            <BaseTextArea :label="$t('attributes.description')"
                          :placeholder="$t('attributes.description')"
                          name='comment'
                          :value='payment.comment'
                          :errors='errors.comment'
                          :disabled='permission'
                          @change.self='getInputValue' />

            <BaseSelectSimple :key="payment" v-if='payment.next_invoice_status && metadata.invoice_status'
                              :label="$t('attributes.next_invoice_status')"
                              :placeholder="$t('attributes.next_invoice_status')"
                              name='next_invoice_status'
                              :defaultValue='metadata.invoice_status?.filter( el=> el.id === payment.next_invoice_status)'
                              :options='metadata.invoice_status'
                              :disabled="permission"
                              :errors='errors.next_invoice_status'
                              :need-translation='true'
                              translationPrefix='enums.invoicing.status'
                              @setSelected='getNextStatusValue' />
          </div>
        </section>
        <!--#endregion -->
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class='backdrop' @click='_backdropClicked' v-if='isModalVisible'></div>
    </transition>
  </teleport>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import moment from 'moment'
import BaseShowEditFilteredUsersComponent from "@c/GlobalFormComponents/BaseShowEditFilterdUsersComponent.vue";

export default {
  name: 'InvoicingAddPaimentDialog',
  components: {BaseShowEditFilteredUsersComponent},
  emits: ['close', 'backdropClicked'],
  props: {
    isModalVisible: {
      type: Boolean,
      required: true
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String, required: false, default: 'medium',
      validator: function(value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPulsing: false,
      next_invoice_status: null,
    }
  },
  computed: {
    ...mapGetters({
      payment: 'invoicing/getPayment',
      metadata: 'invoicing/getPaymentMetadata',
      invoice: 'invoicing/getInvoice',
      errors: 'auth/getErrors',
    }),
    cEmissionDate(){
      return this.payment.emission_date ? new Date(moment(this.payment.emission_date, 'DD/MM/YYYY')) : null
    },
    cCustomerDefaultValue(){
      return this.invoice.customer ? this.metadata.customers.filter(el=> el.id === this.invoice.customer.id) : {}
    },
    permission(){
      return this.$can('manage_invoice')
    },
    hasHeader() {
      return this.$slots.header || (this.headerTitle && this.headerTitle.length > 0)
    },
    hasFooter() {
      return this.$slots.actions
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    }
  },
  methods: {
    ...mapActions({
      fetchNewPayment:'invoicing/fetchNewPayment',
      postNewPayment:'invoicing/storeNewPayment',
    }),
    ...mapMutations({
      alterPayment: 'invoicing/alterPayment',
      setErrors:'auth/setErrors'
    }),

    getInputValue({ attribute, value }) {
      if(attribute === 'payment_mode'){
        value === 'CHE' ? this.alterPayment({attribute: 'issuing_bank', value: this.invoice?.customer?.default_bank_id}) : this.alterPayment({attribute: 'issuing_bank', value: null})
      }
      this.alterPayment({attribute: attribute, value: value})
    },
    getNextStatusValue({ attribute, value }) {
      this.next_invoice_status = value
    },
    submitForm() {

      this.postNewPayment({
        invoice_id: this.$route.params.invoicing_id,
        data_request: { ...this.payment},
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.created_m', {var: this.$t('global.payment')}), { className: 'toastify-content success' })
          this._close()
        }
      })
    },
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    }
  },
  watch: {
    isPulsing: function(currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    },
    payment(){
      if(this.payment?.customer_id === null && this.invoice?.customer?.id){
        this.alterPayment({attribute: 'customer_id', value: this.invoice?.customer?.id})
      }
      this.alterPayment({attribute: 'next_invoice_status', value: this.invoice.status})
    },
    isModalVisible(currentValue, _prevValue) {
      if (currentValue != _prevValue && currentValue) {
        this.fetchNewPayment({ invoice_id: this.$route.params.invoicing_id })
        this.alterPayment({attribute: 'next_invoice_status', value: this.invoice.status})
      }
    }
  },
}
</script>

<style scoped>

/* dialog */
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 577px) {
  dialog.small {
    width: 20%;
    max-width: 300px;
    scroll-behavior: auto;
  }

  dialog.medium {
    width: 30%;
    max-width: 500px;
  }

  dialog.large {
    width: 50%;
    max-width: 700px;
  }

  dialog.x-large {
    width: 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active {
  animation: modal 0.3s ease-in;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active {
  animation: backdrop 0.3s ease-in;
}

.backdrop-leave-active {
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation: pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>
