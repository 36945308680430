<template>
  <BasePageFull>

    <template #cta>
      <BaseButton
          type="dark"
          icon='long-arrow-left'
          @click="this.$router.push({name: 'works'})"
          :title="$t('button.back')"/>
    </template>


    <template #body>
      <div v-if='work' class='col-span-3 flex flex-col gap-y-4'>
        <TheWorkNav  />
        <WorkPreviewEvent v-for="(event, index) in work?.events" :key="index" :event='event'/>
      </div>

      <div v-if='work' class='col-span-9'>
        <router-view ></router-view>
      </div>

    </template>

  </BasePageFull>
</template>

<script>
import TheWorkNav from '@p/works/form/sideBar/nav/TheWorkNav'
import WorkPreviewEvent from '@/components/pages/works/form/sideBar/events/WorkPreviewEvent'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheWorkFormNewPage',
  components: { TheWorkNav, WorkPreviewEvent },
  computed: {
    ...mapGetters({
      work: 'work/getWork',
      auth:'auth/getAuth'
    }),
    auth_role(){
      return this.auth.roles[0]
    },
  },
  methods:{
    ...mapActions({fetchNewWork: 'work/fetchNewWork'})
  },
  // created() {
  //   this.fetchNewWork()
  // },
  beforeMount() {
    this.fetchNewWork()
  },
}
</script>
