export default {

  //#region setters

  setSelectedWorkTask(state, payload) {
    state.selectedWorkTask = payload
  },

  //#endregion

  //#region modifiers

  alterWorkTaskQuestionValue(state, payload){
    if(payload.form === 'starting_form'){
      state.selectedWorkTask.starting_form[payload.index].value = payload.value
    }else if(payload.form === 'ending_form'){
      state.selectedWorkTask.ending_form[payload.index].value = payload.value
    }
  },

  updateWorkTaskInWork( state, payload ){
    if( payload.parent_type === 'App\\Models\\Event' ){
      if( state.work.events && state.work.events.length > 0 ){
        state.work.events.forEach(( event, index_event ) => {
          if( event.work_tasks && event.work_tasks.length ){
            event.work_tasks.forEach(( work_task, index_work_task ) => {
              if( work_task.id === payload.id ){
                state.work.events[ index_event ].work_tasks[ index_work_task ] = payload;
              }
            })
          }
        });
      }
    }
    else if( payload.parent_type === 'App\\Models\\Work' ){
      if( state.work.work_tasks && state.work.work_tasks.length ){
        state.work.work_tasks.forEach(( work_task, index_work_task ) => {
          if( work_task.id === payload.id ){
            state.work.work_tasks[ index_work_task ] = payload;
          }
        })
      }
    }
  }

  //#endregion

}
