<template>

  <div class="box"  v-if="this.auth && this.auth?.roles">
    <div class="relative flex items-center p-5">
      <div class="flex-1 flex flex-row">
        <div class="flex-1 flex flex-col">
          <h2 class='text-lg font-medium text-base'>{{ work.title }}</h2>
          <h3 class='font-medium text-small'>{{ work.reference }}</h3>
        </div>
        <DropdownWorkStatus v-if='cIsEditView && !cIsCustomerUser' :options='metadata.status' :current='work.status' @select='mUpdateWorkStatus' />
        <BaseLabel v-else :content='work.status_string' :color="work.status_color"/>
      </div>
    </div>
    <div class="p-5 border-t border-gray-200 dark:border-dark-5 space-y-5" v-if="this.auth">

      <workNavLink :route-name='cIndexRoute'
                   icon='user'
                   :label="$t('global.general_information') " />

      <workNavLink  v-if='!cIsCustomerUser' :disabled='!cIsEditView'
                   route-name='work_form_urn_route'
                   icon='database'
                   :label="$t('global.urn')" />

      <workNavLink v-if='!cIsCustomerUser' :disabled='!cIsEditView'
                   route-name='work_form_event_list_route'
                   icon='clock'
                   :label="$t('global.events')" />

      <workNavLink v-if="!cIsCustomerUser && this.$can('see_tasks')" :disabled='!cIsEditView'
                   route-name='work_form_tasks_route'
                   icon='tasks'
                   :label="$t('global.tasks')" />
    </div>

    <div class="p-5 border-t border-gray-200 dark:border-dark-5 space-y-5"  v-if='!cIsCustomerUser'>
      <workNavLink :disabled='!cIsEditView'
                   route-name='work_form_documents_route'
                   icon='file'
                   :label="$t('global.document')" />

      <workNavLink :disabled='!cIsEditView'
                   route-name='work_form_attachments_route'
                   icon='paperclip'
                   :label="$t('global.attachments')" />
    </div>

    <div class="p-5 border-t border-gray-200 dark:border-dark-5 space-y-5"  v-if='!cIsCustomerUser'>
      <workNavLink :disabled='!cIsEditView'
                   route-name='work_form_invoicing_route'
                   icon='euro-sign'
                   :label="$t('global.invoicing')" />
    </div>

    <div class="p-5 border-t border-gray-200 dark:border-dark-5 space-y-5"  v-if='!cIsCustomerUser'>
      <workNavLink :disabled='!cIsEditView'
                   route-name='work_form_activities_route'
                   icon='file-alt'
                   :label="$t('global.activities')" />
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WorkNavLink from './WorkNavLink'


export default {
  name: 'TheWorkNav',
  components: { WorkNavLink },
  computed: {
    ...mapGetters({ work:'work/getWork', metadata:'work/getWorkMetadata', auth:'auth/getAuth' }),
    cIsEditView() {
      return this.$route.name !== 'work_form_new_route';
    },
    cIndexRoute() {
      return this.cIsEditView ? 'work_form_edit_route' : 'work_form_new_route'
    },
    cIsCustomerUser() {
      return this.auth.roles[0] === "customer-user";
    },
  },
  methods: {
    ...mapActions({updateWorkStatus: 'work/updateWorkStatus'}),
    mUpdateWorkStatus( newStatus ){
      this.updateWorkStatus({
        work_id: this.work.id,
        form_data: {
          status: newStatus.id,
        },
        successCallback: () =>
            this.$h.toastify( this.$t('toasts.updated_m', {var: this.$t('global.work')}) , {className: 'toastify-content success'})
      });
    }
  },
}
</script>
