<template>
  <div class='col-span-12 xl:col-span-6'>

    <template v-if='editionMode'>
      <BaseSelectSimple v-if='metadata.deceased.burial_types'
                        :label="$t('attributes.burial_type')"
                        :placeholder="$t('attributes.burial_type')"
                        name='burial_type'
                        :defaultValue='metadata.deceased.burial_types.filter(el=> el.id === computedDeceased.burial_type)'
                        :options='metadata.deceased.burial_types'
                        :required='true'
                        :errors='errors.deceased ? errors.deceased.burial_type : []'
                        @setSelected='getInputValue' />
    </template>
    <div v-else class='mt-1'>
      <dt class='text-sm font-medium text-gray-500 capitalize-first pt-1'>
        {{ $t('attributes.burial_type') }}
      </dt>
      <dd class='mt-1 text-sm text-gray-900'>
        {{ cDisplayedValueWhenNotEditionMode }}
      </dd>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'


export default {
  name: 'WorkFormDeceasedBurialType',
  props: {
    computedDeceased: {type: Object, required: true},
    getInputValue: {type: Function, required: true},
    editionMode: {type: Boolean, required: true},
  },
  computed:{
    ...mapGetters({work: 'work/getWork', metadata: 'work/getWorkMetadata', errors: 'auth/getErrors'}),
    cDisplayedValueWhenNotEditionMode(){
      if (this.computedDeceased.burial_type){
        return this.metadata?.deceased?.burial_types?.find(el=> el.id === this.computedDeceased.burial_type).name
      } else {
        return '-'
      }

    },
  }
}
</script>
