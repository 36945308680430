<template>
  <div class='grid grid-cols-3 gap-x-5'  v-if="editionMode">
    <div class='col-start-2'>
      <BaseInput
                 :label="$t('attributes.option')"
                 :placeholder="$t('attributes.option')"
                 name='option'
                 v-model='option.name'
                 :disabled="permission"
                 />
      <div v-if="(option.name === null || option.name.trim() === '') && (errorsOptionStart || errorsOptionEnd) " class="text-xs text-red-700">Veuillez écrire le nom de l'option</div>
    </div>

    <BaseButton
        icon='times'
        color='primary'
        :tooltip="$t('button.delete_v', {var: $t('attributes.option')})"
        @click="this.$emit('deleteOptionButton')"
        class='ml-5 mt-11 w-8'
    />
  </div>
  <div v-else>
      <span class='flex flex-row mb-1' >
        <font-awesome-icon :icon='["fal","check-square"]' class='mr-1 mt-0.5 text-blue-900'/><span class="capitalize-first"> {{ option.name }}</span>
      </span>
  </div>
</template>

<script>

export default {
  name: 'TaskOptionComponent',
  props: {
    option: {
      type: Object,
      required: true
    },
    optionIndex: {
      type: Number,
      required: true
    },
    permission: {
      type: Object,
      required: false
    },
    editionMode:{
      type: Boolean,
      required: true
    },
    errorsOptionStart:{
      type: Boolean,
      required: false,
      default: false
    },
    errorsOptionEnd:{
      type: Boolean,
      required: false,
      default: false
    },
  },
  computed: {
    cClassfield() {
      if(editionMode) {
        return 'grid grid-cols-3 gapx-5'
      } else {
        return ''
      }
    }
  },
  methods:{
    getInputValue({attribute, value}){
      this.$emit('optionChange', { attribute, value, optionIndex: this.optionIndex})
    }
  }
}
</script>
