<template>
  <SettingsPageFull>

    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','badge-dollar']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ pageTitle }}</span>
      </div>
    </template>

    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100'>
        <BaseButton
          v-if="editionMode"
          :isElevated='true'
          icon='paper-plane'
          @click='submitForm'
          :title="$t(buttonText, { var: $t('global.customer')} ) "/>
        <BaseButton
          v-if="this.$route.name !== 'settings_create_customer_route' && $can('manage_content')"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getCustomerAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
        <BaseButton
          color="dark"
          icon='long-arrow-left'
          @click="this.$router.push({name: 'settings_list_customer_route'})"
          :title="$t('button.back')"
        />
      </div>
    </template>

    <template #body>
      <div class='space-y-8'>
        <BaseCardSection :section-title='$t("global.general_information")'>
          <template #default>
            <div class="grid grid-cols-2 gap-10 my-5">
              <!--#region col left -->
              <div class="col-start-1">
                <BaseShowEditInput
                  :label="$t('attributes.name')"
                  v-model="customer.name"
                  :errors="errors.name"
                  :placeholder="$t('attributes.name')"
                  :editionMode="editionMode"
                  :required="true"
                />
                <BaseShowEditInput
                  :label="$t('attributes.name')"
                  v-model="customer.TVA"
                  :errors="errors.TVA"
                  :placeholder="$t('attributes.TVA')"
                  :editionMode="editionMode"
                  :required="true"
                />
                <BaseShowEditInput
                  :label="$t('attributes.bank')"
                  v-model="customer.bank"
                  :errors="errors.bank"
                  :placeholder="$t('attributes.bank')"
                  :editionMode="editionMode"
                  :required="true"
                />

                <BaseShowEditSelectSimple
                  :label="$t('attributes.default_bank_id')"
                  :placeholder="$t('attributes.default_bank_id')"
                  name="default_bank_id"
                  :editionMode='editionMode'
                  v-model='customer.default_bank_id'
                  :defaultValue="metadata?.bank?.filter(el => el.id === customer.default_bank_id)"
                  :options="metadata.bank"
                  :errors="errors.bank_id"
                  :required="true"/>


                <BaseShowEditInput
                  :label="$t('attributes.email')"
                  v-model="customer.email"
                  :errors="errors.email"
                  :placeholder="$t('attributes.email')"
                  :editionMode="editionMode"
                  :required="true"/>

                <BaseLanguageShowEditSelectSimple v-model='customer.language'
                                                  :metadata='metadata'
                                                  :errors='errors'
                                                  :edition-mode='editionMode'/>

              </div>
              <!--#endregion -->
              <!--#region col right -->
              <div class="col-start-2">
                <BaseShowEditInput
                  :label="$t('attributes.street')"
                  v-model="customer.street"
                  :errors="errors.street"
                  :placeholder="$t('attributes.street')"
                  :editionMode="editionMode"
                  :required="true"
                />
                <!--#region CityPostCode -->
                <div class="flex flex-row mt-3 space-x-3">
                  <BaseShowEditInput
                    :label="$t('attributes.post_code')"
                    v-model="customer.post_code"
                    :errors="errors.post_code"
                    :placeholder="$t('attributes.post_code')"
                    :editionMode="editionMode"
                    :required="true"
                  />
                  <BaseShowEditInput
                    :label="$t('attributes.city')"
                    v-model="customer.city"
                    :errors="errors.city"
                    :placeholder="$t('attributes.city')"
                    :editionMode="editionMode"
                    :required="true"
                  />
                </div>
                <!--#endregion -->

                <BaseCountryShowEditSelectSimple v-model='customer.country'
                                                 :metadata='metadata'
                                                 :errors='errors'
                                                 :edition-mode='editionMode'/>

                <BaseShowEditPhoneInput
                  :label="$t('attributes.phone')"
                  name='phone'
                  :editionMode="editionMode"
                  v-model:countryValue="customer.phone_country"
                  v-model:numberValue="customer.phone_field"
                  :errors='errors'
                  :metadata='metadata'/>
                <BaseShowEditInput
                  :label="$t('attributes.website')"
                  v-model="customer.website"
                  :errors="errors.website"
                  :placeholder="$t('attributes.website')"
                  :editionMode="editionMode"
                />
                <BaseShowEditInput
                  :label="$t('attributes.accounting_code')"
                  v-model="customer.accounting_code"
                  :errors="errors.accounting_code"
                  :placeholder="$t('attributes.accounting_code')"
                  :editionMode="editionMode"
                />

              </div>
              <!--#endregion -->
            </div>

            <!--            <div class='flex justify-end '>-->
            <!--              <BaseButton-->
            <!--                          v-if="editionMode"-->
            <!--                          :isElevated='true'-->
            <!--                          icon='paper-plane'-->
            <!--                          @click='submitForm'-->
            <!--                          :title="$t(buttonText, { var: $t('global.customer')} ) "/>-->
            <!--            </div>-->
            <CustomerUserIndexComponent
              v-if="this.$route.name !== 'settings_create_customer_route'"
              @addUser="handleAddUser"
              :editionMode="editionMode"
            />
          </template>
        </BaseCardSection>
      </div>
    </template>
  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import CustomerUserIndexComponent from './components/CustomerUserIndexComponent'

export default {
  name: 'TheCustomerFormPage',
  components: {
    CustomerUserIndexComponent,
  },
  data() {
    return {
      editionMode: false
    }
  },
  computed: {
    ...mapGetters({
      customer: 'customer/getSelectedCustomer',
      metadata: 'customer/getCustomerMetadata',
      errors: 'auth/getErrors',
    }),
    buttonText() {
      return this.$route.name === 'settings_create_customer_route' ? 'button.add_new_m' : 'button.edit_new_m'
    },
    pageTitle() {
      return this.customer ? this.customer.name : ''
    }
  },
  methods: {
    ...mapActions({
      fetchCustomerByID: 'customer/fetchCustomerByID',
      fetchNewCustomer: 'customer/fetchNewCustomer',
      postNewCustomer: 'customer/storeNewCustomer',
      putUpdateCustomer: 'customer/updateCustomer',
      fetchNewUser: 'user/fetchNewUser'
    }),
    getCustomerAndChangeEditionMode() {
      this.editionMode ? this.fetchCustomerByID({id: this.$route.params.id}) : {}
      this.editionMode = !this.editionMode
    },
    getInputValue({attribute, value}) {
      this.customer[attribute] = value
    },
    submitForm() {
      if (this.$route.name === 'settings_create_customer_route') {
        this.postNewCustomer({
          customer: this.customer,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.created_m', {var: this.$t('attributes.customer')}), {className: 'toastify-content success'})
            this.$router.push({name: 'settings_list_customer_route'})
          },
        })
      } else {
        this.putUpdateCustomer({
          id: this.$route.params.id,
          customer: this.customer,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.updated_m', {var: this.$t('attributes.customer')}), {className: 'toastify-content success'})
            this.editionMode = false
          },
        })
      }
    },
    handleAddUser(user) {
      this.customer.users.push(user)
    }
  },
  async beforeMount() {
    if (this.$route.name !== 'settings_create_customer_route') {
      await this.fetchCustomerByID({id: this.$route.params.id})
      await this.fetchNewUser()
    } else {
      await this.fetchNewCustomer()
      await this.fetchNewUser()
      this.editionMode = true
    }
  },
}
</script>
