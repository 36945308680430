import PlanningPage from '../../components/pages/planning/PlanningPage'
import TheReportPlanning from "../../components/pages/planning/reportPlanning/TheReportPlanning";
import ReportPlanningDocument from "@p/planning/reportPlanning/ReportPlanningDocument";

export const planningRootPages = {
  path: '/planning',
  name: 'planning_page_route',
  component: PlanningPage,
}

export const planningSubPages = [
  
  {
    path: 'report',
    name: 'report_planning_route',
    component: TheReportPlanning,
    meta: {
      breadcrumb: [{ title: 'content_title', active: false }, { title: 'content_user_title', active: true }]
    }
  },
  {
    path: '/report_planing/:start_date/:end_date',
    component: ReportPlanningDocument,
    name: 'report_planning_document_route',
    
  }
]

