<template>
  <Menu as="div" class="relative mx-5 text-left">
    <div>
      <MenuButton
          class="btn btn-primary text-sm font-medium text-white btn-primary rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <span class='capitalize-first'>{{ $t('global.action')}}</span>
        <font-awesome-icon
            class="ml-3 w-5 h-5 text-violet-200 hover:text-violet-100"
            aria-hidden="true"
            :icon="['fal', 'chevron-down']"/>
      </MenuButton>
    </div>

    <transition
        enter-active-class="transition duration-100 ease-out"
        enter-from-class="transform scale-95 opacity-0"
        enter-to-class="transform scale-100 opacity-100"
        leave-active-class="transition duration-75 ease-in"
        leave-from-class="transform scale-100 opacity-100"
        leave-to-class="transform scale-95 opacity-0">
      <MenuItems
          class="absolute right-0 w-56 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300">

        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click="handleCloneInvoice">
              <font-awesome-icon :icon="['fal', 'copy']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.clone') }}</span>
            </button>
          </MenuItem>

          <MenuItem v-slot="{ active }">
            <router-link :to="{name:'invoice_form_edit_route', params: {id: this.$route.params.invoicing_id}}"
                         class="flex items-center space-x-2 dark:text-white p-2 rounded-md ">
              <font-awesome-icon :icon="['fal', 'edit']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.edit') }}</span>
            </router-link>
          </MenuItem>

          <MenuItem v-slot="{ active }">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click="this.$emit('changeItemOrder')">
              <font-awesome-icon :icon="['fal', 'sort-alt']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.change_item_order') }}</span>
            </button>
          </MenuItem>

          <MenuItem v-slot="{ active }">
            <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md " @click='handleSendButton'>
              <font-awesome-icon :icon="['fal', 'paper-plane']" size="lg"/>&nbsp;
              <span class="cursor-pointer text-sm capitalize-first">{{ $t('button.send') }}</span>
            </button>
          </MenuItem>

        </div>

        <div class="px-1 py-1">
          <template v-for="type in metadata.convertissable_types">
            <MenuItem v-slot="{ active }">
              <button class="flex items-center space-x-2 dark:text-white p-2 rounded-md "
                      @click="handleConvertInvoice(type)">
                <font-awesome-icon :icon="['fal', 'arrow-right']" size="lg"/>&nbsp;
                <span class="cursor-pointer text-sm capitalize-first">  {{
                    $t('button.convert_to', {var: $t('enums.invoicing.type.' + type)})
                  }}</span>
              </button>
            </MenuItem>
          </template>
        </div>

      </MenuItems>


    </transition>
  </Menu>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'

import InvoicingNavLink from '../../../nav/InvoicingNavLink'

export default {
  name: 'InvoicingActionDropDown',
  components: {InvoicingNavLink, Menu, MenuButton, MenuItem, MenuItems},
  computed: {
    ...mapGetters({invoice: 'invoicing/getInvoice', metadata: 'invoicing/getInvoiceMetadata'})
  },
  methods: {
    ...mapActions({cloneInvoice: 'invoicing/cloneInvoice', convertInvoice: 'invoicing/convertInvoice'}),
    handleSendButton(){
      this.$emit('buttonSend')
    },
    handleCloneInvoice() {
      this.cloneInvoice({
        invoice_id: this.invoice.id,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.cloned_f', {var: this.$t('global.invoice')}), {className: 'toastify-content success'})
        }
      })
    },
    handleConvertInvoice(type) {
      this.convertInvoice({
        invoice_id: this.invoice.id,
        doc_type: type,
        successCallback: () => {
          this.$h.toastify(type === "INV" || type === "CN" ? this.$t('toasts.converted_f', {var: this.$t(`enums.invoicing.type.${type}`)}) : this.$t('toasts.converted_m', {var: this.$t(`enums.invoicing.type.${type}`)}), {className: 'toastify-content success'})
        }
      })
    }
  },
}
</script>
