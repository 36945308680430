
<template>
  <nav class="space-y-1">

    <div>

      <h2 class='text-base font-medium px-5 mb-5 capitalize-first'>{{ $t('navigation.settings_title') }}</h2>

      <settings-nav-link-component
        routeName='settings_list_general_route'
        :label='$t("global.general")'
        icon='cogs'
      />

      <settings-nav-link-component
        routeName='settings_list_events_route'
        :label='$t("global.event")'
        icon='cogs'
      />

      <settings-nav-link-component
        routeName='settings_list_invoicing_route'
        :label='$t("global.invoicing")'
        icon='cogs'
      />

      <settings-nav-link-component
        routeName='settings_notification_route'
        :label='$t("global.notification")'
        icon='cogs'
      />

    </div>

    <h2 class='text-base font-medium px-5 py-5 capitalize-first'>{{ $t('navigation.content_title') }}</h2>

    <settings-nav-link-component
      routeName='settings_list_user_route'
      :label='$t("navigation.content_user_title")'
      icon='users'
    />

    <settings-nav-link-component
      routeName='settings_list_company_route'
      :label='$t("navigation.content_company_title")'
      icon='landmark'
    />

    <settings-nav-link-component
      routeName='settings_list_bank_route'
      :label='$t("navigation.content_bank_title")'
      icon='piggy-bank'
    />

    <settings-nav-link-component
      routeName='settings_list_customer_route'
      :label='$t("navigation.content_customer_title")'
      icon='badge-dollar'
    />

    <settings-nav-link-component
      routeName='settings_list_implantation'
      :label='$t("navigation.content_implantation_title")'
      icon='hotel'
    />

    <settings-nav-link-component
      routeName='settings_list_item_route'
      :label='$t("navigation.content_item_title")'
      icon='ticket'
    />

    <settings-nav-link-component
      routeName='settings_list_tasks_route'
      :label='$t("global.tasks")'
      icon='tasks'
    />
<!-- ici -->
    <settings-nav-link-component
      routeName='settings_add_item_list_route'
      :label='$t("navigation.list_item_title")'
      icon='list'
    />

    <settings-nav-link-component
      routeName='settings_message_texts_index_route'
      :label='$t("navigation.message_texts")'
      icon='file-alt'
    />
  </nav>
</template>

<script>
import SettingsNavLinkComponent from './SettingsNavLinkComponent'

export default {
  name: 'TheSettingsNav',
  components: {
    SettingsNavLinkComponent,
  },
  data(){
    return {}
  },
}
</script>
