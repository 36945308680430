import { createI18n } from 'vue-i18n/index'

import fr_BE from './assets/translations/fr_BE'
import nl_BE from './assets/translations/nl_BE'

const messages = {
  fr_BE,
  nl_BE
}

const i18n = createI18n({
  locale: 'fr_BE',
  fallbackLocale: 'fr_BE',
  messages
})

export default i18n
