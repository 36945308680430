<template>
  <div>
    <h2 class='text-xl font-bold capitalize-first mb-3'>{{ $t('global.assets') }}</h2>
    <div class='flex flex-col space-y-3'>
      <div v-for='(items, type) in cAssetsGrouped'>
        <div>
          <h3 class='font-bold mb-1 capitalize-first'>{{ this.$t('enums.asset.type.' + type) }}</h3>
          <ul class='grid grid-cols-2 gap-2 lg:grid-cols-3'>
            <li
              v-for='item in items'
              class='col-span-1 flex'
            >
              <a
                href='#'
                :class="Array.isArray( dAssets ) && dAssets.includes( item.id ) ? 'bg-blue-500 text-white' : ''"
                class='flex-1 bg-gray-300 text-black rounded-md px-2 py-1 capitalize-first'
                @click.prevent='mEventClick(item)'
              >{{ item.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FilterPlanningAssets',
  props: {
    assets: Array,
    assetList: Array
  },
  data() {
    return {
      dAssets: this.assets
    }
  },
  emits: ['update:assets'],
  computed: {
    cAssetsGrouped() {
      return this.assetList.reduce( ( object, item ) => {
        (object[item.type] = object[item.type] || []).push( item );
        return object;
      } , {})
    }
  },
  methods: {
    mEventClick(item) {
      let index = this.dAssets.indexOf(item.id)
      index === -1 ? this.dAssets.push(item.id) : this.dAssets.splice(index, 1);
      this.$emit('update:assets', this.dAssets );
      localStorage.setItem('planning-filter-assets', JSON.stringify(this.dAssets))
    }
  }
}
</script>
