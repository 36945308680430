<template>
  <BaseSimpleCard name='company-form' classes='col-start-2 col-span-10' :title="$t('global.tasks')">
    <template #default>
      <div class='flex flex-col space-y-10'>
        <!-- WorkTask-->
        <div>
          <ul v-if='work.work_tasks' role='list' class='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
            <li v-for='(task, index) in work.work_tasks' :key='index'
                class='col-span-1 border-gray-300 bg-gradient-to-br from-gray-100 to-gray-50 rounded-lg shadow-md'>
              <WorkTaskCard :task='task' @click-openModal='clickTask'  @openModal='openModal'/>
            </li>
          </ul>
          <div v-else>
            {{ $t('validation.there_is_no_for_m', { content: $t('global.task'), container: $t('global.work') }) }}
          </div>
        </div>

        <template v-for='type in this.metadata?.events?.event_type'>
          <div
            v-if='work.events?.find( event => event.type === type.id ) && work.events?.find( event => event.type === type.id )?.work_tasks?.length > 0'>
            <h3 class='text-xl font-bold capitalize-first mb-2'>{{ $t('enums.event.type.' + type.id) }}</h3>
            <ul
              role='list'
              class='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3'>
              <li v-for='(task, index) in work.events?.find( event => event.type === type.id ).work_tasks' :key='index'
                  class='col-span-1 border-gray-300 bg-gradient-to-br from-gray-100 to-gray-50 rounded-lg shadow-md divide-y divide-gray-200 '
              >
                <WorkTaskCard :task='task' @click-openModal='clickTask' @openModal='openModal'/>
              </li>
            </ul>
          </div>
        </template>
      </div>
    </template>
  </BaseSimpleCard>
  <WorkTaskFormDialog v-if='isModalVisible'
                      ref='eventModal'
                      :isModalVisible="isModalVisible['WorkTaskFormDialog']"
                      size='x-large'
                      :work-id="work.id"
                      @openModal='openModal'
                      @close='closeAllModals'
                      @backdropClicked='closeAllModals' />

  <WorkTaskStatusChangeDialog @backdropClicked='closeAllModals'
                  @close='closeAllModals'
                  :is-modal-visible="isModalVisible['TaskFormDialog']"
                  :form="selectedForm"
                  :nextStatus='nextStatusToChange'
                  :has-close-icon='true'
                  size='x-large'
  />

</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import WorkTaskCard from './components/WorkTaskCard'
import WorkTaskFormDialog from './components/WorkTaskFormDialog'
import WorkTaskStatusChangeDialog from '@p/works/form/subpages/tasks/components/WorkTaskStatusChangeDialog'

export default {
  name: 'TheWorkTasksPage',
  components: {
    WorkTaskCard,
    WorkTaskFormDialog,
    WorkTaskStatusChangeDialog
  },
  data() {
    return {
      isModalVisible: {
        WorkTaskFormDialog: false,
        TaskFormDialog: false,
      },
      selectedTaskId: null,
      selectedForm: null,
      nextStatusToChange: null,
    }
  },
  computed: {
    ...mapGetters({ work: 'work/getWork', metadata: 'work/getWorkMetadata' })
  },
  methods: {
    ...mapMutations({setSelectedWorkTask: 'work/setSelectedWorkTask'}),
    clickTask({ workTask, modal } ) {
      if(workTask){
        console.log('click task')
        this.setSelectedWorkTask( workTask );
        this.isModalVisible[modal] = true
      }
    },
    openModal({modal, task, form, nextStatus}) {
      console.log('open modal', form)
      console.log('ici', nextStatus)
      this.closeAllModals()
      this.setSelectedWorkTask( task );
      this.selectedForm = form
      this.nextStatusToChange = nextStatus
      this.isModalVisible[modal] = true
    },
    closeAllModals() {
      this.selectedTaskId = null
      for (const property in this.isModalVisible) {
        this.isModalVisible[property] = false
        this.setSelectedWorkTask( {} );
      }
    }
  },

}
</script>

