import apiClient from '@u/apiClient'

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}
export default {

    //region fetch
    async fetchNewPayment({commit}, payload) {
        commit('auth/setErrors', {}, {root: true})
        await apiClient.get(`api/model/new?model=payment&invoice_id=${payload.invoice_id}`)
            .then(async response => {
                await commit('setPayment', response.data.payment)
                await commit('setPaymentMetadata', response.data.metadata)
            })
            .catch(reason => {
                console.error("fetchNewPayment reason", reason)
            })
    },
    //endregion

    //region store
    async storeNewPayment({commit}, payload) {
        await apiClient.post(`api/invoicing/add-payment/${payload.invoice_id}`, payload.data_request)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setInvoice', response.data.invoice)
                    if (payload.successCallback) {
                        payload.successCallback()
                    }
                }
            })
            .catch(async reason => {
                console.error("storeNewPayment reason", reason)
            })
    },
    //endregion

    //region delete
    async deletePayment({commit}, payload) {
        await apiClient.delete(`api/invoicing/delete/payment/` + payload.payment_id)
            .then(async response => {
                await commit('setInvoice', response.data.invoice)
                if (response.status === 200) {
                    processSuccessCallback(payload)
                }
            })
            .catch(async reason => {
                console.error(reason)
            })
    },
    //endregion

}
