import getters from '@/store/modules/content/accountingCode/getters'
import mutations from '@/store/modules/content/accountingCode/mutations'
import actions from '@/store/modules/content/accountingCode/actions'

export default {
  namespaced: true,
  state() {
    return {
      accountingCodeList: [],
      accountingCode: {
        code:'',
        name:'',
      },
    }
  },
  getters,
  actions,
  mutations
}
