<template>

  <template v-if='auth_role === "crema-user" || auth_role === "super-admin"'>
    <div class="box print:hidden">
      <div class='p-5 flex space-x-3'>
        <span class='flex-1 text-2xl font-bold capitalize-first'>{{ $t('global.invoicing') }}</span>
        <Menu as="div" class="relative mx-5 text-left">
          <Tippy tag="a"
                 href="javascript:;"
                 class="tooltip  flex"
                 :content="$t('button.add')" >
            <MenuButton class="inline-flex justify-center w-full px-2 py-1 text-sm font-medium text-white btn-primary rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <font-awesome-icon
                class="w-5 h-5 text-violet-200 hover:text-violet-100"
                aria-hidden="true"
                :icon="['fal', 'plus']" />
            </MenuButton>
          </Tippy>

          <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0">

            <MenuItems v-if="storageSystemSettingsAvailableDocs"
                       class="absolute right-0 w-56 mt-2 origin-top-right bg-white
                     divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

              <div class="px-1 py-1">
                <MenuItem v-slot="{ active }" v-if="Object.values(storageSystemSettingsAvailableDocs).indexOf('INV') > -1">
                  <InvoicingNavLink
                    :class="[active ? 'btn-primary text-white' : 'text-gray-900','group flex rounded-md items-center w-full px-2 py-2 text-sm',]"
                    route-name='invoice_form_new_route'
                    icon='file-plus'
                    :label="$t('global.invoice')" />
                </MenuItem>
                <MenuItem v-slot="{ active }" v-if="Object.values(storageSystemSettingsAvailableDocs).indexOf('PO') > -1">
                  <InvoicingNavLink
                    :class="[ active ? 'btn-primary text-white' : 'text-gray-900', 'group flex rounded-md items-center w-full px-2 py-2 text-sm',]"
                    route-name='invoicing_purchase_order_new_route'
                    icon='file-plus'
                    :label="$t('global.purchase_order')" />
                </MenuItem>

                <MenuItem v-slot="{ active }" v-if="Object.values(storageSystemSettingsAvailableDocs).indexOf('QUO') > -1">
                  <InvoicingNavLink
                    :class="[active ? 'btn-primary text-white' : 'text-gray-900','group flex rounded-md items-center w-full px-2 py-2 text-sm',]"
                    route-name='invoicing_quote_new_route'
                    icon='file-plus'
                    :label="$t('global.quote')" />
                </MenuItem>

                <MenuItem v-slot="{ active }" v-if="Object.values(storageSystemSettingsAvailableDocs).indexOf('CN') > -1">
                  <InvoicingNavLink
                    :class="[active ? 'btn-primary text-white' : 'text-gray-900','group flex rounded-md items-center w-full px-2 py-2 text-sm',]"
                    route-name='invoicing_credit_note_new_route'
                    icon='file-plus'
                    :label="$t('global.credit_note')" />
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>

      <div class="space-y-5 pb-5">

        <div class='flex flex-col px-5'>

          <InvoicingNavLink route-name='invoice_index_route'
                            icon='file-invoice'
                            class='hover:bg-gray-100'
                            :label="$t('global.invoice')" />

          <InvoicingNavLink route-name='invoice_unpaid_index_route'
                            icon='alarm-exclamation'
                            class='hover:bg-gray-100 pl-5'
                            :label="$t('global.unpaid')" />
          <InvoicingNavLink route-name='invoice_paid_index_route'
                            icon='badge-dollar'
                            class='hover:bg-gray-100 pl-5'
                            :label="$t('global.paid')" />

        </div>

        <hr>

        <div class='flex flex-col px-5'>

          <InvoicingNavLink route-name='check_index_route'
                            icon='money-check-alt'
                            class='hover:bg-gray-100'
                            :label="$t('global.checks')" />

        </div>

        <hr>

        <div class='flex flex-col px-5'>

          <InvoicingNavLink route-name='invoicing_purchase_order_index_route'
                            icon='file-invoice'
                            class='hover:bg-gray-100'
                            :label="$t('global.purchase_order')" />

        </div>

        <hr>

        <div class='flex flex-col px-5'>

          <InvoicingNavLink route-name='invoicing_quote_index_route'
                            icon='file-invoice'
                            class='hover:bg-gray-100'
                            :label="$t('global.quote')" />

        </div>

        <hr>

        <div class='flex flex-col px-5'>

          <InvoicingNavLink route-name='invoicing_credit_note_index_route'
                            icon='file-invoice'
                            class='hover:bg-gray-100'
                            :label="$t('global.credit_note')" />

        </div>

      </div>
    </div>
  </template>

  <template v-if='auth_role === "customer-user"'>
    <div class="box print:hidden">
      <div class='p-5 flex space-x-3'>
        <span class='flex-1 text-2xl font-bold capitalize-first'>{{ $t('global.invoicing') }}</span>
      </div>

      <div class="space-y-5 pb-5">

        <div class='flex flex-col px-5'>

          <InvoicingNavLink route-name='invoice_index_route'
                            icon='file-invoice'
                            class='hover:bg-gray-100'
                            :label="$t('global.invoice')" />

          <InvoicingNavLink route-name='invoice_unpaid_index_route'
                            icon='alarm-exclamation'
                            class='hover:bg-gray-100 pl-5'
                            :label="$t('global.unpaid')" />

        </div>
      </div>
    </div>
  </template>

</template>

<script>
import { mapGetters } from 'vuex'
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import InvoicingNavLink from './InvoicingNavLink'

export default {
  components: {
    InvoicingNavLink,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  name: 'InvoicingNav',
  data(){
    return {
      work: this.$store.getters.getWork
    }
  },
  computed: {
    ...mapGetters({
      settings: 'settings/getSystemSettings',
      auth: 'auth/getAuth'
    }),
    auth_role() {
      return this.auth?.roles[0];
    },
    isEditView() {
      return this.$route.name !== 'work_form_new_route'
    },
    indexRoute() {
      return this.$route.name === 'work_form_new_route' ? 'work_form_new_route' : 'work_form_edit_route'
    },
    storageSystemSettingsAvailableDocs(){
      return this.settings.invoicing.available_docs
    }
  }
}
</script>

