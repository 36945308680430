<template>
  <teleport to='body'>
    <transition name="modal">
      <dialog class="modal-content p-0 max-h-screen flex flex-col" :class="modalClasses" open v-if="isOpen">
        <!--#region header -->
        <div class='flex p-5 items-center'>
          <div class='w-3/5 text-2xl font-bold'>
            {{ $t('global.message_text') }}
          </div>
          <div class='w-2/5 flex justify-end cursor-pointer' @click="_close">
            <font-awesome-icon :icon="['fal', 'times']" size='lg'/>
          </div>
        </div>
        <!--#endregion -->
        <section class="flex-1 modal-body overflow-scroll pt-0">

          <div class="space-y-6 mb-8 px-12 sm:space-y-5 ">
            <!--#region form  -->
            <BaseInput
                :label="$t('attributes.title')"
                :placeholder="$t('attributes.title')"
                name="title"
                :required="true"
                v-model="message_text.title"
                :errors="errors.title"
                />

            <BaseSelectSimple
                v-if="metadata.languages"
                :key="message_text.language"
                :label="$t('attributes.language')"
                :placeholder="$t('attributes.language')"
                name="language"
                :defaultValue="metadata.languages?.find(type => type.id === message_text.language)"
                :options="metadata.languages"
                :errors="errors.language"
                :required="true"
                @setSelected="getInputValue"/>

            <BaseSelectSimple
                v-if="metadata.groupes"
                :key="message_text.group"
                :label="$t('attributes.group')"
                :placeholder="$t('attributes.group')"
                name="group"
                :defaultValue="metadata.groupes?.find(type => type.id === message_text.group)"
                :options="metadata.groupes"
                :errors="errors.group"
                :required="true"
                @setSelected="getInputValue"/>

            <BaseSelectSimple
                v-if="metadata.types"
                :key="message_text.type"
                :label="$t('attributes.type')"
                :placeholder="$t('attributes.type')"
                :name="$t('attributes.type')"
                :options="metadata.types"
                :defaultValue="metadata.types?.find(type => {
                return  type.id === message_text.type
                })"
                :required="true"
                :errors="errors.type"
                @setSelected="getInputValue"/>
            <!--#endregion -->
            <!--#region variables -->

            <div class="border p-6 ">
              <div class="flex justify-around">
                <BaseButton
                    v-for="(variableGroup, index) in metadata.variables"
                    :key="index"
                    :title="$t('enums.message_text.groups_variables.' + index.toLowerCase())"
                    color="default"
                    :isOutline="true"
                    @click="openVariablesGroup(variableGroup, index)"
                />
              </div>
              <div
                  v-if="isEventVariable"
                  class="grid grid-cols-3 p-3 ">
                <BaseButton
                    v-for="(variableGroup, index) in eventVariables"
                    :key="index"
                    :title="index"
                    color="primary"
                    :isOutline="true"
                    size="small"
                    scopedClasses="m-1"
                    @click="openVariablesGroup(variableGroup, index)"
                />
              </div>

              <div v-if="messageVariables.length" class="p-3 ">
              <span
                  v-for="(variable, index) in messageVariables"
                  :key="index"
                  @click="variableAssigned(variable)"
                  class="inline-flex flex-1 items-center  mr-2.5 mt-2 rounded-md text-sm border border-gray-700 py-0.5 px-1">
              @{{ variable.value }}
             </span>
              </div>
            </div>


            <!--#endregion -->
            <!--#region wysiwyg -->
            <BaseWysiwyg
                v-if="this.message_text.type"
                :displayFormatButton="message_text.type.toUpperCase() === 'EMAIL'"
                label="message"
                ref="editorForm"
                v-model="message_text.text"
                name="text"
                :suggestionVariables="allVariables"
                :required="true"
                :value="message_text.text"
                :errors="errors.text"
                @change.self="getInputValue"
            />
            <!--#endregion -->
            <!--#region submit -->

            <div class="flex justify-center ">
              <BaseButton color='primary'
                          icon='paper-plane'
                          :title="buttonText"
                          @click="submitMessageText"
              />
            </div>
            <!--#endregion -->
          </div>
        </section>
      </dialog>
    </transition>

    <transition class='fixed inset-0 bg-black bg-opacity-30 z-100'>
      <div class="backdrop" @click="_backdropClicked" v-if="isOpen"></div>
    </transition>
  </teleport>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  name: "MessageTextFormDialog",
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    message_text_load_id: {
      required: false
    },
    headerTitle: {
      type: String,
      required: false
    },
    size: {
      type: String,
      required: false,
      default: 'x-large',
      validator: function (value) {
        const possibleSizes = ['', 'small', 'medium', 'large', 'x-large']
        return possibleSizes.indexOf(value) !== -1
      }
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isPulsing: false,
      editor: null,
      messageVariables: [],
      eventVariables: [],
      isEventVariable: false,
    }
  },
  computed: {
    ...mapGetters({
      message_text: 'messageText/getMessageText',
      metadata: "messageText/getMessageTextMetadata",
      errors: 'auth/getErrors'

    }),
    buttonText() {
      if (isNaN(this.message_text_load_id)) {
        return this.$t('button.add', {var: ''})
      }
      return this.$t('button.edit', {var: ''})
    },
    modalClasses() {
      return [this.size, this.isPulsing ? 'pulsing' : '']
    },
    _backdropClicked() {
      if (this.isStatic) {
        this.isPulsing = true
        return
      }
      this.$emit('backdropClicked')
    },
    allVariables() {
      let allVariables = [];
      for (let i in this.metadata.allVariables) {
        allVariables.push(this.metadata.allVariables[i]);
      }
      return allVariables;
    }
  },
  methods: {
    ...mapActions({
      fetchNewMessageText: 'messageText/fetchNewMessageText',
      fetchNewMessageTextById: 'messageText/fetchMessageTextById',
      addMessageText: 'messageText/storeMessageText',
      editMessageText: 'messageText/updateMessageText',
    }),
    getInputValue({attribute, value}) {
      this.message_text[attribute] = value;
    },
    submitMessageText() {
      if (!isNaN(this.message_text_load_id)) {
        this.editMessageText({
          messageText: this.message_text,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.updated_m', {var: this.$t('global.message_text')}), {className: 'toastify-content success'})
            this._close()
          }
        })

      } else {
        this.addMessageText({
          messageText: this.message_text,
          successCallback: () => {
            this.$h.toastify(this.$t('toasts.created_m', {var: this.$t('global.message_text')}), {className: 'toastify-content success'})
            this._close()
          }
        });
      }
    },
    _close() {
      this.isPulsing = false
      this.$emit('close')
    },
    variableAssigned(variable) {
      this.$refs.editorForm.setTextAtPosition(variable);
    },
    openVariablesGroup(group, index) {
      if (index !== 'Event') {
        this.isEventVariable = false;
        this.messageVariables = group;
      } else {
        this.isEventVariable = true;
        this.eventVariables = group;
        this.messageVariables = []
      }
    },
  },
  watch: {
    isPulsing: function (currentValue, _prevValue) {
      if (currentValue === true) {
        setTimeout(() => {
          this.isPulsing = false
        }, 350)
      }
    },
    async isOpen(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        if (!isNaN(this.message_text_load_id)) {
          await this.fetchNewMessageTextById({id: this.message_text_load_id});
        } else {
          await this.fetchNewMessageText()
        }
      }
    },
  }
}
</script>

<style scoped>
dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  overflow: hidden;
}

/* dialog : responsive */
@media only screen and (max-width: 576px) {
  dialog {
    width: 90%;
  }
}

@media only screen and (min-width: 577px) {
  dialog.small {
    width: 20%;
    max-width: 300px;
    scroll-behavior: auto;
  }

  dialog.medium {
    width: 30%;
    max-width: 500px;
  }

  dialog.large {
    width: 50%;
    max-width: 700px;
  }

  dialog.x-large {
    width: 70%;
    max-width: 900px;
  }
}

/* animations : usage */

.modal-enter-active {
  animation: modal 0.3s ease-in;
}

.modal-leave-active {
  animation: modal 0.3s ease-in reverse;
}

.backdrop-enter-active {
  animation: backdrop 0.3s ease-in;
}

.backdrop-leave-active {
  animation: backdrop 0.3s ease-in reverse;
}

.pulsing {
  animation: pulse 0.3s ease-in-out;
}

/* animations : declaration */
@keyframes modal {
  from {
    opacity: 0;
    transform: translate(-50%, -60%) scale(0.9);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes backdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.3);
  }
}
</style>

