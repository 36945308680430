<template>
  <ActivityTable v-if='userActivity && userActivity.length > 0' :activities="userActivity" />
  <div v-else class='mt-10 pt-10 flex justify-center font-semibold text-gray-500'>
    {{$t('global.no_activity')}}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TheUserActivityPage',
  computed: { ...mapGetters({userActivity: 'user/getUserActivities'}) },
  methods: { ...mapActions({fetch: 'user/fetchUserActivitiesByUserID'}) },
  beforeMount() {
    this.fetch({user_id: this.$route.params.id})
  }
}
</script>

