<template>
  <hr>
  <div>

    <BaseCardSection :sectionTitle="$t('global.deceased')" v-if="metadata?.deceased">
      <template #default>

        <div class='grid grid-cols-12 gap-x-5'>
          <WorkFormDeceasedInfo :computed-deceased='computedDeceased'
                                :get-input-value='getInputValue'
                                :editionMode="editionMode"/>

          <hr class='col-span-12 my-5'>

          <WorkFormDeceasedSpouse :computed-deceased='computedDeceased'
                                  :editionMode='editionMode'/>

          <hr class='col-span-12 my-5'>

          <WorkFormDeceasedDeath :computed-deceased='computedDeceased'
                                 :editionMode='editionMode'/>

          <hr class='col-span-12 my-5'>

          <WorkFormDeceasedBurial :computed-deceased='computedDeceased'
                                  :editionMode='editionMode'/>

          <hr class='col-span-12 my-5'>

          <WorkFormDeceasedBurialType :computed-deceased='computedDeceased'
                                      :get-input-value='getInputValue'
                                      :editionMode='editionMode'/>

          <WorkFormDeceasedCoffinPuttingInfo :computed-deceased='computedDeceased'
                                             :editionMode='editionMode'/>

        </div>
      </template>
    </BaseCardSection>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

import WorkFormDeceasedBurial from '@p/works/form/subpages/informations/components/deceased/WorkFormDeceasedBurial'
import WorkFormDeceasedBurialType
  from '@p/works/form/subpages/informations/components/deceased/WorkFormDeceasedBurialType'
import WorkFormDeceasedCoffinPuttingInfo
  from '@p/works/form/subpages/informations/components/deceased/WorkFormDeceasedCoffinPuttingInfo'
import WorkFormDeceasedDeath from '@p/works/form/subpages/informations/components/deceased/WorkFormDeceasedDeath'
import WorkFormDeceasedInfo from '@p/works/form/subpages/informations/components/deceased/WorkFormDeceasedInfo'
import WorkFormDeceasedSpouse from '@p/works/form/subpages/informations/components/deceased/WorkFormDeceasedSpouse'

export default {
  name: 'WorkFormDeceased',
  components: {
    WorkFormDeceasedBurial,
    WorkFormDeceasedBurialType,
    WorkFormDeceasedCoffinPuttingInfo,
    WorkFormDeceasedDeath,
    WorkFormDeceasedInfo,
    WorkFormDeceasedSpouse,
  },
  props: {
    editionMode: {type: Boolean, required: true},
  },
  computed: {
    ...mapGetters({work: 'work/getWork', metadata: 'work/getWorkMetadata', authRole: 'auth/getAuthRole'}),
    computedDeceased() {
      return this.work.deceased || {}
    },
  },
  methods: {
    ...mapMutations({
      alterWorkDeceased: 'work/alterWorkDeceased',
      alterWorkEventValuesByEventType: 'work/alterWorkEventValuesByEventType',
      cleanWorkEventsFromTypes: 'work/cleanWorkEventsFromTypes',
      processSetWorkUrnMutation: 'work/setWorkUrn',
      resetWorkContact: 'work/replaceContact'
    }),
    updateBurialEvents: function (attribute, value) {
      //remove all burial event
      this.cleanWorkEventsFromTypes()
      this.resetWorkContact()
      this.work.recoverer_type = null
      this.work.hash_recovered_by = null

      if (value != null) {
        //create new typed event
        this.alterWorkEventValuesByEventType({
          type: this.work.deceased.burial_type,
          attribute: 'type',
          value: this.work.deceased.burial_type
        })
      }

      if (this.work.deceased.burial_type === 'CON') {
        if (!this.work.urn) {
          this.processSetWorkUrnMutation()
        }
      }
      // else {
      //     if(this.work.urn) this.work.urn.awaiting_at_crema = !this.this.work.urn.awaiting_at_crema
      // }

      if (this.work.deceased.burial_type === 'BUR') {
        this.alterWorkEventValuesByEventType({type: 'BUR', attribute: 'inhumation_type', value: null})
      }

    },
    getInputValue({attribute, value}) {
      console.log("attribute et value" , attribute + ' ' + value)
      this.alterWorkDeceased({attribute: attribute, value: value});
      if (attribute === 'burial_type') {
        this.updateBurialEvents(attribute, value);
      }
      if(attribute === 'CON'){
        this.work.urn.is_awaiting_at_crema = 1
      }
    },
  },
}
</script>

