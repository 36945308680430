<template>

  <base-card-section :section-title='$t("attributes.reception_place")'>
    <BaseSimpleRadioGroup name="reception"
                          defaultValue="false"
                          :isShowingKey='false'
                          :isReturningKey='true'
                          :options="yes_no_options"
                          :disabled='permission'
                          translationExtention="enums.yes_no"
                          @change.self="getReceptionValue" />

  <div v-if="reception === 'true'" class="grid grid-cols-4 gap-x-5">


    <div v-if='settings.events.ask_nb_people_when_work_creating'>
      <div class='col-span-1'>

        <BaseInput :label="$t('attributes.nb_peoples')"
                   :placeholder="$t('attributes.nb_peoples')"
                   name='nb_peoples'
                   type="number"
                   parseType="int"
                   :min='0'
                   :max='999999'
                   v-model='eventREC.nb_peoples'
                   :disabled="permission"
                   />
      </div>

      <div v-if=' reception && metadata?.events?.formInputs?.display_timeslots?.visible.type?.includes(eventREC.type) && eventREC.nb_peoples && auth_role !== "customer-user"' class='col-start-2 mt-11 grid grid-cols-5'>
        <BaseButton class='col-start-3' :tooltip="dispoButtonText" :icon="dispoButtonIcon" @click='HandleDispoButton' />
      </div>
    </div>

    <div v-else>
      <div v-if=' reception && metadata?.events?.formInputs?.display_timeslots?.visible.type?.includes(eventREC.type) && auth_role !== "customer-user"' class='col-start-1 mt-11 grid grid-cols-5'>
        <BaseButton class='col-start-1' :tooltip="dispoButtonText" :icon="dispoButtonIcon" @click='HandleDispoButton' />
      </div>
    </div>


  </div>



    <div v-if="auth_role !== 'customer-user' && eventREC"  class='mt-5'>
      <SelectEventTimeslot v-if='dispoVisible % 2 === 0 && eventREC'
                           class="my-5"
                           :asset="eventREC.asset_id"
                           :type="eventREC.type"
                           :eventKind="eventREC.event_kind_id"
                           :options='eventREC.timeslots'
                           :index='work.events.indexOf(eventREC)'
                           v-model='selectedEventTimeslot'/>
    </div>

  </base-card-section>
</template>

<script>
import SelectEventTimeslot from '@p/components/Event/SelectEventTimeslot'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'SelectReceptionForm',
  components:{ SelectEventTimeslot },
  data() {
    return {
      yes_no_options: { true: true , false: false},
      reception: null,
      dispoVisible: 1,
      selectedEventTimeslot: null,
    }
  },
  computed:{
    ...mapGetters({work: 'work/getWork', metadata: 'work/getWorkMetadata', events: 'getEvents', auth:'auth/getAuth', settings: 'settings/getSystemSettings'}),
    permission(){
      return !this.$can('manage_work')
    },
    dispoButtonIcon(){
      return this.dispoVisible % 2 !== 0 ? 'calendar-alt' : 'compress-alt'
    },
    dispoButtonText(){
      return this.dispoVisible % 2 !== 0 ? this.$t('button.show_plural', {var: this.$t('global.availabilities')}) : this.$t('button.hide_plural', {var: this.$t('global.availabilities')})
    },
    eventREC(){
      return this.work.events && this.work.events.filter(event => event.type === 'REC')[0]
    },
    assetDisponibilities() {
      return this.metadata.room_dispo && this.metadata.room_dispo.filter(dispo => dispo.asset.nb_places >= this.eventREC.nb_peoples && dispo.asset.type === "ROM")
    },
    auth_role(){
      return this.auth.roles[0];
    },
  },
  methods:{
    ...mapActions({fetchEventDisponbilitiesByOption: 'fetchEventDisponbilitiesByOption'}),
    ...mapMutations({
      alterWorkEventValuesByEventType: 'work/alterWorkEventValuesByEventType',
      removeEventFromWork: 'work/removeEventFromWork'
    }),
    getReceptionValue({value}){
      if(value === "false"){
        this.dispoVisible = 1
      }
      this.reception = value
    },
    getInputValue({attribute, value}){
      if(attribute === 'nb_peoples'){
        this.dispoVisible = 1
      }
      this.alterWorkEventValuesByEventType( {type: 'REC', attribute: attribute, value: value} );
    },
    HandleDispoButton() {
      this.dispoVisible += 1
      if(this.dispoVisible % 2 === 0){
        this.fetchEventDisponbilitiesByOption({ params: {
          asset_id: this.eventREC.asset_id,
          type: this.eventREC.type,
            week_from: moment().locale('fr').week(),
            year: moment().format('YYYY'),
          event_kind_id: this.eventREC.event_kind_id ? this.eventREC.event_kind_id : null
          }, index: this.work.events.indexOf(this.eventREC)
        })
      }
    },
  },
  watch: {
    reception(newValue) {
      if(newValue === 'true'){
        this.alterWorkEventValuesByEventType({type:'REC', attribute: 'type', value: 'REC'})
      }else{
        this.removeEventFromWork({type:'REC'})
      }
    },
    selectedEventTimeslot(newValue, oldValue){
      if(newValue !== oldValue && newValue !== null){
        this.alterWorkEventValuesByEventType({type: 'REC', attribute: 'date_from', value: newValue.string_date})
        this.alterWorkEventValuesByEventType({type: 'REC', attribute: 'date_to', value: newValue.string_date})
        this.alterWorkEventValuesByEventType({type: 'REC', attribute: 'time_from', value: newValue.string_from})
        this.alterWorkEventValuesByEventType({type: 'REC', attribute: 'time_to', value: newValue.string_to})
        this.alterWorkEventValuesByEventType({type: 'REC', attribute: 'asset_id', value: newValue.asset.id})
      }
    }
  }
}
</script>

<style scoped>

</style>
