<template>
  <div v-if='editionMode' class='mt-3'>
    <BaseEditLabel :label="label ? label : $t('attributes.user')" :required="required"/>
    <multiselect
      v-if='filteredOptions.length > 0'
      :options="filteredOptions"
      :placeholder="label ? label : $t('attributes.user')"
      :model-value="cDefautlValue"
      :searchable="searchable"
      :label="attributeLabel"
      :track-by="trackBy"
      :required="required"
      @update:model-value="updateValueAction"/>

    <template v-if="errors && errors[errorsKey]">
      <div v-for='(error,index) in errors[errorsKey]' :key='index' class="form-help text-red-600">
        {{ $t(error, {'attribute': $t('attributes.' + name)}) }}
      </div>
    </template>
  </div>

  <div v-else class='mt-3'>
    <BaseShowLabel :label="label ? label : $t('attributes.user')" :model-value="cDisplayedValueWhenNotEditionMode"/>
  </div>
</template>

<script>
import multiselect from 'vue-multiselect'

export default {
  name: 'BaseShowEditFilteredUsersComponent',
  components: {multiselect },
  props: {
    editionMode: {
      type: Boolean,
      default: true,
    },
    modelValue: {
      type: Object,
      required: false,
      default() {return {}},
    },
    trackBy: {
      type: String,
      required: false,
      default: 'id',
    },
    attributeLabel: {
      type: String,
      required: false,
      default: 'name',
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
      default: 'user_id',
    },
    errors: {
      type: Object,
      required: false,
      default() {return {}},
    },
    errorsKey: {
      type: String,
      required: false,
      default: 'user_id'
    },
    metadata: {
      type: Array,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      filteredUsers: []
    }
  },
  computed: {
    cDisplayedValueWhenNotEditionMode() {
      if (this.cDefautlValue) {
        if (this.attributeLabel){
          return this.cDefautlValue[this.attributeLabel]
        } else {
          return this.cDefautlValue
        }
      } else {
        return '-'
      }
    },
    cDefautlValue(){
      if(this.metadata && this.metadata.users && this.metadata.users.find( item => item.id === this.modelValue )) return this.metadata.users.find( item => item.id === this.modelValue )
      else return null
    },
    filteredOptions() {
      return this.metadata?.users?.filter(user => {
        return (!user.exit_date || new Date(user.exit_date) > new Date())
      }) ?? []
    },
  },
  methods: {
    updateValueAction(newValue) {
      if(newValue != null) {
        this.$emit("update:modelValue", newValue[this.trackBy]);
      } else {
        this.$emit("update:modelValue", null);
      }
    }
  }
}
</script>
