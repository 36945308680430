import apiClient from '@u/apiClient'
import router from "@/router";

function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {

    //region fetch

    async fetchInvoiceItemID({commit}, payload) {
        await apiClient.get(`api/invoicing/invoice-item/${payload.id}`)
            .then(async response => {
                await commit('setInvoiceItem', response.data.invoice_item)
                commit('auth/setErrors', {}, {root: true})
            })
            .catch(reason => {
                console.error("fetchInvoiceItemID reason", reason)
            })
    },
    //endregion

    //region store
    async storeInvoiceItem({commit}, payload) {
        await apiClient.put(`api/invoicing/add-item-fast/${payload.invoiceId}`, {item_id: payload.item_id})
            .then(async response => {
                await commit('setInvoice', response.data.invoice)
            })
            .catch(reason => {
                console.error("storeInvoiceItem reason", reason)
            })
    },
    //endregion

    //region update
    async updateInvoiceItem({commit}, payload) {
        await apiClient.put(`api/invoicing/invoice-item/${payload.id}`, payload.invoice_item)
            .then(async response => {
                if (payload.successCallback) {
                    payload.successCallback()
                }
                await commit('setInvoice', response.data.invoice)
            })
            .catch(reason => {
                console.error("updateInvoiceItem reason", reason)
            })
    },
    async updateMultipleInvoiceItems({commit}, payload) {
        await apiClient.put(`api/invoicing/add-multiple-items/${payload.invoiceId}`, payload.items)
            .then(async response => {
                await commit('setInvoice', response.data.invoice)
                if (payload.successCallback) {
                    payload.successCallback()
                }
            })
            .catch(async reason => {
                console.error("putMultipleInvoiceItems reason", reason)
            })
    },
    //endregion

    //region delete
    async deleteInvoiceItem({commit}, payload) {
        apiClient.delete(`api/invoicing/invoice-item/${payload.id}`, payload.invoice_item)
            .then(async response => {
                console.log(response)
                if (payload.successCallback) {
                    payload.successCallback()
                }
                await commit('setInvoice', response.data.invoice)
            })
            .catch(async reason => {
                await console.error(reason)
            })
    },
    async undoDeleteInvoiceItem({commit}, payload) {
        console.log('', payload.id)
        await apiClient.get(`api/invoicing/undo-delete/${payload.id}`)
            .then(async response => {
                console.log(response)
                if (payload.successCallback) {
                    payload.successCallback()
                }
                await commit('setInvoice', response.data.invoice)

            })
            .catch(async reason => {
                console.error('undoDeleteMessageTextById', reason)
            })
    },
    //endregion
    //region order invoice items
    async reorderInvoiceItems({commit}, payload) {
        apiClient.put(`api/invoicing/reorder-items/${payload.invoice_id}`, {items: payload.ordered_list})
            .then(async response => {
                if (response.status === 200) {
                    commit('setInvoice', response.data.invoice)
                    processSuccessCallback(payload)
                }
            })
            .catch(async reason => {
                await console.error(reason)
            })
    },
    //endregion

}
