export default {
  isAuthenticated(state, getters) {
    if (!state.auth || !getters.isSessionStillValid) return false

    return state.auth && getters.isSessionStillValid
  },
  getAuth(state) {
    return state.auth
  },
  getAuthRole(state){
    if(state.auth && state.auth?.roles) {
      return state.auth && state.auth?.roles[0]
    }
  },
  getAuthPermissions(state) {
    return state.auth && state.auth?.permissions
  },

  getErrors(state) {
    return state.errors || {}
  },
  isSessionStillValid(state) {
    return Date.now() < (new Date(state.sessionExpiration)).getTime()
  }

}
