<template>
  <div class="search hidden sm:block">
    <input
      type="text"
      class="search__input form-control border-transparent placeholder-theme-13"
      :placeholder="$t('global.search')"
      v-model='searchValue'
      @focus="this.$emit('focus')"
      @blur="this.$emit('blur')"
    />
    <SearchIcon class="search__icon dark:text-gray-300"/>
  </div>
</template>

<script>

export default {
  name: 'SearchAllComponentInput',
  computed:{
    searchValue: {
      get () {
        return this.$store.state.globalSearch.searchValue
      },
      set (value) {
        this.$store.commit('globalSearch/setSearchValue', value)
      }
    }
  },
}
</script>

<style scoped>

</style>
