<template>
  <li class="pl-3 pr-4 py-3 flex items-center text-sm flex flex-col" >
      <div class='flex w-full items-center justify-between '>
        <div class="w-0 flex-1 ml-2 flex items-center gap-x-5" v-on="!editMode ? { click: emitToOpenModal } : {}">
          <font-awesome-icon :icon="['fal', icon]" class="cursor-pointer"/>
          <span v-if='!editMode' class="flex-1 w-0 truncate select-none cursor-pointer"> {{ attachment.name }} <span class='text-gray-400 ml-3'>({{creationDate}})</span></span>
          <div v-else class='w-full'>
            <BaseInput
              :placeholder="attachment.name"
              name="name"
              :disabled="!$can('manage_content')"
              input-class='form-control text-xs w-3/4 py-1.5 px-2'
              v-model="editNameInput"/>
          </div>
        </div>

        <div class="ml-4 flex flex-shrink-0">

          <WorkAttachmentsDropDown v-if='!editMode'
                                   :attachmentIndex='attachmentIndex'
                                   @changeEditMode='setEditMode'
                                   @printFile='printFile'
                                   @sendFile='sendFile'
                                   @showFile='isModalVisible = true'
                                   @deleteFile='deleteFile'/>
          <div v-else class='flex gap-x-2 mt-2.5 mr-5'>
            <BaseButton icon='edit' @click="submitFileNameChange"/>
            <BaseButton icon='undo' color='secondary' @click="editMode = !editMode"/>
          </div>
        </div>

      </div>

    <WorkAttachmentShowDialog
      :attachmentIndex='attachmentIndex'
      @backdropClicked='closeAllModals'
      @close='closeAllModals'
      @printFile='printFile'
      :mime-type="attachment.mime_type"
      :url="attachment.url"
      :is-modal-visible="isModalVisible"
      :has-close-icon='true'
      size='x-large' />

  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import WorkAttachmentsDropDown from '@p/works/form/subpages/attachments/components/WorkAttachmentsDropDown'
import WorkAttachmentShowDialog from '@p/works/form/subpages/attachments/components/WorkAttachmentShowDialog'
import printJS from 'print-js'
import moment from 'moment'

export default {
  name: 'AttachmentsRow',
  components: { WorkAttachmentsDropDown, WorkAttachmentShowDialog },
  props: {
    attachment: {type: Object, required: true},
    attachmentIndex: {type: Number, required: true},
  },
  data() {
    return {
      editMode: false,
      isModalVisible: false,
      editNameInput: null,
    }
  },
  computed:{
    ...mapGetters({work: 'work/getWork'}),
    creationDate(){
      return moment(this.attachment.created_at).format('DD/MM/YYYY')
    },
    icon() {
      switch (this.attachment.mime_type) {
        case 'application/pdf' :
          return 'file-pdf'
        case 'image/jpeg' :
        case 'image/png' :
        case 'image/gif' :
          return 'file-image'
        case 'application/msword' :
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' :
          return 'file-word'
        case 'text/csv' :
        case 'application/vnd.ms-excel' :
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' :
          return 'file-excel'
        case 'application/vnd.ms-powerpoint' :
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation' :
          return 'file-powerpoint'
        default:
          return 'file-alt'
      }
    }
  },
  methods: {
    ...mapActions({renameWorkAttachment: 'work/renameWorkAttachment', deleteWorkAttachment: 'work/deleteWorkAttachment'}),
    deleteFile(){
      this.deleteWorkAttachment({work_id: this.work.id, attachment_id: this.work.attachments[this.attachmentIndex].id})
    },
    sendFile(){
      // Micky - 25/02/2022: la fonctionalité n'est pas encore implémentée
    },
    printFile(){
      printJS(this.work.attachments[this.attachmentIndex].url)
    },
    closeAllModals(){
      this.isModalVisible = false
    },
    setEditMode(){
      this.editMode = true
    },
    getInputValue({attribute, value}){
      this.editNameInput = value
    },
    emitToOpenModal() {
      this.isModalVisible = true
    },
    submitFileNameChange(){
      this.renameWorkAttachment({work_id: this.work.id, attachment_id: this.work.attachments[this.attachmentIndex].id, new_name: this.editNameInput})
      this.editNameInput = null
      this.editMode = false
    }
  }
}
</script>
