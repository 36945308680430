<template>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditDatePicker :editionMode='editionMode'
                            :label="$t('attributes.burial_date')"
                            v-model='computedDeceased.burial_date'
                            name='burial_date'
                            :errors='errors.deceased ? errors.deceased.burial_date : []'/>
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditTimeInput :label="$t('attributes.burial_time')"
                           :placeholder="$t('attributes.burial_time')"
                           name='burial_time'
                           :editionMode='editionMode'
                           v-model='work.deceased.burial_time'
                           :errors='errors?.deceased?.burial_time'/>
  </div>
  <div class='col-span-12 xl:col-span-6'>
    <BaseShowEditSelectSimple v-if="metadata?.events?.burial_type"
                              :label="$t('attributes.burial_place')"
                              :placeholder="$t('attributes.burial_place')"
                              name="burial_place_type"
                              :editionMode='editionMode'
                              v-model='work.deceased.burial_place_type'
                              :defaultValue="metadata?.events?.burial_type?.filter(el => el.id === work.deceased.burial_place_type)"
                              :options="metadata?.events?.burial_type"
                              :errors="errors?.burial_place_type"/>
  </div>
  <div class='col-span-12 xl:col-span-6 mt-2'>
    <div v-if='computedDeceased && computedDeceased.burial_place_type === 9'>
      <BaseShowEditSelectAjax :key='computedDeceased.burial_place'
                              :editionMode='editionMode'
                              url='api/church-for-select'
                              :label="$t('attributes.church')"
                              v-model='computedDeceased.burial_place_id'
                              name='burial_place_id'
                              trackBy='id'
                              attributeLabel='title'
                              :defaultAjax='burialPlaceBaseValue'/>
    </div>
    <div v-if='computedDeceased && computedDeceased.burial_place_type === 10'>
      <BaseShowEditSelectAjax :key='computedDeceased.burial_place'
                              :editionMode='editionMode'
                              url='api/hospital-for-select'
                              :label="$t('attributes.hospital')"
                              v-model='computedDeceased.burial_place_id'
                              name='burial_place_id'
                              trackBy='id'
                              attributeLabel='title'
                              :defaultAjax='burialPlaceBaseValue'/>
    </div>
    <div v-if='computedDeceased && computedDeceased.burial_place_type === 11'>
      <BaseShowEditSelectAjax :key='computedDeceased.burial_place'
                              :editionMode='editionMode'
                              url='api/rest-home-for-select'
                              :label="$t('attributes.rest_home')"
                              v-model='computedDeceased.burial_place_id'
                              name='burial_place_id'
                              trackBy='id'
                              attributeLabel='title'
                              :defaultAjax='burialPlaceBaseValue'/>
    </div>
    <div v-if='computedDeceased && computedDeceased.burial_place_type === 12'>
      <BaseShowEditInput :label="$t('attributes.burial_place')"
                         v-model="computedDeceased.burial_place"
                         :errors="errors.deceased ? errors.deceased.burial_place : []"
                         :placeholder="$t('attributes.burial_place')"
                         :editionMode="editionMode"
                         :required="true"/>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'


export default {
  name: 'WorkFormDeceasedBurial',
  components: {
  },
  props: {
    computedDeceased: {type: Object, required: true},
    editionMode: {type: Boolean, required: true},
  },
  computed: {
    ...mapGetters({work: 'work/getWork', metadata: 'work/getWorkMetadata', errors: 'auth/getErrors'}),
    burialPlaceBaseValue(){
      if (this.computedDeceased.burial_place_id && this.computedDeceased.burial_place_type) {
        switch(this.computedDeceased.burial_place_type){
          case 9:
            return this.computedDeceased.burial_place_id && this.computedDeceased.church
              ? { id: this.computedDeceased.burial_place_id, title: `${this.computedDeceased.church?.nom}`}
              : {}
            break
          case 10:
            return this.computedDeceased.burial_place_id && this.computedDeceased.hospital_burial
              ? { id: this.computedDeceased.burial_place_id, title: this.computedDeceased.hospital_burial?.nom_formated } :
              {}
            break
          case 11:
            return this.computedDeceased.burial_place_id && this.computedDeceased.rest_home_burial
              ? { id: this.computedDeceased.burial_place_id, title: `${this.computedDeceased.rest_home_burial?.nom_formated}` } :
              {}
            break
          default:
            return {}
            break
        }
      } else {
        return {}
      }
    },
  }
}
</script>
