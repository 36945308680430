<template>
  <div
      :class="[ isActive ? 'bg-theme-red text-white ring-2 ring-offset-2 ring-offset-red-300 ring-white ring-opacity-60' : 'bg-gray-300 text-grey ', 'relative flex-1 flex px-5 py-4 rounded-lg shadow-md cursor-pointer focus:outline-none']"
      id="headlessui-radiogroup-option-4" role="radio" aria-checked="false" tabindex="0"
      aria-labelledby="headlessui-label-5" aria-describedby="headlessui-description-6"
    >
    <div class="flex items-center justify-between w-full">
      <div class="flex items-center">
        <div class="text-sm">
          <p id="headlessui-label-5"
             :class="[ isActive ? 'text-white':'text-gray-900',' font-medium capitalize-first' ]">{{ type.name }}</p>
          <span id="headlessui-description-6" :class="[ isActive ? 'text-white':'text-black',' inline' ]"><p
              class="capitalize-first">{{ type.count }}</p>
            <p class="capitalize-first">{{ type.total }} €</p>
            </span>
        </div>
      </div>
      <div class="flex-shrink-0 text-white" v-if="isActive">
        <svg class="w-6 h-6" viewBox="0 0 24 24" fill="none">
          <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2"></circle>
          <path d="M7 13l3 3 7-7" stroke="#fff" stroke-width="1.5" stroke-linecap="round"
                stroke-linejoin="round"></path>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'SelectCheckViewLink',
  props: {
    type: {},
    totalAmount: {},
    isActive: {},
    status:{}
  },
  computed: {
    ...mapGetters({
      checkDepositList: 'invoicing/getCheckDepositList',
      metadata: 'invoicing/getCheckMetadata'
    }),
    count() {
      return `${this.$t('attributes.quantity')}: ${this.checkQuantity(this.status)}`
    },
    total() {
      let total = this.metadata?.checks_list?.filter(check => check.check_status === this.status).reduce((a, b) => a + parseFloat(b.amount), 0);
      console.log('tot' ,typeof total)
      return 12;
    },
  },
  methods: {
    checkQuantity(status) {
      return this.metadata?.checks_list?.filter(check => check.check_status === status).length
    },
    checkTotalAmount(status) {
      return this.metadata?.checks_list?.filter(check => check.check_status === status).reduce((a, b) => a + parseFloat(b.amount), 0);
    },
  },
}
</script>
