<template>
  <BasePageFull>
    <!--#region header -->
    <template #title>

      <div class='flex space-x-3'>
        <font-awesome-icon :icon="cIcon" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ cTitle }}</span>
      </div>

    </template>

    <template #cta>

      <BaseButton color='primary' icon='plus' @click="this.$router.push({ name : 'work_form_new_route' })"
                  :title="$t('button.add_new_m', {var: $t('global.work')})"/>
    </template>
    <!--#endregion -->

    <!--#region settings -->
    <template #body>

      <ToastUndoComponent :text="$t('toasts.deleted_m', {var: $t('attributes.work')}) "/>


      <BaseSimpleCard name='user body' title='' classes='col-span-12 h-screen' :borderedHeader="false"
                      @resetFilters="resetFilter">
        <template #default>

          <div class='fixed right-10 bottom-10 z-100 overflow-visible'>
            <TheWorkIndexSettingsComponent @filterChange="setDateFilter" @otherFilterChange="setOtherFilter"
                                           @customerFilter="customerFilterChange" @resetFilter="resetFilter"/>
          </div>

          <BaseContentTable v-if="metadata?.columns"
                            :columns="metadata.columns"
                            :modelList="cWorkList"
                            :search="true"
                            @rowClick="redirectToModelPage"
                            @refreshClick="fetchWorkList"
                            @deleteModel="deleteModelById"
                            :isHoverable="true"
                            :isResponsive="true"/>

        </template>
      </BaseSimpleCard>
    </template>
    <!--#endregion -->

  </BasePageFull>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment'

import TheWorkIndexSettingsComponent from './components/TheWorkIndexSettingsComponent'

export default {
  name: 'TheWorkIndexPage',
  components: {TheWorkIndexSettingsComponent},
  data() {
    return {
      filterKind: [],
      filterKindStatus: [],
      customerFilter: [],
      filterValue: null,
    }
  },
  computed: {
    ...mapGetters({workList: 'work/getWorkIndex', metadata: 'work/getWorkMetadata'}),
    cTitle() {
      return this.$route.name === 'work_archived_route' ? this.$t('navigation.work_archived') : this.$t('navigation.work_progress')
    },
    cIcon() {
      return this.$route.name === 'work_archived_route' ? ['fal', 'archive'] : ['fal', 'folder']
    },
    cWorkList() {
      let tempModels = this.workList
      if (this.filterKind.includes('today')) {

        tempModels = tempModels.filter(work => work?.cremation?.value != null && moment(work?.cremation?.value).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY'))
      }
      if (this.filterKind.includes('tomorrow')) {
        tempModels = tempModels.filter(work => work?.cremation?.value != null &&  moment(work?.cremation?.value).format('DD/MM/YYYY') === moment().add(1, 'd').format('DD/MM/YYYY'))
      }
      if (this.filterKind.includes('day_after_tomorrow')) {
        tempModels = tempModels.filter(work => work?.cremation?.value != null && moment(work?.cremation?.value).format('DD/MM/YYYY') === moment().add(2, 'd').format('DD/MM/YYYY'))
      }
      if (this.filterKindStatus.length > 0) {
        tempModels = tempModels.filter(work => {
          return this.filterKindStatus.includes(work.status)
        })
      }
      if (this.customerFilter.length > 0) {
        tempModels = tempModels.filter(work => {
          return this.customerFilter.includes(work.customer)
        })
      }
      return tempModels
    }
  },
  methods: {
    ...mapActions({
      fetchWorkList: 'work/fetchWorkList',
      fetchArchivedWorkList: 'work/fetchArchivedWorkList',
      deleteWorkById: 'work/deleteWorkById',
      undoDeleteWorkById: 'work/undoDeleteWorkById',
    }),

    setDateFilter(filter) {

      if (filter.selected) {
        this.filterKind.push(filter.value)
      } else {
        this.filterKind = this.filterKind.filter(value => {
          return value !== filter.value
        })
      }
    },
    setOtherFilter(status) {
      this.filterKindStatus = status
    },
    customerFilterChange(customers) {
      this.customerFilter = customers['customer'].map(c => c.name)
    },
    resetFilter() {
      this.filterKind = []
      this.filterKindStatus = []
    },
    redirectToForm() {
      this.$router.push({name: 'work_create_route'})
    },
    redirectToModelPage(id) {
      this.$router.push({name: 'work_form_edit_route', params: {id: id}})
    },
    deleteModelById(work_id) {
      this.deleteWorkById({
        work_id: work_id,
        successCallback: () => {
          let toast = cash("#notification-with-actions-content").clone()
          toast.find('#undo_btn').on('click', () => {
            this.undoDeleteWork(work_id)
          })
          this.$h.toastifyHtml({
            close: true,
            duration: 10000,
            node: toast.removeClass("hidden")[0]
          })
        }
      })
    },
    undoDeleteWork(work_id) {
      this.undoDeleteWorkById({
        work_id: work_id,
        successCallback: () => {
          this.$h.toastify(this.$t('toasts.reseted_m', {var: this.$t('attributes.work')}), {className: 'toastify-content success'})
        }
      })
    }
  },
  watch: {
    dArrayFilter: {
      handler(newValue) {
      },
      deep: true
    },
    '$route.name'(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        if (newValue === 'work_archived_route') {
          this.fetchArchivedWorkList({status: 'ARC'})
        } else {
          this.fetchWorkList()
        }
      }
    }
  },
  async mounted() {
    await this.fetchWorkList()
  }
}
</script>
