export default {
  enums: {
    asset: {
      type:{
        ROM : 'salle',
        ROM_REC: 'salle de réception',
        OVE : 'four',
        LAW : 'pelouse',
        GRA : 'inhumation',
        OTH : 'autre',
      }
    },
    config: {
      permissions: {
        permissions: {
          SEE_CONTENT : 'voir le contenu',
          MANAGE_CONTENT : 'modifier le contenu',
          DELETE_CONTENT : 'supprimer le contenu',
          SEE_WORK : 'voir les dossiers',
          MANAGE_WORK : 'modifier les dossiers',
          DELETE_WORK : 'supprimer les dossiers',
          SEE_INVOICING : 'voir la facturations',
          MANAGE_INVOICING : 'modifier la facturation',
          DELETE_INVOICING : 'supprimer dans la facturation',
          SEE_PLANNING : 'voir le planning',
          MANAGE_PLANNING : 'modifier le planning',
          DELETE_PLANNING : 'supprimer dans le planning',
        },
        permissions_group: {
          CONTENT: 'contenu',
          WORK: 'dossier',
          INVOICING: 'facturation',
          PLANNING: 'planning',
          TASKS: 'tâches',
          SETTINGS: 'paramètres',
        },
        types:{
          see: 'voir',
          manage: 'modifier',
          delete: 'supprimer',
        }
      },
      civility: {
        '1': 'monsieur',
        '2': 'madame',
        '3': 'mademoiselle',
        '4': 'docteur',
        '5': 'professeur',
        '6': 'maitre',
        '7': 'révérend',
        '8': 'soeur',
        '9': 'ecuyer',
        '10': 'madamemonsieur',
        '11': 'succession',
        '12': 'famille',
      },
      country: {
        BE: 'belgique',
        FR: 'france',
        DE: 'allemagne',
        IT: 'italie',
        LU: 'luxembourg',
        NL: 'pays-bas',
        PT: 'portugal',
        ES: 'espagne',
        CH: 'suisse',
        UK: 'royaume-uni',

        AF: 'afghanistan',
        AL: 'albanie',
        DZ: 'algérie',
        AS: 'samoa américaine',
        AD: 'andore',
        AO: 'angola',
        AI: 'anguilla',
        AQ: 'antarctique',
        AG: 'antigua-et-barbuda',
        AR: 'argentine',
        AM: 'arménie',
        AW: 'aruba',
        AU: 'australie',
        AT: 'autriche',
        AZ: 'azerbaijan',
        BS: 'bahamas',
        BH: 'bahreïn',
        BD: 'bangladesh',
        BB: 'barbados',
        BY: 'biélorussie',
        BZ: 'bélize',
        BJ: 'bénin',
        BM: 'bermude',
        BT: 'bhoutan',
        BO: 'bolivie',
        BA: 'bosnie-herzégovine',
        BW: 'botswana',
        BV: 'île bouvet',
        BR: 'brésil',
        IO: 'territoire britannique de l\'océan indien',
        BN: 'brunéi darussalam',
        BG: 'bulgarie',
        BF: 'burkina faso',
        BI: 'burundi',
        KH: 'burundi',
        CM: 'cameroun',
        CA: 'canada',
        CV: 'cap-vert',
        KI: 'îles caïmans',
        CF: 'république d\'afrique centrale',
        TD: 'tchad',
        CL: 'chili',
        CN: 'chine',
        CX: 'île christmas',
        CC: 'îles cocos',
        CO: 'colombie',
        KM: 'comores',
        CG: 'congo',
        CD: 'république démocratique du congo',
        CK: 'îles cook',
        CR: 'costa rica',
        CI: 'côte d\'ivoir',
        HR: 'croatie',
        CU: 'cuba',
        CY: 'chypre',
        CZ: 'république tchèque',
        DK: 'danemark',
        DJ: 'djibouti',
        DM: 'dominique',
        DO: 'république dominicaine',
        EC: 'équateur',
        EG: 'égypte',
        SV: 'el salvador',
        GQ: 'guinée équatoriale',
        ER:  'érythrée',
        EE: 'estonie',
        ET: 'éthiopie',
        FK: 'îles malouines',
        FO: 'îles féroé',
        FJ: 'fidji',
        FI: 'finlande',
        GF: 'guyane française',
        PF: 'polynésie française',
        TF: 'terres australes et antarctiques française',
        GA: 'gabon',
        GM: 'gambie',
        GE:  'géorgie',
        GH: 'ghana',
        GI: 'gibraltar',
        GR: 'grèce',
        GL: 'groenland',
        GD: 'grenade',
        GP: 'guadeloupe',
        GU: 'guam',
        GT: 'guatemala',
        GN: 'guinée',
        GW: 'guinée-bissai',
        GY: 'guyane',
        HT: 'haïti',
        HM: 'îles heard et mcdonald  ',
        VA: 'vatican',
        HN: 'honduras',
        HK: 'hong kong',
        HU: 'hongrie',
        IS: 'islande',
        IN: 'inde',
        ID: 'indonésie',
        IR: 'iran',
        IQ: 'irak',
        IE: 'irlande',
        IL: 'israël',
        JM: 'jamaïque',
        JP: 'japon',
        JO: 'jordanie',
        KZ: 'kazakhstan',
        KE: 'kenya',
        // KI: 'kiribati',
        KP: 'corée du nord',
        KR: 'corée du sud',
        KW: 'koweït',
        KG: 'kirghizistan',
        LA: 'laos',
        LV: 'lettonie',
        LB: 'liban',
        LS: 'lesotho',
        LR: 'libéria',
        LY: 'libye',
        LI: 'liechtenstein',
        LT: 'lituanie',
        MO: 'macao',
        MK: 'macédoine du nort',
        MG: 'madagascar',
        MW: 'malawi',
        MY: 'malaisie',
        MV: 'maldives',
        ML: 'mali',
        MT: 'malte',
        MH: 'île marshall',
        MQ: 'martinique',
        MR: 'mauritanie',
        MU: 'île maurice',
        YT: 'mayotte',
        MX: 'méxico',
        FM: 'micronésie',
        MD: 'moldavie',
        MC: 'monaco',
        MN: 'mongolie',
        MS: 'montserrat',
        MA: 'maroc',
        MZ: 'mozambique',
        MM: 'birmanie (myanmar)',
        NA: 'namibie',
        NR: 'nauru',
        NP: 'népal',
        AN: 'antilles néerlandaise',
        NC: 'nouvelle-calédonie',
        NZ: 'nouvelle-zélande',
        NI: 'nicaragua',
        NE: 'niger',
        NG: 'nigéria',
        NU: 'niué',
        NF: 'île norfolk',
        MP: 'îles mariannes du nord',
        NO: 'norvège',
        OM: 'oman',
        PK: 'pakistan',
        PW: 'palaos',
        PS: 'territoires palestiniens occupés',
        PA: 'panama',
        PG: 'papouasie',
        PY: 'paraguay',
        PE: 'pérou',
        PH: 'philipines',
        PN: 'îles pitcairn',
        PL: 'pologne',
        PR: 'puerto rico',
        QA: 'qatar',
        RE: 'la réunion',
        RO: 'roumanie',
        RU: 'russie',
        RW: 'rwanda',
        SH: 'sainte-hélène',
        KN: 'saint christophe',
        LC: 'sainte-lucie',
        PM: 'saint-pierre-et-miquelon',
        VC: 'saint-vincent et les grenadines',
        WS: 'samoa',
        SM: 'saint-marin',
        ST: 'sao tomé-et-principe',
        SA: 'arabie saoudite',
        SN: 'sénégal',
        CS: 'serbie-et-monténégro',
        SC: 'seychelles',
        SL: 'sierra leone',
        SG: 'singapour',
        SK: 'slovaquie',
        SI: 'slovénie',
        SB: 'îles salomon',
        SO: 'somalie',
        ZA: 'afrique du sud',
        GS: 'géorgie du sud et les îles sandwich du sud',
        LK: 'sri lanka',
        SD: 'soudan',
        SR: 'suriname',
        SJ: 'svalbard et jan mayen',
        SZ: 'ewwatini',
        SE: 'suède',
        SY: 'syrie',
        TW: 'taïwan',
        TJ: 'tajikistan',
        TZ: 'tanzanie',
        TH: 'thaïlande',
        TL: 'timor-leste',
        TG: 'togo',
        TK: 'tokelau',
        TO: 'tonga',
        TT: 'tinité-et-tobago',
        TN: 'tunisie',
        TR: 'turquie',
        TM: 'turkménistan',
        TC: 'îles turks et caïques',
        TV: 'tuvalu',
        UG: 'uuganda',
        UA: 'ukraine',
        AE: 'émirats arabees unis',
        GB: 'royaume-uni',
        US: 'états-unis',
        UM: 'îles mineures éloignées des états-unis',
        UY: 'uruguay',
        UZ: 'uzbekistan',
        VU: 'vanuatu',
        VE: 'venezuela',
        VN: 'vietnam',
        VG: 'îles vierges britaniques',
        VI: 'îles vierges des états-unis',
        WF: 'wallis et futuna',
        EH: 'sahara occidental',
        YE: 'yémen',
        ZM: 'zambie',
        ZW: 'zimbabwe',
      },
      days:{
        '0': 'dimanche',
        '1': 'lundi',
        '2': 'mardi',
        '3': 'mercredi',
        '4': 'jeudi',
        '5': 'vendredi',
        '6': 'samedi',
      },
      Languages: {
        fr_BE: 'français (Belgique)',
        fr_FR: 'français (France)',
        nl_BE: 'néerlandais (Belgique)',
      },
    },
    customer: {
      types: {
        FUN: 'funérarium',
        HOS: 'hôpital',
        MUN: 'municipalité',
        OTH: 'autre',
      }
    },
    deceased: {
      burial_types: {
        BUR: 'inhumation',
        DIS: 'dispersion',
        CON: 'conservation au crématorium',
        REP: 'reprise',
        ASR: 'reprise',
        OTH: 'autre',
      },
      size_types: {
        EXTRA_SIZE:"hors mesure (à partir de 120kg)",
        NORMAL:"normal"
      },
      size_types_returning: {
        EXT:"hors mesure (à partir de 120kg)",
        NOR:"normal"

      },
      operator_types: {
        OQP: 'personne ayant qualité pour pourvoir aux funérailles',
        OPE: 'opérateur'
      }
    },
    document:{
      document_type: {
        DOCUMENT: 'document',
        MUNICIPAL_DOCUMENT: 'document ville',
        INVOICE: 'facture',
        PAYMENT: 'versement',
      }
    },
    event:{
      type:{
        REC : 'réception',
        CER : 'cérémonie',
        ASR : 'reprise des cendres',
        DIS : 'dispersion',
        CRE : 'crémation',
        CON : 'conservation au crématorium',
        CAR : 'arrivée du cercueil',
        BUR : 'inhumation',
        MAI: 'maintenance',
        FOR: 'formation',
        SEV: 'événement spécial',
        OTH: 'autre',
        OD: 'journée à l\'arrêt'
      },
     ceremony_type: {
        ALL: 'tout',
        CIV: 'civil',
        DA: 'dernier adieu'
      },
      operator_type: {
        OPE: 'opérateur',
        OQP:'Personne ayant qualité pour pourvoir aux funérailles'
      }
    },
    invoicing: {
      status:{
        CAN: 'annulée',
        DRA: 'brouillon',
        EST: 'établie',
        FRM: '1er rappel',
        LAW: 'avocat',
        PAI: 'payée',
        PBI: 'paiement échelonné',
        PP:  'payé partiellement',
        SRM: '2eme rappel',
      },
      check_deposit_status:{
        TBDEP: 'à déposer',
        DEP: 'déposé',
        CAS: 'encaissé',
        P_CAS: 'partiellement encaissé',
      },
      check_status:{
        TBDEP: 'à déposer',
        DEP: 'déposé',
        CAS: 'encaissé',
        REF: 'refusé',
      },
      mode:{
        BAN: 'virement bancaire',
        CAR: 'carte',
        CAS: 'espèce',
        CHE: 'chèque',
        CRN: 'note de crédit',
        ELE: 'paiment életronique',
        INS: 'assurance',
      },
      payment_type: {
        CRE: 'crédit',
        CHA: 'frais',
      },
      type: {
        INV: 'facture',
        PO:  'bon de commande',
        QUO: 'devis',
        CN:  'note de crédit',
      },
    },
    item: {
      awaiting_duration: {
        CASE_A: 'de 0 à 2 mois',
        CASE_B: 'de 2 à 6 mois',
        CASE_C: 'de 6 à 12 mois',
      },
      type:{
        FOOD: 'allimentation',
        ASH_CONTAINER: 'urne et accessoires',
        SERVICE: 'service',
        DUTY: 'frais',
        OTHER: 'autre',
      }
    },
    task: {
      group: {
        ADMIN: 'administrative',
        OPERATIONNAL: 'opérationelle',
        WORKSHOP: 'entrepot',
        COMMERCIAL: 'commercial',
        OTHER: 'autre',
      },
      group_by_task: {
        NONE: 'aucun',
        PARENT_TYPE: 'type de parent',
        EVENT_TYPE: 'type d\'évênement',
      },
      inputs:{
        input: 'champs de saisie',
        textArea: 'grand champ texte',
        select: 'menu déroulant',
        radio: 'liste à choix unique',
      },
      priorities: {
        LOW: 'basse',
        NORMAL: 'normale',
        HIGH: 'haute',
        VERY_HIGH: 'très haute',
      },
      status: {
        1: 'à faire',
        2: 'en cours',
        3: 'terminée',
        4: 'en attente',
      },
      statusType: {
        SIMPLIFIED: 'simplifiée',
        NORMAL: 'normale',
      },
    },
    work: {
      awaiting_duration:{
        'CASE_A': 'de 0 à 1 mois',
        'CASE_B': 'de 1 à 6 mois',
        'CASE_C': 'de 6 à 12 mois',
      },
      status: {
        UCON: 'non confirmé',
        CON:  'confirmé',
        INPRO: 'en cours',
        AWA:  'en attente',
        FIN: 'terminé',
        ARC: 'archivé',
        DEL: 'supprimé',
        PAID: 'payé',
        BIL: 'facturé',
        FREM: 'premier rappel',
        SREM: 'second rappel',
        '4LAW': 'avocat',
      },
      awaiting_duration_returning:{
        '0_1': 'de 0 à 1 mois',
        '1_6': 'de 1 à 6 mois',
        '6_12': 'de 6 à 12 mois',
      },
    },

    yes_no: {
      true: 'oui',
      false: 'non',
      0: 'non',
      1: 'oui',
    },
    listeItem:{
      dispersion_type:'type de dispersion',
      inhumation_type:'type d\'inhumation',
      work_type:'type de dossier',
      burial_type: 'Lieu des funérailles',
      death_place_type: 'lieu du décès',
      item_type:'type d\'article'

    },
    notification_settings:{
      new_user:'notification de la création d\'un nouvel utilisateur',
      new_work_subject_to_confirmation:'notification de la création d\'un nouveau dossier sous-reserve',
      new_work_subject_to_confirmation_deleted_soon:'notification de la prochaine suppression d\'un nouveau dossier sous-reserve',
      new_work_subject_to_confirmation_deleted: 'notification de la suppression d\'un nouveau dossier sous-reserve'
    },
    message_text: {
      groups_variables: {
        deceased: 'défunt',
        work: 'dossier',
        date: 'date',
        invoice: 'facture',
        event: 'évènement',
        user: 'utilisateur',
        app: 'url'
      }
    }
  },
}

