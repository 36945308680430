export default {
  button: {
    add: 'ajouter',
    add_new_f: 'ajouter une {var}',
    add_new_m: 'ajouter un {var}',
    add_plural: 'ajouter des {var}',
    back: 'retour',
    browse_f: 'parcourir la {var}',
    browse_m: 'parcourir le {var}',
    browse_plural: 'parcourir les {var}',
    cancel: 'annuler',
    cancel_modification: 'annuler la modification',
    change_item_order: 'changer l\'ordre des articles',
    clone: 'dupliquer',
    close: 'fermer',
    confirm: 'confirmer',
    convert_to: 'convertir en {var}',
    create: 'créer',
    create_m: 'créer un {var}',
    create_f: 'créer une {var}',
    create_plural: 'créer des {var}',
    delete: 'supprimer',
    delete_m: 'supprimer le {var}',
    delete_new_mv: 'supprimer cet {var}',
    delete_f: 'supprimer la {var}',
    delete_v: 'supprimer l\'{var}',
    delete_plural: 'suprimer les {var}',
    edit: 'modifier',
    edit_new_f: 'modifier cette {var}',
    edit_new_m: 'modifier ce {var}',
    edit_new_mv: 'modifier cet {var}',
    edit_plural: 'modifier des {var}',
    hide: 'cacher',
    hide_m: 'cacher le {var}',
    hide_f: 'cacher la {var}',
    hide_v: 'cacher l\' {var}',
    hide_plural: 'cacher les {var}',
    login: 'connexion',
    modify_password: 'modifier le mot de passe',
    print: 'imprimer',
    reduce: 'réduire',
    refresh: 'rafraîchir',
    rename: 'renommer',
    save: 'enregistrer',
    save_new_f: 'enregister cette {var}',
    save_new_m: 'enregister ce {var}',
    save_new_mv: 'enregister cet {var}',
    save_plural: 'enregistrer ces {var}',
    send: 'envoyer',
    show: 'afficher',
    show_plural: 'afficher les {var}',
    show_v: 'afficher l\'{var}',
    to_f: 'vers la {var}',
    to_m: 'vers le {var}',
    to_plural:'vers les {var}',
    to_the: 'vers l\'{var}',
  }
}
