export default {
  attributes: {
    valeur:"bedrag (€)",
    address: 'address',
    coffin_putting_info: 'kisting informatie',
    age: 'leeftijd',
    asset: 'asset',
    available: 'beschikbaar',
    bank: 'bankrekening',
    birth_date: 'geboortedatum',
    birth_place: 'geboorteplaats',
    burial_date: 'begrafenisdatum',
    burial_place: 'begrafenisplaats',
    burial_type: 'bestemming',
    buy_price: 'aankoopprijs',
    cemetery: 'begraafplaats',
    ceremony: 'plechtigheid',
    city: 'stad',
    civility: 'beleefdheid',
    company: 'firma',
    company_id:'firma',
    content: 'contenu',
    country: 'land',
    cremation: 'creatie',
    current_password: 'current password',
    customer: 'klant',
    customer_id:'klant',
    date: 'datum',
    date_from: 'begindatum',
    date_to: 'einddatum',
    day: 'dag',
    death_date: 'datum van overlijden',
    death_place: 'plaats van overlijden',
    deceased: 'overledene',
    description: 'omschrijving',
    due_date: 'verval datum',
    email: 'e-mail address',
    emission_date: 'datum',
    event_title: 'evenementtitel',
    event_type: 'soort evenement',
    excerpt: 'uittreksel',
    extra_size: 'buiten maat',
    first_name: 'voornaam',
    fiscal_year: 'boekjaar',
    gender: 'geslacht',
    height: 'hoogte',
    hour: 'tijd',
    hour_from: 'begintijd',
    hour_to: 'eindtijd',
    invoice_number: 'factuurnummer',
    language: 'taal',
    last_name: 'naam',
    maiden_name: 'meisjesnaam',
    minute: 'minuut',
    mobile: 'gsm',
    mobile_country:'land(mobile)',
    mobile_field :'mobile',
    month: 'maand',
    name: 'naam',
    national_number: 'nationaal nummer',
    nb_place: 'aantal plaatsen',
    nb_peoples: 'aantal personen',
    note_intern: 'interne nota',
    password: 'wachtwoord',
    password_confirmation: 'wachtwoord bevestiging',
    payment: 'betaling',
    phone: 'telefoon',
    phone_country:'land(telephone)',
    phone_field:'telefoon',
    post_code: 'postcode',
    price_excluding_vat: 'prijs exclusief BTW',
    price_including_vat: 'prijs inclusief BTW',
    quantity: 'aantal',
    reduction: 'verkorting',
    reference: 'referentie',
    reference_extern: 'externe referentie',
    reference_quote: 'kostenraming referentie',
    remarks: 'opmerkingen',
    remember_me: 'herinner mij',
    responsable: 'verantwoordelijk',
    responsable_id:'verantwoordelijk',
    scattering: 'verspreiding',
    second: 'seconde',
    sex: 'geslacht',
    size: 'maat',
    solde: 'saldo',
    spouse_first_name: 'voornaam van de echtgenoot(te)',
    spouse_last_name: 'roepnaam van de echtgenoot(te)',
    spouse_usual_first_name: 'naam van de echtgenoot(te)',
    status: 'status',
    street: 'address',
    time: 'uur',
    title: 'titel',
    TVA: 'BTW',
    total_htva: 'totaal exclusief BTW',
    total_tvac: 'totaal inclusief BTW',
    type: 'type',
    asset_type: 'type de ressource',
    user_name: "gebruikersnaam",
    usual_first_name: 'roepnaam',
    website: 'website',
    weight: 'gewicht',
    work: 'bestand',
    work_title: 'titel van het bestand',
    year: 'jaar',
    time_from:'begintijd',
    time_to:'eindtijd',
    vat_rate:'btw-tarieven',
  }
}
