<template>
  <div class='mx-12 box px-6 py-5 my-4'>
    <div class="flex justify-end sm:flex-row items-center mb-5">
        <BaseButton color='danger'
                    icon='times'
                    :isOutline="true"
                    @click.stop="$emit('deleteMessage', this.message_text.id)"
        />
    </div>
    <div class="grid grid-cols-12 gap-4">
      <div class=" col-span-2 font-normal">{{ $t('enums.config.Languages.' + message_text.language) }}</div>

      <div class="col-span-1 font-normal">{{ message_text.type.toLowerCase()}}</div>
      <div class="col-span-6 pb-4">
        <div class="mb-3 capitalize font-bold text-base">{{ message_text.title }}</div>
        <div class="truncate h-20 pt-5  font-normal" v-html="message_text.text"></div>
      </div>
      <div class="col-span-3 text-center">{{ getGroup }}</div>
    </div>
  </div>
</template>


<script>

export default {
  name: "SingleMessageTextComponent",
  props: {
    message_text: {
      type: Object,
      required: true
    },
    groups: {
      type: Object
    }
  },
  computed: {
    getGroup() {
      let group =  this.groups.filter(g => g.id === this.message_text.group)
      return Object.values(group)[0]['name'];
    }
  },
  methods: {
    deleteMessageText() {
      this.$emit("deleteMessageText", this.message_text.id)
    }
  }
}
</script>
