<template>

  <div class="px-12 py-3">

    <div class="mb-3 flex justify-end">
      <BaseButton color='primary'
                  :icon='getIcon'
                  :title="getTitle"
                  @click="openForm"
      />
    </div>

    <div class="space-y-6 mb-8 px-12 sm:space-y-5 " v-if="isOpen && listItem">


      <div
          v-if="listItem.label"
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <BaseInput
            :label="`Nom (${Object.keys(listItem.label)})`"
            :placeholder="Object.keys(listItem.label)"
            v-model="listItem.label[Object.keys(listItem.label)]"
        />
      </div>
      <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5"
           v-else
      >
        <div
            v-for="(langue, index) in availableLabels.general.available_languages"
            :key="index">
          <BaseInput
              :label="`Nom (${langue})`"
              :placeholder="langue"
              v-model="this.newListItem.label[langue]"
          />
        </div>
      </div>

      <div class="flex justify-center ">
        <BaseButton color='primary'
                    icon='paper-plane'
                    :title="$t('button.send')"
                    @click="submitForm"
        />
      </div>

    </div>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'AddItemForm',
  props: {
    listItem: {
      type: Object,
      required: false
    },
    groupName: {
      type: String,
      required: true
    },
    showForm: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: this.showForm,
      item: '',
      newListItem: {
        group: this.groupName,
        label: {}
      }
    }
  },
  computed: {
    ...mapGetters({
      availableLabels: 'settings/getSystemSettings',
    }),
    getTitle() {
      return !this.isOpen ? this.$t('button.add_new_m', {var: this.$t('global.listItems')}) : this.$t('button.cancel', {var: ''});
    },
    getIcon() {
      return !this.isOpen ? 'plus' : 'times';
    },
  },
  methods: {
    ...mapActions({
      updateItemList: 'itemList/updateListItem',
      addItemList: 'itemList/storeListItem'
    }),
    getInputValueLanguage({attribute, value}) {
      this.newListItem.label = {...this.newListItem.label, [attribute]: value}
    },
    openForm() {
      if (this.listItem.hasOwnProperty('label')) {
        this.$emit('valueUpdated', false);
      } else {
        this.isOpen = !this.isOpen;
      }
    },
    submitForm() {
      if (this.listItem.hasOwnProperty('label')) {
        this.updateItemList({listItem: this.listItem});
        this.$emit('valueUpdated', true);
      } else {
        this.addItemList({listItem: this.newListItem})
      }
      this.isOpen = false;
    }
  }
}
</script>
