<template>
  <router-link :to="{name:routeName}" class="flex items-center gap-x-2 dark:text-white" :class="activeClasses" v-if='!disabled'>
    <font-awesome-icon :icon="['fal', icon]" size="lg" />&nbsp;
    <p class="cursor-pointer capitalize-first">{{ label }}</p>
  </router-link>
  <span  :class="activeClasses" class="flex items-center gap-x-2 dark:text-white text-gray-500" v-else>
    <font-awesome-icon :icon="['fal', icon]" size="lg" />&nbsp;
    <p class="cursor-pointer capitalize-first">{{ label }}</p>
  </span>
</template>

<script>
export default {
  name: 'workNavLink',
  props:{
    disabled:{
      type: Boolean,
      required: false,
      default: false
    },
    routeName:{
      type: String,
      required: true
    },
    icon:{
      type: String,
      required: true
    },
    label:{
      type: String,
      required: true
    },
  },
  computed:{
    isActive(){
      return this.$route.name === this.routeName
    },
    activeClasses(){
      return  this.isActive ? 'flex items-center text-theme-25 dark:text-theme-22 font-medium' : 'flex items-center'
    }
  }
}
</script>
