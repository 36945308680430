<template >
  <div>
    <template v-if="editionMode" >
      <div class="flex" >
        <BaseEditLabel :label="label" :required="required" />
      </div >
      <div class="flex -space-x-px">
        <div class="w-4/6 flex-1 min-w-0">
            <datepicker
              v-model="datePickerValue"
              @update:modelValue="handleDateSelectedInDatePicker"
              :monthHeadingFormat='monthFormat'
              :weekdayFormat='weekFormat'
              :inputFormat='datePickerFormat'
              :weekStartsOn='startingDayOfTheWeek'
              :clearable='clearButtonVisible'
              :typeable='canTypeDateManually'
              :startingView='viewType'
              :style='style'
              :readonly="false"
              :locale='locale'
              class='focus:ring-blue-300 focus:border-blue-300 relative block w-full rounded-none rounded-bl-md rounded-tl-md bg-transparent focus:z-10 sm:text-sm border-gray-300'
            />
        </div >
        <div class=" min-w-0">
          <datepicker
            v-model="timePickerValue"
            @update:modelValue="handleTimeSelectedInTimePicker"
            :monthHeadingFormat='monthFormat'
            :weekdayFormat='weekFormat'
            :inputFormat='timePickerFormat'
            :weekStartsOn='startingDayOfTheWeek'
            :clearable='clearButtonVisible'
            :typeable='canTypeDateManually'
            :startingView='"time"'
            :minimum-view="'time'"
            :style='style'
            :readonly="false"
            :locale='locale'
            class="focus:ring-blue-300  focus:border-blue-300  relative block w-full rounded-none rounded-br-md rounded-tr-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
          />
        </div >
      </div >
      <template v-if="internalErrors">
        <div v-for='(error,index) in internalErrors' :key='index' class="form-help text-red-600">
          {{ $t(error) }}
        </div>
      </template>
      <template v-if="errors">
        <div v-for='(error,index) in errors' :key='index' class="form-help text-red-600">
          {{ $t(error, {attribute: $t('attributes.' + name)}) }}
        </div>
      </template>
    </template>
    <template v-else>
<!--      <BaseShowLabel-->
<!--        :label="label"-->
<!--        :model-value="displayedValueWhenNotInEditMode"-->
<!--      />-->
      <div v-if="label" class="text-sm font-medium text-gray-500 capitalize-first">
        {{ label }}
      </div >
      <div class="flex text-black">
          {{ displayedValueWhenNotInEditMode }}
      </div >
    </template>
  </div >
</template >

<script >
import Datepicker from 'vue3-datepicker'
import { fr } from "date-fns/locale";
import moment from "moment";

export default {
  name: "BaseShowEditIsoDateTimeInput",
  components: {
    Datepicker
  },
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null
    },
    undefinedTimeValue: {
      type: Boolean,
      required: false,
      default: null,
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY',
    },
    dateMask: {
      type: String,
      required: false,
      default: '##/##/####'
    },
    timeFormat: {
      type: String,
      required: false,
      default: 'HH:mm',
    },
    timeMask: {
      type: String,
      required: false,
      default: '##:##'
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    editionMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    datePlaceholder: {
      type: String,
      required: false,
      default: null
    },
    timePlaceholder: {
      type: String,
      required: false,
      default: null
    },
    errors: {
      type: Array,
      required: false,
      default: null
    },
    customReferenceDate: {
      type: String,
      required: false,
      default: null
    },
    customReferenceDateFormat: {
      type: String,
      required: false,
      default: 'DD/MM/YYYY'
    },
    isDatePicker: {
      type: Boolean,
      required: false,
      default: true
    },
    monthFormat: {
      type: String,
      required: false,
      default: 'LLLL yyyy'
    },
    weekFormat: {
      type: String,
      required: false,
      default: 'EE'
    },
    startingDayOfTheWeek: {
      type: Number,
      required: false,
      default: 1
    },
    canTypeDateManually: {
      type: Boolean,
      required: false,
      default: false
    },
    clearButtonVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    viewType: {
      type: String,
      required: false,
      default: 'day',
      validator: function(value) {
        const admittedTypes = ['day', 'month', 'year', 'time']
        return admittedTypes.indexOf(value) !== -1
      }
    },
    datePickerFormat: {
      type: String,
      required: false,
      default: 'dd/MM/yyyy'
    },
    isTimePicker: {
      type: Boolean,
      required: false,
      default: false
    },
    timePickerFormat: {
      type: String,
      required: false,
      default: 'hh:mm'
    }
  },
  emits: ['update:modelValue', 'update:undefinedTimeValue', 'change'],
  data() {
    return {
      internalDate: null,
      internalTime: null,
      internalUndefinedTime: null,
      internalErrors: [],
      locale: fr,
      style: {
        "--vdp-bg-color": "#ffffff",
        "--vdp-text-color": "#424242",
        "--vdp-box-shadow": "0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)",
        "--vdp-border-radius": "8px",
        "--vdp-heading-size": "2.5em",
        "--vdp-heading-weight": "bold",
        "--vdp-heading-hover-color": "#eeeeee",
        "--vdp-arrow-color": "currentColor",
        "--vdp-elem-color": "currentColor",
        "--vdp-disabled-color": "#d5d9e0",
        "--vdp-hover-color": "#ffffff",
        "--vdp-hover-bg-color": "#4386C8",
        "--vdp-selected-color": "#ffffff",
        "--vdp-selected-bg-color": "#4386C8",
        "--vdp-elem-font-size": "0.8em",
        "--vdp-elem-border-radius": "3px",
        "--vdp-divider-color": "#d5d9e0"
      },
    }
  },
  computed: {
    timePickerValue(){
      return new Date(moment(this.internalTime, this.timeFormat))
    },
    datePickerValue(){
      return new Date(moment(this.internalDate, this.dateFormat))
    },
    isAValidDate() {
      const subValidation = moment(this.joinedInternalValue, this.joinedFormat).format(this.joinedFormat)
      this.internalErrors = []
      if(subValidation === this.joinedInternalValue){
        return moment(this.joinedInternalValue, this.joinedFormat).isValid()
      }
      if (!this.internalDateIsAValidDate && this.internalDate !== null && this.internalErrors.indexOf("validation.register_valid_date") === -1){
        this.internalErrors.push('validation.register_valid_date')
      }
      if (!this.internalTimeIsAValidTime && this.internalErrors.indexOf("validation.register_valid_time") === -1){
        if (!this.internalUndefinedTime) {
          this.internalErrors.push('validation.register_valid_time')
        }
      }
      return false
    },
    internalDateIsAValidDate(){
      return moment(this.internalDate, this.dateFormat).format(this.dateFormat) === this.internalDate
    },
    internalTimeIsAValidTime(){
      return moment(this.internalTime, this.timeFormat).format(this.timeFormat) === this.internalTime
    },
    joinedFormat(){
      return [this.dateFormat, this.timeFormat].join(' - ')
    },
    joinedInternalValue(){
      let temp = null
      if (!this.internalTime || this.internalTime === '') {
        temp = this.timeMask.replaceAll('#', '0')
        this.$emit('update:undefinedTimeValue', 1)
      } else {
        this.$emit('update:undefinedTimeValue', 0)
        temp = this.internalTime
      }
      return [this.internalDate, temp].join(' - ')
    },
    displayedValueWhenNotInEditMode(){
      if((!this.internalDate || this.internalDate === '') && (!this.internalTime || this.internalTime === '')){
        return null
      } else {
        if (this.internalUndefinedTime) {
          return this.internalDate
        }
        return this.joinedInternalValue
      }
    },
  },
  watch: {
    modelValue: {
      handler(newValue){
        if(newValue){
          this.internalDate = moment(newValue).format(this.dateFormat)
          this.internalTime = this.internalUndefinedTime ? null : moment(newValue).format(this.timeFormat)
        } else {
          this.internalDate = null
          this.internalTime = null
        }
        
      },
      immediate: true,
      deep:true,
    },
    undefinedTimeValue: {
      handler(newValue, oldValue){
        if(newValue !== oldValue){
          this.internalUndefinedTime = Boolean(newValue)
        }
      },
      immediate: true,
      deep:true,
    },
    
  },
  methods: {
    selectAllText(){
      console.log(this.$refs.timeInput.select())
      this.$refs.timeInput.select()
    },
    handleTimeSelectedInTimePicker(timePickerTime){
      this.internalTime = moment(timePickerTime).format(this.timeFormat)
      this.updateInput()
    },
    handleDateSelectedInDatePicker(datePickerDate){
      this.internalDate = moment(datePickerDate).format(this.dateFormat)
      this.updateInput()
    },
    updateInput() {
      if(this.isAValidDate){
        this.$emit('update:modelValue', moment(this.joinedInternalValue, this.joinedFormat).format())
        this.$emit('change', moment(this.joinedInternalValue, this.joinedFormat).format())
      }
    },
  },
}
</script >
