<template>
      <BaseSimpleCard name='user body' title='' classes='col-span-12 h-screen' :borderedHeader="false">
        <template #default>
          <div class="flex justify-between">
            <h1 class="text-2xl font-bold mt-4">{{ $t('global.export_invoices')}}</h1>
            <BaseButton
                color='dark'
                icon='long-arrow-left'
                @click="this.$router.go(-1)"
                :title="$t('button.back')"/>
          </div>

          <div class="container mx-auto py-20 w-1/2">
            <div>
              <div class="mt-2.5">
                <BaseShowEditSelectSimple
                    v-if="companies"
                    :editionMode='true'
                    :label="$t('attributes.company')"
                    :placeholder="$t('attributes.company')"
                    name="company"
                    v-model="company"
                    defaultValue=""
                    :options="companies"
                />
              </div>
              <div class=" mt-2.5">
                <div class="text-sm text-gray-500 capitalize-first">{{ $t('global.invoice_range') }}</div>
                  <litepie-datepicker
                      i18n="fr"
                      :placeholder="$t('global.from_to')"
                      separator=" - "
                      :formatter="date_formatter"
                      :shortcuts="false"
                      v-model="due_date"
                      :errors="errors.period"
                  />
<!--                {{typeof errors}}-->
                  <div v-if="errors[0] !== ''" class="text-red-700 text-xs">{{ errors[0] }}</div>
              </div>
              <div class="mt-4">
                <div class="text-sm text-gray-500 capitalize-first">{{ $t('global.with_exported') }}</div>
                <BaseCheckBox
                 label=""
                 name="with_exported"
                 :value="with_exported"
                @checkboxChange="getInputValue"/>

              </div>
              <div class="mt-4">
                <div class="text-sm text-gray-500 capitalize-first">{{ $t('global.mark_exported') }}</div>
                <BaseCheckBox
                    label=""
                    name="mark_exported"
                    :value="mark_exported"
                    @checkboxChange="getInputValue"/>
              </div>
            </div>
            <div class="flex flex-row-reverse mt-4">
              <BaseButton
                  color='primary'
                  icon='arrow-square-down'
                  @click="submitForm"
                  class=""
                  :title="$t('global.export')"/>
            </div>
          </div>

        </template>
      </BaseSimpleCard>
    </template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

import LitepieDatepicker from "litepie-datepicker";

export default {
  name: "TheInvoicingExportPage",
  components: {
    LitepieDatepicker
  },
  data() {
    return {
      company: {},
      due_date: null,
      date_formatter: {
        date: 'DD/MM/YYYY',
        month: 'MM'
      },
      with_exported: false,
      mark_exported : false
    }
  },
  computed: {
    ...mapGetters({
      companies: 'company/getCompaniesList',
      errors: 'auth/getErrors'
    })
  },
  methods: {
    ...mapMutations({setErrors: "auth/setErrors"}),
    ...mapActions({
      fetchCompaniesList: 'company/fetchCompanyIndex',
      fecthInvoiceExport: 'invoicing/fecthInvoiceExport',
    }),
    getInputValue({attribute, value}) {
      if(attribute === 'with_exported') {
        return this.with_exported = !this.with_exported
      } else if(attribute === 'mark_exported') {
        return this.mark_exported = !this.mark_exported
      }
    },
    submitForm() {
        this.fecthInvoiceExport({
          company: this.company,
          period: {from: this.due_date.startDate, to: this.due_date.endDate},
          with_exported: this.with_exported,
          mark_exported: this.mark_exported
        })
    }
  },
  mounted() {
    this.fetchCompaniesList()
  }
}
</script>

