<template>
  <hr>
  <BaseCardSection :section-title="$t('attributes.urn_pending_at_the_crematorium')">
    <div>

      <BaseShowEditRadioGroup
          name='awaiting_duration_in_month'
          :label="$t('attributes.presumed_duration')"
          :options='metadata?.awaiting_duration'
          v-model="work.urn.awaiting_duration_in_month"
          :editionMode="editionMode"
          :isShowingKey="true"
          :isReturningKey="true"
          attribute-label='name'
      />
    </div>
  </BaseCardSection>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'WorkFormUrn',
  props: {
    editionMode: { type: Boolean, required: true },
  },
  methods: {
    ...mapMutations({
      alterWorkItemsValuesByWorkItemType: 'work/alterWorkItemsValuesByWorkItemType',
      alterWork: 'work/alterWork'
    }),
    // getInputValue({attribute, value}) {
    //   console.log(attribute)
    //   this.alterWork({attribute: attribute, value: value})
    // }
  },
  computed: {
    ...mapGetters({
      work: 'work/getWork',
      metadata: 'work/getWorkMetadata'
    }),
  },
}
</script>
