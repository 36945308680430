import getters from '@/store/modules/content/customer/getters'
import mutations from '@/store/modules/content/customer/mutations'
import actions from '@/store/modules/content/customer/actions'

export default {
  namespaced: true,
  state() {
    return {
      customersList: [],
      customer: {},
      customerActivity: {},
      metadata: {},
    }
  },
  getters,
  actions,
  mutations
}
