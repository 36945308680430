<template>
  <Popover v-slot="{ open, close }" class="relative">
    <PopoverButton
      class="flex h-10 w-10 items-center justify-center rounded-full bg-theme-23"
    >
      <font-awesome-icon :icon="['fal', 'plus']" size="lg" />
    </PopoverButton>
    <Transition
      enter-active-class="transform duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transform duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        class="absolute left-0 z-10 mt-3 w-screen max-w-sm transform px-4 sm:px-0 lg:w-72"
      >
        <div
          class="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="relative grid gap-8 bg-white p-5">
            <router-link
              v-for="item in solutions"
              :key="item.name"
              :to="item.to"
              @click="close()"
              class="focus:outline-none focus-visible:ring-orange-500 -m-3 flex items-center rounded-lg p-1 hover:bg-gray-50 focus-visible:ring focus-visible:ring-opacity-50"
            >
              <div
                class="flex h-8 w-8 shrink-0 items-center justify-center rounded-md bg-theme-23 text-gray-700 sm:h-8 sm:w-8"
              >
                <font-awesome-icon :icon="['fal', item.icon]" size="lg" />
              </div>
              <div class="ml-4">
                <p class="text-sm font-medium capitalize text-gray-900">
                  {{ item.name }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </PopoverPanel>
    </Transition>
  </Popover>
</template>

<script>
import {
  Popover,
  PopoverPanel,
  PopoverButton,
  PopoverOverlay
} from '@headlessui/vue'

export default {
  components: { Popover, PopoverPanel, PopoverButton, PopoverOverlay },
  name: 'QuickAdd',
  data() {
    return {
      solutions: [
        {
          name: this.$t('navigation.work_new'),
          description: 'lorem ipsum',
          to: { name: 'work_form_new_route' },
          icon: 'user'
        },
        {
          name: this.$t('global.invoicing_title.INV'),
          description: 'lorem ipsum',
          to: { name: 'invoice_form_new_route' },
          icon: 'file'
        },
        {
          name: this.$t('global.invoicing_title.PO'),
          description: 'lorem ipsum',
          to: { name: 'invoicing_purchase_order_new_route' },
          icon: 'file'
        },
        {
          name: this.$t('global.invoicing_title.QUO'),
          description: 'lorem ipsum',
          to: { name: 'invoicing_quote_new_route' },
          icon: 'file'
        },
        {
          name: this.$t('global.invoicing_title.CR'),
          description: 'lorem ipsum',
          to: { name: 'report_planning_route' },
          icon: 'file-pdf'
        }
      ]
    }
  }
}
</script>
