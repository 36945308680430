<template>
  <SettingsPageFull >
    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','landmark']" size="2x"/>
        &nbsp;<span class='text-2xl font-bold capitalize-first'>{{ this.$route.name === 'settings_create_asset_route' ? $t('button.add_new_f', {var: $t('global.asset')}) : pageTitle }}</span>
      </div>
    </template>
    <template #cta>
      <BaseButton
          v-if="this.$route.name !== 'settings_create_asset_route' && $can('manage_content')"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getAssetAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"/>
      <BaseButton
          color="dark"
          icon='long-arrow-left'
          @click="this.$router.go(-1)"
          :title="$t('button.back')"
      />
    </template>
    <template #body>
      <div class='flex flex-col gap-8'>
        <BaseCardSection :sectionTitle='$t("global.general_information")'>
          <template #default>

            <input
                type="hidden"
                name="implantation_id"
                v-model="asset.implantation_id">

            <div class='grid grid-cols-12 gap-x-5'>
              <div class='col-span-12 xl:col-span-6 xl:col-start-1'>

                <BaseShowEditSelectSimple v-if="metadata?.type"
                                          :label="$t('attributes.asset_type')"
                                          :placeholder="$t('attributes.asset_type')"
                                          name="asset_type"
                                          :editionMode='editionMode'
                                          v-model='asset.type'
                                          :defaultValue="metadata?.type?.filter(el => el.id === asset.type)"
                                          :options="metadata?.type"
                                          :errors="errors?.type"/>

              </div>
              <div class='col-span-12 xl:col-span-6 xl:col-start-1'>

                <BaseShowEditInput
                    :label="$t('attributes.name')"
                    v-model="asset.name"
                    :errors="errors.name"
                    :placeholder="$t('attributes.name')"
                    :editionMode="editionMode"
                    :required="true" />

              </div>
              <div class='col-span-12 xl:col-span-6 xl:col-start-1'>

                <BaseShowEditInput
                    v-if="metadata?.formInputs && metadata.formInputs?.nb_places?.display && metadata?.formInputs?.nb_places.visible?.type?.includes( asset.type )"
                    :label="$t('attributes.nb_place')"
                    v-model="asset.nb_places"
                    :errors="errors.nb_place"
                    :placeholder="$t('attributes.nb_place')"
                    :editionMode="editionMode"
                    :required="true" />
              </div>

              <div
                  class='col-span-12 xl:col-span-6 xl:col-start-1'>

                <div class="flex flex-row items-start mt-2 span-2">
                  <BaseSimpleShowEditCheckboxGroup
                                            name='event_kinds'
                                           :label="$t('attributes.event_kinds')"
                                           :options='metadata.event_kinds && metadata.event_kinds?.filter(item=> item.asset_type.includes(asset.type))  '
                                           :errors='errors.event_kinds'
                                           v-model="asset.event_kinds"
                                           trackBy="id"
                                           :translatable="false"
                                           :editionMode="editionMode"/>
                </div>
              </div>
            </div>

            <div class='flex justify-end'>
              <BaseButton
                          v-if="editionMode"
                          :isElevated='true'
                          icon='paper-plane'
                          :title='$t(this.$route.name === "settings_create_asset_route" ? "button.add" : "button.edit")'
                          @click='submitForm' />
            </div>

          </template>
        </BaseCardSection>

        <AssetTimeSlotIndexComponent v-if="this.$route.name !== 'settings_create_asset_route'" :editionMode="editionMode"/>

        <AssetTimeSlotFormComponent v-if="this.$route.name !== 'settings_create_asset_route' && editionMode" :editionMode="editionMode"/>
      </div>

    </template>

  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import AssetTimeSlotFormComponent from './components/AssetTimeSlotFormComponent'
import AssetTimeSlotIndexComponent from './components/AssetTimeSlotIndexComponent'

export default {
  name: 'TheAssetFormPage',
  components: {
    AssetTimeSlotFormComponent,
    AssetTimeSlotIndexComponent,
  },
  data(){
    return {
      editionMode:false,
    }
  },
  computed: {
    ...mapGetters({
      asset: 'asset/getSelectedAsset',
      metadata: 'asset/getAssetMetadata',
      errors: 'auth/getErrors'
    }),
    buttonText() {
      return 'button.add_new_m'
    },
    pageTitle() {
      return this.asset.name
    }
  },
  methods: {
    ...mapActions({
      fetchAssetByID: 'asset/fetchAssetByID',
      updateAsset: 'asset/updateAsset',
      postNewAsset: 'asset/storeNewAsset',
      fetchNewAsset: 'asset/fetchNewAsset'
    }),
    getAssetAndChangeEditionMode() {
      this.editionMode ? this.fetchAssetByID(this.$route.params.id) : {}
      this.editionMode = !this.editionMode
    },
    getInputValue({attribute, value}) {
      if(attribute === 'type'){
        this.asset.event_kinds = []
      }
      this.asset[attribute] = value
    },
    submitForm() {
      if (this.$route.name === 'settings_create_asset_route') {
        this.asset['implantation_id'] = this.$route.params.id
        this.postNewAsset({
          asset:this.asset,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.created_f', {var: this.$t('global.asset')}) , {className: 'toastify-content success'})
            this.$router.push({name: 'settings_edit_asset', params: {id: response.data.asset.id}})
          }
        })
      } else {
        this.updateAsset({
          id: this.$route.params.id,
          asset: this.asset,
          successCallback: () => {
            this.$h.toastify( this.$t('toasts.updated_f', {var: this.$t('global.asset')}) , {className: 'toastify-content success'})
            this.editionMode = false
          }
        })
      }
    },
  },
  async beforeMount() {
    this.$route.name === 'settings_create_asset_route' ? (this.fetchNewAsset(), this.editionMode = true ): this.fetchAssetByID(this.$route.params.id)
  }
}
</script>
