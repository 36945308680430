import apiClient from '@u/apiClient'

export default {
    //region fetch
    async fetchNotificationList({commit}) {
        await apiClient.get('api/fetch-notifications-settings')
            .then(async response => {
                commit('setNotificationSettingsList', response.data.settings)
                commit('setNotificationMetadata', response.data.metadata)
            })
            .catch(async reason => {
                console.error('fetchNotificationList', reason)
            })
    },
    //endregion

    //region update
    async updateNotificationsSettings({commit}, payload) {
        console.log(payload)
        await apiClient.put(`api/update-notifications-settings`, payload.notificationSettings)
            .then(async response => {
                if (response.status === 200) {
                    commit('setNotificationSettingsList', response.data.settings)
                    commit('setNotificationMetadata', response.data.metadata)
                    if (payload.successCallback) {
                        payload.successCallback()
                    }
                }
            })
            .catch(async reason => {
                console.error('updateNotificationsSettings', reason)
            })
    },
    //endregion

}
