<template>
  <div class='grid grid-cols-2  gap-x-5' v-if="worksEventOfTypeBur">
    <div>
      <BaseShowEditSelectSimple v-if="metadata"
                                :label="$t('attributes.inhumation_type')"
                                :placeholder="$t('attributes.inhumation_type')"
                                name="inhumation_type"
                                :editionMode='editionMode'
                                v-model="worksEventOfTypeBur.inhumation_type"
                                :defaultValue="metadata?.inhumation_type?.filter(el => el.id === worksEventOfTypeBur.dispersion_type)"
                                :options="metadata?.inhumation_type"
                                :errors="errors?.inhumation_type"/>
    </div>
    <div>
      <div v-if="worksEventOfTypeBur.inhumation_type === 18" class='col-start-2'>
        <BaseShowEditInput
          v-if="work?.events"
          :label="$t('attributes.other_resource')"
          :placeholder="$t('attributes.other_resource')"
          v-model="worksEventOfTypeBur.operator_infos"
          :editionMode="editionMode"
          :required="true"
        />
      </div>
      <div v-else class='col-start-2'>
        <BaseShowEditSelectAjax v-if="worksEventOfTypeBur" :key='worksEventOfTypeBur'
                                :editionMode='editionMode'
                                url='api/cemetery-for-select'
                                :label="$t('attributes.cemetery')"
                                v-model='worksEventOfTypeBur.cemetery_id'
                                name='cemetery_id'
                                trackBy='id'
                                attributeLabel='title'
                                :defaultAjax="cemeteryBaseValue"/>
      </div>
    </div>

    <RecovererForm :editionMode='editionMode' />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import RecovererForm from './RecovererForm'

export default {
  name: 'SelectBurForm',
  components:{
    RecovererForm,
  },
  props: {
    editionMode: {type: Boolean, required: true},
  },
  computed: {
    ...mapGetters({
      work: 'work/getWork',
      metadata: 'work/getWorkMetadata',
      errors: 'auth/getErrors',
      worksEventOfTypeBur: 'work/getBurEventInWork',
    }),
    cemeteryBaseValue(){
      if (this.worksEventOfTypeBur.cemetery) {
        return { id: this.worksEventOfTypeBur.cemetery_id, title: this.worksEventOfTypeBur.cemetery.nom_formated}
      } else {
        return {}
      }
    },
  },
}
</script>
