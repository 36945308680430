<template >
  <div class="box p-5">
    <div class='flex flex-row'>
      <span class='flex items-center mr-2'>
        <span class="w-2 h-2 rounded-full self-center" :class='buttletClass'>&nbsp;</span >
      </span >
      <h1 class="text-lg font-medium flex line-clamp-1"> {{ eventTitleFormated }}</h1 >
    </div >
    <div class="flex items-center mt-1 space-x-2" v-if="event.date_from && event.type !== 'ASR'">
      <font-awesome-icon :icon="['fal', 'calendar-minus']" fixed-width />
      <div ><span >{{ event.date_from }}</span ><span v-if="event.type === 'CON' && event.date_to"> > {{
          event.date_to
        }}</span ></div >
    </div >
    <div class="flex items-center mt-1 space-x-2" v-if="event.date_to && event.type === 'ASR'">
      <font-awesome-icon :icon="['fal', 'calendar-minus']" fixed-width />
      <div ><span > {{ event.date_to }}</span ></div >
    </div >
    <div class="flex items-center mt-1 space-x-2"
         v-if="event.type !== 'CON' && event.type !== 'ASR' && event.time_from && event.time_to"
    >
      <font-awesome-icon :icon="['fal', 'clock']" fixed-width />
      <div ><span >{{ event.time_from }}</span > <span v-if="event.type !== 'CAR' && event.type !== 'ASR'"
      >> {{ event.time_to }}</span ></div >
    </div >
    <div class="flex items-center mt-1 space-x-2" v-if="event.asset_id && event.type !== 'CRE'">
      <font-awesome-icon :icon="['fal', 'map-marker-alt']" fixed-width />
      <div >{{ assetName }}</div >
    </div >
  </div >
</template >

<script >
import { mapGetters } from "vuex";

export default {
  name: 'WorkPreviewEvent',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({ metadata: 'work/getWorkMetadata' }),
    buttletClass() {
      return 'bg-theme-' + this.event.type
    },
    eventTitleFormated() {
      return (this.event.type ? this.$t(`enums.event.type.${ this.event.type }`) : this.$t('global.new_event'))
    },
    assetName() {
      if (this.event.asset_id !== null) {
        return this.metadata && this.metadata.events && this.metadata.events.assets.filter(asset => {
          return asset.id === this.event.asset_id
        })[0].name
      }
      return ''
    }
  },
}
</script >
