<template>
  <div class="intro-x dropdown">

    <a href="#" class="dropdown-toggle flex-shrink-0 w-full group block" role="button" aria-expanded="false">
      <div class="flex items-center">
        <div>
          <div class='w-8 h-8 rounded-full bg-white flex items-center justify-center' v-if="user.first_name && user.last_name">{{ user.first_name.charAt(0).toUpperCase() + user.last_name.charAt(0).toUpperCase() }}</div>
        </div>
        <div class="ml-3 hidden xl:block">
          <p class="text-sm font-medium text-white" v-show='!collapsedMenu'>
            {{ user.full_name }}
          </p>
          <p class="text-xs font-medium text-indigo-200 group-hover:text-white hidden">
            View profile
          </p>
        </div>
      </div>
    </a>

    <div class="dropdown-menu w-56">
      <div class="dropdown-menu__content box dark:bg-dark-6">

        <div class="p-4 border-b border-black border-opacity-5 dark:border-dark-3">
          <div class="font-medium">{{ user.full_name }}</div>
          <div class="text-xs text-gray-600 mt-0.5 dark:text-gray-600">
            {{ user.email }}
          </div>
          <div class="text-xs text-gray-600 mt-0.5 dark:text-gray-600">
           {{ $t('global.version')}}: {{ packageVersion }}
          </div>

        </div>

        <div class="p-2">

          <router-link :to="{name: 'profile_form_route'}" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md capitalize-first">
            <font-awesome-icon :icon='["fal","user"]' class='mr-2' />
            <div class='inline-block capitalize-first'>{{ $t(`global.my_profile`) }}</div>
          </router-link>

        </div>

        <div class="p-2 border-t border-black border-opacity-5 dark:border-dark-3 flex space-x-3 justify-center">

          <button class='btn btn-sm btn-default' :class='currentLocal === "nl_BE" ? "btn-primary" : "btn-default" ' @click.prevent='$i18n.locale = "nl_BE"'>
            Nederland
          </button>
          <button class='btn btn-sm btn-default' :class='currentLocal === "fr_BE" ? "btn-primary" : "btn-default" ' @click.prevent='$i18n.locale = "fr_BE"'>
            Francais
          </button>

        </div>

        <div class="p-2 border-t border-black border-opacity-5 dark:border-dark-3 flex flex-col space-x-3">

          <h2 class='flex items-center block p-2 capitalize-first'>
            <font-awesome-icon :icon='["fal","swatchbook"]' class='mr-2' />
            <span class='inline-block capitalize-first'>thèmes</span>
          </h2>
          <div class='grid grid-cols-6 gap-2'>
              <span class='w-4 h-4 rounded-md bg-gradient-to-br from-theme-red to-theme-red-darker cursor-pointer' @click='setTheme()'></span>
              <span class='w-4 h-4 rounded-md bg-gradient-to-br from-theme-green to-theme-green-darker cursor-pointer' @click='setTheme("theme-green")'></span>
              <span class='w-4 h-4 rounded-md bg-gradient-to-br from-theme-blue to-theme-blue-darker cursor-pointer' @click='setTheme("theme-blue")'></span>
              <span class='w-4 h-4 rounded-md bg-gradient-to-br from-theme-orange to-theme-orange-darker cursor-pointer' @click='setTheme("theme-orange")'></span>
          </div>

        </div>

        <div class="p-2 border-t border-black border-opacity-5 dark:border-dark-3">

          <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md" @click.prevent='refreshAuthHandler'>
            <font-awesome-icon :icon='["fal","sync-alt"]' class='mr-2' />
            DEBUG: refresh auth
          </a>

          <TheDarkModeSwitcher2 />

        </div>
        <div class="p-2 border-t border-black border-opacity-5 dark:border-dark-3">
          <a href="" class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-dark-3 rounded-md" @click.prevent="handleLogout">
            <font-awesome-icon :icon='["fal","sign-out"]' class='mr-2' />
            Logout
          </a>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import TheDarkModeSwitcher2 from '@c/layouts/dark-mode-switcher/TheDarkModeSwitcher2'
import { mapGetters } from 'vuex'
import cash from 'cash-dom/dist/cash'
export default {
  name: 'InfoUserModalComponent',
  components: { TheDarkModeSwitcher2 },
  computed: {
    ...mapGetters({user: 'auth/getAuth', packageVersion: 'settings/getPackageVersion'}),
    ...mapGetters('sideMenu', ['collapsedMenu']),
    currentLocal(){
      return this.$i18n.locale
    },
  },
  methods: {
    refreshAuthHandler() {
      this.$store.dispatch('auth/refreshAuth')
    },
    async handleLogout() {
      await this.$store.dispatch('auth/logout')
    },
    setTheme: function(theme = null){
      this.$h.setTheme( theme )
    }
  },
  mounted() {
  }
}
</script>
