<template>
  <hr class="work_section">
  <BaseCardSection :sectionTitle="$t('global.anatomicalPieces')">
    <template #default>
      <div class='grid grid-cols-12 gap-x-5'>
        <div class='col-span-6 xl:col-span-6 '>
          <BaseShowEditInput :label="$t('attributes.num_cerfa')"
                             v-model="work.num_cerfa"
                             :errors="errors.num_cerfa "
                             :placeholder="$t('attributes.num_cerfa')"
                             :required='true'
                             :editionMode="editionMode"
          />
        </div>
        <div class='col-span-6 xl:col-span-6'>

          <BaseShowEditSelectSimple v-if="metadata?.customers"
                                    :label="$t('attributes.transporter')"
                                    :placeholder="$t('attributes.transporter')"
                                    name="transporter"
                                    :editionMode='editionMode'
                                    v-model='work.transporter'
                                    :defaultValue="metadata?.customers.filter(el => el.id === work.transporter)"
                                    :options="metadata?.customers"
                                    :required='true'
                                    :errors="errors.transporter"/>
        </div>
        <div class='col-span-6 xl:col-span-6'>
          <BaseShowEditDatePicker :editionMode='editionMode'
                                  :label="$t('attributes.delivery_date')"
                                  :required='true'
                                  v-model='work.delivery_date'
                                  name='delivery_date'
                                  :errors='errors.delivery_date ? errors.delivery_date : []'/>
        </div>
        <div class='col-span-12 flex justify-end mt-3' v-if="editionMode">
          <BaseButton color="warning"
                      :isoutline="true"
                      icon="plus"
                      @click="handleAddBoxes"
                      :tooltip="$t('button.refresh')"
                      :title="null"/>
        </div>
        <div class='col-span-12 xl:col-span-12 mt-3 mb-0'>
        <BaseEditLabel :label="$t('attributes.anatomic_boxes')" :required="false"/>
        </div>
        <div class='col-span-12 mt-3' v-if="editionMode">
          <div v-for='(error,index) in errors.anatomic_boxes' :key='index' class="form-help text-red-600">
            {{ $t(error, {attribute: $t('attributes.' + name)}) }}
          </div>
        </div>
        <template v-for="(num,index) in work.anatomic_boxes">
          <div class='col-span-6 xl:col-span-4'>
            <BaseShowEditInputButton
              v-model="num.box_number"
              :key="index"
              :errors="errors.anatomic_boxes ? errors.anatomic_boxes[index] : []"
              :placeholder="$t('attributes.box_number')"
              :required='true'
              :editionMode="editionMode"
              button-icon="times"
              buttonColor="danger"
              :buttonFunction="()=>handleRemoveBoxButtonClick(index)"
            />
          </div>
        </template>
      </div>

    </template>
  </BaseCardSection>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: "WorkFormAnatomicalPieces",
  
  props: {
    editionMode: {
      type: Boolean,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      metadata: 'work/getWorkMetadata',
      work: 'work/getWork',
      settings: 'settings/getSystemSettings',
      errors: 'auth/getErrors'
    }),
  },
  methods: {
    handleAddBoxes() {
      console.log('handleAddBoxes',this.work.anatomic_boxes)
      console.log('this.work.anatomic_boxes',typeof this.work.anatomic_boxes)
      if (!('anatomic_boxes' in this.work) ||  this.work.anatomic_boxes == null ) {
        console.log('in if')
        this.work.anatomic_boxes = []
      }
      this.work.anatomic_boxes.push({})
      console.log('af§er',this.work.anatomic_boxes)
    },
    handleRemoveBoxButtonClick(index) {
      this.work.anatomic_boxes.splice(index, 1)
    }
  },
}
</script>

