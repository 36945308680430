import apiClient from '@u/apiClient'

let base_errors = {deceased:{},events:[]}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}
export default {

  //#region store

  async storeWorkTask({ commit }, obj) {
    commit('auth/setErrors', base_errors,  {root: true})
    await apiClient.post(`api/work-task`, obj.event)
      .then(async response => {
        if (response.status === 201) {
          if (obj.successCallback) {
            obj.successCallback()
          }
        }
      })
      .catch(async reason => {
        await commit('setErrors', reason)
      })
  },

  //#endregion

  //#region update

  async updateWorkTask({ commit }, payload) {
    console.log(payload)
    commit('auth/setErrors', base_errors,  {root: true})
    await apiClient.put(`api/work-task/${payload.id}`, payload.task)
      .then(async response => {
        if (response.status === 200) {
          commit('updateWorkTaskInWork', response.data.workTask );
          commit('setSelectedWorkTask', response.data.workTask );
          if (payload.successCallback) {
            payload.successCallback()

          }
        }
      })
      .catch(async reason => {
        await commit('setErrors', reason)
      })
  },

  async updateWorkTaskStatus({ commit, state }, payload) {
    commit('auth/setErrors',base_errors,  {root: true})
    await apiClient.get(`api/work-task/update-status/${payload.id}?new_status=${payload.new_status}`,)
      .then(response => {

        if(response.status === 200) {
          processSuccessCallback(payload)
          commit('setWork',response.data.work)
          if( state.selectedWorkTask ){
            if( state.selectedWorkTask.parent_type === 'App\\Models\\Work' ){
              commit('setSelectedWorkTask', response.data.work.work_tasks.find( work_task => work_task.id === state.selectedWorkTask.id ))
            }
            else if( state.selectedWorkTask.parent_type === 'App\\Models\\Event' ){
              commit('setSelectedWorkTask', response.data.work.events.find( event => event.id === state.selectedWorkTask.parent_id )?.work_tasks.find( work_task => work_task.id === state.selectedWorkTask.id ))
            }
          }
        }

      })
      .catch( reason => {
        console.error('updateWorkTaskStatus', reason)
      })
  },

  //#endregion

}
