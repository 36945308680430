import apiClient from '@u/apiClient'
import router from "../../../../router";

let base_errors = {};
function processSuccessCallback(payload) {
    if (payload.successCallback) {
        payload.successCallback()
    }
}

export default {

    //region fetch
    async fetchAssetIndex({commit}) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get('api/asset')
            .then(async response => {
                await commit('setAssetList', response.data)
            })
            .catch(reason => {
                console.error('storeNewImplantation', reason)
            })
    },
    async fetchAssetByID({commit}, id) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/asset/${id}`)
            .then(async response => {
                await commit('setAsset', response.data.asset)
                await commit('setAssetMetadata', response.data.metadata)
            })
            .catch(reason => {
                console.error('storeNewImplantation', reason)
            })
    },
    async fetchNewAsset({commit}) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get('api/model/new?model=asset')
            .then(async response => {
                await commit('setAsset', response.data.asset)
                await commit('setAssetMetadata', response.data.metadata)
            })
            .catch(reason => {
                console.error('storeNewImplantation', reason)
            })
    },
    async fetchAssetActivitiesByAssetID({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/asset/fetch-activities/${payload.asset_id}`)
            .then(async response => {
                await commit('setAssetActivity', response.data.activities)
            })
            .catch(async reason => {
                await console.error(reason)
            })
    },
    //endregion

    //region store
    async storeNewAsset({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.post('api/asset', payload.asset)
            .then(async response => {
                if (response.status === 200) {
                    commit('setAsset', response.data.asset)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('storeNewAsset', reason)
            })
    },
    async storeTimeSlot({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.put(`api/asset/add-timeslot/${payload.id}`, payload.timeSlot)
            .then(async response => {
                await commit('setAsset', response.data.data)
                processSuccessCallback(payload);
            })
            .catch(async reason => {
                console.error('storeTimeSlot', reason)
            })
    },

    //endregion

    //region update
    async updateAsset({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.put(`api/asset/${payload.id}`, payload.asset)
            .then(async response => {
                if (response.status === 200) {
                    commit('setAsset', response.data.asset)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('updateAsset', reason)
            })
    },
    //endregion

    //region delete
    async deleteAssetById({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.delete(`api/asset/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setAssetList', response.data)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('deleteAssetById', reason)
            })
    },
    async deleteTimeslotById({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.delete(`api/timeslot/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setAsset', response.data.asset)
                    await commit('setAssetMetadata', response.data.metadata)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('deleteTimeslotById', reason)
            })
    },
    //endregion

    //region undoDelete
    async undoDeleteAssetById({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/asset/undo-delete/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setAssetList', response.data)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('undoDeleteAssetById', reason)
            })
    },
    async undoDeleteTimeslotById({commit}, payload) {
        commit('auth/setErrors', base_errors, {root: true})
        await apiClient.get(`api/timeslot/undo-delete/${payload.id}`)
            .then(async response => {
                if (response.status === 200) {
                    await commit('setAsset', response.data.asset)
                    await commit('setAssetMetadata', response.data.metadata)
                    processSuccessCallback(payload);
                }
            })
            .catch(async reason => {
                console.error('undoDeleteTimeslotById', reason)
            })
    },
    //endregion
}
