<template>
  <SettingsPageFull>
    <template #title>
      <div class='flex space-x-3'>
        <font-awesome-icon :icon="['fal','tasks']" size='2x'/>
        <span class='text-2xl font-bold capitalize-first'>{{ pageTitle }}</span>
      </div>
    </template>

    <template #cta>
      <div class='flex gap-x-3 fixed top-24 right-20 z-100 '>
        <BaseButton
          v-if="editionMode"
          :icon="buttonIcon"
          :title="buttonTitle"
          @click="submitForm"
        />
        <BaseButton
          v-if="this.$route.name === 'settings_edit_tasks_route' && !permission"
          color="warning"
          :icon="editionMode ? 'times' : 'pen'"
          @click="getTaskAndChangeEditionMode"
          :title="editionMode ? $t('button.cancel') : $t('button.edit')"
        />
        <BaseButton color='dark'
                    icon='long-arrow-left'
                    :title="$t('button.back')"
                    @click="this.$router.go(-1)"
        />
      </div>
    </template>

    <template #body>
      <div class='grid grid-cols-12 gap-x-5'>
        <div class='col-span-12 xl:col-span-6'>
          <BaseShowEditRadioGroup
            :label="$t('attributes.parent_type')"
            :key='task.parent_type'
            name="parent_type"
            :options="metadata?.parent_types"
            :required="true"
            trackBy='id'
            :errors="errors.parent_type"
            v-model="task.parent_type"
            translationExtention="global"
            :translatable="true"
            :editionMode="editionMode"
          />
        </div>
        <div v-if='isParentTypeEventSelected' class='col-span-12 xl:col-span-6'>

          <BaseEventTypeShowEditSelectSimple
            v-if="metadata"
            v-model='task.event_type'
            :metadata='metadata'
            :errors='errors.event_type'
            :edition-mode='editionMode'
          />

        </div>
        <div v-else class='col-span-12 xl:col-span-6'>
        </div>
        <div class='col-span-12 xl:col-span-6'>

          <BaseShowEditInput :label="$t('attributes.name')"
                             v-model="task.name"
                             :errors="errors.name"
                             :placeholder="$t('attributes.name')"
                             :editionMode="editionMode"
                             :required="true"
          />

        </div>
        <div class='col-span-12 xl:col-span-6'>

          <BaseShowEditInput :label="$t('attributes.short_name')"
                             v-model="task.short_name"
                             :errors="errors.short_name"
                             :placeholder="$t('attributes.short_name')"
                             :editionMode="editionMode"
                             :required="true"
          />

        </div>
        <div class='col-span-12 xl:col-span-6'>

          <BaseShowEditSelectSimple v-if="metadata?.group_tasks"
                                    :label="$t('attributes.group_tasks')"
                                    :placeholder="$t('attributes.group_tasks')"
                                    name="group"
                                    :editionMode='editionMode'
                                    v-model='task.group'
                                    :defaultValue="metadata?.group_tasks?.filter(el => el.id === task.group)"
                                    :options="metadata?.group_tasks"
                                    :errors="errors?.group"
          />

        </div>
        <div class='col-span-12 xl:col-span-6'>

<!--          <BaseUserShowEditSelectSimple-->
<!--            v-if="metadata"-->
<!--            v-model='task.responsable_id'-->
<!--            :label="$t('attributes.responsable')"-->
<!--            :metadata='metadata'-->
<!--            :errors='errors.responsable_id'-->
<!--            :required="true"-->
<!--            :edition-mode='editionMode'-->
<!--          />-->
          <BaseShowEditFilteredUsersComponent
            v-if="metadata"
            :label="$t('attributes.responsable')"
            v-model='task.responsable_id'
            :editionMode='editionMode'
            name='responsable_id'
            :metadata='metadata'
            :errors='errors?.responsable_id'
          />
        </div>
        <div class='col-span-12 xl:col-span-6'>

          <BaseShowEditInput :label="$t('attributes.default_duration')"
                             v-model="task.default_duration"
                             type='number'
                             parseType='int'
                             :min="0"
                             :max="999"
                             :errors="errors.default_duration"
                             :placeholder="$t('attributes.default_duration')"
                             :editionMode="editionMode"
                             :required="true"
          />

        </div>
        <div class='col-span-12 xl:col-span-6'>
          <BaseShowEditRadioGroup
            v-if='metadata?.priorities'
            :label="$t('attributes.task_priority')"
            :key='task.parent_type'
            name='task_priority'
            :options='metadata?.priorities'
            :required="true"
            trackBy='id'
            :errors="errors.task_priority"
            v-model="task.task_priority"
            :translatable="false"
            :editionMode="editionMode"
            :isNumber="true"
          />

        </div>
        <div class='col-span-12 xl:col-span-6'>
          <BaseShowEditRadioGroup
            v-if='metadata?.statusTypes'
            name='simplified_status'
            :label="$t('attributes.status')"
            :key='task.parent_type'
            :options='metadata?.statusTypes'
            :required="true"
            trackBy='id'
            :translatable="false"
            :errors="errors.task_priority"
            v-model="task.simplified_status"
            :editionMode="editionMode"
            :isNumber="true"
          />
        </div>

        <div class='col-span-12 xl:col-span-6'>

        </div>
        <div class='col-span-12 xl:col-span-6 mb-5'>

          <BaseShowEditTextArea :label="$t('attributes.description')"
                                :placeholder="$t('attributes.description')"
                                v-model="task.description"
                                :errors="errors.description"
                                :editionMode="editionMode"
          />

        </div>
      </div>

      <div class="mt-10 grid grid-cols-1 gap-y-10">
        <TaskQuestionComponent
          v-if='task.simplified_status !== 1'
          :label="$t('attributes.start_task_survey')"
          :permission='permission'
          :checkBoxValue='Boolean(task.form_on_start)'
          :model='task.starting_form ? task.starting_form : forms.starting_form'
          :editionMode="editionMode"
          :errors-form-start="errors_form_start"
          :errors-option-start="errors_option_start"
        />

        <TaskQuestionComponent
          :label="$t('attributes.end_task_survey')"
          :permission='permission'
          :checkBoxValue='Boolean(task.form_on_end)'
          :model='task.ending_form ? task.ending_form : forms.ending_form'
          :editionMode="editionMode"
          :errors-form-end="errors_form_end"
          :errors-option-end="errors_option_end"
        />
      </div>

<!--      <div class='flex-1 flex flex-row justify-end mt-10'>-->
<!--        <BaseButton-->
<!--          v-if="editionMode"-->
<!--          :icon="buttonIcon"-->
<!--          :title="buttonTitle"-->
<!--          @click="submitForm"-->
<!--        />-->
<!--      </div>-->
    </template>
  </SettingsPageFull>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

import TaskQuestionComponent from './components/TaskQuestionComponent'
import BaseShowEditFilteredUsersComponent from "@c/GlobalFormComponents/BaseShowEditFilterdUsersComponent.vue";

export default {
  name: 'TheTaskFormPage',
  components: {
    BaseShowEditFilteredUsersComponent,
    TaskQuestionComponent,
  },
  data() {
    return {
      forms: {
        starting_form: [],
        ending_form: [],
      },
      editionMode: false,
      errors_form_start: false,
      errors_form_end: false,
      errors_option_start: false,
      errors_option_end: false
    }
  },
  computed: {
    ...mapGetters({task: 'task/getTask', metadata: 'task/getTaskMetadata', errors: 'auth/getErrors'}),
    permission() {
      return !this.$can('manage_content')
    },
    pageTitle() {
      return this.$route.name === 'settings_edit_tasks_route' ? this.$t('navigation.content_task_edit_title') : this.$t('navigation.content_task_new_title')
    },
    buttonTitle() {
      return this.$route.name === 'settings_edit_tasks_route' ? this.$t('button.edit_new_f', {var: this.$t('global.task')}) : this.$t('button.add_new_f', {var: this.$t('global.task')})
    },
    buttonIcon() {
      return this.$route.name === 'settings_edit_tasks_route' ? 'paper-plane' : 'plus'
    },
    isParentTypeEventSelected() {
      return this.task.parent_type === `App\\Models\\Event`
    }
  },
  methods: {
    ...mapActions({
      fetchNewTask: 'task/fetchNewTask',
      fetchTaskById: 'task/fetchTaskById',
      postTask: 'task/storeTask',
      updateTask: 'task/updateTask'
    }),
    ...mapMutations({
      alterTask: 'task/alterTask'
    }),
    getTaskAndChangeEditionMode() {
      this.editionMode ? this.fetchTaskById({id: this.$route.params.id}) : {}
      this.editionMode = !this.editionMode
    },
    getInputValue({attribute, value}) {
      console.log(attribute)
      console.log(value)
      if (attribute === 'simplified_status' && value === 1) {
        this.alterTask({attribute: 'form_on_start', value: false})
      }
      this.alterTask({attribute: attribute, value: value})
    },
    submitForm() {
      if (!Boolean(this.task.form_on_start)) {
        this.forms.starting_form = []
        this.alterTask({attribute: 'starting_form', value: null})
      }
      if (!Boolean(this.task.form_on_end)) {
        this.forms.ending_form = []
        this.alterTask({attribute: 'ending_form', value: null})
      }
      if (!this.task.starting_form && this.forms.starting_form !== []) {
        this.alterTask({attribute: 'starting_form', value: this.forms.starting_form})
      }
      if (!this.task.ending_form && this.forms.ending_form !== []) {
        this.alterTask({attribute: 'ending_form', value: this.forms.ending_form})
      }
      if (this.task.starting_form.length > 0 || this.task.ending_form.length > 0) {
        this.task.starting_form.forEach(form_field => {
          if (['radio', 'select'].includes(form_field.type)) {
            form_field.options.forEach(value => {
              !value.name || value.name.trim() === '' ? this.errors_option_start = true : this.errors_option_start = false
            })
            form_field.options.length < 2 ? this.errors_form_start = true : this.errors_form_start = false
          }
        })
        this.task.ending_form.forEach(form_field => {
          if (['radio', 'select'].includes(form_field.type)) {
            form_field.options.forEach(value => {
              !value.name || value.name.trim() === '' ? this.errors_option_end = true : this.errors_option_end = false
            })
            form_field.options.length < 2 ? this.errors_form_end = true : this.errors_form_end = false
          }
        })
      }
      if (!this.errors_form_start && !this.errors_option_end && !this.errors_form_end && !this.errors_option_start) {
        this.$route.name === 'settings_edit_tasks_route' ?
          this.updateTask({
            id: this.$route.params.id,
            task: this.task,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.updated_f', {var: this.$t('global.task')}), {className: 'toastify-content success'})
              this.editionMode = false
            }
          })
          : this.postTask({
            task: this.task,
            successCallback: () => {
              this.$h.toastify(this.$t('toasts.created_f', {var: this.$t('global.task')}), {className: 'toastify-content success'})
              this.$router.push({name: 'settings_list_tasks_route'})
            }
          })
      }
    },
  },
  beforeMount() {
    if (this.$route.name === 'settings_edit_tasks_route') {
      this.fetchTaskById({id: this.$route.params.id})
    } else {
      this.fetchNewTask()
      this.editionMode = true
    }
  }
}
</script>

