import apiClient from '@u/apiClient'
import router from '@/router'
import store from '@/store'

import WorkTaskActions from './work_task/actions'
import WorkAttachmentsActions from './work_attachments/actions'

let base_errors = {deceased:{},events:[]}
function processSuccessCallback(payload) {
  if (payload.successCallback) {
    payload.successCallback()
  }
}
export default {

  ...WorkTaskActions,
  ...WorkAttachmentsActions,

  //#region fetch

  async fetchWorkList({ commit }) {
    await apiClient.get('api/work')
      .then(async response => {
        await commit('setWorkList', response.data.works)
        await commit('setWorkMetadata', response.data.metadata )
      }).catch(async reason => {
        await console.error('fetchWorkList', reason)
      })
  },

  async fetchArchivedWorkList({ commit }, payload) {
    await apiClient.get(`api/work/?status=${payload.status}`)
      .then(async response => {
        await commit('setWorkList', response.data.works)
        await commit('setWorkMetadata', response.data.metadata )
      }).catch(async reason =>{
        await console.error('fetchArchivedWorkList', reason)
      })
  },

  async fetchWorkByID({ commit }, id) {
    commit('auth/setErrors', base_errors, {root: true} )
    await apiClient.get(`api/work/${id}`)
      .then(async response => {
        if(response.status === 200){
          await commit('setWork',response.data.work )
          await commit('setWorkMetadata', response.data.metadata )
        await commit('auth/setErrors', base_errors, {root: true})
        }
      })
      .catch(async reason =>{
        await console.error('fetchWorkByID', reason)
      })
  },

  async fetchNewWork({ commit }) {
    commit('setWork', { deceased: {}, work_items: []})
    commit('auth/setErrors',base_errors, {root: true})
    await apiClient.get('api/model/new?model=work')
      .then( async response => {
       await commit('auth/setErrors', base_errors, {root: true})
       await commit('setWork', {...response.data.work, deceased: {}, events: []})
       await commit('setWorkMetadata', response.data.metadata)
       await commit('replaceContact')
      })
      .catch(async reason =>{
        await console.error('fetchNewWork', reason)
      })
  },

  async fetchWorkActivities({commit}, payload){
    commit('setWorkActivities', [])
    await apiClient.get(`api/work/fetch-activities/${payload.work_id}`)
      .then(async response => {
        commit('setWorkActivities', response.data.activities)
      }).catch(async reason => {
        console.error('fetchWorkActivities', reason)
      })
  },
  //#endregion

  //#region store

  async storeNewWork({ commit }, payload) {
    commit('auth/setErrors',base_errors, {root: true})
    await apiClient.post('api/work', payload.form_data,{ headers: { withCredentials: true } })
      .then(response => {
        if(response.status === 201){
          if(store.getters.getAuthRole === 'customer-user'){
            processSuccessCallback(payload)
            router.push({ name: 'work_form_edit_route', params: {id: response.data.data.id }})
          }else{
            processSuccessCallback(payload)
            router.push({ name: 'work_form_event_list_route', params: {id: response.data.data.id }})
          }
        }
      })
      .catch(reason => {
        console.error('storeNewWork', reason)
      })
  },

  //#endregion

  //#region update

  async updateWorkStatus({ commit }, payload) {
    commit('auth/setErrors', base_errors, {root: true})
    await apiClient.put(`api/work/${payload.work_id}/status`, payload.form_data )
      .then( response => {
        if(response.status === 200) {
          commit('auth/setErrors', {}, {root: true})
          commit('setWork',response.data.data )
          processSuccessCallback(payload)
        }
      }).catch( reason => {
        if( payload.errorCallback ){
          payload.errorCallback()
        }
      })
  },

  async updateWork({ commit }, payload) {
    commit('auth/setErrors',base_errors, {root: true})
    await apiClient.put(`api/work/${payload.form_data.id}`, payload.form_data.work)
      .then(async response => {
      if(response.status === 200) {
        processSuccessCallback(payload)
        await commit('setWork',response.data.work )
      }
    }).catch( reason => {
      console.error('updateWork', reason)
    })
  },

  //#endregion

  //#region delete
  async deleteWorkById({commit}, payload) {
    await apiClient.delete(`api/work/${payload.work_id}`).then(async response => {
      if(response.status  === 200 ){
        processSuccessCallback(payload)
        await commit('setWorkList', response.data.works)
      }
    }).catch( reason => {
      console.error('deleteWorkById', reason)
    })
  },

  async undoDeleteWorkById({commit}, payload) {
    await apiClient.get(`api/work/undo-delete/${payload.work_id}`).then(async response => {
      if(response.status  === 200 ){
        processSuccessCallback(payload)
        await commit('setWorkList', response.data.works)
      }
    }).catch( reason => {
      console.error('undoDeleteWorkById', reason)
    })
  },

  //#endregion

}
