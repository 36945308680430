<template>

  <div >
    <div v-if='no_timeslot' class='text-theme-21 font-medium leading-none mt-3 my-5'>
      {{ $t('validation.there_is_no_for_f', {settings: $t('attributes.timeslot'), container: $t('global.week')}) }}
    </div>
    <div class='grid grid-cols-2' >
      <div class='col-start-1 flex flex-row mb-2 mt-5'>
        <BaseButton color='secondary' icon='chevron-left' :tooltip="$t('global.previous_week')" @click="getPreviousWeekTimeslots"/>
        <BaseButton class='ml-1' color='secondary' icon='chevron-right' :tooltip="$t('global.next_week')" @click="getNextWeekTimeslots"/>
        <BaseButton class='ml-3' color='secondary' :title="$t('global.today')" @click="getCurrentWeekTimeslots"/>
      </div>

      <div class='col-start-2 mt-5'>
        <p class='text-xl font-medium leading-none mt-3'> {{ cDateRange }} </p>
      </div>
    </div>
  </div>

  <RadioGroup v-model='modelValue'>
    <div class='grid grid-cols-3 gap-2'>
      <template v-for='(disp, index) in options'>
        <RadioGroupOption
          as='template'
          :value="disp"
          v-slot='{ active, checked }'>
          <div
            :class="[
                    active ? 'ring-2 ring-offset-2 ring-offset-blue-300 ring-white ring-opacity-60' : '',
                    checked ? 'bg-blue-500 text-white ' : 'bg-gray-300 text-white ',]"
            class='relative col-span-1 flex px-2 py-1 rounded-md shadow-md cursor-pointer focus:outline-none'>
            <div class='flex items-center w-full'>
              <RadioGroupLabel as='div'
                               :class="checked ? 'text-white' : 'text-gray-900'"
                               class='font-medium flex flex-1'>
                <div class='flex-1 capitalize-first select-none'>{{ formatTitle( disp.date ) }}</div>
                <div class='flex-shrink-0 select-none'>{{ disp.string_from }}</div>
              </RadioGroupLabel>
            </div>
          </div>
        </RadioGroupOption>
      </template>
    </div>
  </RadioGroup>
</template>

<script>
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption
} from '@headlessui/vue'
import moment from 'moment'
import { mapActions } from 'vuex'

export default {
  name: 'SelectEventTimeslot',
  emits: ['update:modelValue'],
  props: [
    'modelValue',
    'selectedType',
    'type',
    'asset',
    'eventKind',
    'options',
    'index',
  ],
  data() {
    return {
      no_timeslot:false,

      currentWeekNumber: null,
      weekDelta: 0,
    }
  },
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupDescription,
    RadioGroupOption
  },
  computed: {
    cDateRange(){
      if(this.options && this.options.length === 0 ) {
        this.no_timeslot = true
        return '';
      }
      else{
        this.no_timeslot = false
      }
      return this.options && this.options.length?
        `${moment(this.options[0].date && this.options[0].date).lang('fr').format('dddd D')} -
        ${moment(this.options[this.options.length -1].date && this.options[this.options.length -1].date).lang('fr').format('dddd D MMMM YYYY')}`
        : ''
    }
  },
  methods: {
    ...mapActions({fetchEventDisponbilitiesByOption: 'fetchEventDisponbilitiesByOption' }),
    formatTitle(date) {
      return moment(date).locale('fr').format('dddd D MMM')
    },
    fetchTimeslotsByDate(date){
      this.fetchEventDisponbilitiesByOption({ params: {
          asset_id: this.asset,
          type: this.type,
          week_from: date,
          year: moment().format('YYYY'),
          event_kind_id: this.eventKind
        }, index: this.index
      })
    },
    getPreviousWeekTimeslots(){
      this.weekDelta > 0 ? this.weekDelta-- : {}
      !this.currentWeekNumber ? this.currentWeekNumber = moment().locale('fr').week() : {}
      this.weekDelta >= 0 ? this.fetchTimeslotsByDate(this.currentWeekNumber + this.weekDelta) : {}

    },
    getNextWeekTimeslots(){
      this.weekDelta++

      !this.currentWeekNumber ? this.currentWeekNumber = moment().locale('fr').week() : {}
      this.weekDelta > 0 ? this.fetchTimeslotsByDate(this.currentWeekNumber + this.weekDelta) : {}
    },
    getCurrentWeekTimeslots(){
      this.weekDelta = 0


      !this.currentWeekNumber ? this.currentWeekNumber = moment().locale('fr').week() : {}
      this.fetchTimeslotsByDate(this.currentWeekNumber)
    }
  },
  watch: {
    isOpen(newValue){
      this.getCurrentWeekTimeslots()
    },
    modelValue(newValue) {
      if(newValue !== null && newValue !== undefined){
        this.$emit('update:modelValue', newValue);
      }
    }
  },
}
</script>

<style scoped>

</style>
