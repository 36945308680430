<template>
  <div class='flex justify-end mb-3 mr-2'>
    <BaseButton v-if="$can('manage_invoicing')" icon="plus" @click="newCheckDeposit" :title="$t('button.create_m', {var: $t('global.check_deposit')})"/>
  </div>

  <BaseContentTable
    v-if="metadata.columns"
    :columns="metadata.columns"
    :modelList="checkDepositList"
    :search="true"
    @rowClick="redirectToModelPage"
    @refreshClick="this.fetchCheckDepositList()"
    @deleteModel="deleteModelById"
    :isHoverable="true"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CheckDepositIndex',
  computed:{
    ...mapGetters({
      checkDepositList: 'invoicing/getCheckDepositList',
      metadata: 'invoicing/getCheckMetadata'
    }),
  },
  methods:{
    ...mapActions({
      fetchCheckDepositList: 'invoicing/fetchCheckDepositList'
    }),
    redirectToModelPage(id){
      this.$router.push({name: 'check_deposit_form_route', params: { id: id}})
    },
    newCheckDeposit(){
      this.$router.push({name: 'check_deposit_form_route', params: { id: 'new'}})
    },
    deleteModelById(){
    }
  },
  beforeMount() {
    this.fetchCheckDepositList()
  }
}
</script>

<style scoped>
</style>
