<template>
  <div>
    <h2 class='text-xl font-bold capitalize-first mb-3'>{{ $t('global.events') }}</h2>
    <ul class='grid grid-cols-2 gap-2 lg:grid-cols-3'>
      <li
        v-for='item in eventTypeList'
        class='col-span-1 flex'
      >
        <a
          href='#'
          :class="Array.isArray( dEventType ) && dEventType.includes( item.id ) ? 'bg-blue-500 text-white' : ''"
          class='flex-1 bg-gray-300 text-black rounded-md px-2 py-1 capitalize-first'
          @click.prevent='mEventClick(item)'
        >{{ item.name }}</a>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'FilterPlanningEventType',
  props: {
    eventType: Array,
    eventTypeList: Array
  },
  data() {
    return {
      dEventType: this.eventType
    }
  },
  emits: ['update:eventType'],
  methods: {
    mEventClick(item) {
      let index = this.dEventType.indexOf(item.id)
      index === -1 ? this.dEventType.push(item.id) : this.dEventType.splice(index, 1);
      this.$emit('update:eventType', this.dEventType );
      localStorage.setItem('planning-filter-event-type', JSON.stringify(this.dEventType))
    }
  }
}
</script>
